import { Routes } from '@angular/router';
import { TranscodeContainersComponent } from './pages/transcode-containers/transcode-containers.component';
import { TranscodeTypesComponent } from './pages/transcode-types/transcode-types.component';

export enum TRANSCODE_BASE_ROUTES {
  ROOT = 'transcode',
  CONTAINERS = 'containers',
  TYPES = 'types',
}

export const TRANSCODE_BASE_ROUTING: Routes = [
  {
    path: '',
    redirectTo: `${TRANSCODE_BASE_ROUTES.CONTAINERS}`,
    pathMatch: 'full',
  },
  {
    path: TRANSCODE_BASE_ROUTES.CONTAINERS,
    component: TranscodeContainersComponent,
  },
  {
    path: TRANSCODE_BASE_ROUTES.TYPES,
    component: TranscodeTypesComponent,
  },
  {
    path: '**',
    redirectTo: `${TRANSCODE_BASE_ROUTES.CONTAINERS}`,
    pathMatch: 'full',
  },
];
