import { Component, OnInit, inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule, UIFormModule, UILayoutModule, BreadcrumbsButtonConfig } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import {
  take,
  EMPTY,
  Observable,
  shareReplay,
  takeUntil,
  combineLatest,
  startWith,
  switchMap,
  Subject,
  tap,
} from 'rxjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  SeparatorType,
  Separator,
  GetFilenameConvention,
  AssetTypeEnum,
  DefaultForTypes,
  DefaultForType,
  FilenameConventionsService,
  DefaultForTypesForMetadataRecognition,
} from '@vdms-hq/api-contract';
import { SelectOption, Loadable, Destroyable, castTo, capitalize } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { MetadataRecognitionService } from '../../logic/metadata-recognition.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { WithPermissions, ActivatedClientModule, ActivatedClientService, Permission } from '@vdms-hq/activated-client';
import { MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { FilenameConventionsHelpDialogComponent } from '../../components/filename-conventions-help-dialog/filename-conventions-help-dialog.component';
import { MatTooltip } from '@angular/material/tooltip';

type FormGroupType<T> = {
  [K in keyof T]: FormControl<T[K]>;
};

type FilenameConventionFormGroup = FormGroupType<Omit<GetFilenameConvention, 'uuid' | 'default_for'>> & {
  uuid: FormControl<GetFilenameConvention['uuid'] | null | undefined>;
  default_for: FormArray<FormGroup>;
};

interface DefaultForForm {
  [key: string]: FormArray<FormGroup<FileConventionControl>>;
}

type FileConventionControl = {
  assetTypes: FormControl<string[]>;
  conventionUuid: FormControl<string>;
  initialUuid: FormControl<string>;
  initialAssetTypes: FormControl<string[]>;
};

@Component({
  selector: 'vdms-hq-filename-conventions',
  templateUrl: './filename-conventions.component.html',
  styleUrls: ['./filename-conventions.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    UILayoutModule,
    TranslateModule,
    UIButtonModule,
    UIFormModule,
    ActivatedClientModule,
    MatTabGroup,
    MatTab,
    MatTabContent,
    MatTooltip,
  ],
})
export class FilenameConventionsComponent extends Loadable(Destroyable(WithPermissions())) implements OnInit {
  private activatedClient = inject(ActivatedClientService);
  private metadataRecognitionService = inject(MetadataRecognitionService);
  private toastService = inject(ToastService);
  private filenameConventionsService = inject(FilenameConventionsService);
  private cdr = inject(ChangeDetectorRef);
  private dialog = inject(MatDialog);

  protected readonly capitalize = capitalize;

  $FormArray = castTo<FormArray>();
  $FormGroup = castTo<FormGroup>();

  form = new FormGroup({
    conventions: new FormArray<FormGroup>([]),
  });

  private refreshConventions$ = new Subject<void>();
  conventions$ = this.refreshConventions$.pipe(
    startWith(null),
    switchMap(() => this.metadataRecognitionService.filenameConventions$),
    shareReplay(1),
  );

  canEdit$ = this.activatedClient.permissions$.pipe(
    map((permissions) => permissions.includes(Permission.EDIT_FILENAME_CONVENTIONS)),
    tap((canEdit) => {
      if (!canEdit) {
        this.form.controls.conventions.disable();
        this.defaultForForm.disable();
        return;
      }
    }),
  );

  canDelete$ = this.activatedClient.permissions$.pipe(
    map((permissions) => permissions.includes(Permission.DELETE_FILENAME_CONVENTIONS)),
  );

  canSave$ = combineLatest([this.canEdit$, this.form.statusChanges.pipe(startWith(this.form.status))]).pipe(
    map((canEdit) => canEdit && this.form.dirty && this.form.valid),
  );

  extWarning$ = this.form.controls.conventions.valueChanges.pipe(
    map((values) => {
      const extRegex = new RegExp('\\.[A-Za-z0-9]{3,5}$');
      return values.map((v) => extRegex.test(v.template));
    }),
  );

  defaultForForm = new FormGroup<DefaultForForm>({});

  canSaveDefaultFor$ = combineLatest([
    this.canEdit$,
    this.defaultForForm.valueChanges.pipe(startWith(this.defaultForForm.value)),
  ]).pipe(
    map(([canEdit, value]) => {
      const controls = this.multipleDefaultForType.map((type) => this.defaultForForm.get(type.key));
      const dirty = controls.some((control) => control?.dirty);
      return canEdit && dirty;
    }),
  );

  separatorSelectOptions: SelectOption<SeparatorType>[] = [
    { key: Separator.NONE, label: 'none' },
    { key: Separator.HYPHEN, label: 'hyphen' },
    { key: Separator.SPACE, label: 'space' },
    { key: Separator.UNDERSCORE, label: 'underscore' },
  ];

  assetTypesSelectOptions: SelectOption[] = Object.values(AssetTypeEnum).map((assetType) => ({
    key: assetType,
    label: assetType,
  }));

  conventionsSelectOptions$: Observable<SelectOption[]> = this.conventions$.pipe(
    map((conventions) =>
      conventions.map((convention) => ({ key: convention.uuid, label: convention.name }) as SelectOption),
    ),
    map((conventions) => [{ key: null, label: 'None' }, ...conventions]),
    shareReplay(1),
  );

  defaultForTypes = DefaultForTypes.map((type) => ({ key: type, label: type.replace('-', ' ') }));

  singleDefaultForType = this.defaultForTypes.filter(
    (type) => !DefaultForTypesForMetadataRecognition.includes(type.key),
  );
  multipleDefaultForType = this.defaultForTypes.filter((type) =>
    DefaultForTypesForMetadataRecognition.includes(type.key),
  );
  breadcrumbsButtonConfig: BreadcrumbsButtonConfig = {
    color: 'transparent',
    iconOnly: true,
    icon: 'tips_and_updates',
    iconStyle: 'outlined',
    tooltip: 'filename-conventions.breadcrumbs.tooltip',
  };

  get conventionsFormArray() {
    return this.form.controls.conventions;
  }

  ngOnInit() {
    this.#buildDefaultForForm();
    this.#listenToDefaultForForm();

    this.conventions$.pipe(take(1)).subscribe((filenameConventions) => {
      filenameConventions.forEach((convention) => {
        this.addToConventionsForm(convention);
        this.addToDefaultForForm(convention);
      });
      this.form.markAsPristine();
      this.form.markAsUntouched();
    });
  }

  addToConventionsForm(convention?: GetFilenameConvention) {
    if (!convention) {
      this.conventionsFormArray.insert(
        0,
        new FormGroup<FilenameConventionFormGroup>({
          uuid: new FormControl<GetFilenameConvention['uuid'] | null | undefined>(null),
          name: new FormControl('', { validators: [Validators.required], nonNullable: true }),
          template: new FormControl('', { validators: [Validators.required], nonNullable: true }),
          separator: new FormControl('none', { validators: [Validators.required], nonNullable: true }),
          default_for: new FormArray<FormGroup>([]),
        }),
      );
      return;
    }

    const defaultValue = convention.default_for.map((defaultFor) => {
      return new FormGroup({
        asset_type: new FormControl<string>(defaultFor.asset_type),
        default_for_type: new FormControl<DefaultForType>(defaultFor.default_for_type),
      });
    });

    this.conventionsFormArray.insert(
      0,
      new FormGroup<FilenameConventionFormGroup>({
        uuid: new FormControl<GetFilenameConvention['uuid'] | null | undefined>(convention?.uuid),
        name: new FormControl(convention?.name ?? '', { validators: [Validators.required], nonNullable: true }),
        template: new FormControl(convention?.template ?? '', { validators: [Validators.required], nonNullable: true }),
        separator: new FormControl(convention?.separator ?? 'none', {
          validators: [Validators.required],
          nonNullable: true,
        }),
        default_for: new FormArray<FormGroup>(defaultValue),
      }),
    );

    this.cdr.detectChanges();
  }

  addToDefaultForForm(convention?: GetFilenameConvention) {
    if (!convention) {
      return;
    }

    const defaultFor = convention.default_for;

    defaultFor.forEach((defaultFor) => {
      if ((defaultFor.asset_type as string) === 'subtitle') {
        defaultFor.asset_type = 'subtitles';
      }

      if (!DefaultForTypesForMetadataRecognition.includes(defaultFor.default_for_type)) {
        const control = this.defaultForForm.get(defaultFor.default_for_type) as FormArray;
        if (!control) {
          return;
        }

        const assetTypeControl = control.controls.find((control) => control.value.assetType === defaultFor.asset_type);

        if (!assetTypeControl) {
          return;
        }

        assetTypeControl.patchValue({
          conventionUuid: convention.uuid,
          initialUuid: convention.uuid,
          initialAssetTypes: [],
        });
      }

      if (DefaultForTypesForMetadataRecognition.includes(defaultFor.default_for_type)) {
        const control = this.defaultForForm.get(defaultFor.default_for_type) as FormArray;
        if (!control) {
          return;
        }

        const conventionControl = control.controls.find((control) => control.value.conventionUuid === convention.uuid);

        const assetTypes = convention.default_for
          .filter((defaultFor) => DefaultForTypesForMetadataRecognition.includes(defaultFor.default_for_type))
          .map((defaultFor) => defaultFor.asset_type);

        if (!conventionControl) {
          control.push(
            new FormGroup({
              assetTypes: new FormControl<string[]>(assetTypes, { validators: [Validators.required] }),
              conventionUuid: new FormControl<string | null>(convention.uuid, { validators: [Validators.required] }),
              initialUuid: new FormControl<string | null>(convention.uuid),
              initialAssetTypes: new FormControl<string[]>(assetTypes),
            }),
          );
          return;
        }

        conventionControl.patchValue({
          assetTypes,
          conventionUuid: convention.uuid,
          initialUuid: convention.uuid,
          initialAssetTypes: assetTypes,
        });
      }
    });
    this.cdr.detectChanges();
  }

  remove(index: number) {
    const uuid: string = this.conventionsFormArray.at(index).value.uuid;

    if (!uuid) {
      this.conventionsFormArray.removeAt(index);
      return;
    }

    this.startLoading();

    this.metadataRecognitionService
      .delete(uuid)
      .pipe(
        take(1),
        catchError((error) => {
          this.toastService.error({ id: 'metadata_recognition', message: 'notifications.delete.error' });
          this.stopLoading();
          return error;
        }),
      )
      .subscribe(() => {
        this.conventionsFormArray.removeAt(index);
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.toastService.success({ id: 'metadata_recognition', message: 'notifications.delete.done' });
        this.stopLoading();
        this.refreshConventions$.next();
      });
  }

  removeDefaultFor(index: number, type: string, refresh = false) {
    const form = this.defaultForForm.get(type) as FormArray;
    const value = form.controls[index].value;
    if (refresh) {
      form.controls[index].patchValue({
        assetTypes: value.initialAssetTypes,
        conventionUuid: value.initialUuid,
        initialUuid: value.initialUuid,
        initialAssetTypes: value.initialAssetTypes,
      });
      form.controls[index].markAsPristine();
      return;
    }

    const uuid = value.initialUuid;
    if (!uuid) {
      form.removeAt(index);
      return;
    }
    const assetTypes: AssetTypeEnum[] =
      value.assetTypes && value.assetTypes.length > 0
        ? value.assetTypes
        : value.assetType
          ? [value.assetType]
          : value.initialAssetTypes;
    const allAssetTypes = Object.values(AssetTypeEnum);

    const payload = {
      add: [] as any[],
      remove: [] as any[],
    };

    allAssetTypes.forEach((assetType: AssetTypeEnum) => {
      if (assetTypes.includes(assetType)) {
        payload.remove.push({ asset_type: assetType === 'subtitles' ? 'subtitle' : assetType, default_for_type: type });
      }
    });

    this.startLoading();
    this.filenameConventionsService
      .editDefaultFor(uuid, payload)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.toastService.success({ id: 'metadata_recognition', message: 'notifications.update.done' });
          form.removeAt(index);

          this.stopLoading();
          this.defaultForForm.markAsPristine();
        },
        error: () => {
          this.stopLoading();
        },
      });
  }

  save(index = 0) {
    if (this.conventionsFormArray.invalid || this.isLoading) {
      return;
    }

    this.startLoading();

    const payload = this.conventionsFormArray.at(index).value;
    let request$;

    if (payload.uuid) {
      request$ = this.#update(payload);
    } else {
      request$ = this.#create(payload);
    }

    request$
      .pipe(
        take(1),
        catchError((error) => {
          this.form.controls.conventions.at(index).setErrors({ server: error.error.data });
          this.toastService.error({ id: 'metadata_recognition', message: error.error.data });
          return EMPTY;
        }),
        finalize(() => {
          this.stopLoading();
        }),
      )
      .subscribe(() => {
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.form.updateValueAndValidity();
        this.toastService.success({ id: 'metadata_recognition', message: 'notifications.update.done' });
        this.stopLoading();
        this.refreshConventions$.next();
      });
  }

  saveDefaultFor(control: any, type: string) {
    const value = control.value;
    let saveDefaultFor$;

    if (!value) {
      return;
    }

    if (this.#isSingleDefaultForType(type)) {
      saveDefaultFor$ = this.#saveSingleDefaultFor(control.value, type);
    } else {
      saveDefaultFor$ = this.#saveMultipleDefaultFor(control.value, type);
    }

    this.startLoading();
    saveDefaultFor$.pipe(take(1)).subscribe({
      next: () => {
        this.toastService.success({ id: 'metadata_recognition', message: 'notifications.update.done' });
        this.stopLoading();
        const form = this.defaultForForm.get(type) as FormArray;

        if (!form) {
          return;
        }

        const controlIndex = form.controls.findIndex(
          (control) => control.value.conventionUuid === value.conventionUuid,
        );

        form.controls[controlIndex].patchValue({
          initialUuid: value.conventionUuid,
          initialAssetTypes: value.assetTypes,
        });
        form.controls[controlIndex].markAsPristine();
      },
      error: () => {
        this.stopLoading();
      },
    });
  }

  appendEmptyDefaultFor(controlName: string) {
    const control = this.defaultForForm.get(controlName) as FormArray;
    if (!control) {
      return;
    }

    const emptyControl = control.controls.find(
      (control) => !control.value.assetTypes.length || !control.value.conventionUuid,
    );

    if (!emptyControl) {
      control.push(
        new FormGroup({
          assetTypes: new FormControl<string[]>([], { validators: [Validators.required] }),
          conventionUuid: new FormControl<string | null>(null, { validators: [Validators.required] }),
          initialUuid: new FormControl<string | null>(null),
        }),
      );
    }

    const emptyControlIndex = control.controls.findIndex(
      (control) => !control.value.assetTypes.length || !control.value.conventionUuid,
    );

    if (emptyControlIndex !== control.controls.length - 1) {
      const emptyControl = control.controls.splice(emptyControlIndex, 1);
      control.push(emptyControl[0]);
    }
  }

  #create(payload: GetFilenameConvention) {
    return this.metadataRecognitionService.create(payload).pipe(take(1));
  }

  #update(payload: GetFilenameConvention) {
    return this.metadataRecognitionService.update(payload.uuid, payload).pipe(take(1));
  }

  #buildDefaultForForm() {
    const assetTypes = Object.values(AssetTypeEnum);
    this.singleDefaultForType.forEach((type) => {
      this.defaultForForm.addControl(type.key, new FormArray([] as FormGroup<FileConventionControl>[]));
      const control = this.defaultForForm.get(type.key) as FormArray;

      assetTypes.forEach((assetType) => {
        control.push(
          new FormGroup({
            assetType: new FormControl<AssetTypeEnum>(assetType),
            conventionUuid: new FormControl<string | null>(null),
            initialUuid: new FormControl<string | null>(null),
          }),
        );
      });
    });

    this.multipleDefaultForType.forEach((type) => {
      this.defaultForForm.addControl(type.key, new FormArray([] as FormGroup<FileConventionControl>[]));
    });
  }

  #listenToDefaultForForm() {
    this.singleDefaultForType.forEach((type) => {
      const control = this.defaultForForm.get(type.key) as FormArray;

      if (!control) {
        return;
      }

      control.controls.forEach((control) => {
        control.valueChanges.pipe(takeUntil(this.isDestroyed$)).subscribe(() => {
          if (control.touched && control.dirty) {
            control.markAsUntouched();
            control.markAsPristine();
            this.saveDefaultFor(control, type.key);
          }
        });
      });
    });
  }

  #isSingleDefaultForType(type: string) {
    return this.singleDefaultForType.some((singleType) => singleType.key === type);
  }

  #removeSingleDefaultFor(value: any, type: string) {
    if (!value) {
      return EMPTY;
    }

    const uuid = value.initialUuid;
    const assetType = value.assetType;

    if (!assetType) {
      return EMPTY;
    }

    return this.filenameConventionsService.editDefaultFor(uuid, {
      remove: [{ asset_type: assetType, default_for_type: type }],
    });
  }

  #saveMultipleDefaultFor(value: any, type: string) {
    if (!value) {
      return EMPTY;
    }

    const uuid = value.conventionUuid || value.initialUuid;
    const assetTypes: AssetTypeEnum[] = value.assetTypes ?? [value.assetType];
    const initialAssetTypes: AssetTypeEnum[] = value.initialAssetTypes ?? [];
    const allAssetTypes = Object.values(AssetTypeEnum);

    const backendMisspellingFix = (value: string) => {
      if (value !== 'subtitles') {
        return value;
      }
      return 'subtitle';
    };

    if (!assetTypes.length) {
      return EMPTY;
    }

    const payload = {
      add: [] as any[],
      remove: [] as any[],
    };

    const addedAssetTypes = assetTypes.filter((assetType) => !initialAssetTypes.includes(assetType));
    const removedAssetTypes = initialAssetTypes.filter((assetType) => !assetTypes.includes(assetType));

    allAssetTypes.forEach((assetType: AssetTypeEnum) => {
      if (removedAssetTypes.includes(assetType)) {
        payload.remove.push({ asset_type: backendMisspellingFix(assetType), default_for_type: type });
      }

      if (addedAssetTypes.includes(assetType)) {
        payload.add.push({ asset_type: backendMisspellingFix(assetType), default_for_type: type });
      }
    });
    return this.filenameConventionsService.editDefaultFor(uuid, payload);
  }

  #saveSingleDefaultFor(value: any, type: string) {
    if (!value) {
      return EMPTY;
    }

    if (value.conventionUuid === null) {
      return this.#removeSingleDefaultFor(value, type);
    }

    const mappedValue = this.#mapToMultipleDefaultFor(value);
    return this.#saveMultipleDefaultFor(mappedValue, type);
  }

  #mapToMultipleDefaultFor(value: any) {
    if (!value) {
      return;
    }

    return { assetTypes: [value.assetType], conventionUuid: value.conventionUuid, initialUuid: value.initialUuid };
  }

  handleBreadcrumbsAction(event: void) {
    this.dialog.open(FilenameConventionsHelpDialogComponent);
  }
}
