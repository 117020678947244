import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReplaceWhenExceedsPipe, SharedModule } from '@vdms-hq/shared';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationsDatasource } from '../../logic/notifications.datasource';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { NotificationsButtonOverlayComponent } from '../notifications-button-overlay/notifications-button-overlay.component';
import { MatBadgeModule } from '@angular/material/badge';
import { BehaviorSubject, take, tap } from 'rxjs';

@Component({
  selector: 'vdms-hq-notifications-button',
  templateUrl: './notifications-button.component.html',
  styleUrls: ['./notifications-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    SharedModule,
    TranslateModule,
    MatTooltipModule,
    RouterLink,
    OverlayModule,
    MatBadgeModule,
    ReplaceWhenExceedsPipe,
  ],
  providers: [],
})
export class NotificationsButtonComponent implements OnInit {
  #datasource = inject(NotificationsDatasource);
  #overlay = inject(Overlay);
  #router = inject(Router);

  total$ = new BehaviorSubject<number>(0);
  isLoading$ = new BehaviorSubject<boolean>(true);
  overlayActive$ = new BehaviorSubject(false);

  ngOnInit() {
    this.#datasource.unreadNotifications$.subscribe(({ total }) => {
      this.total$.next(total);
      this.isLoading$.next(false);
    });
  }

  displayOverlay() {
    const target = document.querySelector('.trigger--button') as HTMLElement;
    const overlayRef = this.#overlay.create({
      disposeOnNavigation: true,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: 'mat-elevation-z8',
      positionStrategy: this.#overlay
        .position()
        .flexibleConnectedTo(target)
        .withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
          },
        ]),
    });
    const component = new ComponentPortal(NotificationsButtonOverlayComponent);
    const componentRef = overlayRef.attach(component);
    this.overlayActive$.next(true);
    overlayRef.backdropClick().subscribe(() => overlayRef.detach());
    this.#router.events
      .pipe(
        tap(() => overlayRef.detach()),
        take(1),
      )
      .subscribe();

    overlayRef.detachments().subscribe(() => {
      this.overlayActive$.next(false);
    });
  }
}
