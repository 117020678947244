import { Component, inject, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UIButtonModule,
  UIDialogWrapperModule,
  UiProcessViewComponent,
  ProcessView,
  UIFormModule,
  UiFieldsetComponent,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { CombinedStatus, OrderService } from '@vdms-hq/api-contract';
import { FormControl } from '@angular/forms';
import { FlatOrderViewModel } from '../../../results/logic/order-results-ds';
import { CommonModule } from '@angular/common';
import { camelCaseToWords, capitalize, dashedToCapitalizedString, underscoreToString } from '@vdms-hq/shared';
import { MatDivider } from '@angular/material/divider';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { MatAccordion, MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { MatTooltip } from '@angular/material/tooltip';
import { GetTypeIconPipe } from '../../../../../../ui/src/lib/ui-pipes/pipes/get-type-icon.pipe';

@Component({
  selector: 'vdms-hq-order-status-dialog',
  standalone: true,
  imports: [
    UIDialogWrapperModule,
    TranslateModule,
    UIButtonModule,
    UiProcessViewComponent,
    UIFormModule,
    CommonModule,
    MatDivider,
    MatTabGroup,
    MatTab,
    MatAccordion,
    MatExpansionModule,
    UiFieldsetComponent,
    MatTooltip,
    GetTypeIconPipe,
  ],
  viewProviders: [MatExpansionPanel],
  templateUrl: 'order-status-dialog.component.html',
  styleUrls: ['order-status-dialog.component.scss'],
})
export class OrderStatusDialogComponent implements OnInit {
  private dialogRef = inject(MatDialogRef<OrderStatusDialogComponent>);
  private ordersService = inject(OrderService);
  protected data: {
    order: FlatOrderViewModel;
  } = inject(MAT_DIALOG_DATA);

  protected readonly camelCaseToWords = camelCaseToWords;
  protected readonly underscoreToString = underscoreToString;
  protected readonly dashedToCapitalizedString = dashedToCapitalizedString;
  protected readonly capitalize = capitalize;
  protected readonly Array = Array;
  protected readonly Object = Object;

  title = '';

  statusHide = new FormControl(false, { nonNullable: true });
  status$ = new BehaviorSubject(true);

  isLoading$ = new BehaviorSubject(false);
  statusData: ProcessView = {
    title: 'Status',
    list: [],
  };

  orderConfig = [
    { process: CombinedStatus.ERROR, color: 'failed' },
    { process: CombinedStatus.SCHEDULED, color: 'yellow-dashed' },
    { process: CombinedStatus.PROCESSING, color: 'orange' },
    { process: CombinedStatus.DELIVERING, color: 'orange' },
    { process: CombinedStatus.READY_FOR_DOWNLOAD, color: 'done-light' },
    { process: CombinedStatus.DOWNLOADED, color: 'orange' },
    { process: CombinedStatus.COMPLETE, color: 'done' },
  ];

  ngOnInit() {
    this.title = `Order: ${this.data.order.packageTitle}`;

    this.#getOrderStatusLogs(this.data.order.uuid);

    this.statusData.pill = {
      value: this.data.order.combinedOrderStatus,
      color:
        this.orderConfig.find((config) =>
          config.process.toLowerCase().includes(this.data.order.combinedOrderStatus.toLowerCase()),
        )?.color ?? 'orange',
    };
  }

  #getOrderStatusLogs(uuid: string) {
    this.isLoading$.next(true);
    this.ordersService.getOrderStatusLogs(uuid).subscribe({
      next: (history) => {
        history.statuses.forEach((status, index) => {
          const hasCompletedLog =
            history.logs.length > 0 &&
            history.logs.find(
              (log, index) =>
                log.status.toLowerCase().includes(status.toLowerCase()) && index < history.logs.length - 1,
            );
          const isLastHighlightedLog =
            history.logs.length > 0 &&
            history.logs[history.logs.length - 1].status.toLowerCase().includes(status.toLowerCase());
          const isBeforeLastHighlightedLog =
            history.statuses.findIndex(
              (status) =>
                history.logs.length > 0 &&
                history.logs[history.logs.length - 1].status.toLowerCase().includes(status.toLowerCase()),
            ) > index;
          this.statusData.list.push({
            state: isLastHighlightedLog
              ? 'highlighted'
              : hasCompletedLog || isBeforeLastHighlightedLog
                ? 'completed'
                : 'incomplete',
            line: isLastHighlightedLog || hasCompletedLog || isBeforeLastHighlightedLog ? 'highlighted' : 'default',
            icon: isLastHighlightedLog
              ? 'fiber_manual_record'
              : hasCompletedLog || isBeforeLastHighlightedLog
                ? 'check'
                : 'empty',
            title: this.data.order.combinedOrderStatus.toLowerCase().includes(status.toLowerCase())
              ? this.data.order.combinedOrderStatus
              : status,
            metadata: [],
            error: undefined,
          });
        });

        if (history.logs.length === 0) {
          this.statusData.list[0].state = 'highlighted';
          this.statusData.list[0].line = 'highlighted';
          this.statusData.list[0].icon = 'fiber_manual_record';
          this.statusData.list[0].metadata.push({
            label: 'Timestamp',
            value: 'N/A',
          });
        } else {
          history.logs.forEach((log, index, logs) => {
            const historyLogDataIndex = this.statusData.list.findIndex(
              (status) =>
                status.title.toLowerCase().includes(log.status.toLowerCase()) ||
                log.status.toLowerCase().includes(status.title.toLowerCase()),
            );

            if (historyLogDataIndex === -1) {
              if (index === logs.length - 1 && log.status === 'Error') {
                const previousLogStatusIndex = this.statusData.list.findIndex(
                  (status) => logs[index - 1].status === status.title,
                );
                this.statusData.list.splice(previousLogStatusIndex + 1, 0, {
                  state: 'highlighted',
                  line: 'highlighted',
                  icon: 'priority_high',
                  title: log.status,
                  metadata: [
                    {
                      label: 'Timestamp',
                      value: new Date(log.createdAt).toLocaleString(),
                    },
                  ],
                  error: log.errorMessage ? { message: log.errorMessage } : undefined,
                });

                this.statusData.list.slice(0, previousLogStatusIndex + 1).forEach((status) => {
                  status.state = 'completed';
                  status.line = 'highlighted';
                  status.icon = 'check';
                });
              } else {
                let nextLogStatusIndex = history.logs[index + 1]
                  ? this.statusData.list.findIndex((status) =>
                      status.title.toLowerCase().includes(history.logs[index + 1].status.toLowerCase()),
                    )
                  : -1;
                if (nextLogStatusIndex !== -1) {
                  this.statusData.list.splice(nextLogStatusIndex, 0, {
                    state: ['completed', 'highlighted'].includes(this.statusData.list[nextLogStatusIndex].state)
                      ? 'completed'
                      : 'incomplete',
                    line: this.statusData.list[nextLogStatusIndex].line === 'highlighted' ? 'highlighted' : 'default',
                    icon: ['completed', 'highlighted'].includes(this.statusData.list[nextLogStatusIndex].state)
                      ? 'check'
                      : 'empty',
                    title: log.status,
                    metadata: [
                      {
                        label: 'Timestamp',
                        value: new Date(log.createdAt).toLocaleString(),
                      },
                    ],
                    error: undefined,
                  });
                }
              }
            } else {
              this.statusData.list[historyLogDataIndex].metadata = [
                {
                  label: 'Timestamp',
                  value: new Date(log.createdAt).toLocaleString(),
                },
              ];
            }
          });
        }

        this.isLoading$.next(false);
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }

  toggleView() {
    this.status$.next(!this.statusHide.value);
  }

  close() {
    this.dialogRef.close();
  }
}
