import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { CurrentOrderService } from './current-order.service';

@Injectable({ providedIn: 'root' })
export class OrderResolverService implements Resolve<string | null> {
  private currentOrderService = inject(CurrentOrderService);

  resolve(route: ActivatedRouteSnapshot) {
    const orderId = route.paramMap.get('orderId') as string;

    if (!orderId) {
      this.currentOrderService.updateOrderId(null);
      return null;
    }

    this.currentOrderService.updateOrderId(orderId);

    return orderId;
  }
}
