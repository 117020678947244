import moment from 'moment/moment';

export const isEmbargoActive = (embargoed_to: string | undefined | null): boolean => {
  if (!embargoed_to) {
    return false;
  }

  const embargoedMoment = moment(embargoed_to).local();
  const now = moment();

  return now.isSameOrBefore(embargoedMoment);
};
