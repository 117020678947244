import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { UIButtonModule, UILoaderModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ActiveAssetActionService, AssetQuickPreviewFormService } from '@vdms-hq/asset-details';

@Component({
  standalone: true,
  selector: 'vdms-hq-asset-edit-form-footer',
  templateUrl: './asset-edit-form-footer.component.html',
  styleUrls: ['./asset-edit-form-footer.component.scss'],
  imports: [CommonModule, UILoaderModule, UIButtonModule, TranslateModule],
})
export class AssetEditFormFooterComponent {
  private activeAssetActionService = inject(ActiveAssetActionService);
  private assetQuickPreviewFormService = inject(AssetQuickPreviewFormService);
  isSaving$ = this.activeAssetActionService.isSaving$;
  formGroup = this.assetQuickPreviewFormService.formGroup;

  save(params: { withFinish: boolean; withLock?: boolean; newFormBuilder?: boolean }) {
    this.activeAssetActionService.save(params);
  }
}
