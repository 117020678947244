import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AUTH_CONFIG, AuthConfig } from '../config-token';
import { AuthService } from '../services/auth.service';
import { ToastService } from '@vdms-hq/toast';

@Injectable()
export class AuthOnlyGuard implements CanActivate {
  constructor(
    private state: AuthService,
    private router: Router,
    @Inject(AUTH_CONFIG) private env: AuthConfig,
    private readonly toastService: ToastService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.state.auth$.pipe(
      map((auth) => {
        if (!auth) {
          this.state.logout(state.url).then(() => {
            this.toastService.error({
              id: route.data?.permissions,
              // to avoid translation issues, we use the message directly
              message: route.data?.message ?? 'Please login to access this application',
            });
          });
          return false;
        }

        const operator = route.data?.operator ?? 'every';
        const permissions = route.data?.permissions ?? [];
        const clientsPermission = auth?.clientsPermissions ?? [];
        const hasPermissions = permissions[operator]((e: string) => clientsPermission.includes(e));
        if (!hasPermissions) {
          this.state.logout().then(() =>
            this.toastService.error({
              id: route.data?.permissions,
              message: route.data?.message ?? 'common.notifications.permission.no_app_access',
            }),
          );
          return false;
        }

        if (auth) {
          return true;
        }

        if (route.data?.redirectIfForbidden) {
          return this.router.parseUrl(route.data?.redirectIfForbidden);
        }

        if (this.env?.routing?.forUnauthorized) {
          const redirectTo = state.url !== this.env?.routing?.forUnauthorized && !state.url.startsWith('/error/');
          if (redirectTo) {
            const parsedUrl = this.router.parseUrl(state.url);
            const params = parsedUrl.queryParams;
            parsedUrl.queryParams = {};
            return this.router.createUrlTree([this.env.routing.forUnauthorized], {
              queryParams: {
                redirect: parsedUrl.toString(),
                ...params,
              },
            });
          }
          return this.router.parseUrl(this.env?.routing?.forUnauthorized);
        }

        return false;
      }),
    );
  }
}
