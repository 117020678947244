import { VideoCodec } from '@vdms-hq/api-contract';

export enum TRANSCODE_BASE_ENDPOINTS {
  ALL_CONTAINERS = 'transcode-containers',
  CONTAINER = 'transcode-container',
  ALL_TYPES = 'transcode-types',
  TYPE = 'transcode-type',
}
//CONTAINERS
export interface TranscodeContainer {
  uuid: string;
  kind: string;
  label: string;
}

export interface TranscodeContainerPostPatch {
  kind: string;
  label: string;
}
//TYPES
export interface TranscodeType {
  uuid: string;
  name: string;
  transcode_containers: TranscodeContainer[];
  video_codec_mappings: VideoCodec[];
}

export interface TranscodeTypePostPatch {
  name: string;
  container_uuids: string[];
  codec_mapping_uuids: string[];
}

export class TranscodeTypeViewModel implements TranscodeType {
  constructor(public type: TranscodeType) {}

  get name() {
    return this.type.name;
  }

  get uuid() {
    return this.type.uuid;
  }

  get transcode_containers() {
    return this.type.transcode_containers;
  }

  get video_codec_mappings() {
    return this.type.video_codec_mappings;
  }

  get containers() {
    return this.type.transcode_containers.map((container) => container.label);
  }

  get codecs() {
    return this.type.video_codec_mappings.map((codec) => codec.display_name);
  }

  static fromType(type: TranscodeType) {
    return new TranscodeTypeViewModel(type);
  }
}
