import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIButtonModule } from '../../../ui-button';

@Component({
  selector: 'vdms-hq-ui-drawer-container',
  templateUrl: './drawer-container.component.html',
  styleUrls: ['./drawer-container.component.scss'],
  standalone: true,
  imports: [MatMenuModule, TranslateModule, UIButtonModule, CommonModule],
})
export class DrawerContainerComponent {
  @Output() action = new EventEmitter<{ key: string }>();
  @Input() showClose = true;
}
