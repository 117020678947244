<vdms-hq-ui-dialog-wrapper size="wide">
  <div title class="flex-row justify-between w100">
    <h1 class="title">
      {{ title }}
    </h1>
  </div>
  <ng-container content>
    @switch (data.orderType) {
      @case ('workflow') {
        <ng-container *ngTemplateOutlet="aiProcessing"></ng-container>
      }
      @case ('delivery_destination') {
        <ng-container *ngTemplateOutlet="deliveryDestination"></ng-container>
      }
    }
  </ng-container>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">{{ 'common.global.close' | translate }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>

<ng-template #aiProcessing>
  <div class="details-wrapper">
    <vdms-hq-ui-process-view [process]="{ title: '', list: data.aiProcessingConfig ?? [] }"></vdms-hq-ui-process-view>
  </div>
</ng-template>

<ng-template #deliveryDestination>
  <div class="details-wrapper">
    <div class="delivery-destinations-details">
      <mat-tab-group class="material-custom-tab">
        @for (destination of data.deliveryDestinationsConfig; track destination.name) {
          <mat-tab>
            <ng-template mat-tab-label>
              <div class="tab-label">
                {{ destination.name }}
                @if (destination.anyConfigJobFailed) {
                  <mat-icon color="warn">priority_high</mat-icon>
                }
              </div>
            </ng-template>
            <mat-accordion [multi]="true">
              @for (config of destination.configs; track config.name) {
                <mat-expansion-panel [expanded]="config.anyJobFailed" #panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      ><h2>
                        Config: {{ config.name }}
                        @if (config.anyJobFailed && !panel.expanded) {
                          <mat-icon color="warn">priority_high</mat-icon>
                        }
                      </h2></mat-panel-title
                    >
                  </mat-expansion-panel-header>
                  <div class="config-details">
                    <vdms-hq-ui-process-view [process]="{ title: '', list: config.process }"></vdms-hq-ui-process-view>
                  </div>
                  <div class="config-actions">
                    <vdms-hq-ui-button
                      *requiresPermissions="[Permission.RETRY_DELIVERY_DESTINATIONS]"
                      [iconOnly]="true"
                      [icon]="'refresh'"
                      [size]="'small'"
                      [loading]="loading$ | async"
                      [tooltip]="'Retry config'"
                      (click)="retryDeliveryDestination(config)"
                      [disabled]="!(config | canRefreshDelivery)"
                    ></vdms-hq-ui-button>
                    @if (!data.hideDownload) {
                      <vdms-hq-ui-button
                        [iconOnly]="true"
                        [icon]="'download'"
                        [size]="'small'"
                        [tooltip]="'Download with Aspera'"
                        [disabled]="
                          !data.orderActive ||
                          data.isEmbargoActive ||
                          data.disableDownload ||
                          (config.jobs.length === 0 ? false : config.transcodeStatus !== 'completed')
                        "
                        (click)="download(config.uuid)"
                      ></vdms-hq-ui-button>
                      <vdms-hq-ui-button
                        [iconOnly]="true"
                        [size]="'small'"
                        [icon]="'download_for_offline'"
                        [tooltip]="'Download in browser'"
                        [disabled]="
                          !data.orderActive ||
                          data.isEmbargoActive ||
                          data.disableDownload ||
                          (config.jobs.length === 0 ? false : config.transcodeStatus !== 'completed') ||
                          !!(downloadInProgress$ | async)
                        "
                        (click)="downloadHTTPS(config.uuid)"
                      ></vdms-hq-ui-button>
                    }
                  </div>
                </mat-expansion-panel>
              }
            </mat-accordion>
          </mat-tab>
        }
      </mat-tab-group>
    </div>
  </div>
</ng-template>
