<vdms-hq-ui-layout-page-wrapper>
  <div class="adobe-auth">
    <div class="adobe-auth__column adobe-auth__column--background">
      <div class="adobe-auth__column__logo">
        <img src="/assets/common/logos/adobe.svg" alt="Adobe Logo" />
      </div>
    </div>
    <div class="adobe-auth__column adobe-auth__column--relative">
      @if (loading$ | async) {
        <vdms-hq-ui-loader [backdrop]="true" [mode]="'over-parent'"></vdms-hq-ui-loader>
      }
      <div class="adobe-auth__column__content">
        <div class="adobe-auth__column__content__form-window">
          <h1 class="adobe-auth__column__content__form-window--h1">Adobe authentication</h1>
          <h2 class="adobe-auth__column__content__form-window--h2">Paste this code in Adobe App</h2>
          @if (token$ | async; as token) {
            <div class="adobe-auth__column__content__form-window__inputs">
              <div class="adobe-auth__column__content__form-window__inputs__wrapper">
                @for (t of token; track t) {
                  <div class="adobe-auth__column__content__form-window__inputs--input">{{ t }}</div>
                }
              </div>
              @if ((error$ | async) === false) {
                <mat-icon (click)="copy(token)">content_copy</mat-icon>
              }
            </div>
            <div class="adobe-auth__column__content__form-window__time">
              @if (expiresAt) {
                <vdms-hq-ui-progress-status
                  [value]="(Number(expiresAt | countdown: true | async) / expiresAtAsSeconds) * 100"
                  [mode]="'determinate'"
                  [labelVisible]="false"
                ></vdms-hq-ui-progress-status>
                <span> {{ expiresAt | countdown | async }}</span>
              }
            </div>
          }
        </div>

        <div class="adobe-auth__column__content__socials">
          <div>
            <img ngSrc="/assets/common/icons/email.svg" alt="Email" height="15" width="15" />
            <a href="mailto:hello@vida.studio">{{ 'hello@vida.studio' }}</a>
          </div>
          <div>
            <img ngSrc="/assets/common/icons/calendar.svg" alt="Email" height="15" width="15" />
            <a href="https://www.vida.studio/">Book a meeting!</a>
          </div>
          <div>
            <img ngSrc="/assets/common/icons/linkedin.svg" alt="Email" height="15" width="15" />
            <a href="https://www.linkedin.com/company/vidastudio/">Stay in touch with us</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</vdms-hq-ui-layout-page-wrapper>
