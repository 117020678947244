import { Pipe, PipeTransform } from '@angular/core';
import objectPath from 'object-path';

@Pipe({
  name: 'objectPath',
  standalone: true,
})
export class ObjectPathPipe implements PipeTransform {
  value: string | undefined;

  transform(obj: Record<string, unknown>, path: string): unknown {
    this.value = objectPath.get(obj, path);
    return this.value;
  }
}
