@if (state$ | async; as state) {
  <vdms-hq-ui-menu
    [branding]="branding"
    [isSalesForceEnabled]="isSalesForceEnabled"
    [isExternalSupport]="isExternalSupport"
    [config]="config"
    [state]="state"
    [ngClass]="state"
    [tourConfig]="tourConfig"
    [version]="version"
    (mousemove)="onMouseOver(state)"
    (mouseleave)="onMouseLeave(state)"
  >
    <ng-content before select="[before]"></ng-content>
    <ng-content after select="[after]"></ng-content>
  </vdms-hq-ui-menu>
}
