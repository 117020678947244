import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { FieldsFetcherService, FilterDefinitionModel } from '@vdms-hq/fields';
import { UserContractService } from '@vdms-hq/firebase-contract';

type SchemaView = {
  id: string;
  name: string;
  type: string;
  config: FilterDefinitionModel[];
  isDefault?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class LibrarySchemaService {
  private activatedClientService = inject(ActivatedClientService);
  private userContractService = inject(UserContractService);
  private fieldsConfigService = inject(FieldsFetcherService);

  allUsersSchema$ = this.activatedClientService
    .forwardEmailAndClientId(this.userContractService.userLibraryConfig)
    .pipe(map((attributes) => attributes?.configs ?? []));

  allClientsSchema$ = this.activatedClientService.libraryConfigs$;

  allSchemas$: Observable<SchemaView[]> = combineLatest([
    this.allUsersSchema$,
    this.allClientsSchema$,
    this.fieldsConfigService.getConfiguration$('default'),
  ]).pipe(
    map(([userConfig, clientSchemas, fieldsConfig]) => {
      const allDefinitions = fieldsConfig.filters.getSystemWithAggregations();
      const next = [
        ...(clientSchemas ?? []).map((config) => ({
          id: config.id,
          name: config.name,
          type: 'client',
          config: (config?.structure ?? [])
            .map((id) => allDefinitions.find((item) => item.id === id))
            .filter((item) => !!item) as FilterDefinitionModel[],
        })),
        ...(userConfig ?? []).map((config) => ({
          id: config.id,
          name: config.name,
          type: 'user',
          config: (config?.structure ?? [])
            .map((id) => allDefinitions.find((item) => item.id === id))
            .filter((item) => !!item) as FilterDefinitionModel[],
        })),
      ];
      return next;
    }),
  );
  #activeSchemaIdSubject$ = new BehaviorSubject<string | null>(null);
  #activeSchemaId$ = this.#activeSchemaIdSubject$.asObservable();
  activeSchema$ = combineLatest([this.#activeSchemaId$, this.allSchemas$]).pipe(
    map(([id, all]) => all.find((schema) => schema.id === id)),
  );

  activeSchemaFields$ = this.activeSchema$.pipe(map((item) => item?.config ?? []));

  resolve(route: ActivatedRouteSnapshot): Observable<SchemaView | null> {
    const id = route.paramMap.get('schema');

    return this.allSchemas$.pipe(
      map((all) => {
        const found = all?.find((item) => item.id === id) ?? null;
        if (!found) {
          const defaultSchema = all[0] ?? null;
          this.#activeSchemaIdSubject$.next(defaultSchema?.id ?? null);
          return defaultSchema;
        }
        this.#activeSchemaIdSubject$.next(id);
        return found;
      }),
    );
  }
}
