<div class="stats-grid">
  <div class="headers">
    @for (header of this.statsStaticVM.columnsHeaders; track header) {
      <span class="headers__item">{{ header }}</span>
    }
  </div>
  @for (row of this.statsStaticVM.rows; track row) {
    <div class="row">
      @for (field of row.fields; track field) {
        <div class="field">
          @if (field.icon) {
            <mat-icon class="field__icon">{{ field.icon }}</mat-icon>
          }
          <span class="field__value">{{ field.value }}</span>
        </div>
      }
    </div>
  }
</div>
