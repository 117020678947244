import { Component, inject } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule, UIStatusModule } from '@vdms-hq/ui';
import { AuthAdobeService } from '../../logic/auth-adobe.service';
import { BehaviorSubject, catchError, map, of, startWith, tap } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { CountdownPipe } from '@vdms-hq/shared';
import moment from 'moment';
import { ToastService } from '@vdms-hq/toast';

@Component({
  selector: 'vdms-hq-auth-adobe',
  templateUrl: './auth-adobe.component.html',
  styleUrls: ['./auth-adobe.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    UILayoutModule,
    UIFormModule,
    UIButtonModule,
    UIStatusModule,
    CountdownPipe,
    NgOptimizedImage,
  ],
})
export class AuthAdobeComponent {
  protected readonly Number = Number;
  private authAdobeService = inject(AuthAdobeService);
  private clipboard = inject(Clipboard);
  private toastService = inject(ToastService);

  readonly tokenStartedValue = '------';
  loading$ = new BehaviorSubject<boolean>(true);
  error$ = new BehaviorSubject<boolean>(false);

  expiresAt!: string;
  expiresAtAsSeconds = 0;

  token$ = this.authAdobeService.createNewDeviceForPairing().pipe(
    tap((resp) => {
      const expiredAtDate = moment(resp.expires_at).utc(true);
      this.expiresAt = expiredAtDate.toISOString();
      this.expiresAtAsSeconds = moment.duration(expiredAtDate.diff(moment().utc(true))).asSeconds();
    }),
    map((resp) => resp.code),
    startWith(this.tokenStartedValue),
    tap((data) => (data !== this.tokenStartedValue ? this.loading$.next(false) : null)),
    catchError(() => {
      this.loading$.next(false);
      this.error$.next(true);
      return of(this.tokenStartedValue);
    }),
  );

  copy(token: string) {
    this.clipboard.copy(token);
    this.toastService.success({
      id: 'copy',
      message: 'Copied to clipboard.',
    });
  }
}
