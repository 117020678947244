import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import {
  CollectionAccessTypeEnum,
  CollectionModelFlat,
  CollectionsService,
  PaginationAPIModel as Pagination,
} from '@vdms-hq/api-contract';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { SelectOption } from '@vdms-hq/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { SORT_BY } from '../utils/collections-filters-provider';
import { AuthService } from '@vdms-hq/auth';
import { CollectionsRefresh } from '@vdms-hq/collections-public';
import { ParamsPagination } from '@vdms-hq/view-settings';
import { SelectableTilesDataSource } from '@vdms-hq/selectable-tiles-wrapper';

export interface CollectionsAddData {
  key: string;
  label: string;
  number_of_assets: number;
  owner: string;
  type: string;
  access_type?: CollectionAccessTypeEnum;
}

export type CollectionsAddFiltersView = {
  keyword: FormControl<string>;
  sort: FormControl<string>;
  collectionType: FormControl<string>;
};

@Injectable({ providedIn: 'root' })
export class CollectionsAddDataSource
  extends ParamsPagination
  implements SelectableTilesDataSource<SelectOption, FormGroup<CollectionsAddFiltersView>, CollectionModelFlat>
{
  private collectionsService = inject(CollectionsService);
  private collectionsRefresher = inject(CollectionsRefresh);
  private authService = inject(AuthService);

  filters = new FormGroup<CollectionsAddFiltersView>({
    keyword: new FormControl<string>('', { nonNullable: true }),
    sort: new FormControl<string>(SORT_BY[0].key, { nonNullable: true }),
    collectionType: new FormControl<CollectionAccessTypeEnum>(CollectionAccessTypeEnum.OWNED, { nonNullable: true }),
  });

  #userId$ = this.authService.id$;

  refresh$ = this.collectionsRefresher.addToCollection$;

  isLoading$ = new BehaviorSubject<boolean>(true);
  currentCollectionUuid$ = new BehaviorSubject<string | null>(null);

  responseData$ = combineLatest([
    this.refresh$,
    this.pageIndex$,
    this.pageSize$,
    this.filters.valueChanges.pipe(startWith(this.filters.value), debounceTime(300)),
  ]).pipe(
    distinctUntilChanged(),
    tap(() => this.isLoading$.next(true)),
    switchMap(([, page, perPage, filters]) => {
      const pagination = {
        page,
        perPage,
      };

      const selectedSort = SORT_BY.find((item) => item.key === filters?.sort)?.props;

      return this.collectionsService.getList(
        {
          filter: filters.keyword,
        },
        Pagination.create({
          ...pagination,
          ...selectedSort,
        }),
        filters.collectionType ?? 'all',
      );
    }),
    shareReplay(1),
  );

  emptyResults$ = this.responseData$.pipe(map((data) => data.total === 0));
  total$ = this.responseData$.pipe(map((data) => data.total));

  allData$: Observable<CollectionsAddData[]> = this.responseData$.pipe(
    withLatestFrom(this.#userId$),
    switchMap(([data, userId]) => {
      const uuids = data.data.map(({ uuid }) => uuid);
      return combineLatest([
        of(data),
        this.collectionsService.getCollectionAccessType(uuids),
        of(userId),
        this.currentCollectionUuid$,
      ]);
    }),
    catchError((error) => {
      this.#errorHandler();
      throw error;
    }),
    map(([data, accessTypes, userId, currentCollectionUuid]) => {
      return data.data
        .filter((item) => item.uuid !== currentCollectionUuid)
        .map((collection) => ({
          key: collection.uuid,
          label: collection.name,
          number_of_assets: collection.number_of_assets,
          description: collection.description ?? '',
          owner: collection.owner_name ?? '',
          type: collection.owner === userId ? 'owned' : 'shared',
          access_type: accessTypes.find((type) => type.uuid === collection.uuid)?.access_type,
        }));
    }),
    tap((data) => this.isLoading$.next(false)),
    shareReplay(1),
  );

  connection$ = this.allData$;

  getFormGroup() {
    return this.filters as FormGroup<CollectionsAddFiltersView>;
  }

  searchKeyword(keyword: string) {
    this.filters.controls['keyword'].setValue(keyword);
  }

  refresh(): void {
    if (this.filters.dirty || this.changePageIndex$.value !== 0) {
      this.filters.controls['sort'].setValue('created_desc');
      this.filters.controls['collectionType'].setValue(CollectionAccessTypeEnum.OWNED);
      this.filters.controls['keyword'].setValue('');
    }
  }

  #errorHandler(): Observable<never> {
    this.isLoading$.next(false);
    this.emptyResults$ = of(true);
    return EMPTY;
  }
}
