<div class="content">
  <div class="container__content-fields">
    <form [formGroup]="assetQuickPreviewFormService.formGroup">
      <vdms-hq-form-builder
        formControlName="assetData"
        [definitions]="groupedElements"
        [viewMode]="assetQuickPreviewFormService.formViewMode"
        [columns]="columns"
        [content]="{ enabled: 'common.global.overwrite' }"
      ></vdms-hq-form-builder>
    </form>
  </div>
</div>
