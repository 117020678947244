import { inject, Injectable } from '@angular/core';
import {
  ASPERA_DOWNLOAD_STATUS,
  AsperaDownloadableFile,
  DeliveryDestinationConfig,
  DeliveryDestinationJobStatusEnum,
  DownloadTypeEnum,
  ORDER_TYPE,
  OrderConfirmationPdfRequest,
  OrderDeliveryStatus,
  OrderDownloadPayload,
  OrderModel,
  OrderPatchItemModel,
  OrderService,
  PostAsperaDownloadStatus,
  RetryDeliveryDestination,
  RetryDeliveryDestinationJob,
} from '@vdms-hq/api-contract';
import { AssetDownloadService, DownloadableUrls, TransferEvent } from '@vdms-hq/storage';
import { ToastService } from '@vdms-hq/toast';
import { BehaviorSubject, combineLatest, concat, delay, EMPTY, iif, Observable, of, switchMap } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { isEmbargoActive, SelectionManager } from '@vdms-hq/shared';
import { ActionContextLess, DataAction, UIConfirmationDialogService } from '@vdms-hq/ui';
import { OrderActionsProperties, OrderAssetViewModel2 } from './models';
import moment from 'moment/moment';
import { Permission } from '@vdms-hq/firebase-contract';
import { ActivatedClientService, PermissionService } from '@vdms-hq/activated-client';
import { MatDialog } from '@angular/material/dialog';
import { TranscodeDetailsDialogComponent } from '../components/transcode-details-dialog/transcode-details-dialog.component';
import { FieldsFetcherService } from '@vdms-hq/fields';
import { AddToCartActionsService } from '@vdms-hq/cart-core';
import {
  ReDeliverOrderDialogComponent,
  ReDeliverOrderDialogInput,
} from '../components/re-deliver-order-dialog/re-deliver-order-dialog.component';
import { ORDERS_ROUTER_BASE } from '../../orders-routing.module';
import { Router } from '@angular/router';
import { ResultsActions } from '@vdms-hq/asset-results';
import { FlatOrderViewModel, OrdersDataSource } from '../../results/logic/order-results-ds';
import { DOCUMENT } from '@angular/common';
import { CurrentOrderAssetsDataSource } from './current-order-assets.ds';
import { ChangeOrderDiscountsDialogComponent } from '../components/change-order-discounts/change-order-discounts-dialog.component';
import { NewAssetsDialogComponent } from '../components/new-assets-dialog/new-assets-dialog.component';
import { OrderItemStatusDialogComponent } from '../components/order-item-status-dialog/order-item-status-dialog.component';

type DownloadableOrderAsset = {
  downloadUuid: string;
};

@Injectable({ providedIn: 'root' })
export class OrderActionsService {
  private orderService = inject(OrderService);
  private assetDownloadService = inject(AssetDownloadService);
  private toastService = inject(ToastService);
  private permissionService = inject(PermissionService);
  private activatedClientService = inject(ActivatedClientService);
  private fieldsFetcherService = inject(FieldsFetcherService);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private currentOrderAssets = inject(CurrentOrderAssetsDataSource);
  public orderResultsDataSource = inject(OrdersDataSource);
  private dialog = inject(MatDialog);
  private addToCartService = inject(AddToCartActionsService);
  private router = inject(Router);
  private document = inject(DOCUMENT);

  retriggerInProgress$ = new BehaviorSubject(false);
  downloadManyLoading$ = new BehaviorSubject(false);

  #refresh$ = new BehaviorSubject<Date>(new Date());

  #isSalesForce$ = this.activatedClientService.clientDefinite$.pipe(
    map((client) => Boolean(client.integrations.salesforce?.enabled)),
  );

  #completedTransferArray: TransferEvent[] = [];
  #asperaPayloadData!: AsperaDownloadableFile;
  #downloadInProgress$ = new BehaviorSubject(0);
  downloadInProgress$ = this.#downloadInProgress$.asObservable().pipe(
    switchMap((delayTime) => concat([of(delayTime > 0), of(false).pipe(delay(delayTime))])),
    switchMap((value) => value),
  );

  get isApprovalPage(): boolean {
    return this.router.url.includes(ORDERS_ROUTER_BASE.ORDERS_APPROVAL);
  }

  buildAddToCartAction$(): Observable<DataAction<FlatOrderViewModel>> {
    return of({
      key: ResultsActions.ADD_TO_CART,
      icon: 'add_shopping_cart',
      onDoubleClick: false,
      label: 'common.global.add_to_cart',
      hiddenIf: (item?: FlatOrderViewModel) => item?.orderScheduled || item?.isWarmingUp || false,
    });
  }

  buildAddToCollectionAction$(): Observable<DataAction<FlatOrderViewModel> | null> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_COLLECTIONS]).pipe(
      map((permitted) => {
        return permitted
          ? ({
              key: ResultsActions.ADD_TO_COLLECTION,
              label: 'common.global.add_to_collection',
              icon: 'playlist_add',
              hiddenIf: (item: FlatOrderViewModel) => item?.orderScheduled || item?.isWarmingUp || false,
            } as DataAction<FlatOrderViewModel>)
          : null;
      }),
    );
  }

  buildDownloadDeliveryConfirmationAction$<T>(): Observable<DataAction<FlatOrderViewModel> | null> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.GENERATE_ORDER_DELIVERY_CONFIRMATION]).pipe(
      map((permitted) => ({
        key: OrderActionsProperties.DOWNLOAD_DELIVERY_CONFIRMATION,
        label: 'orders.download.delivery_confirmation',
        icon: 'picture_as_pdf',
        hiddenIf: (item?: FlatOrderViewModel) => !item?.isDelivered,
      })),
    );
  }

  buildApproveOrderAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    order: OrderModel,
  ): Observable<ActionContextLess | null> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.APPROVE_ORDERS]).pipe(
      map((permitted) => {
        const available = !!(this.isApprovalPage && permitted);
        const disabled = !!order.approval_status || order.has_embargoed_asset || !order.has_approved_item;

        return available
          ? {
              key: OrderActionsProperties.APPROVE_ORDER,
              label: 'pages.orders.approve_order',
              color: 'primary',
              disabled,
            }
          : null;
      }),
    );
  }

  buildApproveAssetAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    order: OrderModel,
  ): Observable<DataAction<T>> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.APPROVE_ORDERS]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.SHOPPING_CART_V2]),
    ]).pipe(
      map(([hasPermission, cartV2]) => {
        return {
          key: OrderActionsProperties.APPROVE_ASSET,
          label: 'pages.orders.approve',
          icon: 'thumb_up_alt',
          hiddenIf: () => !hasPermission || cartV2 || order.sales_force_id || !this.isApprovalPage,
          disabledIf: (item: OrderAssetViewModel2) => item.isApproved,
        } as DataAction<T>;
      }),
    );
  }

  buildRejectAssetAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    order: OrderModel,
  ): Observable<DataAction<T>> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.APPROVE_ORDERS]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.SHOPPING_CART_V2]),
    ]).pipe(
      map(([hasPermission, cartV2]) => {
        return {
          key: OrderActionsProperties.REJECT_ASSET,
          label: 'pages.orders.reject',
          icon: 'thumb_down_alt',
          hiddenIf: () => !hasPermission || cartV2 || order.sales_force_id || !this.isApprovalPage,
          disabledIf: (item: OrderAssetViewModel2) => item.isRejected,
        } as DataAction<T>;
      }),
    );
  }

  buildDownloadAsperaAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    order: OrderModel,
  ): Observable<DataAction<T>> {
    return of({
      key: OrderActionsProperties.DOWNLOAD_ASPERA,
      label: 'common.global.download',
      icon: 'download',
      onDoubleClick: false,
      hiddenIf: (item?: OrderAssetViewModel2) => {
        return !(
          Boolean(item?.props.storage === 'hot' && !item.deliveryDestinations?.length) ||
          Boolean(item?.props.storage === 'hot' && item?.deliveryDestinations?.length) ||
          item?.downloadUuid
        );
      },
      disabledIf: (item?: OrderAssetViewModel2) =>
        moment(order.expires_at).isBefore(moment()) ||
        !item?.downloadUuid ||
        item?.isEmbargoActive ||
        item?.remainingDownloads === 0 ||
        [OrderDeliveryStatus.SCHEDULED].includes(order.delivery_status) ||
        (!!item?.transcodeStatus && !['download ready', 'completed'].includes(item?.transcodeStatus.toLowerCase())),
    });
  }

  buildDownloadHTTPSAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    order: OrderModel,
  ): Observable<DataAction<T>> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.DOWNLOAD_HTTPS_FROM_DOWNLOADS]).pipe(
      map((isPermitted) => ({
        key: OrderActionsProperties.DOWNLOAD_HTTPS,
        label: 'common.global.download_https',
        icon: 'download_for_offline',
        onDoubleClick: false,
        hiddenIf: (item?: OrderAssetViewModel2) => {
          return !isPermitted || Boolean(item?.props.storage !== 'hot');
        },
        disabledIf: (item?: OrderAssetViewModel2) =>
          moment(order.expires_at).isBefore(moment()) ||
          !item?.downloadUuid ||
          item?.isEmbargoActive ||
          item?.remainingDownloads === 0 ||
          [OrderDeliveryStatus.SCHEDULED].includes(order.delivery_status) ||
          (!!item?.transcodeStatus && !['download ready', 'completed'].includes(item?.transcodeStatus.toLowerCase())),
      })),
    );
  }

  buildAddToCartHeaderAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    order?: OrderModel,
  ): Observable<ActionContextLess | null> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.SHOPPING_CART]),
      this.currentOrderAssets.allData$,
    ]).pipe(
      map(([permitted, assets]) => {
        const disabled =
          !assets?.length ||
          moment(order?.delivery_date).isAfter(moment()) ||
          order?.delivery_status == OrderDeliveryStatus.RETRIEVING_FROM_COLD;
        return permitted
          ? {
              key: OrderActionsProperties.ADD_TO_CART,
              label: 'pages.order.add_to_cart',
              icon: 'shopping_cart',
              color: 'primary',
              disabled,
            }
          : null;
      }),
    );
  }

  buildRetryAllDeliveryDestinationsAction$(order: OrderModel): Observable<ActionContextLess | null> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.RETRY_DELIVERY_DESTINATIONS]),
      this.currentOrderAssets.allData$,
    ]).pipe(
      map(([permitted, assets]) => {
        const disabled = assets.some((item) => {
          return !item.deliveryDestinations?.some((dd) =>
            dd.configs.some((c) => c.jobs.some((j) => j.status == DeliveryDestinationJobStatusEnum.FAILED)),
          );
        });

        const available = order?.delivery_destinations && permitted;
        return available
          ? {
              key: OrderActionsProperties.RETRY_DELIVERY_DESTINATIONS,
              icon: 'refresh',
              label: 'tooltip.retry_delivery_destinations',
              color: 'transparent',
              disabled,
            }
          : null;
      }),
    );
  }

  buildRetryDeliveryDestinationsAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    order: OrderModel,
  ): Observable<DataAction<T>> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.RETRY_DELIVERY_DESTINATIONS]).pipe(
      map(
        (permitted) =>
          ({
            key: OrderActionsProperties.RETRY_DELIVERY_DESTINATIONS,
            icon: 'refresh',
            label: 'tooltip.retry_delivery_destinations',
            disabledIf: (item: OrderAssetViewModel2) => {
              return !item.deliveryDestinations?.some((dd) =>
                dd.configs.some((c) => c.jobs.some((j) => j.status == DeliveryDestinationJobStatusEnum.FAILED)),
              );
            },
            hiddenIf: () => !order?.delivery_destinations?.length || !permitted,
          }) as DataAction<T>,
      ),
    );
  }

  buildItemStatusAction$<T>(order: OrderModel, showDownload = true): Observable<DataAction<T>> {
    return of({
      key: showDownload ? OrderActionsProperties.ITEM_STATUS : OrderActionsProperties.ITEM_STATUS_NO_DOWNLOAD,
      icon: 'list_alt',
      label: 'common.global.order_item_status.button',
      hiddenIf: () => order.type === ORDER_TYPE.EMAIL_DELIVERY || order.type === ORDER_TYPE.WARM_UP,
    } as DataAction<T>);
  }

  buildSharedPackRetryDeliveryDestinationAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    order: OrderModel,
  ): Observable<DataAction<T>> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.RETRY_DELIVERY_DESTINATIONS]),
      this.currentOrderAssets.allData$,
    ]).pipe(
      map(
        ([permitted, assets]) =>
          ({
            key: OrderActionsProperties.RETRY_DELIVERY_DESTINATIONS,
            icon: 'refresh',
            label: 'tooltip.retry_delivery_destinations',
            color: 'transparent',
            disabledIf: () => {
              const hasEmbargo = !!assets.find(({ asset }) => isEmbargoActive(asset.embargoed_to));
              return (
                moment(order.expires_at).isAfter(moment()) ||
                (order?.completed_items ?? 0) <= order?.total_assets ||
                hasEmbargo
              );
            },
            hiddenIf: (asset) => !order?.delivery_destinations || !permitted || asset?.isEmbargoActive,
          }) as DataAction<T>,
      ),
    );
  }

  buildRetriggerClippingAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    selection: SelectionManager<OrderAssetViewModel2>,
  ): Observable<ActionContextLess | null> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.RETRIGGER_CLIPPING]),
      this.#isSalesForce$,
      this.retriggerInProgress$,
      selection.entities$,
    ]).pipe(
      map(([permitted, isSalesForce, inProgress, entities]) => {
        return permitted && isSalesForce && entities?.length
          ? {
              key: OrderActionsProperties.RETRIGGER_CLIPPING,
              label: 'pages.orders.retrigger_clipping',
              color: 'secondary',
              disabled: inProgress,
            }
          : null;
      }),
    );
  }

  buildForceSalesforceRequestAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    order: OrderModel,
  ): Observable<ActionContextLess | null> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.FORCE_RELEASE_SF_REQUEST]),
      this.#isSalesForce$,
    ]).pipe(
      map(([permitted, isSalesForce]) => {
        const available = !!(permitted && order.sales_force_id && isSalesForce);
        return available
          ? {
              key: OrderActionsProperties.FORCE_SALESFORCE_REQUEST,
              label: 'pages.orders.force_salesforce_request',
              color: 'secondary',
            }
          : null;
      }),
    );
  }

  buildRedeliverOrderAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    order: OrderModel,
  ): Observable<ActionContextLess | null> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.REDELIVER_ORDERS]).pipe(
      map((permitted) => {
        const available = !!(permitted && order.sales_force_id);
        return available
          ? {
              key: OrderActionsProperties.REDELIVER_ORDER,
              label: 'common.orders.redeliver_order.button',
              color: 'secondary',
            }
          : null;
      }),
    );
  }

  buildPreviewSalesforceRequestAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    order: OrderModel,
  ): Observable<ActionContextLess | null> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.REDELIVER_ORDERS]),
      this.#isSalesForce$,
    ]).pipe(
      map(([permitted, isSalesForce]) => {
        const available = !!(permitted && order.sales_force_uri && isSalesForce && order.sales_force_id);
        return available
          ? {
              key: OrderActionsProperties.PREVIEW_SALESFORCE_REQUEST,
              label: 'common.orders.redeliver_order.preview_salesforce_request',
              icon: 'open_in_new',
              color: 'secondary',
            }
          : null;
      }),
    );
  }

  buildDownloadClearanceNotesAction$(primary = false): Observable<ActionContextLess | null> {
    return this.#isSalesForce$.pipe(
      map((permitted) => {
        return permitted
          ? {
              key: OrderActionsProperties.DOWNLOAD_CLEARANCE_NOTES,
              label: 'tooltip.clearance',
              color: primary ? 'primary' : 'secondary',
            }
          : null;
      }),
    );
  }

  buildNewAssetsPreview<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(): Observable<DataAction<T>> {
    return of({
      key: OrderActionsProperties.SHOW_NEW_ASSETS,
      icon: 'preview',
      label: 'pages.orders.show_new_assets',
      onDoubleClick: false,
      hiddenIf: (item?: OrderAssetViewModel2) => !item?.newAssetUuidsCount,
    });
  }

  handleApproveAssetAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: {
      key: string;
      item: OrderAssetViewModel2;
    },
    uuid: string,
    onComplete: () => void,
  ) {
    if ($event.key !== OrderActionsProperties.APPROVE_ASSET) {
      return;
    }

    this.updateAssets(uuid, [{ status: 'approved', uuid: $event.item.orderUuid }])
      .pipe(
        tap(() => {
          if (!onComplete) {
            return;
          }

          onComplete();
          this.#refresh$.next(new Date());
        }),
      )
      .subscribe();
  }

  handleRejectAssetAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: {
      key: string;
      item: OrderAssetViewModel2;
    },
    uuid: string,
    onComplete: () => void,
  ) {
    if ($event.key !== OrderActionsProperties.REJECT_ASSET) {
      return;
    }

    this.updateAssets(uuid, [{ status: 'rejected', uuid: $event.item.orderUuid }])
      .pipe(
        tap(() => {
          if (!onComplete) {
            return;
          }

          onComplete();
          this.#refresh$.next(new Date());
        }),
      )
      .subscribe();
  }

  handleApproveOrderAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: { key: string },
    uuid: string,
  ) {
    if ($event.key !== OrderActionsProperties.APPROVE_ORDER) {
      return;
    }

    this.approveOrder(uuid).subscribe({
      next: () => this.toastService.success({ id: 'order_approved', message: 'common.orders.approval.message' }),
    });
  }

  handleDownloadClearanceNotesAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: { key: string },
    order: OrderModel,
  ) {
    if ($event.key !== OrderActionsProperties.DOWNLOAD_CLEARANCE_NOTES || !order.clearance_notes_uri) {
      return;
    }

    this.downloadClearance(order.uuid)
      .pipe(
        take(1),
        tap(() =>
          this.toastService.info({
            id: 'download-clearance',
            message: 'common.orders.clearance_note_download.initialized',
          }),
        ),
      )
      .subscribe({
        next: (response) => {
          this.toastService.success({
            id: 'download-clearance',
            message: 'common.orders.clearance_note_download.ready',
          });
          const link = document.createElement('a');
          link.setAttribute('href', response.clearance_notes_uri);
          link.setAttribute('download', response.clearance_notes_uri);
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
      });
    return EMPTY;
  }

  handleDownloadDeliveryConfirmationAction($event: { key: string; item?: FlatOrderViewModel }) {
    if ($event.key !== OrderActionsProperties.DOWNLOAD_DELIVERY_CONFIRMATION || !$event.item) {
      return;
    }
    this.orderService
      .getDeliveryConfirmationPdf($event.item.uuid)
      .pipe(take(1))
      .subscribe({
        next: ({ url }) => {
          this.toastService.success({ id: 'download', message: 'orders.download.success' });
          this.document.defaultView?.open(url, '_blank');
        },
        error: () => {
          return EMPTY;
        },
      });
  }

  handleDiscountChange$($event: { key: string; item?: FlatOrderViewModel }, payload?: { uuids: string[] }) {
    if ($event.key !== OrderActionsProperties.CHANGE_DISCOUNT || !payload) {
      return EMPTY;
    }

    return this.dialog
      .open(ChangeOrderDiscountsDialogComponent)
      .afterClosed()
      .pipe(
        filter((data) => !!data),
        take(1),
        tap(() => {
          this.toastService.processing({
            id: 'change_discount',
            message: 'common.orders.change_discount.processing',
          });
        }),
        switchMap((data) => {
          if (data.discount == 'null') {
            return this.orderService.changeOrderDiscounts(payload.uuids, null);
          }
          return this.orderService.changeOrderDiscounts(payload.uuids, data.discount);
        }),
        tap(() => {
          this.orderResultsDataSource.refresh$.next(Date.now());
          this.toastService.success({
            id: 'change_discount',
            message: 'common.orders.change_discount.success',
          });
        }),
      );
  }

  handleDownloadManyDeliveryConfirmationAction$(
    $event: { key: string; item?: FlatOrderViewModel },
    payload?: OrderConfirmationPdfRequest,
  ) {
    if ($event.key !== OrderActionsProperties.DOWNLOAD_MANY_DELIVERY_CONFIRMATION || !payload) {
      return EMPTY;
    }
    this.downloadManyLoading$.next(true);
    return this.orderService.getManyDeliveryConfirmationPdf(payload).pipe(
      tap(() => this.toastService.success({ id: 'download', message: 'orders.download_many.success' })),
      tap(() => {
        this.downloadManyLoading$.next(false);
      }),
      catchError(() => {
        return EMPTY;
      }),
    );
  }

  handleRedeliverOrderAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: { key: string },
    uuid: string,
  ) {
    if ($event.key !== OrderActionsProperties.REDELIVER_ORDER) {
      return;
    }

    this.dialog
      .open<ReDeliverOrderDialogComponent, ReDeliverOrderDialogInput>(ReDeliverOrderDialogComponent, {
        data: {
          orderId: uuid,
        },
      })
      .afterClosed()
      .subscribe();
  }

  handleForceSalesforceRequestAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: { key: string },
    uuid: string,
  ) {
    if ($event.key !== OrderActionsProperties.FORCE_SALESFORCE_REQUEST) {
      return;
    }

    this.confirmationDialog
      .open({
        title: 'pages.orders.force_salesforce_request',
        message: 'pages.orders.force_salesforce_message',
        okAction: { label: 'Confirm', color: 'primary' },
        abortAction: { label: 'Cancel', color: 'secondary' },
      })
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.salesForceUnlockRequest(uuid)),
      )
      .subscribe();
  }

  handlePreviewSalesforceRequestAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: {
      key: string;
    },
    order: OrderModel,
  ) {
    if ($event.key !== OrderActionsProperties.PREVIEW_SALESFORCE_REQUEST || !order?.sales_force_uri) {
      return;
    }

    const url = order.sales_force_uri;
    url && window && window.open(url, '_blank');
    return true;
  }

  buildDownloadAllAsperaAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    withFolders = false,
    order: OrderModel,
  ): Observable<ActionContextLess> {
    const key = withFolders
      ? OrderActionsProperties.DOWNLOAD_ALL_ASPERA_FOLDERS
      : OrderActionsProperties.DOWNLOAD_ALL_ASPERA;
    const label = withFolders ? 'tooltip.download_all_with_aspera_in_folders' : 'tooltip.download_all_with_aspera';
    const icon = withFolders ? 'cloud_download' : 'download';
    const disabled =
      order.has_embargoed_asset ||
      !order.could_be_downloaded ||
      moment().isAfter(order.expires_at) ||
      (order?.completed_items ?? 0) == 0 ||
      [OrderDeliveryStatus.SCHEDULED, OrderDeliveryStatus.RETRIEVING_FROM_COLD].includes(order.delivery_status);
    return of({
      key,
      label,
      icon,
      color: 'transparent',
      disabled,
    });
  }

  handleRetriggerClippingAction$<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: {
      key: string;
    },
    selection: SelectionManager<OrderAssetViewModel2>,
    uuid: string,
  ) {
    if ($event.key !== OrderActionsProperties.RETRIGGER_CLIPPING) {
      return;
    }

    selection.entities$
      .pipe(
        take(1),
        switchMap((entities) => {
          if (!entities?.length) {
            this.toastService.error({ id: 'retrigger_error', message: 'pages.orders.retrigger_validation_error' });
            return EMPTY;
          }

          const assetsNotAllowedToRetrigger = entities.some(
            (entity) => entity.approvalStatus !== 1 || entity.clippingStatus === 'completed',
          );
          if (assetsNotAllowedToRetrigger) {
            this.toastService.error({ id: 'retrigger_error', message: 'pages.orders.selected_assets_not_allowed' });
            return EMPTY;
          }

          this.retriggerInProgress$.next(true);
          const items = entities.map(({ externalId }) => externalId)?.filter(Boolean) as string[];
          return this.retriggerClipping(uuid, { items });
        }),
        tap(() => selection.clear()),
      )
      .subscribe({
        error: () => {
          this.retriggerInProgress$.next(false);
        },
        next: () => {
          this.retriggerInProgress$.next(false);
          this.toastService.success({ id: 'retrigger_success', message: 'notifications.create.done' });
        },
      });
  }

  handleAddToCartOrderAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: { key: string },
    uuid: string,
  ) {
    if ($event.key !== OrderActionsProperties.ADD_TO_CART) {
      return;
    }

    this.addToCartService.addOrders([uuid]);
  }

  handleRetryDeliveryDestinationsAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: { key: string; item?: OrderAssetViewModel2 },
    uuid: string,
  ) {
    if ($event.key !== OrderActionsProperties.RETRY_DELIVERY_DESTINATIONS) {
      return;
    }

    if ($event.item) {
      this.#retryDeliveryDestinationsAction(uuid, [$event.item]);
    } else {
      this.#retryDeliveryDestinationsAction(uuid);
    }
  }

  handleItemStatusAction(
    $event: {
      key: string;
      item: OrderAssetViewModel2;
    },
    order: OrderModel,
  ) {
    if (
      $event.key !== OrderActionsProperties.ITEM_STATUS &&
      $event.key !== OrderActionsProperties.ITEM_STATUS_NO_DOWNLOAD
    ) {
      return;
    }
    const disabledDownload = !order.could_be_downloaded || moment().isAfter(order.expires_at);
    this.dialog
      .open(OrderItemStatusDialogComponent, {
        data: {
          item: $event.item,
          orderType: order.type,
          orderUuid: order.uuid,
          orderActive: order.expires_at ? moment().isBefore(order.expires_at) : false,
          isEmbargoActive: $event.item.isEmbargoActive,
          hideDownload: $event.key === OrderActionsProperties.ITEM_STATUS_NO_DOWNLOAD,
          disabledDownload,
        },
      })
      .afterClosed()
      .subscribe();
  }

  handleNewAssetsPreviewAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>($event: {
    key: string;
    item: OrderAssetViewModel2;
  }) {
    if ($event.key !== OrderActionsProperties.SHOW_NEW_ASSETS) {
      return;
    }

    return this.dialog
      .open(NewAssetsDialogComponent, {
        data: {
          asset: $event.item,
          delivery_destinations: $event.item.deliveryDestinations,
        },
      })
      .afterClosed();
  }

  handleDownloadAllAsperaAction<T extends OrderAssetViewModel2 = OrderAssetViewModel2>(
    $event: { key: OrderActionsProperties },
    uuid: string,
  ) {
    if (
      ![OrderActionsProperties.DOWNLOAD_ALL_ASPERA_FOLDERS, OrderActionsProperties.DOWNLOAD_ALL_ASPERA].includes(
        $event.key,
      )
    ) {
      return;
    }

    const withFolders = $event?.key === OrderActionsProperties.DOWNLOAD_ALL_ASPERA_FOLDERS;
    this.downloadAll(uuid, withFolders);
  }

  handleDownloadHTTPSAction<T = OrderAssetViewModel2>($event: { key: string; item: T }, orderUuid: string) {
    const key = $event.key;
    const items = Array.isArray($event.item) ? $event.item : [$event.item];
    const downloadUuids = items.map((item) => ({ downloadUuid: item.downloadUuid }));

    if (
      ![OrderActionsProperties.DOWNLOAD_HTTPS as string, ResultsActions.DOWNLOAD_SELECTED_HTTPS as string].includes(key)
    ) {
      return;
    }

    this.download(orderUuid, downloadUuids, { download_type: DownloadTypeEnum.HTTPS });
  }

  handleDownloadAsperaAction($event: { key: string; item: OrderAssetViewModel2 }, uuid: string) {
    if (OrderActionsProperties.DOWNLOAD_ASPERA !== $event.key) {
      return;
    }

    this.download(uuid, [$event.item], { download_type: DownloadTypeEnum.ASPERA });
  }

  download(
    orderId: string,
    assets: DownloadableOrderAsset[],
    options: {
      keep_folders_structure?: boolean;
      config_uuid?: string;
      download_type?: keyof typeof DownloadTypeEnum;
    } = {},
  ) {
    const { keep_folders_structure, config_uuid, download_type } = options;

    const payload: OrderDownloadPayload = {
      download_uuids: assets.map(({ downloadUuid }) => downloadUuid),
      keep_folders_structure: !!keep_folders_structure,
      download_type: download_type || DownloadTypeEnum.ASPERA,
    };

    if (config_uuid) {
      payload.config_uuid = config_uuid;
    }

    const aspera$ = this.orderService.downloadAssets(orderId, payload).pipe(
      catchError(() => {
        return EMPTY;
      }),
      map(({ data }) => data),
      tap((asperaPayload) => {
        this.toastService.info({
          id: 'download_processing',
          message: 'common.notifications.generic.download_processing',
        });

        this.assetDownloadService.withAspera(asperaPayload);
        this.#asperaPayloadData = asperaPayload;
      }),
      switchMap(() => this.assetDownloadService.completedTransfer$),
      filter(Boolean),
      tap((transfer) => {
        if (
          this.#completedTransferArray.find((uniqueTransfer) => uniqueTransfer.uuid === transfer.uuid) === undefined
        ) {
          this.#completedTransferArray.push(transfer);
          if (transfer?.file_counts?.failed === 0 && transfer?.file_counts?.skipped === 0) {
            this.sendDownloadStatus({
              status: ASPERA_DOWNLOAD_STATUS.SUCCESS,
              transferUuid: this.#asperaPayloadData.transferUuid,
            });
          }
          if (transfer?.file_counts?.attempted === transfer?.file_counts?.skipped) {
            this.sendDownloadStatus({
              status: ASPERA_DOWNLOAD_STATUS.SKIPPED,
              transferUuid: this.#asperaPayloadData.transferUuid,
            });
          }
          if (transfer?.file_counts?.failed > 0) {
            this.sendDownloadStatus({
              status: ASPERA_DOWNLOAD_STATUS.ERROR,
              transferUuid: this.#asperaPayloadData.transferUuid,
            });
          }
        }
      }),
    );

    const https$ = this.orderService.downloadAssets<DownloadableUrls>(orderId, payload).pipe(
      catchError(() => {
        return EMPTY;
      }),
      map(({ data }) => data),
      tap((httpsPayload) => {
        this.toastService.info({
          id: 'download_initiated',
          message: 'common.notifications.generic.download_initiated',
        });

        const delay = this.assetDownloadService.withHttps(httpsPayload.downloadUrls);
        this.#downloadInProgress$.next(delay);
      }),
    );

    iif(() => download_type === DownloadTypeEnum.HTTPS, https$, aspera$).subscribe();
  }

  sendDownloadStatus(status: PostAsperaDownloadStatus) {
    this.orderService.sendOrderDownloadStatus(status).pipe(take(1)).subscribe();
  }

  downloadAll(orderId: string, keep_folders_structure = false, configUuid?: string) {
    this.download(orderId, [], {
      keep_folders_structure,
      config_uuid: configUuid,
      download_type: DownloadTypeEnum.ASPERA,
    });
  }

  downloadClearance(uuid: string) {
    return this.orderService.downloadClearance(uuid);
  }

  approveOrder(uuid: string): Observable<OrderModel> {
    return this.orderService.approveOrder(uuid);
  }

  updateAssets(orderUuid: string, assets: OrderPatchItemModel[]) {
    return this.orderService.updateAssets(orderUuid, assets);
  }

  salesForceUnlockRequest(orderUuid: string) {
    return this.orderService.salesForceUnlockRequest(orderUuid);
  }

  retriggerClipping(
    uuid: string,
    payload: {
      items: string[];
    },
  ) {
    return this.orderService.retriggerClipping(uuid, payload);
  }

  retryDeliveryDestinations(
    uuid: string,
    payload: RetryDeliveryDestination,
  ): Observable<RetryDeliveryDestinationJob[]> {
    return this.orderService.retryDeliveryDestination(uuid, payload);
  }

  generateRetryDeliveryDestinationsPayload(order: OrderAssetViewModel2[]) {
    !Array.isArray(order) && (order = [order]);

    const availableConfigs = order
      .map((asset) => {
        const deliveryDestinationConfigs: DeliveryDestinationConfig[] = [];
        asset.deliveryDestinations?.forEach((deliveryDestination) => {
          deliveryDestination.configs.forEach((config) => deliveryDestinationConfigs.push(config));
        });
        const permittedConfigs = deliveryDestinationConfigs.filter(
          ({ jobs }) =>
            !(
              jobs?.every(({ status }) => status === DeliveryDestinationJobStatusEnum.COMPLETED) ||
              jobs?.every(({ status }) => status === DeliveryDestinationJobStatusEnum.STARTED) ||
              jobs?.some(({ status }) => status === DeliveryDestinationJobStatusEnum.NOT_INITIALIZED)
            ),
        );
        return permittedConfigs?.length ? { asset, delivery_destinations: permittedConfigs } : null;
      })
      .filter(Boolean);
    const jobs: RetryDeliveryDestination['jobs'] = [];
    availableConfigs.forEach((data) => {
      data?.delivery_destinations.forEach((config) => {
        jobs.push({ item_uuid: data?.asset.orderUuid, config_uuid: config.uuid });
      });
    });
    return jobs;
  }

  #retryDeliveryDestinationsAction(uuid: string, data?: OrderAssetViewModel2[]) {
    const startPoint = data ? of(data) : this.currentOrderAssets.allData$.pipe(take(1));

    startPoint
      .pipe(
        switchMap((assets) => {
          const message = this.#chooseCorrectRetryDDMessage(assets);
          return combineLatest([
            this.confirmationDialog.open({
              message,
            }),
            of(assets),
          ]);
        }),
        take(1),
        switchMap(([result, all]) => {
          if (!result) {
            return EMPTY;
          }

          const payload: RetryDeliveryDestination = {
            jobs: this.generateRetryDeliveryDestinationsPayload(data ?? all),
          };

          if (!payload.jobs?.length) {
            this.toastService.error({
              id: 'dd_retry',
              message: 'common.delivery_destinations.retry_dd.no_assets_to_retry',
            });
            return EMPTY;
          }

          return this.retryDeliveryDestinations(uuid, payload);
        }),
      )
      .subscribe();
  }

  #chooseCorrectRetryDDMessage(data: OrderAssetViewModel2[]): string {
    !Array.isArray(data) && (data = [data]);
    const assetAlreadyProcessing = data.some((asset) => asset.assetAvailableToRetrigger);

    return assetAlreadyProcessing
      ? 'common.delivery_destinations.retry_dd.already_retried'
      : 'common.delivery_destinations.retry_dd.retry';
  }
}
