import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  ActivatedClientModule,
  ActivatedClientService,
  Permission,
  PermissionService,
} from '@vdms-hq/activated-client';
import {
  CollectionAccessTypeEnum,
  CollectionModelFlat,
  DownloadTypeEnum,
  OrderItemTypeEnum,
} from '@vdms-hq/api-contract';
import {
  AssetActionsService,
  AssetResults2Component,
  AssetResultsModule,
  ContextMenuActionsService,
  ResultsActions,
} from '@vdms-hq/asset-results';
import { AuthService } from '@vdms-hq/auth';
import { AddToCartActionsService, CartStateService } from '@vdms-hq/cart-core';
import { DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { E2eIdDirective } from '@vdms-hq/shared';
import {
  ActionContextLess,
  ActionIdentifier,
  DataAction as Action,
  DataAction,
  DataPresentationHeaderComponent,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIButtonModule,
  UIConfirmationDialogService,
  UILayoutModule,
  UILoaderModule,
  UIResultsWrapperModule,
  UISimpleDetailsListModule,
  UIStatsModule,
} from '@vdms-hq/ui';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  Observable,
  shareReplay,
  startWith,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SingleCollectionAssetsDataSource } from '../../logic/datasources/single-collection-assets-ds.service';
import { SingleCollectionSubDataSource } from '../../logic/datasources/single-collection-sub-data-source';
import {
  CollectionAssetViewModel,
  CollectionsActionsService,
  COLLECTIONS_ROUTER_BASE,
  SingleCollectionViewModel,
} from '@vdms-hq/collections';
import { CollectionAssetsFilterService } from '../../logic/services/collection-assets-filter.service';
import { SingleCollectionService } from '../../logic/services/single-collection.service';
import { CollectionsRefresh } from '@vdms-hq/collections-public';
import { TABLE_TYPE } from '@vdms-hq/view-settings';
import { FieldsFetcherService, FieldsScopeKey } from '@vdms-hq/fields';
import { LicensePackagesActionsService } from '@vdms-hq/license-packages';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { CollectionActivityDialogComponent } from '../../components/collection-activity-dialog/collection-activity-dialog.component';
import { BrowseAssetViewModel } from '@vdms-hq/asset-search';
import { OrderActionsService, FastOrdersService, FastOrderLoadingState } from '@vdms-hq/orders';
import { ToastService } from '@vdms-hq/toast';

@Component({
  selector: 'vdms-hq-single-collection',
  templateUrl: './single-collection.component.html',
  styleUrls: ['./single-collection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UILayoutModule,
    UIResultsWrapperModule,
    UISimpleDetailsListModule,
    UILoaderModule,
    TranslateModule,
    UIButtonModule,
    MatTooltipModule,
    MatDividerModule,
    AssetResultsModule,
    ActivatedClientModule,
    AssetResults2Component,
    DataPresentationHeaderComponent,
    DynamicFiltersModule,
    MultipleDataPresentationComponent,
    E2eIdDirective,
    MatMenu,
    MatMenuTrigger,
    MatMenuItem,
    // StatsCounterComponent,
    UIStatsModule,
  ],
  providers: [SingleCollectionSubDataSource],
})
export class SingleCollectionComponent implements OnDestroy, OnInit {
  private confirmationDialog: UIConfirmationDialogService = inject(UIConfirmationDialogService);
  public collectionAssetDataSource: SingleCollectionAssetsDataSource = inject(SingleCollectionAssetsDataSource);
  public subCollectionDataSource: SingleCollectionSubDataSource = inject(SingleCollectionSubDataSource);
  public dataSource: SingleCollectionService = inject(SingleCollectionService);
  public collectionsActions: CollectionsActionsService = inject(CollectionsActionsService);
  public addToCartActionsService: AddToCartActionsService = inject(AddToCartActionsService);
  public cartStateService: CartStateService = inject(CartStateService);
  private collectionsRefresher: CollectionsRefresh = inject(CollectionsRefresh);
  public filterService: CollectionAssetsFilterService = inject(CollectionAssetsFilterService);
  private permissionService: PermissionService = inject(PermissionService);
  private assetActionsService: AssetActionsService = inject(AssetActionsService);
  private contextMenuActions: ContextMenuActionsService = inject(ContextMenuActionsService);
  private auth: AuthService = inject(AuthService);
  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private fieldsFetcherService: FieldsFetcherService = inject(FieldsFetcherService);
  private activatedClientService: ActivatedClientService = inject(ActivatedClientService);
  private licensePackagesActionsService: LicensePackagesActionsService = inject(LicensePackagesActionsService);
  private fastOrdersService: FastOrdersService = inject(FastOrdersService);
  private orderActionsService: OrderActionsService = inject(OrderActionsService);
  private toastService: ToastService = inject(ToastService);

  protected readonly TABLE_TYPE = TABLE_TYPE;
  protected readonly assetResultsMenuConfig2: DataAction<CollectionAssetViewModel>[] = [
    {
      key: 'tab',
      icon: 'tab',
      label: 'Open in new tab',
    },
    {
      key: 'window',
      icon: 'window',
      label: 'Open in new window',
    },
    {
      key: 'uuid',
      icon: 'uuid',
      label: 'Copy asset Uuid',
    },
  ];
  protected readonly ResultsActions = ResultsActions;
  Permission = Permission;
  destroy$ = new Subject<void>();

  scopeName: FieldsScopeKey = 'collections';
  currentCollectionUuid = new BehaviorSubject<string>('');
  actions: Action<CollectionModelFlat>[] = [];
  subCollectionConfig: MultipleViewConfiguration<CollectionModelFlat> = {
    multiView: {
      fitToVisibleArea: false,
    },
    gridAdvanced: {
      layout: {
        columns: 'up-to-2',
      },
      actions: this.actions,
      content: {
        titlePath: 'name',
        metadata: [
          {
            label: 'common.collection_type',
            valuePath: 'access_type',
            viewFormat: {
              modifiers: {
                textTransform: 'capitalize',
              },
            },
          },
          {
            label: 'common.collection_owner',
            valuePath: 'owner_name',
            hiddenIf: (item: CollectionModelFlat) => item.access_type !== CollectionAccessTypeEnum.OWNED,
          },
          {
            label: 'common.assets_number',
            valuePath: 'number_of_assets',
            viewFormat: {
              fallback: 'Empty',
            },
          },
          {
            label: 'common.created_at',
            valuePath: 'created_at',
            viewFormat: {
              modifiers: {
                dateFormat: 'date-time',
              },
            },
          },
          {
            label: 'common.updated_at',
            valuePath: 'updated_at',
            viewFormat: {
              modifiers: {
                dateFormat: 'date-time',
              },
            },
          },
          {
            label: 'common.teams',
            valuePath: 'teams',
            fullLine: true,
            viewFormat: {
              fallback: 'N/A',
            },
          },
          {
            label: 'common.description',
            valuePath: 'description',
            fullLine: true,
            viewFormat: {
              fallback: 'N/A',
            },
          },
        ],
      },
      image: {
        bgPath: 'custom_cover_path',
        bgHoverPath: 'custom_cover_path',
      },
    },
  };

  isDashboardCollection$ = this.dataSource.collectionData$.pipe(
    map((data) => data.access_type === 'dashboard'),
    shareReplay(1),
  );

  headerActions$: Observable<ActionContextLess[]> = combineLatest([
    this.collectionAssetDataSource.total$,
    this.activatedClientService.permissions$,
    this.activatedClientService.clientDefinite$,
    this.collectionsActions.buildEditCollectionAction$<CollectionAssetViewModel>(),
    this.collectionsActions.buildExportMetadataAction$<CollectionAssetViewModel>(),
  ]).pipe(
    map(([total, permissions, clientConfig, editCollection, exportMetadata]) => [
      editCollection,
      exportMetadata,
      {
        key: ResultsActions.ADD_TO_LICENSED_PACKAGE,
        label:
          total <= 500 ? 'tooltip.send_filter_to_licensed_package' : 'tooltip.send_filter_to_licensed_package_limited',
        disabled: total === 0 || total > 500,
        icon: 'folder_special',
        hiddenIf: () => !permissions.includes(Permission.EDIT_LICENSED_PACKAGES),
      },
      {
        key: ResultsActions.SET_AS_COLD,
        label: total <= 500 ? 'tooltip.set_filtered_as_cold' : 'tooltip.set_filtered_as_cold_limited',
        disabled: total === 0 || total > 500,
        hiddenIf: () =>
          !clientConfig?.vida?.coldStorageEnabled || !permissions.includes(Permission.SET_ASSETS_AS_COLD_ONLY),
        icon: 'ac_unit',
      },
    ]),
    map((actions) => (actions as ActionContextLess[]).filter((action) => action !== null)),
  );

  actions$: Observable<DataAction<CollectionAssetViewModel>[]> = this.isDashboardCollection$.pipe(
    switchMap((condition) => {
      return combineLatest([
        this.addToCartActionsService.buildAddAssetAction$<CollectionAssetViewModel>(),
        this.collectionsActions.buildAddToCollectionAction$<CollectionAssetViewModel>(),
        this.collectionsActions.buildDeleteAssetFromCollectionAction$<CollectionAssetViewModel>(condition),
        this.assetActionsService.buildQAResultsAction<CollectionAssetViewModel>(),
        this.assetActionsService.buildPreviewAction$<CollectionAssetViewModel>(),
        this.assetActionsService.buildImposeQuarantineAction$<CollectionAssetViewModel>(),
        this.assetActionsService.buildLiftQuarantineAction$<CollectionAssetViewModel>(),
      ]).pipe(map((actions) => actions.filter((action) => action !== null)));
    }),
  );

  subCollectionsOnlyView$ = this.dataSource.subCollectionsOnlyView$;

  subCollectionConfig$ = this.subCollectionsOnlyView$.pipe(
    map((subCollectionsOnly) =>
      subCollectionsOnly
        ? { ...this.subCollectionConfig, multiView: { fitToVisibleArea: true } }
        : this.subCollectionConfig,
    ),
    takeUntil(this.destroy$),
  );
  canMoveToCold$ = this.activatedClientService.coldStorageEnabled$;
  selectedVirtualAssets$?: Observable<boolean>;
  selectedQuarantinedAssets$?: Observable<boolean>;
  selectedColdAssets$?: Observable<boolean>;

  isDownloadLoading: FastOrderLoadingState = { downloadNowLoading: false, sendToDownloadsLoading: false };
  dialog = inject(MatDialog);

  ngOnInit() {
    this.dataSource.uuid$.pipe(takeUntil(this.destroy$)).subscribe((uuid) => {
      this.currentCollectionUuid.next(uuid);
    });

    this.collectionsRefresher.clearSelection$.pipe(takeUntil(this.destroy$)).subscribe((clear) => {
      if (clear && this.collectionAssetDataSource.selection) {
        this.collectionAssetDataSource.selection.clear();
        this.collectionsRefresher.clearSelection$.next(false);
      }
    });

    this.activatedRoute.params.pipe(distinctUntilChanged()).subscribe((params: Params) => {
      this.dataSource.setUuid(params['uuid']);
      this.subCollectionDataSource.pageIndex$.next(0);
    });
    this.filterService.listenUntil(this.destroy$);
    this.filterService.initConfig();

    this.auth.authDefinite$.pipe(take(1), takeUntil(this.destroy$)).subscribe((user) => this.#buildActions(user.id));
    this.selectedVirtualAssets$ = this.collectionAssetDataSource.selection.entities$.pipe(
      takeUntil(this.destroy$),
      map((selection) => selection.some((asset) => asset.isVirtual)),
    );
    this.selectedQuarantinedAssets$ = this.collectionAssetDataSource.selection.entities$.pipe(
      takeUntil(this.destroy$),
      map((selection) => selection.some((asset) => asset.isQuarantined)),
    );
    this.selectedColdAssets$ = this.collectionAssetDataSource.selection.entities$.pipe(
      takeUntil(this.destroy$),
      map((selection) => selection.some((asset: BrowseAssetViewModel) => asset.isColdAsset)),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.dataSource.destroy();
    this.collectionAssetDataSource.selection.clear();
  }

  hasAnalyticsPermission$ = this.permissionService.verifyWithOwnedPermissions$([
    Permission.BROWSE_COLLECTIONS_ANALYTICS,
  ]);

  #buildActions(userUuid: string): void {
    this.cartStateService.isEnabled$
      .pipe(
        takeUntil(this.destroy$),
        startWith(false),
        withLatestFrom(
          this.cartStateService.isClipOnlyAndPermitted$,
          this.permissionService.verifyWithOwnedPermissions$([Permission.SHARE_COLLECTIONS]),
        ),
      )
      .subscribe(([isDisabled, permitted, permittedToShare]) => {
        this.actions = permittedToShare
          ? [
              {
                key: 'sub.share',
                icon: 'share',
                onDoubleClick: false,
                label: 'common.global.share',
                hiddenIf: (element: CollectionModelFlat | undefined) => element?.owner !== userUuid,
              },
            ]
          : [];

        if (permitted) {
          this.actions.push({
            key: 'sub.cart',
            icon: 'add_shopping_cart',
            onDoubleClick: false,
            label: 'common.global.add_to_cart',
            disabledIf: () => isDisabled,
          });
        }

        this.permissionService
          .filterItemsAgainstPermissions$([
            {
              key: 'sub.collection',
              icon: 'playlist_add',
              onDoubleClick: false,
              label: 'common.global.add_to_collection',
              permissions: { ids: [Permission.EDIT_COLLECTIONS] },
              hiddenIf: (element: CollectionModelFlat | undefined) => element?.access_type === 'dashboard',
            },
            {
              key: 'sub.delete',
              icon: 'delete',
              onDoubleClick: false,
              label: 'common.global.delete',
              permissions: { ids: [Permission.EDIT_COLLECTIONS] },
              hiddenIf: (element: CollectionModelFlat) => element.owner !== userUuid,
            },
            {
              key: 'sub.delete',
              icon: 'delete',
              onDoubleClick: false,
              label: 'common.global.remove',
              permissions: { ids: [Permission.EDIT_COLLECTIONS] },
              hiddenIf: (element: CollectionModelFlat) => element.owner === userUuid,
            },
          ])
          .pipe(take(1))
          .subscribe((config) => {
            this.actions = [...(this.actions || []), ...config] as Action<CollectionModelFlat>[];
            this.actions.push({
              key: 'sub.preview',
              onDoubleClick: true,
              label: 'common.global.open_collection',
              icon: 'visibility',
            });
            if (this.subCollectionConfig.gridAdvanced) {
              this.subCollectionConfig.gridAdvanced.actions = this.actions;
            }
          });
      });
  }

  onActivityLogAction() {
    this.dataSource.collectionData$
      .pipe(
        take(1),
        switchMap((collection) => {
          return this.dialog
            .open(CollectionActivityDialogComponent, {
              data: {
                collectionName: collection.name,
                collectionUuid: collection.uuid,
              },
            })
            .afterClosed();
        }),
      )
      .subscribe();
  }

  customActionHandler($event: { key: ResultsActions | ActionIdentifier | string; item?: any }) {
    const assetIds: { asset_uuid: string; item_uuid: string }[] = [];
    const selection = this.collectionAssetDataSource.selection;

    if ($event.item) {
      assetIds.push({ asset_uuid: $event.item.props.asset_uuid, item_uuid: $event.item.props.item_uuid });
      this.handleActions($event, assetIds);
      return true;
    } else {
      const selected = selection.identifiersSnapshot as string[];
      this.collectionAssetDataSource.allDataUuidsLoaded$.pipe(take(1)).subscribe((loaded) => {
        selected.forEach((item: string) => {
          const assetLoadedData = loaded.find((data) => data.asset_uuid === item);
          if (assetLoadedData) {
            assetIds.push({ asset_uuid: assetLoadedData.asset_uuid, item_uuid: assetLoadedData.item_uuid as string });
          }
        });
        this.handleActions($event, assetIds);
      });
      return true;
    }
  }

  handleActions(
    $event: { key: ResultsActions | ActionIdentifier | string; item?: any },
    assetIds: { asset_uuid: string; item_uuid: string }[],
  ) {
    const asset_uuids = assetIds.map((assetId) => assetId.asset_uuid);
    const item_uuids = assetIds.map((assetId) => assetId.item_uuid);

    this.contextMenuActions.handleAction($event);
    switch ($event.key) {
      case ResultsActions.QUALITY_CONTROL_RESULTS:
        this.assetActionsService.handleQAResultsAction($event);
        return true;
      case ResultsActions.BATCH_UPDATE:
        this.assetActionsService.popBatchUpdateDialog(asset_uuids, 'collection_batch_update');
        return true;
      case ResultsActions.ASSET_COLLECTION:
        this.collectionsActions.addAssetsToCollection(asset_uuids, $event.item ? $event.item.collections : undefined);
        return true;
      case ResultsActions.ADD_TO_CART:
        this.addToCartActionsService.addAssets(asset_uuids.map((assetId) => ({ assetId })));
        return true;
      case ResultsActions.ASSET_DELETE:
        this.collectionsActions.removeAssets(this.currentCollectionUuid.value, item_uuids);
        return true;
      case ResultsActions.DESELECT_ALL:
        this.collectionAssetDataSource.selection.clear();
        return true;
      case ResultsActions.ASSET_PREVIEW:
        this.router.navigate(['/asset', $event.item.props.asset_uuid]);
        return true;
      case ResultsActions.ADD_TO_LICENSED_PACKAGE:
        this.licensePackagesActionsService.addAssetsToPackage(asset_uuids);
        return true;
      case ResultsActions.SET_AS_COLD:
        this.assetActionsService.setAsCold(asset_uuids).subscribe();
        return true;
      case ResultsActions.BATCH_IMPOSE_QUARANTINE:
      case ResultsActions.IMPOSE_QUARANTINE:
        this.confirmationDialog
          .open({
            title: 'Impose quarantine',
            message: 'Are you sure you want to impose quarantine on selected assets?',
          })
          .pipe(
            take(1),
            filter((confirmed) => confirmed),
            switchMap(() => this.assetActionsService.imposeQuarantine(asset_uuids)),
          )
          .subscribe();
        return true;
      case ResultsActions.BATCH_LIFT_QUARANTINE:
      case ResultsActions.LIFT_QUARANTINE:
        this.confirmationDialog
          .open({
            title: 'Lift quarantine',
            message: 'Are you sure you want to lift quarantine for selected assets?',
          })
          .pipe(
            take(1),
            filter((confirmed) => confirmed),
            switchMap(() => this.assetActionsService.liftQuarantine(asset_uuids)),
          )
          .subscribe();
        return true;
      case ResultsActions.SEND_TO_DOWNLOADS:
        this.isDownloadLoading = { sendToDownloadsLoading: true };
        this.fastOrdersService
          .fastDownload(asset_uuids, OrderItemTypeEnum.ASSET)
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.toastService.success({
                id: 'send_to_downloads',
                message: 'common.notifications.generic.sent_to_downloads_success',
              });
              this.isDownloadLoading = { sendToDownloadsLoading: false };
            },
            error: () => (this.isDownloadLoading = { sendToDownloadsLoading: false }),
          });
        return true;
      case ResultsActions.DOWNLOAD_NOW:
        this.isDownloadLoading = { downloadNowLoading: true };
        this.fastOrdersService
          .fastDownload(asset_uuids, OrderItemTypeEnum.ASSET)
          .pipe(
            take(1),
            switchMap(async (response) =>
              this.orderActionsService.download(response.uuid, [], {
                download_type: DownloadTypeEnum.ASPERA,
              }),
            ),
          )
          .subscribe(() => (this.isDownloadLoading = { downloadNowLoading: false }));
        return true;
      default:
        return false;
    }
  }

  handleHeaderAction($event: { key: string }): boolean {
    const appliedFilters = this.collectionAssetDataSource.filters$.value;
    const appliedFiltersAsAssetSearch = {
      facility_of_origin: appliedFilters.facilityOfOrigin ? [appliedFilters.facilityOfOrigin as string] : undefined,
      tx_date: appliedFilters.txDate
        ? {
            from: appliedFilters.txDate?.from?.toDate(),
            to: appliedFilters.txDate?.to?.toDate(),
          }
        : undefined,
    };

    switch ($event.key) {
      case ResultsActions.EXPORT_COLLECTIONS:
        this.exportCSV();
        return true;
      case ResultsActions.EDIT_COLLECTION:
        this.editCollection();
        return true;
      case ResultsActions.ADD_TO_LICENSED_PACKAGE:
        this.licensePackagesActionsService.addFiltersToPackage({
          text: appliedFilters.text,
          filters: {
            ...appliedFiltersAsAssetSearch,
            collection_uuid: [this.currentCollectionUuid.value],
          },
        });
        return true;
      case ResultsActions.SET_AS_COLD:
        this.assetActionsService
          .setFiltersAsCold({
            text: appliedFilters.text,
            filters: {
              ...appliedFiltersAsAssetSearch,
              collection_uuid: [this.currentCollectionUuid.value],
            },
          })
          .subscribe();
        return true;
      default:
        return false;
    }
  }

  editCollection() {
    this.dataSource.collectionData$
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((collection) => {
          this.collectionsActions.openEditDialog(collection, true);
        }),
      )
      .subscribe();
  }

  exportCSV() {
    this.confirmationDialog
      .open({
        title: 'Please confirm export action',
        message: 'If you confirm, we’ll send you a CSV with applied filters via email shortly.',
      })
      .pipe(
        filter((confirmed) => confirmed),
        takeUntil(this.destroy$),
        withLatestFrom(this.dataSource.collectionData$, this.fieldsFetcherService.getConfiguration$(this.scopeName)),
        switchMap(([, collection, config]) => {
          return this.collectionsActions.exportCollection(collection.uuid, config.table?.getForExport() ?? [], {
            orderBy: this.collectionAssetDataSource.sortBy$.value,
            orderDir: this.collectionAssetDataSource.sortDirection$.value,
          });
        }),
      )
      .subscribe();
  }

  addCollectionToCart() {
    this.dataSource.collectionData$
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        map((value: SingleCollectionViewModel) => value.uuid),
        tap((uuid) => {
          this.addToCartActionsService.addCollections([uuid]);
        }),
      )
      .subscribe();
  }

  emitAction($event: { key: string; item?: SingleCollectionViewModel }) {
    if (!$event.item) {
      return;
    }

    switch ($event.key) {
      case 'sub.delete':
        this.collectionsActions.removeSubCollection($event.item, this.currentCollectionUuid.value);
        this.collectionsRefresher.refreshSubCollectionsPagination$
          .pipe(takeUntil(this.destroy$), filter(Boolean))
          .subscribe(() => this.subCollectionDataSource.pageIndex$.next(0));
        break;
      case 'sub.cart':
        this.addToCartActionsService.addCollections([$event.item.uuid]);
        break;
      case 'sub.collection':
        this.collectionsActions.addToCollection($event.item.uuid);
        break;
      case 'sub.share':
        this.collectionsActions.openShareDialog({ collection: $event.item });
        break;
      case 'sub.preview':
        this.router.navigate([COLLECTIONS_ROUTER_BASE.COLLECTIONS, $event.item.uuid]).then();
        break;
    }
  }
}
