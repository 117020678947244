import { SimpleType } from '../types.model';

export interface ExportableField {
  value: string;
  label: string;
  default?: boolean;
}

export enum RightContractsEndpoints {
  ROOT = 'rights-contract',
  ROOT_LIST = 'rights-contracts',
}

export enum RightContractsEndpointsOptions {
  EXPORT = 'export',
  LOGS = 'logs',
}

export interface RightsContractsExportMetadataPost {
  fields: ExportableField[];
}

export interface RightsContractsLog {
  user: string;
  message: string;
  date: string;
  base_object: string;
  changeset: string;
}

export type RightsContractsFiltersView = {
  keyword: string;
  sort: string;
  partners?: string[];
  licensed_package_uuid?: string;
  start_before?: string;
  end_after?: string;
};

// GET /rights-contracts
export interface ContractListItem {
  uuid: string;
  name: string;
  contract_id: string;
  license_id: string;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
  status: string;
  partner: {
    uuid: string;
    name: string;
    partner_users_count: number;
  };
  licensed_packages: {
    uuid: string;
    name: string;
  }[];
}

// GET /rights-contract/:uuid
export interface ContractGet {
  uuid: string;
  name: string;
  contract_id?: string;
  license_id?: string;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
  download_limit?: number;
  partner: SimpleType & {
    partner_users_count: number;
  };
  licensed_packages: SimpleType[];
  status: RightsContractStatus;
  notifications_enabled: boolean;
}

// POST /rights-contract
export interface ContractCreate {
  name: string;
  start_date: string;
  end_date: string;
  partner_uuid: string;
  licensed_package_uuids: string[];
  contract_id: string;
}

// PATCH /rights-contract/:uuid
export interface ContractUpdate extends ContractCreate {
  contract_id: string;
  licensed_packages_to_attach: string[];
  licensed_packages_to_detach: string[];
}

export enum RightsContractStatus {
  COLLECTING_CONTENT = 'collecting_content',
  READY_TO_RELEASE = 'ready_to_release',
  FIRST_NOTIFICATION_SENT = 'first_notification_sent',
  SECOND_NOTIFICATION_SENT = 'second_notification_sent',
}
