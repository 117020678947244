<vdms-hq-ui-layout-page-wrapper
  [breadcrumbs]="[{ name: 'Filename Conventions' }]"
  [buttonConfig]="breadcrumbsButtonConfig"
  [relative]="true"
  (buttonAction)="handleBreadcrumbsAction($event)"
>
  <ng-container actions>
    <div class="header--actions__wrapper">
      <vdms-hq-ui-button
        *requiresPermissions="[Permission.CREATE_FILENAME_CONVENTIONS]"
        (click)="addToConventionsForm()"
        color="primary"
        >Add new</vdms-hq-ui-button
      >
    </div>
  </ng-container>
  <div class="content">
    <ng-container [formGroup]="form">
      <div formArrayName="conventions">
        <div
          class="content__row"
          *ngFor="let convention of conventionsFormArray.controls; let index = index"
          [formGroupName]="index"
        >
          <div class="content__row__controls">
            <vdms-hq-ui-form-input-text formControlName="name" [label]="'Name'"></vdms-hq-ui-form-input-text>
            <vdms-hq-ui-form-input-text
              [withFooter]="true"
              formControlName="template"
              [label]="'Pattern'"
            ></vdms-hq-ui-form-input-text>
            <div class="content__row__controls__warning">
              <ng-container *ngIf="extWarning$ | async as extWarning">
                <mat-icon
                  *ngIf="extWarning[index]"
                  [matTooltip]="
                    'This pattern appears to include a file extension. Please make sure there are no file extensions included in the pattern.'
                  "
                >
                  error_outline
                </mat-icon>
              </ng-container>
            </div>
            <vdms-hq-ui-form-input-select
              formControlName="separator"
              [selectOptions]="separatorSelectOptions"
              [isSortedByLabel]="true"
              [label]="'Separator'"
            ></vdms-hq-ui-form-input-select>

            <div class="content__row__controls__actions">
              <vdms-hq-ui-button
                [icon]="'delete'"
                [iconOnly]="true"
                (click)="remove(index)"
                [disabled]="(canEdit$ | async) === false || (canDelete$ | async) === false"
                color="warn"
              ></vdms-hq-ui-button>
              <vdms-hq-ui-button
                (click)="save(index)"
                color="primary"
                [icon]="'save'"
                [iconOnly]="true"
                [disabled]="(canEdit$ | async) === false || (canSave$ | async) === false || convention.pristine"
              ></vdms-hq-ui-button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="conventionsFormArray.controls.length > 0">
      <div class="default-for">
        <mat-tab-group>
          <mat-tab *ngFor="let defaultForType of singleDefaultForType" [label]="defaultForType.label | titlecase">
            <ng-template matTabContent>
              <h2 class="default-for__title">Default for {{ defaultForType.label | titlecase }}</h2>
              <div
                class="content__row"
                *ngFor="let control of $FormArray(defaultForForm.get(defaultForType.key))?.controls"
                [formGroup]="$FormGroup(control)"
              >
                <div class="content__row__controls single">
                  <h3>{{ capitalize(control.value.assetType) }}:</h3>
                  <vdms-hq-ui-form-input-select
                    [selectOptions]="(conventionsSelectOptions$ | async) ?? []"
                    formControlName="conventionUuid"
                    [label]="'Convention Name'"
                  ></vdms-hq-ui-form-input-select>
                </div>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab
            *ngFor="let defaultForType of multipleDefaultForType; let index = index"
            [label]="defaultForType.label | titlecase"
          >
            <ng-template matTabContent>
              <h2 class="default-for__title">Default for {{ defaultForType.label | titlecase }}</h2>
              <div
                class="content__row"
                *ngFor="let control of $FormArray(defaultForForm.get(defaultForType.key))?.controls; let i = index"
                [formGroup]="$FormGroup(control)"
              >
                <div class="content__row__controls">
                  <vdms-hq-ui-form-input-select
                    formControlName="assetTypes"
                    [label]="'Asset Type'"
                    [selectOptions]="assetTypesSelectOptions"
                    [multiple]="true"
                  ></vdms-hq-ui-form-input-select>
                  <vdms-hq-ui-form-input-select
                    [selectOptions]="(conventionsSelectOptions$ | async) ?? []"
                    formControlName="conventionUuid"
                    [label]="'Convention Name'"
                  ></vdms-hq-ui-form-input-select>

                  <div class="content__row__controls__actions">
                    <vdms-hq-ui-button
                      [icon]="'delete'"
                      [iconOnly]="true"
                      (click)="removeDefaultFor(i, defaultForType.key)"
                      [disabled]="
                        (canEdit$ | async) === false ||
                        (canDelete$ | async) === false ||
                        (control.dirty &&
                          control.get('initialUuid')?.value &&
                          control.get('initialUuid')?.value !== control.get('conventionUuid')?.value)
                      "
                      color="warn"
                    ></vdms-hq-ui-button>
                    <vdms-hq-ui-button
                      (click)="saveDefaultFor(control, defaultForType.key)"
                      color="primary"
                      [icon]="'save'"
                      [iconOnly]="true"
                      [disabled]="(canSaveDefaultFor$ | async) === false || control.pristine || control.invalid"
                    ></vdms-hq-ui-button>
                    @if (
                      control.dirty &&
                      control.get('initialUuid')?.value &&
                      (control.get('initialUuid')?.value !== control.get('conventionUuid')?.value ||
                        control.get('initialAssetTypes')?.value !== control.get('assetTypes')?.value)
                    ) {
                      <vdms-hq-ui-button
                        (click)="removeDefaultFor(i, defaultForType.key, true)"
                        color="primary"
                        [icon]="'restart_alt'"
                        [iconOnly]="true"
                      ></vdms-hq-ui-button>
                    }
                  </div>
                </div>
              </div>
              <div class="content__row">
                <div class="content__row__controls">
                  <vdms-hq-ui-button (click)="appendEmptyDefaultFor(defaultForType.key)" color="primary"
                    >Add new</vdms-hq-ui-button
                  >
                </div>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </ng-container>
  </div>

  <vdms-hq-ui-loader [mode]="'over-parent'" [backdrop]="true" *ngIf="(isLoading$ | async) ?? false"></vdms-hq-ui-loader>
</vdms-hq-ui-layout-page-wrapper>
