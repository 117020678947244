type Permission = unknown;

export interface ItemPermissions {
  ids: Permission[];
  comparison?: 'some' | 'every';
  required?: Permission[];
}

export interface PermissionAwareItem {
  [key: string]: any;
  permissions?: ItemPermissions;
}

export type MenuSectionWithPermissions = PermissionAwareItem & {
  [key: string]: any;
  menuItems: PermissionAwareItem[];
};

export const isMenuSectionWithPermissions = (item: any): item is MenuSectionWithPermissions => {
  return item && Array.isArray(item.menuItems);
};
