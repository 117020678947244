import { SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import { inject, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { filter, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AdminFieldsConfigService, FormSettings } from './admin-fields-config.service';
import { ToastService } from '@vdms-hq/toast';
import { UIConfirmationDialogService } from '@vdms-hq/ui';
import { ClientPostSavePageRefresher } from '@vdms-hq/activated-client-admin';

@Injectable({
  providedIn: 'root',
})
export class AdminFieldsFormService {
  #adminFieldsConfigService = inject(AdminFieldsConfigService);
  #confirmationDialog = inject(UIConfirmationDialogService);
  #clientPostSavePageRefresher = inject(ClientPostSavePageRefresher);

  #toastService = inject(ToastService);
  #activeType?: 'table' | 'list' | 'filters';

  isLoading$ = new BehaviorSubject(false);
  isSaving$ = new BehaviorSubject(false);

  #initialSettings = {
    canRemoveFromAvailable: false,
    canRevertToDefault: false,
    isInheritedFromDefault: false,
  };
  allFields$ = new BehaviorSubject<SelectOption[]>([]);
  settings$ = new BehaviorSubject<FormSettings>(this.#initialSettings);

  form = new FormGroup({
    dragDrop: new FormControl<{
      defaultList: SelectOptionKey[];
      availableList: SelectOptionKey[];
      outsideList: SelectOptionKey[];
    }>({
      defaultList: [],
      availableList: [],
      outsideList: [],
    }),
  });

  get readConfigStream$() {
    if (this.#activeType === 'filters') {
      return this.#adminFieldsConfigService.configForFilters$;
    }

    if (this.#activeType === 'table') {
      return this.#adminFieldsConfigService.configForTable$;
    }

    if (this.#activeType === 'list') {
      return this.#adminFieldsConfigService.configForList$;
    }

    throw Error('Invalid active type');
  }

  prepareForm(type: 'table' | 'list' | 'filters') {
    this.isLoading$.next(true);
    this.#activeType = type;
    this.form.controls.dragDrop.setValue({
      defaultList: [],
      availableList: [],
      outsideList: [],
    });
    this.allFields$.next([]);
    this.settings$.next(this.#initialSettings);

    this.readConfigStream$.pipe(take(1)).subscribe((config) => {
      this.form.controls.dragDrop.setValue({
        defaultList: config.defaultList,
        availableList: config.availableList,
        outsideList: config.outsideList,
      });

      this.allFields$.next(config.all);
      this.settings$.next(config.settings);
      this.isLoading$.next(false);
    });
  }

  reset() {
    if (!this.#activeType) {
      return;
    }

    this.prepareForm(this.#activeType);
  }

  revertToDefault() {
    this.#confirmationDialog
      .open()
      .pipe(
        filter(Boolean),
        switchMap(() => {
          switch (this.#activeType) {
            case 'list':
              return this.#adminFieldsConfigService.revertToDefaultList$();

            case 'table':
              return this.#adminFieldsConfigService.revertToDefaultTable$();

            case 'filters':
              return this.#adminFieldsConfigService.revertToDefaultFilters$();
          }
          throw Error('Invalid active type');
        }),
      )
      .subscribe(() => {
        this.#toastService.success({
          id: 'save',
          message: 'notifications.save.done',
        });
        this.reset();
      });
  }

  save() {
    this.isSaving$.next(true);
    const defaultList = this.form.value.dragDrop?.defaultList ?? [];
    const availableList = this.form.value.dragDrop?.availableList ?? [];

    let stream$;

    switch (this.#activeType) {
      case 'list':
        stream$ = this.#adminFieldsConfigService.saveList$({
          defaultList,
          availableList,
        });

        break;

      case 'table':
        stream$ = this.#adminFieldsConfigService.saveTable$({
          defaultList,
          availableList,
        });
        break;

      case 'filters':
        stream$ = this.#adminFieldsConfigService.saveFilters$({
          defaultList,
          availableList,
        });
        break;
    }

    if (!stream$) {
      throw Error('Invalid active type');
    }

    stream$.subscribe(() => {
      this.isSaving$.next(false);
      this.#toastService.success({
        id: 'save',
        message: 'notifications.save.done',
      });
      setTimeout(() => {
        this.#clientPostSavePageRefresher.askToRefresh();
      }, 1000);
    });
  }
}
