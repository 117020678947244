<vdms-hq-ui-dialog-wrapper [size]="'wide'">
  <div title>
    <span class="title">{{
      'pages.collection.activity_logs.activity_log_header' | translate: { collectionName: this.data.collectionName }
    }}</span>
  </div>

  <div content class="content">
    @if (processViewOrLoading$ | async; as processViewOrLoading) {
      @if (!processViewOrLoading.processView || processViewOrLoading.loading) {
        <vdms-hq-ui-loader></vdms-hq-ui-loader>
      } @else {
        <vdms-hq-ui-process-view [process]="processViewOrLoading.processView">
          <div title_right>
            <vdms-hq-dynamic-form-group-renderer
              [form]="filtersGroup"
              [fieldsConfig]="filters"
              [hasLoader]="true"
            ></vdms-hq-dynamic-form-group-renderer>
          </div>
        </vdms-hq-ui-process-view>
      }
    }
    <vdms-hq-ui-paginator [dataSource]="analyticsLogDataSource"> </vdms-hq-ui-paginator>
  </div>

  <ng-container footer class="buttons-group">
    <vdms-hq-ui-button (click)="this.dialogRef.close()" color="secondary">{{
      'common.global.close' | translate
    }}</vdms-hq-ui-button>
    <vdms-hq-ui-button color="primary" [loading]="exportingLoader" (click)="this.exportCSV()">{{
      'common.global.export_csv' | translate
    }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
