import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PersistenceEndpointsModel } from '../persistence-endpoints.model';
import {
  SavedLatestSearchesModel,
  UserClient,
  UserClientColumnsConfig,
  UserClientFiltersConfig,
  UserModel,
  UserUpdateAttributes,
} from './user.model';
import { snakeCaseToCamel } from '@vdms-hq/shared';
import firebase from 'firebase/compat/app';
import { firstValueFrom, from, of, zip, take } from 'rxjs';
import OrderByDirection = firebase.firestore.OrderByDirection;
import { FieldConfigId } from '@vdms-hq/fields';

@Injectable({
  providedIn: 'root',
})
export class UserContractService {
  constructor(private afs: AngularFirestore) {}

  private readonly SEARCH_FILTERS_COLLECTION_NAME = 'saved-filters-v2';
  public readonly MAX_SEARCHES_SNAPSHOTS = 30;

  /** @description this map legacy open to actions, can be removed if there will be no "open" value in any user */
  #transformLegacyKeys = (item: FieldConfigId) => {
    if (item === 'open') {
      return 'actions';
    } else if (item === 'originalSpokenLanguage') {
      return 'language';
    } else if (item === 'library') {
      return 'catalog_item_uuid';
    } else {
      return item;
    }
  };

  #preTransform = (
    items: UserClientColumnsConfig | UserClientFiltersConfig,
  ): UserClientColumnsConfig | UserClientFiltersConfig => {
    const config: UserClientColumnsConfig = {};

    Object.entries(items).forEach(([key, item]) => {
      config[key] = [...new Set(item.map(snakeCaseToCamel).map(this.#transformLegacyKeys))];
    });

    return config;
  };

  userClientData = (userEmail: string, clientId: string) => {
    return this.afs
      .doc<UserClient>(
        `${PersistenceEndpointsModel.USERS_COLLECTION}/${userEmail}/${PersistenceEndpointsModel.USERS_CLIENTS_COLLECTION}/${clientId}`,
      )
      .valueChanges()
      .pipe(catchError(() => of(null)));
  };

  userData = (email: string) => {
    return this.afs
      .doc<UserModel>(`${PersistenceEndpointsModel.USERS_COLLECTION}/${email}`)
      .valueChanges()
      .pipe(catchError(() => of(null)));
  };

  userLibraryConfig = (userEmail: string, clientId: string) =>
    this.userClientData(userEmail, clientId).pipe(
      map(
        (userClientConfig) =>
          userClientConfig?.vidaLibrary ?? {
            configs: [],
          },
      ),
    );

  updateClientProxy = (params: Partial<UserClient>) => (email: string, clientId: string) =>
    this.updateClient(email, clientId, params);

  updateClient = (email: string, clientId: string, params: Partial<UserClient>) =>
    this.afs.doc(`${PersistenceEndpointsModel.USERS_COLLECTION}/${email}/clients/${clientId}`).set(params, {
      merge: true,
    });

  saveUserSearches = (
    email: string,
    clientId: string,
    searchObj: SavedLatestSearchesModel,
    collectionName: string = this.SEARCH_FILTERS_COLLECTION_NAME,
  ) => {
    const path = `${PersistenceEndpointsModel.USERS_COLLECTION}/${email}/${PersistenceEndpointsModel.USERS_CLIENTS_COLLECTION}/${clientId}/${collectionName}`;
    return firstValueFrom(
      this.afs
        .collection<SavedLatestSearchesModel>(path, (ref) => ref.orderBy('createdAt', 'desc'))
        .get({ source: 'server' })
        .pipe(
          take(1),
          map((filters) => {
            return filters.docs.map((a) => ({
              ...a.data(),
              id: a.id,
            }));
          }),
          switchMap((searches) => {
            const deleteQueries = [];
            for (let idx = this.MAX_SEARCHES_SNAPSHOTS; idx < searches.length; idx++) {
              deleteQueries.push(this.removeUserSearchItem(searches[idx].id ?? '', email, clientId, collectionName));
            }
            if (deleteQueries.length <= 1) {
              return of([]);
            }
            return zip(deleteQueries);
          }),
          switchMap((deleted) => {
            return this.afs.collection<SavedLatestSearchesModel>(path).add(searchObj);
          }),
        ),
    );
  };

  removeUserSearchItem = (
    itemId: string,
    email: string,
    clientId: string,
    collectionName: string = this.SEARCH_FILTERS_COLLECTION_NAME,
  ) => {
    const path = `${PersistenceEndpointsModel.USERS_COLLECTION}/${email}/${PersistenceEndpointsModel.USERS_CLIENTS_COLLECTION}/${clientId}/${collectionName}`;
    return this.afs.collection<SavedLatestSearchesModel>(path).doc(itemId).delete();
  };

  getSavedUserSearches = (
    email: string,
    clientId: string,
    collectionName: string = this.SEARCH_FILTERS_COLLECTION_NAME,
    orderBy = 'createdAt',
    orderByDirection: OrderByDirection = 'desc',
  ) => {
    const path = `${PersistenceEndpointsModel.USERS_COLLECTION}/${email}/${PersistenceEndpointsModel.USERS_CLIENTS_COLLECTION}/${clientId}/${collectionName}`;
    return from(
      this.afs
        .collection<SavedLatestSearchesModel>(path, (ref) => ref.orderBy(orderBy, orderByDirection))
        .snapshotChanges()
        .pipe(
          map((filters) => {
            return filters.map((a) => ({
              ...a.payload.doc.data(),
              id: a.payload.doc.id,
            }));
          }),
        ),
    );
  };

  saveUserData = async (email: string | undefined, data: UserUpdateAttributes) => {
    return this.afs.doc(`${PersistenceEndpointsModel.USERS_COLLECTION}/${email}`).update(data);
  };
}
