import { inject, Injectable } from '@angular/core';
import { FilterableDataSource, Loadable } from '@vdms-hq/shared';
import { TranscodeService, TranscodeTypeViewModel } from '@vdms-hq/api-contract';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  shareReplay,
  switchMap,
  tap,
  map,
  Observable,
  catchError,
  EMPTY,
  of,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranscodeTypesDsService extends Loadable() implements Partial<FilterableDataSource> {
  private transcodeService = inject(TranscodeService);
  filterValue$ = new BehaviorSubject('');
  refresh$ = new BehaviorSubject(new Date());

  allTypes$ = this.refresh$.pipe(
    tap(() => {
      this.startLoading(), this.filterValue$.next('');
    }),
    switchMap(() => this.transcodeService.getAllTypes()),
    catchError(() => {
      this.stopLoading();
      return EMPTY;
    }),
    shareReplay(),
  );

  allData$: Observable<TranscodeTypeViewModel[]> = combineLatest([this.allTypes$, this.filterValue$]).pipe(
    tap(() => this.startLoading()),
    debounceTime(400),
    map(([data, value]) => {
      if (value) {
        return data
          .map((type) => TranscodeTypeViewModel.fromType(type))
          .filter((type) => {
            return (
              type.name.toLowerCase().includes(value.toLowerCase()) ||
              type.uuid.toLowerCase().includes(value.toLowerCase())
            );
          });
      }
      return data.map((type) => TranscodeTypeViewModel.fromType(type));
    }),
    tap(() => this.stopLoading()),
  );

  override emptyResults$ = this.allData$.pipe(switchMap((data) => (data.length ? of(false) : of(true))));

  connection$ = this.allData$;

  applyFilter(value: string) {
    this.filterValue$.next(value);
  }

  refresh() {
    this.refresh$.next(new Date());
  }
}
