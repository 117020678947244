import { PipeTransform, Pipe } from '@angular/core';

export enum AssetTypeEnum {
  VIDEO = 'video',
  AUDIO = 'audio',
  IMAGE = 'image',
  SUBTITLES = 'subtitles',
  ARCHIVE = 'archive',
  DOCUMENT = 'document',
  FONT = 'font',
  OTHER = 'other',
}

export type AssetType = (typeof AssetTypeEnum)[keyof typeof AssetTypeEnum];

@Pipe({ name: 'getTypeIcon', standalone: true })
export class GetTypeIconPipe implements PipeTransform {
  transform(value: AssetType): string {
    switch (value) {
      case AssetTypeEnum.VIDEO:
        return 'movie';

      case AssetTypeEnum.AUDIO:
        return 'music_note';

      case AssetTypeEnum.IMAGE:
        return 'image';

      case AssetTypeEnum.SUBTITLES:
        return 'subtitles';

      case AssetTypeEnum.ARCHIVE:
        return 'archive';

      case AssetTypeEnum.DOCUMENT:
        return 'description';

      case AssetTypeEnum.FONT:
        return 'font_download';

      default:
        return 'insert_drive_file';
    }
  }
}
