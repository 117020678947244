import { Pipe, PipeTransform } from '@angular/core';
import { RowViewConfig } from '../logic/models';

@Pipe({
  name: 'formatCells',
  standalone: true,
})
export class FormatCellsPipe implements PipeTransform {
  transform<T>(row: T, path: string, rowViewCfg: RowViewConfig<T> | undefined) {
    if (!row) {
      return;
    }

    if (!path) {
      return;
    }

    if (!rowViewCfg) {
      return;
    }

    if (!rowViewCfg.formatCells?.(row)) {
      return;
    }

    const columnKey = path.split('.').pop() ?? path;

    if (rowViewCfg.formatCells?.(row).columns.includes(columnKey)) {
      return rowViewCfg.formatCells?.(row).viewFormat;
    }

    return undefined;
  }
}
