import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedClientService, AuthorizedClient, PermissionService } from '@vdms-hq/activated-client';
import {
  AssetViewConfigKey,
  ClientFieldConfigModel,
  ColumnsConfig,
  Permission,
  UserClient,
  UserContractService,
} from '@vdms-hq/firebase-contract';
import { filterEmpty, mergeDeep, ValueFormat } from '@vdms-hq/shared';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { fieldDefinitions } from './fields/field-definitions';
import {
  FieldsConfigurationModel,
  FilterFieldsConfiguration,
  FilterFieldsConfigurationModel,
  AssetViewConfiguration,
  AssetViewConfigurationModel,
  ListFieldsConfiguration,
  ListFieldsConfigurationModel,
  TableFieldsConfiguration,
  TableFieldsConfigurationModel,
} from './fields-scoped-configuration.model';
import { AuthService } from '@vdms-hq/auth';
import { pairIdsWithDefinitions } from './helpers';
import { FilterType } from '../models/filter-type';
import {
  FieldDefinitionModel,
  FieldsScopeKey,
  FilterDefinitionModel,
  InputDefinitionModel,
  isVisibleInForm,
  isVisibleInTable,
  isVisibleSearch,
  ResultDefinitionModel,
} from '../models/field-config.id';

@Injectable({
  providedIn: 'root',
})
export class FieldsFetcherService {
  #authService = inject(AuthService);
  #userContractService = inject(UserContractService);
  #activatedClient = inject(ActivatedClientService);
  #translateService = inject(TranslateService);
  #permissionService = inject(PermissionService);
  #streams: {
    fieldsByViewKey$: Partial<Record<FieldsScopeKey, Observable<FieldDefinitionModel[]>>>;
    configuration$: Partial<Record<FieldsScopeKey, Observable<FieldsConfigurationModel>>>;
    assetViewConfig$: Partial<Record<AssetViewConfigKey, Observable<AssetViewConfigurationModel>>>;
  } = {
    fieldsByViewKey$: {},
    configuration$: {},
    assetViewConfig$: {},
  };

  #userData$ = combineLatest([
    this.#activatedClient.clientIdDefinite$,
    this.#authService.auth$.pipe(filterEmpty()),
  ]).pipe(switchMap(([clientId, auth]) => this.#userContractService.userClientData(auth.email, clientId)));

  #allFieldDefinitions$: Observable<FieldDefinitionModel[]> = this.#activatedClient.fields$.pipe(
    map<ClientFieldConfigModel[], FieldDefinitionModel[]>((clientFields) =>
      fieldDefinitions.map((fieldDefinition) => {
        const overriddenConfig = clientFields.find((clientField) => clientField.id === fieldDefinition.id)?.override;
        if (overriddenConfig) {
          return {
            ...fieldDefinition,
            input:
              fieldDefinition.input && overriddenConfig.input
                ? mergeDeep(fieldDefinition.input, {
                    ...overriddenConfig.input,
                  })
                : fieldDefinition.input,
            results: fieldDefinition.results2,
            filters:
              fieldDefinition.filters && overriddenConfig.filters
                ? mergeDeep(fieldDefinition.filters, {
                    ...overriddenConfig.filters,
                  })
                : fieldDefinition.filters,
            label: overriddenConfig.label ?? fieldDefinition.label,
            format: overriddenConfig.format ?? fieldDefinition.format,
            sourceListKey: overriddenConfig.sourceListKey ?? fieldDefinition.sourceListKey,
            isModified: true,
          };
        }
        return fieldDefinition;
      }),
    ),
    switchMap((items) =>
      this.#translateService.get(items.map((item) => item.label)).pipe(
        map((translated) =>
          items.map((item) => ({
            ...item,
            label: translated[item.label],
          })),
        ),
      ),
    ),
    switchMap((defs) => this.#activatedClient.permissions$.pipe(map((permissions) => ({ defs, permissions })))),
    map(({ defs, permissions }): FieldDefinitionModel[] => {
      return defs
        .map((item): FieldDefinitionModel => {
          if (item?.input?.writePermission) {
            item.input.readonly = !this.#permissionService.verifyPermissions(
              item?.input?.writePermission?.permissions as Permission[],
              permissions,
              item.input.writePermission.comparator,
            );
            return item;
          }
          if (item?.input?.readPermission) {
            const hasReadPermission = this.#permissionService.verifyPermissions(
              item.input.readPermission.permissions as Permission[],
              permissions,
              item.input.readPermission.comparator,
            );
            return hasReadPermission ? item : ({} as FieldDefinitionModel);
          }
          return item;
        })
        .filter((item) => !!item.id);
    }),
    shareReplay(1),
  );

  #fieldsForScope$ = (scopeKey: FieldsScopeKey): Observable<FieldDefinitionModel[]> => {
    if (!this.#streams.fieldsByViewKey$[scopeKey]) {
      this.#streams.fieldsByViewKey$[scopeKey] = this.#allFieldDefinitions$.pipe(
        map((definitions) => {
          // todo scope like 'batch_update' does not exist in FieldsScopeKey, but we can return all definitions if it's 'batch_update'???
          if (scopeKey === '_admin-all-fields' || scopeKey === '_any') {
            return definitions;
          }

          return definitions.filter((def) => def.scope.includes(scopeKey));
        }),
        shareReplay(1),
      );
    }

    return this.#streams.fieldsByViewKey$[scopeKey] as Observable<FieldDefinitionModel[]>;
  };

  getConfiguration$ = (scopeKey: FieldsScopeKey, separateStream = false) => {
    if (!this.#streams.configuration$[scopeKey] || separateStream) {
      const stream = combineLatest([this.#activatedClient.clientDefinite$, this.#fieldsForScope$(scopeKey)]).pipe(
        switchMap(([client, definitions]) => {
          return this.#userData$.pipe(
            map((user) => {
              if (user) {
                user = this.transformColumnsScope(scopeKey, user);
              }

              return new FieldsConfigurationModel(
                definitions,
                this.#getTableConfig(scopeKey, definitions, client, user ?? null),
                this.#getListConfig(scopeKey, definitions, client),
                this.#getFilters(scopeKey, definitions, client, user ?? null),
              );
            }),
          );
        }),
        shareReplay(1),
      );

      if (separateStream) {
        return stream;
      }

      this.#streams.configuration$[scopeKey] = stream;
    }

    return this.#streams.configuration$[scopeKey] as Observable<FieldsConfigurationModel>;
  };

  transformColumnsScope = (scopeKey: FieldsScopeKey, user: UserClient) => {
    if (['other-shared-packs', 'other-orders', 'other-orders-approval'].includes(scopeKey)) {
      const orderDeliveryStatusIndex =
        user.columnsConfig[scopeKey]?.findIndex((col) => col === 'orderDeliveryStatus') ?? -1;
      const orderDownloadStatusIndex =
        user.columnsConfig[scopeKey]?.findIndex((col) => col === 'orderDownloadStatus') ?? -1;
      const orderCombinedStatus = user.columnsConfig[scopeKey]?.find((col) => col === 'orderCombinedStatus');
      if ((orderDeliveryStatusIndex !== -1 || orderDownloadStatusIndex !== -1) && !orderCombinedStatus) {
        const index =
          orderDeliveryStatusIndex < orderDownloadStatusIndex ? orderDeliveryStatusIndex : orderDownloadStatusIndex;
        user.columnsConfig[scopeKey].splice(index, 0, 'orderCombinedStatus');
      }
    }

    return user;
  };

  getAssetViewConfiguration$ = (assetViewScopeKey: AssetViewConfigKey, separateStream = false) => {
    if (!this.#streams.assetViewConfig$[assetViewScopeKey]) {
      const stream = combineLatest([this.#activatedClient.clientDefinite$, this.#fieldsForScope$('default')]).pipe(
        switchMap(([client, definitions]) => {
          return this.#userData$.pipe(
            map((user) => {
              return this.#getInputConfig(assetViewScopeKey, definitions, client);
            }),
          );
        }),
        shareReplay(1),
      );

      if (separateStream) {
        return stream;
      }

      this.#streams.assetViewConfig$[assetViewScopeKey] = stream;
    }

    return this.#streams.assetViewConfig$[assetViewScopeKey] as Observable<AssetViewConfigurationModel>;
  };

  #filterOnlyInputDefs(definitions: FieldDefinitionModel[]) {
    return definitions.filter((def) => isVisibleInForm(def)) as InputDefinitionModel[];
  }

  #filterOnlyResultDefs(definitions: FieldDefinitionModel[]) {
    return definitions.filter((def) => isVisibleInTable(def)) as ResultDefinitionModel[];
  }

  #filterOnlyFilterDefs(definitions: FieldDefinitionModel[]) {
    return definitions.filter((def) => isVisibleSearch(def)) as FilterDefinitionModel[];
  }

  #addResultDefinition(allForKey: ResultDefinitionModel[], actions: 'actions' | 'select') {
    switch (actions) {
      case 'select':
        allForKey.unshift({
          format: ValueFormat.AS_IS,
          id: 'select',
          label: this.#translateService.instant('common.global.select'),
          results2: {
            objectPath: '',
            sortable: false,
          },
          scope: ['_any'],
        });
        break;
      case 'actions':
        allForKey.push({
          format: ValueFormat.AS_IS,
          id: 'actions',
          label: this.#translateService.instant('common.global.actions'),
          results2: {
            objectPath: '',
            sortable: false,
          },
          scope: ['_any'],
        });
        break;
    }
  }

  #addFilterDefinition(all: FilterDefinitionModel[], filter: 'text') {
    switch (filter) {
      case 'text':
        all.unshift({
          format: ValueFormat.AS_IS,
          id: 'text',
          label: 'Keywords',
          filters: {
            objectPath: 'text',
            validFormat: 'keyword',
            type: FilterType.MASTER_TEXT,
          },
          scope: ['_any'],
        });
        break;
    }
  }

  #getTableConfig(
    scopeKey: FieldsScopeKey,
    definitions: FieldDefinitionModel[],
    client: AuthorizedClient,
    user: UserClient | null,
  ): TableFieldsConfiguration {
    const isAssetTable = !scopeKey.startsWith('other-');
    const hasSelector =
      isAssetTable || ['other-shared-packs', 'other-orders', 'other-orders-approval'].includes(scopeKey);

    const systemList = this.#filterOnlyResultDefs(definitions);
    const userData = user?.columnsConfig?.[scopeKey] ?? [];
    const columnsConfigForKey = client?.columns?.[scopeKey];

    if (hasSelector) {
      this.#addResultDefinition(systemList, 'select');
    }

    this.#addResultDefinition(systemList, 'actions');

    const columnsConfigLegacy = client?.columnsConfig;

    let columnsConfig: ColumnsConfig;

    if (isAssetTable) {
      const columnsConfigAssetDefault = client?.columns?.['default'];

      columnsConfig = columnsConfigForKey ?? columnsConfigAssetDefault ?? columnsConfigLegacy;
    } else {
      columnsConfig =
        columnsConfigForKey ?? {
          default: systemList.map((def) => def.id),
          available: systemList.map((def) => def.id),
        } ??
        columnsConfigLegacy;
    }

    const enabledClientList = pairIdsWithDefinitions(columnsConfig.default, systemList);
    const availableClientList = pairIdsWithDefinitions(columnsConfig.available, systemList);

    const userList = userData.length > 0 ? pairIdsWithDefinitions(userData, availableClientList) : null;

    return new TableFieldsConfigurationModel(
      systemList,
      {
        isInherited: !columnsConfigForKey,
        available: availableClientList,
        enabled: enabledClientList,
      },
      userList,
      userList ?? enabledClientList,
    );
  }

  #getListConfig(
    scope: FieldsScopeKey,
    definitions: FieldDefinitionModel[],
    client: AuthorizedClient,
  ): ListFieldsConfiguration {
    const isAssetTable = !scope.startsWith('other-');
    const allForKeyResults = this.#filterOnlyResultDefs(definitions);
    const metadataConfigForScope = client?.vida?.grid?.metadataV2?.[scope];
    let defaultList: ResultDefinitionModel[] = [];

    if (isAssetTable) {
      const columnsConfigAssetDefault = client?.vida?.grid?.metadataV2?.['default'];
      const columnsConfigLegacy = client?.vida?.grid?.metadata;
      const defaultListIds = metadataConfigForScope ?? columnsConfigAssetDefault ?? columnsConfigLegacy ?? [];

      defaultList = pairIdsWithDefinitions(defaultListIds, allForKeyResults);
    } else {
      defaultList = metadataConfigForScope
        ? pairIdsWithDefinitions(metadataConfigForScope, allForKeyResults)
        : allForKeyResults;
    }

    return new ListFieldsConfigurationModel(
      allForKeyResults,
      {
        enabled: defaultList,
        isInherited: !metadataConfigForScope,
      },
      defaultList,
    );
  }

  #getInputConfig(
    assetViewScope: AssetViewConfigKey,
    definitions: FieldDefinitionModel[],
    client: AuthorizedClient,
  ): AssetViewConfiguration {
    const allWithInput = this.#filterOnlyInputDefs(definitions);

    return new AssetViewConfigurationModel(
      allWithInput,
      client.assetView[assetViewScope] ?? client.assetView['default'],
    );
  }

  #getFilters(
    scope: FieldsScopeKey,
    definitions: FieldDefinitionModel[],
    client: AuthorizedClient,
    user: UserClient | null,
  ): FilterFieldsConfiguration {
    const allForKeyResults = this.#filterOnlyFilterDefs(definitions);
    this.#addFilterDefinition(allForKeyResults, 'text');

    const enabled = pairIdsWithDefinitions(client.filters?.[scope] ?? client?.search?.filters ?? [], allForKeyResults);
    const userData = user?.filtersConfig?.[scope] ?? [];

    const userList = userData.length > 0 ? pairIdsWithDefinitions(userData, enabled) : null;

    return new FilterFieldsConfigurationModel(
      allForKeyResults,
      {
        enabled,
        isInherited: !client.filters?.[scope],
      },
      userList,
      userList ?? enabled,
    );
  }
}
