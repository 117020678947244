import { inject, Injectable } from '@angular/core';
import { DeviceService, PairDevice, PairDeviceResponse } from '@vdms-hq/api-contract';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthAdobeService {
  readonly TOKEN_SEPARATOR = '|||';
  readonly DEVICE_UUID_KEY = 'device_uuid';
  readonly DEVICE_HASH_KEY = 'device_hash';
  readonly DEVICE_USER_EMAIL_KEY = 'device_user_email';

  private deviceService = inject(DeviceService);

  newLogIn$ = new BehaviorSubject<boolean>(true);

  getPairDevices = () => this.deviceService.getPairedDevices();

  createNewDeviceForPairing = () => this.deviceService.create();

  pairDevice = (device: PairDevice) => this.deviceService.pairDevice(device);

  unpairDevice = (uuid: string) => this.deviceService.deleteDevice(uuid);

  generateAuthHash = (device: PairDevice, hash: string): string => {
    const networkInterfaces = Array.from(new Set(device.networkInterfaces)).join(',');
    const authObject = {
      hash,
      networks: Buffer.from(JSON.stringify(networkInterfaces), 'utf-8').toString('base64'),
      client: 'adobe-premiere',
    };
    return `${authObject.hash}${this.TOKEN_SEPARATOR}${authObject.networks}${this.TOKEN_SEPARATOR}${authObject.client}`;
  };

  removeItemsFromLocalstorage() {
    localStorage.removeItem(this.DEVICE_UUID_KEY);
    localStorage.removeItem(this.DEVICE_HASH_KEY);
    localStorage.removeItem(this.DEVICE_USER_EMAIL_KEY);
  }

  setItemsInLocalstorage(response: PairDeviceResponse, email: string) {
    localStorage.setItem(this.DEVICE_UUID_KEY, response.uuid);
    localStorage.setItem(this.DEVICE_HASH_KEY, response.hash);
    localStorage.setItem(this.DEVICE_USER_EMAIL_KEY, email);
  }
}
