import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedClientModule, ActivatedClientService, WithPermissions } from '@vdms-hq/activated-client';
import { ResultsActions } from '@vdms-hq/asset-results';
import { CartStateService } from '@vdms-hq/cart-core';
import { QuarantineService } from '@vdms-hq/quarantine';
import { E2eIdDirective, Selection } from '@vdms-hq/shared';
import { UIButtonModule, UILayoutModule } from '@vdms-hq/ui';
import { Observable, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchResultsDataSource } from '../../logic/data-sources/search-results-ds';
import { BrowseAssetViewModel } from '@vdms-hq/asset-search';
import { FastOrderLoadingState } from '@vdms-hq/orders';

@Component({
  selector: 'vdms-hq-browse-results-actions',
  templateUrl: './browse-results-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    UIButtonModule,
    UILayoutModule,
    TranslateModule,
    MatMenuModule,
    MatTooltipModule,
    ActivatedClientModule,
    E2eIdDirective,
  ],
})
export class BrowseResultsActionsComponent extends WithPermissions() implements OnInit, OnDestroy {
  private quarantineService = inject(QuarantineService);
  public dataSource = inject(SearchResultsDataSource);
  public cartState = inject(CartStateService);
  public activatedClient = inject(ActivatedClientService);

  @Input() selectionManager?: Selection<BrowseAssetViewModel>;
  @Input() buttonDisabled = false;
  @Input() isDownloadLoading: FastOrderLoadingState = { downloadNowLoading: false, sendToDownloadsLoading: false };
  @Output() actions: EventEmitter<ResultsActions> = new EventEmitter();

  destroyed$ = new Subject<void>();
  ResultsActions = ResultsActions;
  selectionTotal$?: Observable<number>;

  selectedVirtualAssets$?: Observable<boolean>;
  selectedQuarantinedAssets$?: Observable<boolean>;
  selectedColdAssets$?: Observable<boolean>;

  disableAddToCart$: Observable<boolean> = this.cartState.isBlocked$;
  canEditQuarantined$ = this.quarantineService.canEditQuarantinedAssets$;
  canUseQuarantined$ = this.quarantineService.canUseQuarantinedAssets$;
  canMoveToCold$ = this.activatedClient.coldStorageEnabled$;

  constructor() {
    super();
  }

  ngOnInit() {
    this.selectionTotal$ = this.selectionManager?.total$;
    this.selectedVirtualAssets$ = this.selectionManager?.entities$.pipe(
      takeUntil(this.destroyed$),
      map((selection) => selection.some((asset: BrowseAssetViewModel) => asset.isVirtual)),
    );
    this.selectedQuarantinedAssets$ = this.selectionManager?.entities$.pipe(
      takeUntil(this.destroyed$),
      map((selection) => selection.some((asset: BrowseAssetViewModel) => asset.isQuarantined)),
    );
    this.selectedColdAssets$ = this.selectionManager?.entities$.pipe(
      takeUntil(this.destroyed$),
      map((selection) => selection.some((asset: BrowseAssetViewModel) => asset.isColdAsset)),
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
