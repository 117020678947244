import { inject, Injectable } from '@angular/core';
import { AuthService } from '@vdms-hq/auth';
import { WebsocketNotificationInterface, WebsocketProgressInterface } from './websockes.model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, share, switchMap } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { API_CLIENT_ID_PROVIDER, API_CONFIG, ApiConfig, ClientIdProvider } from '../config-token';

@Injectable({ providedIn: 'root' })
export class ProgressStreamService {
  protected auth = inject(AuthService);
  protected env = inject<ApiConfig>(API_CONFIG);
  protected clientIdProvider = inject<ClientIdProvider>(API_CLIENT_ID_PROVIDER);
  #retry$ = new BehaviorSubject<CloseEvent>({} as CloseEvent);
  readonly streamName = '{userId}/progress';
  readonly connection$: Observable<WebsocketNotificationInterface<WebsocketProgressInterface>> = combineLatest([
    this.#retry$,
    this.clientIdProvider.clientIdDefinite$,
    this.auth.auth$,
  ]).pipe(
    switchMap(([, clientId, auth]) => {
      if (!auth) {
        return [];
      }

      return this.auth.activeToken$.pipe(map((token) => ({ clientId, token, uid: auth.id })));
    }),
    map((params) => {
      const url = new URL(`${this.env.websocketUrl}`);
      url.searchParams.set('token', `${params.token}`);
      url.searchParams.set('stream', `${params.clientId}/${this.streamName.replace('{userId}', params.uid)}`);
      return url;
    }),
    switchMap((url) => {
      return webSocket<WebsocketNotificationInterface<WebsocketProgressInterface>>({
        url: url.toString(),
      });
    }),
    filter((msg: WebsocketNotificationInterface<WebsocketProgressInterface> | string) => msg !== 'ping'),
    map((msg) => {
      if (typeof msg === 'string') {
        return {} as WebsocketNotificationInterface<WebsocketProgressInterface>;
      }

      return msg;
    }),
    share(),
  );

  connect = () => {
    return this.connection$;
  };

  retry = () => this.#retry$.next({} as CloseEvent);
}
