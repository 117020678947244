import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UILoaderModule, UIPreviewModule } from '@vdms-hq/ui';
import { AssetEditFormComponent } from '../asset-edit-form/asset-edit-form.component';
import { ActiveAssetService } from '@vdms-hq/asset-details';
import { map, startWith } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { PlayerMasterComponent } from '@vdms-hq/player';
import { isError, isPlayer, isPreview } from '@vdms-hq/asset-details';

@Component({
  standalone: true,
  selector: 'vdms-hq-asset-quick-preview',
  templateUrl: './asset-quick-preview.component.html',
  styleUrls: ['./asset-quick-preview.component.scss'],
  imports: [CommonModule, UILoaderModule, AssetEditFormComponent, PlayerMasterComponent, UIPreviewModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetQuickPreviewComponent {
  activeAssetService = inject(ActiveAssetService);
  currentAssetView$ = this.activeAssetService.currentAssetView$.pipe(startWith(null));
  groupedElements$ = this.activeAssetService.groupedElementsForFirstContainer$;
  isLoading$ = combineLatest([this.activeAssetService.isLoading$, this.currentAssetView$, this.groupedElements$!]).pipe(
    map(([isLoading, currentAssetView, groupedElements]) => {
      return isLoading || !currentAssetView || !groupedElements;
    }),
    startWith(true),
  );
  protected readonly isPlayer = isPlayer;
  protected readonly isError = isError;
  protected readonly isPreview = isPreview;
}
