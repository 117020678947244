import { inject, Injectable, TrackByFunction } from '@angular/core';
import { ConnectableDataSource, LoadableDataSource, PageableDataSource, PageEvent } from '@vdms-hq/shared';
import {
  AnalyticsLogActionEnum,
  CollectionAnalyticsLogInterface,
  CollectionsService,
  GetCollectionAnalyticsLogFilter,
  PaginationAPIModel,
} from '@vdms-hq/api-contract';
import { BehaviorSubject, combineLatest, map, Observable, of, switchMap, tap } from 'rxjs';
import { SingleCollectionService } from '../services/single-collection.service';
import { withLatestFrom } from 'rxjs/operators';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ProcessView } from '@vdms-hq/ui';

@Injectable({
  providedIn: 'root',
})
export class CollectionsAnalyticsLogDataSource
  implements ConnectableDataSource<CollectionAnalyticsLogInterface>, LoadableDataSource, PageableDataSource
{
  singleCollectionService = inject(SingleCollectionService);
  collectionsService = inject(CollectionsService);
  translateService = inject(TranslateService);

  pageSize$ = new BehaviorSubject(10);
  pageIndex$ = new BehaviorSubject(0);
  pageSizeOptions = [10, 20, 50, 100];

  filters$ = new BehaviorSubject<Partial<GetCollectionAnalyticsLogFilter>>({});

  #isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$ = this.#isLoading$.asObservable();

  #total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  total$: Observable<number> = this.#total$.asObservable();

  allData$: Observable<CollectionAnalyticsLogInterface[]> = combineLatest([
    this.singleCollectionService.uuid$,
    this.pageIndex$,
    this.pageSize$,
    this.filters$,
  ]).pipe(
    switchMap(([uuid, page, perPage, filters]) => {
      this.#isLoading$.next(true);
      const pagination = PaginationAPIModel.create({
        page,
        perPage,
      });
      return this.collectionsService.getCollectionAnalyticsLog(uuid, pagination, filters);
    }),
    map(({ data, total }) => {
      this.#total$.next(total);
      this.#isLoading$.next(false);
      return data;
    }),
  );

  processView$: Observable<ProcessView> = this.allData$.pipe(
    withLatestFrom(
      this.singleCollectionService.collectionData$.pipe(
        switchMap((collection) =>
          this.translateService.get('pages.collection.activity_logs.activity_log_header', {
            collectionName: collection.name,
          }),
        ),
      ),
    ),
    map(([logs, title]) => {
      return {
        list: logs.map((log, index) => {
          return {
            state: index < 3 ? ('completed' as 'completed') : ('incomplete' as 'incomplete'),
            line: index < 3 ? ('highlighted' as 'highlighted') : ('default' as 'default'),
            icon: this.getIcon(log.logAction).key,
            iconCircular: this.getIcon(log.logAction).circular,
            title: '',
            metadata: [
              { label: '', value: log.user.name },
              { label: log.user.email, value: '' },
              { label: 'Timestamp', value: `${moment(log.logDate).format('HH:MM DD-MM-YYYY')}` },
            ],
          };
        }),
        title: title,
      };
    }),
  );

  connection$: Observable<CollectionAnalyticsLogInterface[]> = this.allData$;
  trackBy?: TrackByFunction<CollectionAnalyticsLogInterface> | undefined;

  pageChange($event: PageEvent): void {
    this.pageIndex$.next($event.pageIndex);
    this.pageSize$.next($event.pageSize);
  }

  getIcon(logType: AnalyticsLogActionEnum) {
    switch (logType) {
      case AnalyticsLogActionEnum.VIEW:
        return { key: 'visibility' };
      case AnalyticsLogActionEnum.CREATE:
        return { key: 'edit' };
      default:
        return { key: 'info', circular: true };
    }
  }

  getTypeLabel(logType: AnalyticsLogActionEnum) {
    switch (logType) {
      case AnalyticsLogActionEnum.VIEW:
        return 'View';
      case AnalyticsLogActionEnum.CREATE:
        return 'Collection created';
      default:
        return 'View';
    }
  }
}
