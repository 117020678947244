import { Injectable } from '@angular/core';
import {
  filterStreamMsgFactory,
  mapToProgressToastMessage,
  ProgressStreamService,
  WebsocketAnyNotificationMessage,
  WebsocketNotificationActionEnum,
} from '@vdms-hq/api-contract';
import { generateProgressToastMessage, progressToast, ToastService } from '@vdms-hq/toast';
import { CartStateService } from './cart-state.service';
import { ImageCacheService } from '@vdms-hq/shared';

@Injectable({ providedIn: 'root' })
export class CartWebsocketService {
  #popToast = {
    WS_ADD_TO_CART_PROGRESS: (
      id: string,
      percent: number,
      counter?: { processing: number; all: number; errors: WebsocketAnyNotificationMessage },
    ) =>
      this.toastService.processing({
        id,
        message: generateProgressToastMessage(
          'Cart updating',
          percent,
          this.imageCache.getImage('assets/common/asset_fallbacks/info.svg'),
          counter,
        ),
      }),
    WS_ADD_TO_CART_SUCCESS: (id: string) =>
      this.toastService.success({
        id,
        message: 'pages.cart.notifications.add.done',
      }),
    WS_ADD_TO_CART_ERROR: (id: string) =>
      this.toastService.error({
        id,
        message: 'notifications.websockets.cart_failed',
      }),
  };
  readonly connect$ = this.streamService.connect();

  constructor(
    private streamService: ProgressStreamService,
    private toastService: ToastService,
    private imageCache: ImageCacheService,
    private cartStateService: CartStateService,
  ) {}

  registerWebSocketListener() {
    this.#initToastElement();

    return this.connect$.pipe(
      filterStreamMsgFactory([WebsocketNotificationActionEnum.CART_ADD]),
      mapToProgressToastMessage(),
      progressToast({
        SUCCESS: (groupId: string) => {
          this.#popToast.WS_ADD_TO_CART_SUCCESS(groupId);
          this.cartStateService.refreshCart();
          this.cartStateService.isUpdating$.next(false);
        },
        PROGRESS: this.#popToast.WS_ADD_TO_CART_PROGRESS,
        ERROR: (groupId: string) => {
          this.#popToast.WS_ADD_TO_CART_ERROR(groupId);
          this.cartStateService.isUpdating$.next(false);
        },
      }),
    );
  }

  #initToastElement() {
    this.imageCache.cacheImage('assets/common/asset_fallbacks/info.svg');
  }
}
