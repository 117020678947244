import { Inject, Pipe, PipeTransform } from '@angular/core';
import { OptionalPrimitive, ValueFormatter } from '@vdms-hq/shared';
import { isObservable, Observable, of } from 'rxjs';
import { CONTROL_VALUE_FORMATTER_LIB } from '../logic/control-value-formatter-config';
import { FieldDefinitionModel } from '@vdms-hq/fields';

@Pipe({
  name: 'controlValueFormat',
})
export class ControlValueFormatPipe implements PipeTransform {
  constructor(@Inject(CONTROL_VALUE_FORMATTER_LIB) private valueFormatter: ValueFormatter) {}

  transform(value: any, definitionModel: FieldDefinitionModel): Observable<OptionalPrimitive> {
    value = this.valueFormatter.format(value, {
      valueFormat: definitionModel?.input?.format ?? definitionModel.format,
      sourceListKey: definitionModel.sourceListKey,
    });

    if (!value) {
      return of(null);
    }

    if (isObservable(value)) {
      return value as Observable<OptionalPrimitive>;
    }

    return of(value);
  }
}
