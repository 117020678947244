import { map, Observable } from 'rxjs';
import { WebsocketNotificationInterface, WebsocketProgressInterface } from '@vdms-hq/api-contract';
import { ProgressMessage } from '@vdms-hq/toast';

export function mapToProgressToastMessage(): (
  source$: Observable<WebsocketNotificationInterface<WebsocketProgressInterface>>,
) => Observable<ProgressMessage> {
  return (source$) =>
    source$.pipe(
      map((msg) => {
        return {
          groupId: (msg?.data?.action as string) ?? 'UNKNOWN_GROUP',
          uuid: msg.uuid,
          data: {
            processed: msg.data?.processed ?? 0,
            not_processed: msg.data?.not_processed ?? 0,
            total: msg.data?.total ?? 0,
            message: msg.data?.message ?? '',
          },
        };
      }),
    );
}
