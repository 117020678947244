import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import {
  AngularFirestoreModule,
  SETTINGS as USE_FIRESTORE_SETTINGS,
  USE_EMULATOR as USE_FIRESTORE_EMULATOR,
} from '@angular/fire/compat/firestore';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ActivatedClientModule, ActivatedClientService } from '@vdms-hq/activated-client';
import { ApiContractModule } from '@vdms-hq/api-contract';
import { AssetResultsModule } from '@vdms-hq/asset-results';
import { AuthModule as LibAuthModule } from '@vdms-hq/auth';
import { CartButtonComponent, configureCart } from '@vdms-hq/cart';
import { configureSentry, ErrorHandlingCoreModule } from '@vdms-hq/error-handling';
import { configureLoginPage, LoginPageConfig } from '@vdms-hq/login';
import { configureMetadataRecognition } from '@vdms-hq/metadata-recognition';
import { configureOrders } from '@vdms-hq/orders';
import { E2eIdDirective, RefreshService } from '@vdms-hq/shared';
import { AsperaConfig, configureStorage } from '@vdms-hq/storage';
import { configureTheming } from '@vdms-hq/theming';
import { ToastConfig, ToastModule } from '@vdms-hq/toast';
import { TourItemComponent } from '@vdms-hq/tour-guide';
import { configureTranscription } from '@vdms-hq/transcription';
import {
  configureSwiper,
  StickyMessageComponent,
  UIButtonModule,
  UILoaderModule,
  UIModule,
  UINavbarModule,
  UISidenavModule,
  UISiteNavigationModule,
} from '@vdms-hq/ui';
import { DefaultValueFormatterService } from '@vdms-hq/value-formatter';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImageLoadedDirective } from './logic/directives/image.loaded.directive';
import { StorageHandlerService } from './logic/services/storage-handler.service';
import { SecuredComponent } from './pages/secured/secured.component';
import { SharedModule } from './shared/shared.module';
import { LogoComponent } from './smart-components/logo/logo.component';
import { NotificationsButtonComponent } from '@vdms-hq/notifications';
import { configureAnalytics } from '@vdms-hq/analytics';
import { windowProvider, WindowToken } from '@vdms-hq/omakase-player';
import { NgOptimizedImage } from '@angular/common';
import { configureSearch, ReturnResultsComponent, SearchAutocompleteComponent } from '@vdms-hq/asset-search-results';
import { configureSearchLibrary } from '@vdms-hq/asset-search-library';
import { ChatAgentComponent } from './support/chat-agent/chat-agent.component';
import { configureCredentials } from '@vdms-hq/credentials';
import { configureUserSettings, TzDatePipe } from '@vdms-hq/user-settings';
import { configureClientsPage } from '@vdms-hq/clients';
import { configureDynamicForm } from '@vdms-hq/dynamic-form';
import { configureDynamicFilters } from '@vdms-hq/dynamic-filters';

export function HttpLoaderFactory(http: HttpClient) {
  const hash = '?hash=' + Date.now();
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/common/i18n/', suffix: '.json' + hash },
    { prefix: './assets/i18n/', suffix: '.json' + hash },
  ]);
}

const loginPageConfig: LoginPageConfig = {
  app: 'vida',
  useBrandLogin: true,
  useCustomPasswordlessEmail: true,
  providers: {
    microsoft: environment.microsoftEnabled,
    google: true,
    okta: environment.oktaEnabled,
  },
};

const asperaConfig: AsperaConfig = {
  remoteHost: environment.asperaRemoteHost,
  remoteUser: environment.asperaRemoteUser,
  remotePassword: '',
  destinationRoot: environment.asperaDestinationRoot,
  sdkLocation: environment.asperaSDKLocation,
  debug: !environment.production,
};

const ToastModuleConfig: ToastConfig = {
  durationInSeconds: 5,
  position: 'top-right',
  isCloseButton: true,
};

const TooltipOptions = {
  showDelay: 500,
  hideDelay: 0,
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
};

@NgModule({
  declarations: [AppComponent, ImageLoadedDirective, SecuredComponent, LogoComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    UIModule.forRoot({
      params: {
        appVersion: environment.version,
      },
      timezoneDatePipe: TzDatePipe,
    }),
    UIButtonModule,
    UILoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgxMaskModule.forRoot({
      validation: false,
    }),
    OverlayModule,
    LibAuthModule.forRoot({
      firebase: environment.firebaseConfig,
      microsoftTenantId: environment.microsoftTenantId,
      routing: {
        forAuthorized: '/home',
        forUnauthorized: '/login',
      },
    }),
    ApiContractModule.forRoot({
      clientIdProvider: ActivatedClientService,
      websocketUrl: environment.websocketUrl,
      dolbyRaspApiUrl: environment.dolbyRaspApiUrl,
      dolbyRaspApiUrlV2: environment.dolbyRaspApiUrlV2,
      production: environment.production,
      apiUrl: environment.apiUrl,
      cookieDomain: environment.cookieDomain,
      releaseNotes: {
        dark: environment.releaseNotes.dark,
        light: environment.releaseNotes.light,
      },
      interceptors: {
        bearer: true,
        client: true,
        errorHandling: true,
        cloudfrontCookie: {
          notRequiredForPrefixes: [
            '/order/download',
            '/order/aspera-download',
            '/order/aspera-download/**',
            'request-new-account',
          ],
        },
        ccSalesforceToken: true,
        cacheControl: true,
        headerEncoding: true,
        throttling: {
          period: 10000,
          limit: 25,
          limitFor: [
            {
              endpoint: 'asset/aspera',
              limit: 50,
            },
          ],
        },
      },
    }),
    AssetResultsModule.forRoot(TooltipOptions),
    SharedModule,
    AngularFireAnalyticsModule,
    CartButtonComponent,
    ErrorHandlingCoreModule,
    ActivatedClientModule.forRoot({
      routing: {
        insufficientPermissionsRoute: '/error/403',
        clientSwitchRoute: '/home',
      },
      allowChangeClientForHostname: true,
    }),
    ToastModule.forRoot(ToastModuleConfig),
    UISidenavModule,
    UISiteNavigationModule,
    UINavbarModule,
    TourItemComponent,
    NotificationsButtonComponent,
    NgOptimizedImage,
    SearchAutocompleteComponent,
    E2eIdDirective,
    ChatAgentComponent,
    StickyMessageComponent,
    ReturnResultsComponent,
  ],
  providers: [
    { provide: USE_AUTH_EMULATOR, useValue: environment.firebaseEmulators?.authDomain },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.firebaseEmulators?.firestoreDomain },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.firebaseEmulators?.firestoreDomain },
    {
      provide: USE_FIRESTORE_SETTINGS,
      useValue: {
        experimentalForceLongPolling: true,
      },
    },
    configureStorage({ cdn: environment.cdn }, asperaConfig),
    configureDynamicFilters({ valueFormatterService: DefaultValueFormatterService }),
    configureDynamicForm({ valueFormatterService: DefaultValueFormatterService }),
    configureLoginPage(loginPageConfig),
    {
      provide: WindowToken,
      useFactory: windowProvider,
    },
    configureMetadataRecognition(),
    configureTranscription({
      voiceAIApiUrl: environment.voiceAIApiUrl,
      voiceAIApiKey: environment.voiceAIApiKey,
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: (storageHandlerService: StorageHandlerService) => () => storageHandlerService.registerListeners(),
      multi: true,
      deps: [StorageHandlerService],
    },
    configureClientsPage({
      firebaseProjectId: environment.firebaseConfig.projectId,
    }),
    configureCredentials({ oAuthGoogleClientId: environment.googleOAuthClientId }),
    configureTheming(),
    configureOrders({ cdn: environment.cdn }),
    configureCart({ cdn: environment.cdn }),
    RefreshService, // todo its provided in root, do we need to provide it here?
    CookieService,
    DefaultValueFormatterService,
    configureSentry({
      printInConsole: true,
    }),
    configureAnalytics({
      appName: 'vida',
      debug: false,
    }),
    configureSearch(),
    configureSearchLibrary(),
    configureSwiper(),
    configureUserSettings(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
