import { inject, Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from '../api.service';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { PairDevices, NewDeviceResponse, PairDeviceResponse, PairDevice } from './device.model';
import { createErrorContext } from '../../error-handler';

enum DEVICE_ENDPOINTS {
  ROOT = 'device',
  ROOT_PLURAL = 'devices',
}

@Injectable({ providedIn: 'root' })
export class DeviceService {
  private readonly apiService: ApiService = inject(ApiService);

  /**
   * Get user's active paired devices
   */
  getPairedDevices = () => {
    const url = `${DEVICE_ENDPOINTS.ROOT_PLURAL}/my`;
    return this.apiService.get<ApiResponse<PairDevices[]>>(url).pipe(GetResponseData);
  };

  /**
   * Create a new device ready for pairing
   */
  create = () =>
    this.apiService
      .post<undefined, ApiResponse<NewDeviceResponse>>(`${DEVICE_ENDPOINTS.ROOT}`, undefined)
      .pipe(GetResponseData);

  /**
   * Pair device with activation code
   * @param device
   */
  pairDevice = (device: PairDevice) => {
    const context = createErrorContext({ '*': 'common.notifications.adobe.pair_device_error' }, 'pair_device');
    return this.apiService
      .patch<PairDevice, ApiResponse<PairDeviceResponse>>(`${DEVICE_ENDPOINTS.ROOT}/pair`, device, undefined, context)
      .pipe(GetResponseData);
  };

  /**
   * Unpair device
   * @param uuid
   */
  deleteDevice = (uuid: string) => this.apiService.delete(`${DEVICE_ENDPOINTS.ROOT}/${uuid}/unpair`);
}
