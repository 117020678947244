import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogResponse, FormSectionComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { Destroyable } from '@vdms-hq/shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranscodeContainer, TranscodeService } from '@vdms-hq/api-contract';

@Component({
  selector: 'vdms-hq-transcode-container-create-edit-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UIButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FormSectionComponent,
    UIFormModule,
  ],
  templateUrl: './transcode-container-create-edit-dialog.component.html',
})
export class TranscodeContainerCreateEditDialogComponent extends Destroyable() implements OnInit {
  private transcodeService = inject(TranscodeService);
  public dialogRef = inject(MatDialogRef<TranscodeContainerCreateEditDialogComponent>);
  public data = inject(MAT_DIALOG_DATA) as { item: TranscodeContainer | undefined };

  title = '';
  saveButton = 'common.transcode_base.containers.create.button';

  isLoading$ = new BehaviorSubject(false);
  editMode$ = new BehaviorSubject<'edit' | 'create'>('create');

  form = new FormGroup({
    uuid: new FormControl<string>('', { nonNullable: true }),
    label: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    kind: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
  });

  ngOnInit() {
    if (this.data && this.data.item) {
      this.editMode$.next('edit');
      this.title = 'common.transcode_base.containers.edit.title';
      this.saveButton = 'common.transcode_base.containers.edit.button';
      this.form.patchValue(this.data.item);
    } else {
      this.title = 'common.transcode_base.containers.create.title';
    }
  }

  create() {
    this.isLoading$.next(true);
    const { label, kind } = this.form.getRawValue();
    this.transcodeService.createContainer({ label, kind }).subscribe({
      next: () => {
        this.isLoading$.next(false);
        this.closeDialog(true);
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }

  update() {
    if (!this.data.item) {
      return;
    }
    this.isLoading$.next(true);
    const { label, kind } = this.form.getRawValue();
    this.transcodeService.updateContainer(this.data.item.uuid, { label, kind }).subscribe({
      next: () => {
        this.isLoading$.next(false);
        this.closeDialog(true);
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }

  closeDialog(saved = false): void {
    this.dialogRef.close({ status: saved ? DialogResponse.OK : DialogResponse.ABORT });
  }
}
