import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

export enum SANITIZE_TYPE {
  HTML = 'html',
  STYLE = 'style',
  SCRIPT = 'script',
  URL = 'url',
  RESOURCE_URL = 'resourceUrl',
}

@Pipe({
  name: 'sanitize',
  standalone: true,
})
export class SanitizePipe implements PipeTransform {
  private sanitizer = inject(DomSanitizer);

  transform(value: string, type: SANITIZE_TYPE): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case SANITIZE_TYPE.HTML:
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case SANITIZE_TYPE.STYLE:
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case SANITIZE_TYPE.SCRIPT:
        return this.sanitizer.bypassSecurityTrustScript(value);
      case SANITIZE_TYPE.URL:
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case SANITIZE_TYPE.RESOURCE_URL:
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid sanitize type: ${type}`);
    }
  }
}
