<vdms-hq-ui-dialog-wrapper size="wide">
  <div title class="flex-row justify-between w100">
    <h1 class="title settings">
      {{ title }}
    </h1>
    <vdms-hq-form-input-buttons-toggle
      [formControl]="statusHide"
      (valueChange)="toggleView()"
      [leftOptionLabel]="'Order status'"
      [rightOptionLabel]="'Order settings'"
      [hidePadding]="true"
    ></vdms-hq-form-input-buttons-toggle>
  </div>
  <ng-container content>
    @if (status$ | async) {
      <vdms-hq-ui-process-view [process]="statusData" [isLoading$]="isLoading$">
        @if (data.order.workflow) {
          <ng-container title_right>
            {{ data.order.workflow }}
          </ng-container>
        }
      </vdms-hq-ui-process-view>
    } @else {
      @switch (data.order.workflow) {
        @case ('AI Processing') {
          <ng-container *ngTemplateOutlet="aiProcessing"></ng-container>
        }
        @case ('Delivery Destination') {
          <ng-container *ngTemplateOutlet="deliveryDestination"></ng-container>
        }
        @default {
          <ng-container *ngTemplateOutlet="otherWorkflowSettings"></ng-container>
        }
      }
    }
  </ng-container>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">{{ 'common.global.close' | translate }}</vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>

<ng-template #aiProcessing>
  <div class="details-wrapper">
    <ng-container
      [ngTemplateOutlet]="detailsTitle"
      [ngTemplateOutletContext]="{ detailsTitle: 'AI Processing details' }"
    ></ng-container>
    <mat-divider></mat-divider>
    <div class="ai-processing-details">
      @for (job of data.order.workflowJobs; track job.context) {
        <div class="job">
          <div class="context">{{ dashedToCapitalizedString(job.context) }} ({{ job.service }})</div>
          @if (job.payload) {
            <div class="payload-wrapper">
              @for (payload of Object.entries(job.payload); track payload[0]) {
                <div class="payload">
                  <div class="label">{{ underscoreToString(camelCaseToWords(payload[0])) }}:</div>
                  <div class="value">{{ Array.isArray(payload[1]) ? payload[1].join(', ') : payload[1] }}</div>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #deliveryDestination>
  <div class="details-wrapper">
    <ng-container
      [ngTemplateOutlet]="detailsTitle"
      [ngTemplateOutletContext]="{ detailsTitle: 'Delivery destinations details' }"
    ></ng-container>
    <div class="delivery-destinations-details">
      <mat-tab-group class="material-custom-tab">
        @for (destination of data.order.deliveryDestinations; track destination.name) {
          <mat-tab [label]="destination.name">
            <mat-accordion [multi]="true">
              @for (config of destination.configs; track config.name) {
                <mat-expansion-panel [expanded]="$first">
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      ><h2>Config: {{ config.name }}</h2></mat-panel-title
                    >
                  </mat-expansion-panel-header>
                  <div class="config-details">
                    @for (rule of config.rules; track rule.assetType) {
                      <div class="rule-title">
                        <mat-icon>{{ $any(rule).assetType | getTypeIcon }}</mat-icon>
                        <h3>{{ capitalize(rule.assetType) }}</h3>
                      </div>
                      @if (
                        rule.contentClass.length > 0 || rule.resolutions?.length > 0 || rule.videoCodecs?.length > 0
                      ) {
                        <vdms-hq-ui-fieldset [legend]="'General'">
                          <div class="fieldset-data">
                            @if (rule.contentClass.length > 0) {
                              <div class="field">
                                <div class="label">Content class:</div>
                                <div class="value">{{ rule.contentClass.join(', ') }}</div>
                              </div>
                            }
                            @if (rule.resolutions?.length > 0) {
                              <div class="field">
                                <div class="label">Resolutions:</div>
                                <div class="value">{{ rule.resolutions?.join(', ') }}</div>
                              </div>
                            }
                            @if (rule.videoCodecs?.length > 0) {
                              <div class="field">
                                <div class="label">Video codecs:</div>
                                <div class="value">{{ rule.videoCodecs?.join(', ') }}</div>
                              </div>
                            }
                          </div>
                        </vdms-hq-ui-fieldset>
                      }
                      @if (rule.publish) {
                        <vdms-hq-ui-fieldset [legend]="'Publish'">
                          <div class="fieldset-data">
                            <div class="field">
                              <div class="label">Type:</div>
                              <div class="value">{{ rule.publish.type }}</div>
                            </div>
                            @if (rule.publish.configData) {
                              @for (configData of Object.entries(rule.publish.configData); track configData[0]) {
                                @if (configData[1]) {
                                  <div class="field">
                                    <div class="label">{{ camelCaseToWords(configData[0]) }}:</div>
                                    <div class="value">{{ configData[1] }}</div>
                                  </div>
                                }
                              }
                            }
                          </div>
                        </vdms-hq-ui-fieldset>
                      }
                      @if (rule.assetType === 'video' && rule.transcode && rule.transcode.type) {
                        <vdms-hq-ui-fieldset [legend]="'Transcode'">
                          <div class="fieldset-data">
                            <div class="field">
                              <div class="label">Type:</div>
                              <div class="value">{{ rule.transcode.type }}</div>
                            </div>
                            @if (rule.transcode.audioTracks.length > 0) {
                              <div class="field">
                                <div class="label">Audio tracks:</div>
                                <div class="value">{{ rule.transcode.audioTracks.join(', ') }}</div>
                              </div>
                            }
                            @if (rule.transcode.burnInText) {
                              <div class="field">
                                <div class="label">Burn in text:</div>
                                <div class="value">{{ rule.transcode.burnInTextValue }}</div>
                              </div>
                            }
                            @if (rule.transcode.overrideProfile) {
                              <div class="field">
                                <div class="label">Override with JSON Config</div>
                                <mat-icon [matTooltip]="rule.transcode.overrideProfile | json">visibility</mat-icon>
                              </div>
                            }
                            @if (rule.videoCodecs?.length > 0) {
                              <div class="field">
                                <div class="label">Video codecs:</div>
                                <div class="value">{{ rule.videoCodecs?.join(', ') }}</div>
                              </div>
                            }
                          </div>
                        </vdms-hq-ui-fieldset>
                      }

                      @if (rule.assetType === 'video' && rule.newTranscode && rule.newTranscode.typeUuid) {
                        <vdms-hq-ui-fieldset [legend]="'New Transcode'" [icon]="'settings'">
                          <div class="fieldset-data">New Transcode</div>
                        </vdms-hq-ui-fieldset>
                      }
                    }
                  </div>
                </mat-expansion-panel>
              }
            </mat-accordion>
          </mat-tab>
        }
      </mat-tab-group>
    </div>
  </div>
</ng-template>

<ng-template #otherWorkflowSettings>
  <div class="details-wrapper">
    <ng-container
      [ngTemplateOutlet]="detailsTitle"
      [ngTemplateOutletContext]="{ detailsTitle: data.order.workflow + ' details' }"
    ></ng-container>
  </div>
</ng-template>

<ng-template #detailsTitle let-title="detailsTitle">
  <div class="title">
    <h3>
      {{ title }}
      @if (statusData.pill) {
        <span class="pill" [class]="statusData.pill?.color">{{ statusData.pill?.value }}</span>
      }
    </h3>
  </div>

  <div class="shared">
    <div class="shared-by">
      <div>Order created by:</div>
      <div>{{ data.order.sharedBy }}</div>
    </div>
    @if (data.order.orderShared) {
      <div class="shared-with">
        <div>Order shared with:</div>
        @for (shared of data.order.orderShared; track shared) {
          <div class="shared-user">{{ shared }}</div>
        }
      </div>
    }
  </div>
</ng-template>
