import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ApiEmptyResponse, ApiPaginatedResponse, ApiResponse, IdArrayResponse } from '../api.model';
import {
  LinkAccountPost,
  LoginUserPayload,
  UserBulkDelete,
  UserModel,
  UserPatchRequest,
  UserPostRequest,
} from './user.model';
import { Observable, of, tap } from 'rxjs';
import { GetResponseData, GetResponsePaginationData } from '../../operators/get-response-data.operator';
import { HttpHeaders } from '@angular/common/http';
import { PaginationAPIModel } from '../pagination.model';
import { createErrorContext } from '../../error-handler';

export interface UsersFilters {
  text?: string;
  mail?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private apiService: ApiService) {}

  linkAccount(data: LinkAccountPost) {
    const context = createErrorContext({ '*': 'common.notifications.users.link-account-error' }, 'link-account');

    return this.apiService.post<LinkAccountPost, ApiEmptyResponse>('user/link', data, undefined, context);
  }

  getUser(uuid?: string, log?: { perPage: number; page: number }): Observable<UserModel> {
    const path = uuid ? `user/${uuid}` : 'user';

    const options = {
      headers: new HttpHeaders(),
      context: createErrorContext({ '*': 'common.notifications.users.get.single_failure' }, 'get-user'),
    };

    if (log) {
      options.headers = new HttpHeaders({
        'activity-log-page': String(log?.page),
        'activity-log-per-page': String(log?.perPage),
      });
    }

    return this.apiService.get<ApiResponse<UserModel>>(path, options).pipe(GetResponseData);
  }

  getUsers(pagination?: { text?: string; perPage?: string; page?: string }, orderBy?: string, orderDir?: string) {
    const headers = new HttpHeaders({
      text: pagination?.text ?? '',
      'per-page': pagination?.perPage ?? '24',
      page: pagination?.page ?? '0',
      'order-by': orderBy ?? 'user.displayName',
      'order-dir': orderDir ?? 'ASC',
    });
    const context = createErrorContext({ '*': 'common.notifications.users.get.many_failure' }, 'get-users');

    return this.apiService
      .get<ApiPaginatedResponse<UserModel>>(`users`, { headers, context })
      .pipe(GetResponsePaginationData);
  }

  getUsersShare(filters?: Partial<UsersFilters>, pagination?: PaginationAPIModel) {
    let headers = new HttpHeaders();

    headers = pagination ? pagination.applyToHeaders(headers) : headers;
    headers = filters?.text ? headers.append('text', filters.text) : headers;
    headers = filters?.mail ? headers.append('mail', filters.mail) : headers;
    const context = createErrorContext({ '*': 'common.notifications.users.get.many_failure' }, 'share-users');

    return this.apiService
      .get<ApiPaginatedResponse<UserModel>>('users/share', { headers, context })
      .pipe(GetResponsePaginationData);
  }

  patchCurrentUser(json: UserPatchRequest) {
    const context = createErrorContext({ '*': 'common.notifications.users.update.failure' }, 'edit-user');

    return this.apiService
      .patch<UserPatchRequest, ApiResponse<UserModel>>(`user`, json, undefined, context)
      .pipe(GetResponseData);
  }

  postUser(json: UserPostRequest) {
    const context = createErrorContext({ '*': 'common.notifications.users.create.failure' }, 'create-user');

    return this.apiService
      .post<UserPostRequest, ApiResponse<UserModel>>(`user`, json, undefined, context)
      .pipe(GetResponseData);
  }

  patchUser(userUuid: string, json: UserPatchRequest) {
    const context = createErrorContext({ '*': 'common.notifications.users.update.failure' }, 'edit-user');

    return this.apiService.patch<UserPatchRequest, IdArrayResponse>(`user/${userUuid}`, json, undefined, context);
  }

  bulkDelete(userUuids: string[], deleteReason: string) {
    const headers = new HttpHeaders();
    const context = createErrorContext({ '*': 'common.notifications.update.failed' }, 'delete_error');
    return this.apiService.delete<IdArrayResponse, UserBulkDelete>(
      `users`,
      { user_uuids: userUuids, delete_reason: deleteReason },
      headers,
      undefined,
      context,
    );
  }

  delete(userUuid: string) {
    const headers = new HttpHeaders({
      'soft-delete': 'true',
    });
    const context = createErrorContext({ '*': 'common.notifications.update.failed' }, 'delete_error');
    return this.apiService.delete<IdArrayResponse>(`user/${userUuid}`, undefined, headers, undefined, context);
  }

  generateApiToken(user_uuid: string, expires_at: string | null): Observable<{ token: string }> {
    const context = createErrorContext({ '*': 'common.auth.generateTokenError' }, 'auth-token-generate');

    return this.apiService
      .post<{ user_uuid: string; expires_at: string | null }, ApiResponse<{ token: string }>>(
        'user/generate-api-token',
        {
          user_uuid,
          expires_at,
        },
        undefined,
        context,
      )
      .pipe(GetResponseData);
  }

  getCustomToken(staticToken: string, tokenType = 'Token') {
    const headers = new HttpHeaders({
      Authorization: `${tokenType} ${staticToken}`,
    });
    const context = createErrorContext({ '*': 'common.auth.badToken' }, 'auth-token-get');

    return this.apiService
      .get<ApiResponse<{ token: string }>>('user/custom-token', {
        headers,
        context,
      })
      .pipe(GetResponseData);
  }

  loginPasswordLess(params: LoginUserPayload) {
    const context = createErrorContext({ '*': 'common.auth.badEmail' }, 'login-password-less');

    return this.apiService.post<LoginUserPayload, ApiEmptyResponse>('user/login', params, undefined, context);
  }

  getDeviceToken(hash: string) {
    const headers = new HttpHeaders({
      Authorization: `Device ${Buffer.from(hash, 'utf-8').toString('base64')}`,
    });
    const context = createErrorContext({ '*': 'common.auth.badToken' }, 'auth-token-get');

    return this.apiService
      .get<ApiResponse<{ token: string }>>('user/device-token', {
        headers,
        context,
      })
      .pipe(GetResponseData);
  }
}
