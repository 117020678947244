<div class="multi-data-container" [sharedE2eId]="'collections-wrapper'">
  <vdms-hq-ui-data-presentation-header
    (action)="customActionHandler($event.key)"
    [actions]="headerActions$ | async"
    [title]="title | translate"
    [counterIndicator]="multiDataSource.total$ | async"
  >
  </vdms-hq-ui-data-presentation-header>
  <vdms-hq-dynamic-form-group-renderer
    [sharedE2eId]="'collections-filters'"
    [form]="multiDataSource.filters"
    [fieldsConfig]="filtersConfig"
    [hasLoader]="true"
  ></vdms-hq-dynamic-form-group-renderer>
  @if (hasAnalyticsPermission$ | async) {
    <div class="trending-collections-container">
      <span class="trending-collections-container__header"
        ><h3>Trending now</h3>
        <mat-icon>trending_up</mat-icon></span
      >
      <vdms-hq-ui-multiple-data-presentation
        *ngIf="trendingCollectionConfiguration$ | async; let trendingConfig"
        [configuration]="trendingConfig"
        [dataSource]="multiDataSource2"
        (action)="customActionHandler($event.key, $event.item)"
        (changeDefaultView)="setPreferredTableView($event)"
      >
      </vdms-hq-ui-multiple-data-presentation>
    </div>
  }

  <vdms-hq-ui-multiple-data-presentation
    *ngIf="collectionConfiguration$ | async; let configuration"
    [configuration]="configuration"
    [dataSource]="multiDataSource"
    (action)="customActionHandler($event.key, $event.item)"
    (changeDefaultView)="setPreferredTableView($event)"
  >
  </vdms-hq-ui-multiple-data-presentation>
</div>
