<div class="process-wrapper">
  <div class="title">
    <h3>
      {{ process.title }}
      @if (process.pill) {
        <span class="pill" [class]="process.pill.color">{{ process.pill.value }}</span>
      }
    </h3>
    <ng-content select="[title_right]" class="title-right"></ng-content>
  </div>
  <div class="steps-wrapper">
    @if (isLoading$ | async) {
      <vdms-hq-ui-loader [mode]="'over-parent'" [backdrop]="true"></vdms-hq-ui-loader>
    }
    @if (process.list.length === 0) {
      <vdms-hq-ui-empty-results [message]="'No data available'"></vdms-hq-ui-empty-results>
    } @else {
      @for (step of process.list; track step.state) {
        <div
          [ngClass]="{
            step: true,
            highlighted: step.state === 'highlighted',
            completed: step.state === 'completed',
            incomplete: step.state === 'incomplete',
            error: step.error || step.title === 'Error',
            'line-arrow': step.line === 'arrow',
            'line-highlighted': step.line === 'highlighted',
            'line-none': step.line === 'hidden',
          }"
        >
          <div
            [ngClass]="{
              icon: true,
              emptyIcon: step.icon === 'empty',
              circular: step.iconCircular,
            }"
          >
            @if (step.icon !== 'empty') {
              <mat-icon [color]="step.error || step.title === 'Error' ? 'warn' : 'primary'">{{
                step.error || step.title === 'Error' ? 'priority_high' : step.icon
              }}</mat-icon>
            } @else {
              <div class="empty-step"></div>
            }
          </div>
          <div class="data">
            <div class="main">
              @if (step.title.length > 0) {
                <div class="label">{{ step.title }}</div>
              }
              @if (step.error) {
                <div class="error">
                  <span>{{ step.error.message }}</span>
                </div>
              }
              @if (step.metadata) {
                @for (metadata of step.metadata; track metadata.label) {
                  <div class="metadata">
                    @if (metadata.pills) {
                      <div [class]="getPillMetadataClass(metadata)">{{ metadata.value }}</div>
                    } @else {
                      <span>{{ metadata.label + (showColon(metadata) ? ': ' : '') }}</span
                      >{{ metadata.value }}
                    }
                  </div>
                }
              }
            </div>
          </div>
        </div>
      }
    }
  </div>
</div>
