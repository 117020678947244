import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { BreadCrumb } from '../../models/breadcrumb.model';
import { BreadcrumbsButtonConfig } from '../breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'vdms-hq-ui-layout-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageWrapperComponent {
  @Input() breadcrumbs: BreadCrumb[] = [];
  @Input() additionalInfo = '';
  @Input() buttonConfig?: BreadcrumbsButtonConfig;
  @Input() loading = false;
  @Input() gapSize: 'small' | 'medium' | null = null;
  @Input() breadcrumbsSize: 'small' | 'medium' = 'medium';
  @Input() maxWidth?: string;
  @Input() relative = false;
  @Output() buttonAction = new EventEmitter<void>();
}
