<vdms-hq-ui-layout-page-wrapper
  gapSize="medium"
  [loading]="!!(notificationForm.loading$ | async)"
  [breadcrumbs]="[{ name: 'common.notification_subscriptions.app_notifications_settings' | translate }]"
>
  <div class="content" [formGroup]="notificationForm.form">
    <div class="app--notification__settings">
      <div class="notification--switch" formGroupName="NewAssetArrived">
        <vdms-hq-ui-form-input-toggle
          formControlName="app_notifications_enabled"
          [withFooter]="false"
          [label]="'New Asset(s) Arrival'"
        ></vdms-hq-ui-form-input-toggle>
      </div>
      <div class="notification--switch" formGroupName="AssetIngestCompleted">
        <vdms-hq-ui-form-input-toggle
          formControlName="app_notifications_enabled"
          [withFooter]="false"
          [label]="'Asset(s) Ingest Complete'"
        ></vdms-hq-ui-form-input-toggle>
      </div>
      <div class="notification--switch" formGroupName="NewLaunchpadPackagePendingApproval">
        <vdms-hq-ui-form-input-toggle
          formControlName="app_notifications_enabled"
          [withFooter]="false"
          [label]="'New LAUNCHPAD Package Pending Approval'"
        ></vdms-hq-ui-form-input-toggle>
      </div>
      <div class="notification--switch" formGroupName="LaunchpadPackageApproved">
        <vdms-hq-ui-form-input-toggle
          formControlName="app_notifications_enabled"
          [label]="'LAUNCHPAD Package Approved'"
          [withFooter]="false"
        ></vdms-hq-ui-form-input-toggle>
      </div>
    </div>
    <div class="email--notification__settings">
      <h2>{{ 'common.notification_subscriptions.email_notifications_settings' | translate }}</h2>
      <div class="notification--switch" formGroupName="NewAssetArrived">
        <vdms-hq-ui-form-input-toggle
          formControlName="email_notifications_enabled"
          [withFooter]="false"
          [label]="'New Asset(s) Arrival'"
        ></vdms-hq-ui-form-input-toggle>
        <vdms-hq-ui-form-radio-list
          *ngIf="notificationForm.form.controls.NewAssetArrived.controls.email_notifications_enabled.value"
          formControlName="email_notification_type"
          [withFooter]="false"
          [orientation]="'horizontal'"
          [selectOptions]="emailsNotificationTypes"
        ></vdms-hq-ui-form-radio-list>
      </div>
      <div class="notification--switch" formGroupName="AssetIngestCompleted">
        <vdms-hq-ui-form-input-toggle
          formControlName="email_notifications_enabled"
          [withFooter]="false"
          [label]="'Asset(s) Ingest Complete'"
        ></vdms-hq-ui-form-input-toggle>
        <vdms-hq-ui-form-radio-list
          *ngIf="notificationForm.form.controls.AssetIngestCompleted.controls.email_notifications_enabled.value"
          formControlName="email_notification_type"
          [withFooter]="false"
          [orientation]="'horizontal'"
          [selectOptions]="emailsNotificationTypes"
        ></vdms-hq-ui-form-radio-list>
      </div>
      <div class="notification--switch" formGroupName="NewLaunchpadPackagePendingApproval">
        <vdms-hq-ui-form-input-toggle
          formControlName="email_notifications_enabled"
          [withFooter]="false"
          [label]="'New LAUNCHPAD Package Pending Approval'"
        ></vdms-hq-ui-form-input-toggle>
        <vdms-hq-ui-form-radio-list
          *ngIf="
            notificationForm.form.controls.NewLaunchpadPackagePendingApproval.controls.email_notifications_enabled.value
          "
          formControlName="email_notification_type"
          [orientation]="'horizontal'"
          [withFooter]="false"
          [selectOptions]="emailsNotificationTypes"
        ></vdms-hq-ui-form-radio-list>
      </div>
      <div class="notification--switch" formGroupName="LaunchpadPackageApproved">
        <vdms-hq-ui-form-input-toggle
          formControlName="email_notifications_enabled"
          [withFooter]="false"
          [label]="'LAUNCHPAD Package Approved'"
        ></vdms-hq-ui-form-input-toggle>
        <vdms-hq-ui-form-radio-list
          *ngIf="notificationForm.form.controls.LaunchpadPackageApproved.controls.email_notifications_enabled.value"
          formControlName="email_notification_type"
          [orientation]="'horizontal'"
          [withFooter]="false"
          [selectOptions]="emailsNotificationTypes"
        ></vdms-hq-ui-form-radio-list>
      </div>
      <div
        class="notification--switch"
        formGroupName="AssetAddedLicensedPackage"
        *ngIf="notificationForm.form.controls.AssetAddedLicensedPackage.controls.email_notification_type.enabled"
      >
        <vdms-hq-ui-form-input-toggle
          formControlName="email_notifications_enabled"
          [withFooter]="false"
          [label]="'common.notification_subscriptions.types.type_asset_added_licensed_package'"
        ></vdms-hq-ui-form-input-toggle>
        <vdms-hq-ui-form-radio-list
          *ngIf="notificationForm.form.controls.AssetAddedLicensedPackage.controls.email_notifications_enabled.value"
          formControlName="email_notification_type"
          [withFooter]="false"
          [orientation]="'horizontal'"
          [selectOptions]="emailsNotificationTypes"
        ></vdms-hq-ui-form-radio-list>
      </div>
      <div
        class="notification--switch"
        formGroupName="MaterialAvailabilityStart"
        *ngIf="notificationForm.form.controls.MaterialAvailabilityStart.controls.email_notification_type.enabled"
      >
        <vdms-hq-ui-form-input-toggle
          formControlName="email_notifications_enabled"
          [withFooter]="false"
          [label]="'Material Availability Start'"
        ></vdms-hq-ui-form-input-toggle>
        <vdms-hq-ui-form-radio-list
          *ngIf="notificationForm.form.controls.MaterialAvailabilityStart.controls.email_notifications_enabled.value"
          formControlName="email_notification_type"
          [orientation]="'horizontal'"
          [withFooter]="false"
          [selectOptions]="emailsNotificationTypes"
        ></vdms-hq-ui-form-radio-list>
      </div>
      <div
        class="notification--switch"
        formGroupName="RightsContractStart"
        *ngIf="notificationForm.form.controls.RightsContractStart.controls.email_notification_type.enabled"
      >
        <vdms-hq-ui-form-input-toggle
          formControlName="email_notifications_enabled"
          [withFooter]="false"
          [label]="'Rights Contract Start'"
        ></vdms-hq-ui-form-input-toggle>
        <vdms-hq-ui-form-radio-list
          *ngIf="notificationForm.form.controls.RightsContractStart.controls.email_notifications_enabled.value"
          formControlName="email_notification_type"
          [orientation]="'horizontal'"
          [withFooter]="false"
          [selectOptions]="emailsNotificationTypes"
        ></vdms-hq-ui-form-radio-list>
      </div>
      <div
        class="notification--switch"
        formGroupName="RightsContractEnd"
        *ngIf="notificationForm.form.controls.RightsContractEnd.controls.email_notification_type.enabled"
      >
        <vdms-hq-ui-form-input-toggle
          formControlName="email_notifications_enabled"
          [withFooter]="false"
          [label]="'Rights Contract End'"
        ></vdms-hq-ui-form-input-toggle>
        <vdms-hq-ui-form-radio-list
          *ngIf="notificationForm.form.controls.RightsContractEnd.controls.email_notifications_enabled.value"
          formControlName="email_notification_type"
          [orientation]="'horizontal'"
          [withFooter]="false"
          [selectOptions]="emailsNotificationTypes"
        ></vdms-hq-ui-form-radio-list>
      </div>
      <div class="notification--switch" formGroupName="OrderDownloadExpiryReminder">
        <vdms-hq-ui-form-input-toggle
          formControlName="email_notifications_enabled"
          [withFooter]="false"
          [label]="'Order(s) Expiration Reminder'"
        ></vdms-hq-ui-form-input-toggle>
      </div>
    </div>
  </div>
</vdms-hq-ui-layout-page-wrapper>
