import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption, SelectOptionKey } from '../../models/selector.model';

@Pipe({
  name: 'findSelectOption',
  standalone: true,
})
export class FindSelectOption implements PipeTransform {
  transform = FindSelectOption.transform;

  static transform<K = SelectOptionKey>(key: K, selectOptions: SelectOption<K>[]): SelectOption<K> | null {
    if (!key) {
      return null;
    }

    return selectOptions.find((option) => option.key === key) ?? null;
  }
}
