import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightsPartnersResultsMultiDs } from '../../logic/rights-partners-results-multi-ds.service';
import { RightsPartnersActionsService } from '../../logic/rights-partners-actions.service';
import {
  ActionContextLess,
  DataAction as Action,
  DataPresentationHeaderComponent,
  DefaultMultiViewType,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIButtonModule,
  UILayoutModule,
} from '@vdms-hq/ui';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { DestroyComponent, E2eIdDirective, ValueFormat } from '@vdms-hq/shared';
import { RightsPartnerListItem } from '@vdms-hq/api-contract';
import { TranslateModule } from '@ngx-translate/core';
import { Permission } from '@vdms-hq/firebase-contract';
import { ActivatedClientModule, PermissionService } from '@vdms-hq/activated-client';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TABLE_TYPE, ViewSettingsService } from '@vdms-hq/view-settings';
import { ColumnsConfigDialogComponent, FieldsFetcherService, FieldsScopeKey, FilterType } from '@vdms-hq/fields';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'vdms-hq-rights-partners-results',
  standalone: true,
  imports: [
    CommonModule,
    DynamicFiltersModule,
    MultipleDataPresentationComponent,
    TranslateModule,
    UIButtonModule,
    UILayoutModule,
    ActivatedClientModule,
    DataPresentationHeaderComponent,
    E2eIdDirective,
  ],
  templateUrl: './rights-partners-results.component.html',
  styles: [],
})
export class RightsPartnersResultsComponent extends DestroyComponent {
  public rightsPartnersResultsMultiDs = inject(RightsPartnersResultsMultiDs);
  private rightsPartnersActionsService = inject(RightsPartnersActionsService);
  private viewSettingsService = inject(ViewSettingsService);
  private permissionsService = inject(PermissionService);
  private fieldsFetcherService = inject(FieldsFetcherService);
  private matDialog = inject(MatDialog);
  scope: FieldsScopeKey = 'other-rights-partners';

  protected readonly Permission = Permission;

  headerActions$: Observable<ActionContextLess[]> = this.permissionsService
    .verifyWithOwnedPermissions$([Permission.CREATE_RIGHTS_PARTNERS])
    .pipe(
      map((hasPermission) =>
        hasPermission
          ? [
              {
                key: 'create',
                label: 'common.rights_partners.table.actions.create',
                e2eId: 'rights-partners-create',
              },
            ]
          : [],
      ),
    );

  #actions: Observable<Action<RightsPartnerListItem>[]> = combineLatest([
    this.permissionsService.verifyWithOwnedPermissions$([Permission.EDIT_RIGHTS_PARTNERS]),
    this.permissionsService.verifyWithOwnedPermissions$([Permission.DELETE_RIGHTS_PARTNERS]),
  ]).pipe(
    this.takeUntilDestroyed(),
    map(([canEdit, canDelete]) => {
      const actions: Action<RightsPartnerListItem>[] = [];
      if (canEdit) {
        actions.push({
          key: 'edit',
          label: 'common.rights_partners.table.actions.edit',
          icon: 'edit',
          e2eId: 'rights-partners-edit',
        });
      }
      if (canDelete) {
        actions.push({
          key: 'delete',
          label: 'common.rights_partners.table.actions.delete',
          icon: 'delete',
          e2eId: 'rights-partners-delete',
        });
      }

      return actions;
    }),
  );

  protected readonly filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'common.rights_partners.table.filter_placeholder',
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
      scope: ['other-rights-partners'],
    },
  ];

  rightsPartnersConfig$: Observable<MultipleViewConfiguration<RightsPartnerListItem>> = combineLatest([
    this.#actions,
    this.viewSettingsService.defaultViewFor$(TABLE_TYPE.LICENSEES_PARTNER),
    this.fieldsFetcherService.getConfiguration$(this.scope),
  ]).pipe(
    map(([actions, defaultView, config]) => {
      return {
        multiView: {
          defaultView: defaultView,
          emptyResults: {
            message: 'Could not find any Licensees',
            icon: 'stars',
          },
        },
        tableAdvanced: {
          actions: actions,
          layout: {
            headers: true,
          },
          columnsEnabled: config.table.getVisibleKeys(),
          columns: config.table.getColumns(),
          settingsAction: {
            id: 'settings',
            label: 'common.global.edit_columns',
            icon: 'settings',
            color: 'transparent',
          },
        },
        gridAdvanced: {
          actions: actions,
          layout: {
            columns: 'up-to-2',
          },
          content: {
            titlePath: 'name',
          },
        },
      };
    }),
  );

  setPreferredTableView($event: DefaultMultiViewType) {
    this.viewSettingsService.saveDefaultViewFor(TABLE_TYPE.LICENSEES_PARTNER, $event);
  }

  actionHandler(event: { key: string; item?: RightsPartnerListItem }): boolean {
    switch (event.key) {
      case 'settings':
        this.matDialog.open(ColumnsConfigDialogComponent, { data: { scope: this.scope } });
        return true;
      case 'create':
        this.rightsPartnersActionsService.createDialog();
        return true;
      case 'edit':
        if (!event.item) {
          return false;
        }
        this.rightsPartnersActionsService.editDialog(event.item?.uuid);
        return true;
      case 'delete':
        if (!event.item) {
          return false;
        }
        this.rightsPartnersActionsService.deleteDialog(event.item.uuid, event.item.name);
        return true;
      default:
        return false;
    }
  }
}
