import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RouterModule, Routes } from '@angular/router';
import { ErrorHandlingRoutes } from './error-handling.enum';
import { NotPermittedComponent } from './pages/not-permitted/not-permitted.component';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { UIErrorsModule } from '@vdms-hq/ui';

const routes: Routes = [
  {
    path: ErrorHandlingRoutes.NOT_FOUND,
    component: NotFoundComponent,
  },
  {
    path: ErrorHandlingRoutes.NOT_PERMITTED,
    component: NotPermittedComponent,
  },
  {
    path: ErrorHandlingRoutes.NOT_AUTHORIZED_PAGE,
    component: NotAuthorizedComponent,
  },
  {
    path: ErrorHandlingRoutes.NOT_AUTHORIZED_STATUS,
    component: NotAuthorizedComponent,
    data: { status: ErrorHandlingRoutes.NOT_AUTHORIZED_STATUS },
  },
  {
    path: '**',
    redirectTo: ErrorHandlingRoutes.NOT_FOUND,
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), UIErrorsModule],
  declarations: [NotFoundComponent, NotPermittedComponent],
  exports: [NotFoundComponent],
})
export class ErrorHandlingModule {}
