import { Inject, Pipe, PipeTransform } from '@angular/core';
import { OptionalPrimitive, ValueFormatter } from '@vdms-hq/shared';
import { Observable, of } from 'rxjs';
import { DYNAMIC_VALUE_FORMATTER } from '../../logic/dynamic-value-formatter-config';
import { InputDefinitionModel } from '@vdms-hq/fields';

@Pipe({
  name: 'dynamicValueFormat',
})
export class DynamicValueFormatPipe implements PipeTransform {
  constructor(@Inject(DYNAMIC_VALUE_FORMATTER) private valueFormatter: ValueFormatter) {}

  transform(value: any, definitionModel: InputDefinitionModel): Observable<OptionalPrimitive> {
    value = this.valueFormatter.format(value, {
      valueFormat: definitionModel.input.format ?? definitionModel.format,
      sourceListKey: definitionModel.sourceListKey,
    });

    if (value && typeof value === 'object') {
      return value;
    }

    return of(value);
  }
}
