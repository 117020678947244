import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogResponse, FormSectionComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { Destroyable, SelectOption } from '@vdms-hq/shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  TranscodeContainer,
  TranscodeService,
  TranscodeTypeViewModel,
  VideoCodec,
  VideoCodecsApiService,
} from '@vdms-hq/api-contract';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vdms-hq-transcode-type-create-edit-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UIButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FormSectionComponent,
    UIFormModule,
  ],
  templateUrl: './transcode-type-create-edit-dialog.component.html',
})
export class TranscodeTypeCreateEditDialogComponent extends Destroyable() implements OnInit {
  private transcodeService = inject(TranscodeService);
  private videoCodecMappingService: VideoCodecsApiService = inject(VideoCodecsApiService);
  public dialogRef = inject(MatDialogRef<TranscodeTypeCreateEditDialogComponent>);
  public data = inject(MAT_DIALOG_DATA) as { item: TranscodeTypeViewModel | undefined };

  title = '';
  saveButton = 'common.transcode_base.types.create.button';

  isLoading$ = new BehaviorSubject(false);
  editMode$ = new BehaviorSubject<'edit' | 'create'>('create');

  form = new FormGroup({
    uuid: new FormControl<string>('', { nonNullable: true }),
    name: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    container_uuids: new FormControl<string[]>([], { nonNullable: true, validators: Validators.required }),
    codec_mapping_uuids: new FormControl<string[]>([], { nonNullable: true, validators: Validators.required }),
  });

  videoCodecsSelectOptions$: Observable<SelectOption[]> = this.videoCodecMappingService.getAll().pipe(
    map((codecs: VideoCodec[]) =>
      codecs
        .filter((codec) => !!codec.video_codec && !!codec.profile)
        .map(({ display_name, uuid, video_codec, profile }) => ({
          key: uuid,
          label: `${display_name} | Profile: ${profile} | Codec: ${video_codec}`,
        })),
    ),
  );

  containersSelectOptions$: Observable<SelectOption[]> = this.transcodeService.getAllContainers().pipe(
    map((containers: TranscodeContainer[]) =>
      containers.map(({ label, uuid }) => ({
        key: uuid,
        label,
      })),
    ),
  );

  ngOnInit() {
    if (this.data && this.data.item) {
      this.editMode$.next('edit');
      this.title = 'common.transcode_base.types.edit.title';
      this.saveButton = 'common.transcode_base.types.edit.button';
      this.form.patchValue({
        name: this.data.item.name,
        container_uuids: this.data.item.transcode_containers.map((container) => container.uuid),
        codec_mapping_uuids: this.data.item.video_codec_mappings.map((codec) => codec.uuid),
      });
    } else {
      this.title = 'common.transcode_base.types.create.title';
    }
  }

  create() {
    this.isLoading$.next(true);
    const { name, container_uuids, codec_mapping_uuids } = this.form.getRawValue();
    this.transcodeService.createType({ name, container_uuids, codec_mapping_uuids }).subscribe({
      next: () => {
        this.isLoading$.next(false);
        this.closeDialog(true);
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }

  update() {
    if (!this.data.item) {
      return;
    }
    this.isLoading$.next(true);
    const { name, codec_mapping_uuids, container_uuids } = this.form.getRawValue();
    this.transcodeService.updateType(this.data.item.uuid, { name, codec_mapping_uuids, container_uuids }).subscribe({
      next: () => {
        this.isLoading$.next(false);
        this.closeDialog(true);
      },
      error: () => {
        this.isLoading$.next(false);
      },
    });
  }

  closeDialog(saved = false): void {
    this.dialogRef.close({ status: saved ? DialogResponse.OK : DialogResponse.ABORT });
  }
}
