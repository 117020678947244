import { Component } from '@angular/core';
import {
  ConnectableDataSource,
  E2eIdDirective,
  LoadableDataSource,
  SelectableDataSource,
  ValueDisplayFormatConfig,
} from '@vdms-hq/shared';
import { CommonModule } from '@angular/common';
import { RuntimeErrorComponent } from '../components/runtime-error/runtime-error.component';
import { ConfigActions, ContextMenu, TranslatableName } from '../logic/common-config';
import { RouterLinkWithHref } from '@angular/router';
import { ContextMenuComponent } from '../components/context-menu/context-menu.component';
import { ValueRendererComponent } from '../../ui-value-renderer';
import { FloatingControlsV2Component } from '../components/floating-controls-v2/floating-controls-v2.component';
import { IsRowTooltipPipe } from '../pipes/is-row-tooltip.pipe';
import { SelectOneComponent } from '../components/select-one/select-one.component';
import { TileImageComponent } from '../components/tile-image/tile-image.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { ValueByPathPipe } from '../pipes/value-by-path.pipe';
import { DisableIfRowPipe } from '../pipes/disable-if-row.pipe';
import { RowViewConfig } from '../logic/models';
import { BaseViewLogicComponent } from '../base-view-logic/base-view-logic.component';

export type GridTilesDataSource<T> = ConnectableDataSource<T> &
  Partial<LoadableDataSource> &
  Partial<SelectableDataSource<T>>;

export type GridTilesConfig<T> = ContextMenu<T> &
  ConfigActions<T> & {
    rows?: RowViewConfig<T>;
    content?: Partial<{
      iconPath: string;
      titlePath: string;
      pin?: {
        pinValue1Path?: string;
        pinValue2Path?: string;
        pinSuccessIf?: (item: T) => boolean;
        pinLabel?: (item: T) => string;
      };
      additionalInfo?: {
        label: TranslatableName;
        valuePath: string;
        viewFormat?: ValueDisplayFormatConfig;
      };
      metadata: {
        label: TranslatableName;
        viewFormat?: ValueDisplayFormatConfig;
        valuePath: string;
        fallback?: string;
        fullLine?: boolean;
        hiddenIf?: (item: T) => boolean;
      }[];
    }>;
    layout?: {
      columns?: 'up-to-6' | 'up-to-4';
    };
    image?: Partial<{
      bgPath: string;
      bgHoverPath: string;
    }>;
  };

@Component({
  selector: 'vdms-hq-ui-grid-tiles',
  templateUrl: './grid-tiles.component.html',
  styleUrls: ['./grid-tiles.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RuntimeErrorComponent,
    RouterLinkWithHref,
    ContextMenuComponent,
    FloatingControlsV2Component,
    IsRowTooltipPipe,
    SelectOneComponent,
    TileImageComponent,
    TranslateModule,
    ValueRendererComponent,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    ValueByPathPipe,
    DisableIfRowPipe,
    E2eIdDirective,
  ],
})
export class GridTilesComponent<T> extends BaseViewLogicComponent<GridTilesDataSource<T>, GridTilesConfig<T>, T> {
  getLayout() {
    switch (this.configuration?.layout?.columns) {
      case 'up-to-6':
        return 'grid-tiles--up-to-6';
      case 'up-to-4':
        return 'grid-tiles--up-to-4';
      default:
        return null;
    }
  }
}
