<button
  mat-raised-button
  [color]="matColor"
  [type]="isSubmit ? 'submit' : 'button'"
  [class.download]="asDownloadLink"
  class="material-custom-button"
  [disabled]="loading || disabled"
  [matTooltip]="tooltip | translate"
  [loading]="loading"
  [class]="classes"
>
  <mat-icon
    *ngIf="icon"
    [class]="
      'icon icon-color--' +
      iconColor +
      ' ' +
      (iconOnly ? '' : 'with-icon') +
      (iconStyle ? iconStylesSet[iconStyle] : '')
    "
    >{{ icon }}</mat-icon
  >
  <ng-content></ng-content>
</button>
