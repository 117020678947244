import { Pipe, PipeTransform } from '@angular/core';
import { AllListComponentConfig, ConfigLessComponent } from '@vdms-hq/firebase-contract';
import { InputDefinitionModel } from '@vdms-hq/fields';

@Pipe({
  name: 'typeDefinition',
  standalone: true,
})
export class TypeDefinitionPipe implements PipeTransform {
  transform(
    definition: InputDefinitionModel | AllListComponentConfig | ConfigLessComponent,
  ): AllListComponentConfig | ConfigLessComponent | null {
    if ('type' in definition && typeof definition.type === 'string') {
      return definition;
    }
    return null;
  }
}
