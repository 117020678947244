import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionIdentifier,
  DialogResponse,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  ResultsWrapperViewConfiguration,
  UIConfirmationDialogService,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranscodeTypesDsService } from '../../logic/transcode-types-ds.service';
import { TranscodeService, TranscodeTypeViewModel } from '@vdms-hq/api-contract';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '@vdms-hq/toast';
import { combineLatest, filter, switchMap, take } from 'rxjs';
import { TranscodeTypeCreateEditDialogComponent } from '../../components/transcode-type-create-edit-dialog/transcode-type-create-edit-dialog.component';

@Component({
  selector: 'vdms-hq-transcode-types',
  standalone: true,
  imports: [CommonModule, TranslateModule, UIResultsWrapperModule, MultipleDataPresentationComponent],
  templateUrl: './transcode-types.component.html',
})
export class TranscodeTypesComponent implements OnInit {
  protected typesDS = inject(TranscodeTypesDsService);
  private transcodeService = inject(TranscodeService);
  private permissionService = inject(PermissionService);
  private translateService = inject(TranslateService);
  private dialog = inject(MatDialog);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private toastService = inject(ToastService);

  popToast = {
    CREATE: () => {
      this.toastService.success({
        id: 'create-types',
        message: 'common.transcode_base.types.create.success',
      });
    },
    EDIT: () => {
      this.toastService.success({
        id: 'edit-types',
        message: 'common.transcode_base.types.edit.success',
      });
    },
    DELETE: () => {
      this.toastService.success({
        id: 'delete-types',
        message: 'common.transcode_base.types.delete.success',
      });
    },
  };

  headerConfig: ResultsWrapperViewConfiguration = {
    headerActions: [],
    withLoading: true,
  };

  viewConfig: MultipleViewConfiguration<TranscodeTypeViewModel> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: false,
      emptyResults: {
        message: 'Could not find any types',
      },
    },
    tableAdvanced: {
      actions: [],
      layout: {
        headers: true,
      },
      columns: [
        {
          id: 'uuid',
          label: 'UUID',
          valuePath: 'uuid',
        },
        {
          id: 'name',
          label: 'Name',
          valuePath: 'name',
        },
        {
          id: 'containers',
          label: 'Containers',
          valuePath: 'containers',
          viewFormat: {
            maxVisibleValues: 3,
          },
        },
        {
          id: 'codecs',
          label: 'Video Codecs',
          valuePath: 'codecs',
          viewFormat: {
            maxVisibleValues: 3,
          },
        },
        {
          id: 'actions',
          type: 'actions',
        },
      ],
      columnsEnabled: ['uuid', 'name', 'containers', 'codecs', 'actions'],
    },
  };

  handleAction($event: { key: ActionIdentifier; item?: TranscodeTypeViewModel }) {
    const { key, item } = $event;

    switch (key) {
      case 'new':
        this.#new();
        break;
      case 'edit':
        if (!item) {
          return;
        }
        this.#edit(item);
        break;
      case 'delete':
        if (!item) {
          return;
        }
        this.#delete(item);
        break;
    }
  }

  ngOnInit() {
    combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.PERMISSION_CREATE_TRANSCODE_TYPES]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.PERMISSION_EDIT_TRANSCODE_TYPES]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.PERMISSION_DELETE_TRANSCODE_TYPES]),
    ])
      .pipe(take(1))
      .subscribe({
        next: ([canCreate, canEdit, canDelete]) => {
          if (canCreate) {
            this.headerConfig.headerActions?.push({
              key: 'new',
              name: 'common.transcode_base.types.actions.create',
              color: 'primary',
            });
          }
          if (this.viewConfig.tableAdvanced) {
            if (canEdit) {
              this.viewConfig.tableAdvanced.actions?.push({
                key: 'edit',
                icon: 'edit',
                onDoubleClick: false,
                label: 'common.transcode_base.types.actions.edit',
              });
            }
            if (canDelete) {
              this.viewConfig.tableAdvanced.actions?.push({
                key: 'delete',
                icon: 'delete',
                onDoubleClick: false,
                label: 'common.transcode_base.types.actions.delete',
              });
            }
          }
        },
      });
  }

  #new() {
    this.dialog
      .open(TranscodeTypeCreateEditDialogComponent, { data: { item: undefined }, disableClose: true })
      .afterClosed()
      .pipe(
        take(1),
        filter(({ status }) => status === DialogResponse.OK),
      )
      .subscribe(() => {
        this.popToast.CREATE();
        this.typesDS.refresh();
      });
  }

  #edit(item: TranscodeTypeViewModel) {
    this.dialog
      .open(TranscodeTypeCreateEditDialogComponent, { data: { item }, disableClose: true })
      .afterClosed()
      .pipe(
        take(1),
        filter(({ status }) => status === DialogResponse.OK),
      )
      .subscribe(() => {
        this.popToast.EDIT();
        this.typesDS.refresh();
      });
  }

  #delete(item: TranscodeTypeViewModel) {
    this.confirmationDialog
      .openDelete({
        title: this.translateService.instant('common.transcode_base.types.delete.title', {
          type_name: item.name,
        }),
        message: 'common.transcode_base.types.delete.message',
      })
      .pipe(
        filter(Boolean),
        switchMap(() => this.transcodeService.deleteType(item.uuid)),
      )
      .subscribe({
        next: () => {
          this.popToast.DELETE();
          this.typesDS.refresh();
        },
        error: () => {
          this.toastService.error({
            id: 'delete-container',
            message: 'common.transcode_base.types.delete.failure',
          });
        },
      });
  }
}
