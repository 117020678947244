import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleLicensePackageDsService } from '../../../logic/single-license-package-ds.service';
import {
  BreadCrumb,
  DataAction,
  DataColumn,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  ResultsWrapperViewConfiguration,
  SimpleDetailsList,
  UIButtonModule,
  UIConfirmationDialogService,
  UIEmptyResultsModule,
  UILayoutModule,
  UILoaderModule,
  UISimpleDetailsListModule,
} from '@vdms-hq/ui';
import {
  AssetActionsService,
  AssetResultsModule,
  BatchActionsButtonsConfig,
  BatchActionsButtonsLabels,
  ContextMenuActionsService,
  ResultsActions,
} from '@vdms-hq/asset-results';
import {
  DownloadTypeEnum,
  LICENSED_PACKAGE_STATUS,
  LicensedPackage,
  LicensedPackageAsset,
  OrderItemTypeEnum,
} from '@vdms-hq/api-contract';
import { Permission } from '@vdms-hq/firebase-contract';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { E2eIdDirective, SharedModule } from '@vdms-hq/shared';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LicensePackagesActionsService } from '../../../logic/license-packages-actions';
import { MatDividerModule } from '@angular/material/divider';
import { ActivatedClientModule, ActivatedClientService, PermissionService } from '@vdms-hq/activated-client';
import { AuthService } from '@vdms-hq/auth';
import { Router } from '@angular/router';
import { LicensePackageAssetViewModel } from '../../../logic/license-package-asset-view.model';
import { MatDialog } from '@angular/material/dialog';
import { TriggerNotificationsDialogComponent } from '@vdms-hq/notifications';
import {
  ColumnsConfigDialogComponent,
  ColumnsConfigDialogData,
  FieldsFetcherService,
  FieldsScopeKey,
} from '@vdms-hq/fields';
import { CollectionsActionsService } from '@vdms-hq/collections-public';
import { AddToCartActionsService } from '@vdms-hq/cart-core';
import { OrderActionsService, FastOrdersService, FastOrderLoadingState } from '@vdms-hq/orders';
import { ToastService } from '@vdms-hq/toast';

interface LicensePackageDetailsViewModel {
  name: BreadCrumb[];
  status: LICENSED_PACKAGE_STATUS;
  subtitle: string;
  details: SimpleDetailsList[];
}

@Component({
  selector: 'vdms-hq-single-license-package',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    UILayoutModule,
    UISimpleDetailsListModule,
    UILoaderModule,
    MatDividerModule,
    AssetResultsModule,
    UIEmptyResultsModule,
    MultipleDataPresentationComponent,
    E2eIdDirective,
    UIButtonModule,
    ActivatedClientModule,
  ],
  templateUrl: './single-license-package.component.html',
  styles: [],
  providers: [SingleLicensePackageDsService],
})
export class SingleLicensePackageComponent implements OnInit, OnDestroy {
  Permission = Permission;
  uuid?: string;

  licensedPackageData$: Observable<LicensePackageDetailsViewModel> = this.dataSource.licensedPackage$.pipe(
    withLatestFrom(this.dataSource.uuid$),
    switchMap(([licensedPackage, uuid]) => {
      this.uuid = uuid;
      return this.#transformToViewModel(licensedPackage);
    }),
  );

  activeHeaderConfiguration: ResultsWrapperViewConfiguration = {
    headerActions: [],
    withLoading: true,
  };

  batchActions: BatchActionsButtonsConfig = {
    addToLicensedPackages: true,
    cart: true,
    batchEdit: true,
    addToCollection: true,
    delete: false,
    setAsColdOnly: false,
    triggerNotifications: true,
    imposeQuarantine: true,
    liftQuarantine: true,
  };

  batchActionLabels: BatchActionsButtonsLabels = {
    delete: 'common.license_package.table.actions.remove_asset',
    triggerNotifications: 'common.notification_subscriptions.trigger.title',
  };
  columns: DataColumn[] = [];
  multiConfig$!: Observable<MultipleViewConfiguration<LicensedPackageAsset>>;

  scopeName: FieldsScopeKey = 'licensed-package';
  #destroyed = new Subject<void>();
  #destroyed$ = this.#destroyed.asObservable();

  permissions$ = this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_LICENSED_PACKAGES]).pipe(
    take(1),
    tap((canEdit) => {
      if (canEdit) {
        this.headerAction = {
          label: this.translate.instant('common.license_package.single_view.edit_btn'),
          icon: 'edit',
        };
        this.batchActions.delete = true;
      }
    }),
  );

  openAssetPreviewInPopup$ = this.authService.userAttributesDefinite$.pipe(
    map((attrs) => attrs.vida.openAssetPreviewInPopup),
  );

  coldStorageEnabled$ = this.activatedClientService.clientDefinite$.pipe(
    map((client) => client?.vida?.coldStorageEnabled),
  );

  selectedLength$ = new BehaviorSubject(0);
  headerAction: { label: string; icon: string } | undefined = undefined;

  isDownloadLoading = new BehaviorSubject<FastOrderLoadingState>({
    downloadNowLoading: false,
    sendToDownloadsLoading: false,
  });

  get selectedLength() {
    return this.selectedLength$.getValue();
  }

  constructor(
    public dataSource: SingleLicensePackageDsService,
    private licensePackagesActionsService: LicensePackagesActionsService,
    private permissionService: PermissionService,
    private translate: TranslateService,
    private fieldsFetcherService: FieldsFetcherService,
    private authService: AuthService,
    private router: Router,
    private contextMenuActions: ContextMenuActionsService,
    private dialog: MatDialog,
    private assetActionsService: AssetActionsService,
    private activatedClientService: ActivatedClientService,
    private confirmationDialog: UIConfirmationDialogService,
    private collectionsActions: CollectionsActionsService,
    private addToCartActionsService: AddToCartActionsService,
    private fastOrdersService: FastOrdersService,
    private orderActionsService: OrderActionsService,
    private toastService: ToastService,
  ) {
    this.dataSource.selection.total$.subscribe((total) => {
      this.selectedLength$.next(total);
      this.activeHeaderConfiguration.headerActions &&
        this.activeHeaderConfiguration.headerActions[0] &&
        (this.activeHeaderConfiguration.headerActions[0].disabled = this.selectedLength === 0);
    });
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
  }

  ngOnInit(): void {
    // todo this place should be based on AssetFlatView2Model
    // todo, this should use results2 component
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.multiConfig$ = combineLatest([
      this.fieldsFetcherService.getConfiguration$(this.scopeName),
      this.assetActionsService.buildQAResultsAction(),
      this.assetActionsService.buildImposeQuarantineAction$<LicensePackageAssetViewModel>(),
      this.assetActionsService.buildLiftQuarantineAction$<LicensePackageAssetViewModel>(),
      this.permissions$,
    ]).pipe(
      takeUntil(this.#destroyed$),
      map(([columns, qaResultAction, imposeQuarantine, liftQuarantine, canEdit]) => {
        const actions = [];
        if (canEdit) {
          actions.push({
            key: 'delete',
            label: 'common.license_package.table.actions.remove_asset',
            icon: 'delete',
          });
        }

        actions.push(qaResultAction);
        actions.push(imposeQuarantine);
        actions.push(liftQuarantine);

        return {
          multiView: {
            defaultView: 'tableAdvanced',
            fitToVisibleArea: true,
            pagination: true,
            emptyResults: {
              message: 'No packages to list.',
            },
          },
          tableAdvanced: {
            actions: [
              {
                key: 'preview',
                icon: 'visibility',
                label: 'common.license_package.table.actions.preview_asset',
              },
              ...actions,
            ],
            contextMenu: {
              actions: <DataAction<LicensedPackageAsset>[]>[
                {
                  key: 'tab',
                  icon: 'tab',
                  label: 'Open in new tab',
                },
                {
                  key: 'window',
                  icon: 'window',
                  label: 'Open in new window',
                },
                {
                  key: 'uuid',
                  icon: 'uuid',
                  label: 'Copy license package UUID',
                },
              ],
            },
            columns: columns.table.getColumns(),
            columnsEnabled: columns.table.getVisibleKeys(),
            settingsAction: {
              id: 'settings',
              label: 'common.global.edit_columns',
              icon: 'settings',
              color: 'transparent',
            },
          },
          gridTiles: {
            content: {
              titlePath: 'props.original_filename',
              iconPath: 'tile.icon',
            },
            layout: {
              columns: 'up-to-6',
            },
            image: {
              bgPath: 'background',
              bgHoverPath: 'backgroundHover',
            },
            actions: [
              {
                key: 'preview',
                icon: 'visibility',
                label: 'common.license_package.table.actions.preview_asset',
              },
              ...actions,
            ],
            contextMenu: {
              actions: [],
            },
          },
          gridAdvanced: {
            content: {
              titlePath: 'props.original_filename',
              iconPath: 'tile.icon',
              metadata: columns.list.getMetadata(),
            },
            layout: {
              columns: 'up-to-2',
            },
            image: {
              bgPath: 'background',
              bgHoverPath: 'backgroundHover',
            },
            actions: [
              {
                key: 'preview',
                icon: 'visibility',
                label: 'common.license_package.table.actions.preview_asset',
              },
              ...actions,
            ],
            contextMenu: {
              actions: [],
            },
          },
        };
      }),
    );
  }

  batchActionHandler(event: { key: ResultsActions | string }): void {
    const asset_uuids = this.dataSource.selection.entities$.value.map(({ props }) => props.uuid);

    switch (event.key) {
      case ResultsActions.DELETE:
        this.licensePackagesActionsService.deleteAssetsFromPackage(
          this.dataSource.selection.identifiers$.value as string[],
          <string>this.uuid,
          true,
        );
        break;
      case ResultsActions.TRIGGER_NOTIFICATIONS:
        this.dataSource.selection.entities$.pipe(take(1)).subscribe((data) => {
          const assetUuids = data.map(({ props }) => props.uuid);
          this.dialog.open(TriggerNotificationsDialogComponent, {
            data: {
              packageUuid: this.uuid,
              assetUuids,
            },
            minWidth: 800,
          });
        });
        break;
      case ResultsActions.ADD_TO_LICENSED_PACKAGE:
        this.licensePackagesActionsService.addAssetsToPackage(asset_uuids);
        break;
      case ResultsActions.SET_AS_COLD:
        this.assetActionsService.setAsCold(asset_uuids).subscribe();
        break;
      case ResultsActions.BATCH_IMPOSE_QUARANTINE:
        this.#imposeQuarantine(asset_uuids);
        break;
      case ResultsActions.BATCH_LIFT_QUARANTINE:
        this.#liftQuarantine(asset_uuids);
        break;
      case ResultsActions.BATCH_UPDATE:
        this.assetActionsService.popBatchUpdateDialog(asset_uuids, 'licensed_packages_batch_update');
        break;
      case ResultsActions.ADD_TO_COLLECTION:
        this.collectionsActions.addAssetsToCollection(asset_uuids);
        break;
      case ResultsActions.ADD_TO_CART:
        this.addToCartActionsService.addAssets(asset_uuids.map((assetId) => ({ assetId })));
        break;
      case ResultsActions.CLEAR_SELECTION:
        this.dataSource.selection.clear();
        break;
      case ResultsActions.SEND_TO_DOWNLOADS:
        this.isDownloadLoading.next({ sendToDownloadsLoading: true });
        this.fastOrdersService
          .fastDownload(asset_uuids, OrderItemTypeEnum.ASSET)
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.toastService.success({
                id: 'send_to_downloads',
                message: 'common.notifications.generic.sent_to_downloads_success',
              });
              this.isDownloadLoading.next({ sendToDownloadsLoading: false });
            },
            error: () => this.isDownloadLoading.next({ sendToDownloadsLoading: false }),
          });
        break;
      case ResultsActions.DOWNLOAD_NOW:
        this.isDownloadLoading.next({ downloadNowLoading: true });
        this.fastOrdersService
          .fastDownload(asset_uuids, OrderItemTypeEnum.ASSET)
          .pipe(
            take(1),
            switchMap(async (response) =>
              this.orderActionsService.download(response.uuid, [], {
                download_type: DownloadTypeEnum.ASPERA,
              }),
            ),
          )
          .subscribe(() => this.isDownloadLoading.next({ downloadNowLoading: false }));
        break;
    }
  }

  customActionHandler(event: { key: ResultsActions | string; item?: LicensePackageAssetViewModel }) {
    if (event.key == 'settings') {
      this.dialog.open<ColumnsConfigDialogComponent, ColumnsConfigDialogData>(ColumnsConfigDialogComponent, {
        data: { scope: this.scopeName },
      });
    }

    this.contextMenuActions.handleAction(event);

    if (!event.item) {
      return;
    }
    switch (event.key) {
      case ResultsActions.DELETE:
        this.licensePackagesActionsService.deleteAssetsFromPackage(
          [event.item.context.uuid],
          <string>this.uuid,
          true,
          event.item.props.original_filename,
        );
        break;
      case ResultsActions.PREVIEW:
        this.openAssetPreview(event.item.props.uuid);
        break;
      case ResultsActions.LIFT_QUARANTINE:
        this.#liftQuarantine([event.item.props.uuid]);
        break;
      case ResultsActions.IMPOSE_QUARANTINE:
        this.#imposeQuarantine([event.item.props.uuid]);
        break;
      default:
        return;
    }
  }

  async openAssetPreview(uuid: string) {
    const openAssetPreviewInPopup = await firstValueFrom(this.openAssetPreviewInPopup$);
    if (openAssetPreviewInPopup) {
      window.open(`/asset/${uuid}`, 'AssetPreview', 'width=1300,height=700');
    } else {
      this.router.navigate([`/asset/${uuid}`]).then();
    }
  }

  #liftQuarantine(asset_uuids: string[]) {
    this.confirmationDialog
      .open({
        title: 'Lift quarantine',
        message: 'Are you sure you want to lift quarantine for selected assets?',
      })
      .pipe(
        take(1),
        filter((confirmed) => confirmed),
        switchMap(() => this.assetActionsService.liftQuarantine(asset_uuids)),
      )
      .subscribe();
  }

  #imposeQuarantine(asset_uuids: string[]) {
    this.confirmationDialog
      .open({
        title: 'Impose quarantine',
        message: 'Are you sure you want to impose quarantine on selected assets?',
      })
      .pipe(
        take(1),
        filter((confirmed) => confirmed),
        switchMap(() => this.assetActionsService.imposeQuarantine(asset_uuids)),
      )
      .subscribe();
  }

  #transformToViewModel(licensedPackage: LicensedPackage): Observable<LicensePackageDetailsViewModel> {
    const viewModel: LicensePackageDetailsViewModel = {
      name: [
        {
          name: 'common.license_package.table.title',
          clickable: true,
          path: 'licensed-packages',
        },
        {
          name: licensedPackage.name,
          clickable: false,
        },
      ],
      status: licensedPackage.status,
      subtitle: 'common.license_package.single_view.subtitle',
      details: [
        {
          title: 'common.license_package.single_view.created_by',
          value: licensedPackage.owner_name,
        },
        {
          title: 'common.license_package.single_view.created_at',
          value: licensedPackage.created_at,
          type: 'date-time',
        },
        {
          title: 'common.license_package.single_view.updated_at',
          value: licensedPackage.updated_at,
          type: 'date-time',
        },
        {
          title: 'common.license_package.single_view.description',
          value: licensedPackage.description,
          size: 'wide',
        },
        {
          title: 'common.license_package.single_view.contracts',
          value: licensedPackage.contracts?.map((contract) => contract.name).join(', '),
          size: 'wide',
        },
      ],
    };

    return of(viewModel);
  }

  editLicensePackageDetails() {
    if (!this.uuid) {
      return;
    }
    this.licensePackagesActionsService.editDialog(this.uuid);
  }

  exportMetadataCSV() {
    const uuid = this.uuid;

    if (!uuid) {
      return;
    }

    this.confirmationDialog
      .open({
        title: 'Please confirm export action',
        message: 'If you confirm, we’ll send you a CSV with applied filters via email shortly.',
      })
      .pipe(
        filter((confirmed) => confirmed),
        take(1),
        withLatestFrom(this.fieldsFetcherService.getConfiguration$(this.scopeName)),
        switchMap(([, config]) => {
          return this.licensePackagesActionsService.exportLicensePackage(uuid, config.table.getForExport() ?? [], {
            orderBy: this.dataSource.sortBy$.value,
            orderDir: this.dataSource.sortDirection$.value,
          });
        }),
      )
      .subscribe();
  }

  addToLicensedPackage() {
    if (!this.uuid) {
      return;
    }

    this.licensePackagesActionsService.addFiltersToPackage({
      filters: {
        licensed_package_uuids: [this.uuid],
      },
    });
  }

  setAsColdOnly() {
    if (!this.uuid) {
      return;
    }

    this.assetActionsService
      .setFiltersAsCold({
        filters: {
          licensed_package_uuids: [this.uuid],
        },
      })
      .subscribe();
  }
}
