<ng-container *ngIf="dataSource && configuration; else missingRequiredData">
  <div class="grid-tiles" [class]="getLayout()">
    <div
      class="grid-tiles__item"
      [class.selected-highlight]="isSelectable(dataSource) && (dataSource.selection.isSelected$(item) | async)"
      (dblclick)="handleDoubleClick($event, item)"
      [matTooltip]="configuration.actions | isRowTooltip | translate"
      [matTooltipShowDelay]="500"
      (click)="isSelectable(dataSource) && !isRowDisabled(item) && selectItem(item)"
      (contextmenu)="showContextMenu($event, item)"
      [class.disabled-row]="(configuration.rows | disableIfRow: item).disabled"
      *ngFor="let item of dataSource.connection$ | async; trackBy: dataSource.trackBy ?? defaultTrackBy"
    >
      <div class="grid-tiles__content" [class.grid-tiles__content--one-row]="true">
        <div class="grid-tiles__content__image">
          <vdms-hq-ui-tile-image
            [src]="$castToString(item | valueByPath: configuration.image?.bgPath | async)"
            [hoverSrc]="$castToString(item | valueByPath: configuration.image?.bgHoverPath | async)"
          >
          </vdms-hq-ui-tile-image>
        </div>
        <div class="grid-tiles__title">
          <ng-container *ngIf="configuration.content?.iconPath; let iconPath">
            <mat-icon *ngIf="item | valueByPath: iconPath | async as icon" inline="true">{{ icon }}</mat-icon>
          </ng-container>
          <span
            [sharedE2eId]="'grid-tiles-title'"
            title
            [matTooltip]="'' + (item | valueByPath: configuration.content?.titlePath | async)"
            >{{ item | valueByPath: configuration.content?.titlePath | async }}</span
          >
        </div>
      </div>
      <vdms-hq-ui-floating-controls-v2
        *ngIf="configuration.actions && configuration.actions.length > 0"
        class="grid-tiles__controls"
        [actions]="configuration.actions"
        (action)="action.emit($event)"
        [item]="item"
        [alternativePillConfig]="configuration.rows"
      >
      </vdms-hq-ui-floating-controls-v2>
      @if (this.configuration?.content?.pin) {
        <div class="pin">
          <div
            class="pin-edge"
            [class.pin-edge--success]="this.configuration?.content?.pin?.pinSuccessIf?.(item)"
          ></div>
          <span class="pin-text"
            ><span class="pin-text__rank"
              >#{{ item | valueByPath: this.configuration?.content?.pin?.pinValue1Path | async }}</span
            >
            {{ item | valueByPath: this.configuration?.content?.pin?.pinValue2Path | async }}
            {{ this.configuration?.content?.pin?.pinLabel?.(item) ?? 'Views' }}</span
          >
        </div>
      }
    </div>
  </div>
</ng-container>

<vdms-hq-ui-context-menu
  [actions]="configuration?.contextMenu?.actions ?? []"
  (action)="action.emit($event)"
></vdms-hq-ui-context-menu>

<ng-template #missingRequiredData>
  <vdms-hq-ui-runtime-error></vdms-hq-ui-runtime-error>
</ng-template>
