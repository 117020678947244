import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@vdms-hq/shared';
import { ProcessView } from '../logic/process-view.model';
import { MatIcon } from '@angular/material/icon';
import { InfoBarType, UILayoutModule } from '../../ui-layout';
import { BehaviorSubject } from 'rxjs';
import { UILoaderModule } from '../../ui-loader';
import { UIEmptyResultsModule } from '../../ui-empty-results';

@Component({
  selector: 'vdms-hq-ui-process-view',
  templateUrl: './process-view.component.html',
  styleUrls: ['./process-view.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, SharedModule, MatIcon, UILayoutModule, UILoaderModule, UIEmptyResultsModule],
})
export class UiProcessViewComponent {
  @Input() process!: ProcessView;
  @Input() isLoading$ = new BehaviorSubject(false);
  @Output() processAction = new EventEmitter<{ key: string; value?: string }>();
  protected readonly InfoBarType = InfoBarType;

  showColon(metadata: { label?: string; value: string }) {
    return [metadata.label ?? '', metadata.value].every((v) => v.length > 0);
  }

  getPillMetadataClass(metadata: { label?: string; value: string; pills?: { value: string; color: string }[] }) {
    const hasColor = metadata.pills?.find((pill) => pill.value === metadata.value);
    return hasColor ? `pill ${hasColor.color}` : 'pill';
  }
}
