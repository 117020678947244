<div class="quick-preview-content">
  <div class="preview-box" *ngIf="activeAssetService.currentFile$ | async; let previewConfig">
    <vdms-hq-ui-preview *ngIf="isPreview(previewConfig); let preview" [settings]="preview"></vdms-hq-ui-preview>
    <vdms-hq-player-master
      #player
      *ngIf="isPlayer(previewConfig); let playerConfiguration"
      [config]="playerConfiguration"
    >
    </vdms-hq-player-master>

    <vdms-hq-ui-preview-error
      *ngIf="isError(previewConfig); let error"
      [header]="error.header"
      [reason]="error.message"
    ></vdms-hq-ui-preview-error>
  </div>

  @if (isLoading$ | async) {
    <vdms-hq-ui-loader mode="over-parent" [centerPadding]="true" [backdrop]="true" [center]="true"></vdms-hq-ui-loader>
  } @else {
    @if (groupedElements$ | async; as groupedElements) {
      <vdms-hq-asset-edit-form [groupedElements]="groupedElements" [columns]="1"> </vdms-hq-asset-edit-form>
    }
  }
</div>
