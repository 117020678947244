<ng-template vdmsHqPortal attachTo="#library-header-right">
  <div class="actions">
    @if ((segmentService.isLastSegment$ | async) === false) {
      <vdms-hq-ui-form-input-toggle
        label="Asset view"
        [(ngModel)]="assetView"
        [withFooter]="false"
      ></vdms-hq-ui-form-input-toggle>
    }
    @if (schemaService.activeSchema$ | async; as activeSchema) {
      @if (activeSchema.type === 'user') {
        <vdms-hq-ui-button
          [iconOnly]="true"
          icon="edit"
          [tooltip]="'common.global.edit' | translate"
          [routerLink]="['/browse-library', activeSchema.id, 'edit']"
        ></vdms-hq-ui-button>
      }
    }
  </div>
</ng-template>

@if (segmentService.lastBreadCrumb$ | async; as breadcrumb) {
  <vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[breadcrumb]">
    <vdms-hq-ui-layout-breadcrumbs
      [breadcrumbs]="(segmentService.breadcrumbs$ | async) ?? []"
      (selectedBreadCrumb)="segmentService.selectBreadCrumb($event.index)"
      size="small"
      before-navbar
    ></vdms-hq-ui-layout-breadcrumbs>
    @if ((segmentService.isLastSegment$ | async) === false && !assetView) {
      <div class="actions" actions [formGroup]="filtersForm">
        <vdms-hq-ui-form-input-text
          formControlName="text"
          [placeholder]="'tooltip.search' | translate"
          [label]="'tooltip.search' | translate"
          [fallbackSuffixIcon]="'search'"
        >
        </vdms-hq-ui-form-input-text>
        <vdms-hq-ui-form-input-select
          formControlName="sortDirection"
          [selectOptions]="filteringSortingOptions"
          [label]="'tooltip.sort_by' | translate"
        ></vdms-hq-ui-form-input-select>
      </div>
    }
    <div>
      @if (assetView || (segmentService.isLastSegment$ | async)) {
        @if ((tableHeaderActions$ | async)?.length > 0) {
          <vdms-hq-asset-results-2
            [dataSource]="dataSource"
            [scopeName]="scopeName"
            [multiViewKey]="TABLE_TYPE.ASSET_LIBRARY"
            [actions]="(actions$ | async) ?? []"
            [tableHeaderActions]="(tableHeaderActions$ | async) ?? []"
            (action)="handleAction($event)"
          >
          </vdms-hq-asset-results-2>
        } @else {
          <vdms-hq-ui-loader></vdms-hq-ui-loader>
        }
      } @else {
        <vdms-hq-ui-folder
          [folders]="(segmentService.currentSegmentValuesAsFolders$ | async) ?? []"
          (clickSegment)="onSegmentChange($event.title)"
          [loading]="(segmentService.loading$ | async) ?? false"
          [actions]="(foldersActionsConfig$ | async) ?? []"
          (action)="handleFolderAction($event)"
        >
        </vdms-hq-ui-folder>
      }
    </div>
    <div class="debug-path" footer>
      <span>Schema: </span>
      <div class="path">
        <span *ngFor="let item of schemaService.activeSchemaFields$ | async">{{ item.label }}</span>
      </div>
    </div>
  </vdms-hq-ui-layout-page-wrapper>
}
@if (dataSource.selection?.selected$ | async) {
  <vdms-hq-asset-results-actions
    [selectionManager]="dataSource.selection"
    [cartButtonVisibility]="(clipsOnlyEnabled$ | async) === false"
    [isDownloadLoading]="isDownloadLoading | async"
    (actions)="handleBatchAction({ key: $event })"
  >
  </vdms-hq-asset-results-actions>
}
