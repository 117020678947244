import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { GetDiscountCodeInterface } from '@vdms-hq/api-contract';
import { DiscountsDsService } from '@vdms-hq/discounts';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { SelectOption } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-change-order-discounts-dialog',
  templateUrl: './change-order-discounts-dialog.component.html',
  imports: [CommonModule, MatDialogModule, UIDialogWrapperModule, UIFormModule, UIButtonModule, TranslateModule],
  standalone: true,
})
export class ChangeOrderDiscountsDialogComponent implements OnInit {
  public dialogRef = inject(MatDialogRef<ChangeOrderDiscountsDialogComponent>);
  discountsDsService = inject(DiscountsDsService);

  selectFormControl = new FormControl(null);

  loadingOptions = true;

  discountsOptions$: Observable<SelectOption[]> = this.discountsDsService.unpaginatedData$.pipe(
    map((discounts: GetDiscountCodeInterface[]) => {
      return [
        { key: 'null', label: 'N/A' },
        ...discounts
          .filter((discount) => discount.is_active)
          .map((discount: GetDiscountCodeInterface) => {
            return {
              key: discount.uuid,
              label: discount.name,
            };
          }),
      ];
    }),
    tap(() => {
      this.loadingOptions = false;
    }),
  );

  ngOnInit() {
    this.discountsDsService.refresh();
  }

  abort() {
    this.dialogRef.close();
  }
  save() {
    this.dialogRef.close({ discount: this.selectFormControl.value });
  }
}
