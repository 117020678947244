import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FieldRelationsService } from '../logic/field-relations.service';
import { FieldDefinitionModel } from '@vdms-hq/fields';

@Pipe({
  name: 'isRelationLoading',
})
export class IsRelationLoadingPipe implements PipeTransform {
  constructor(private relationService: FieldRelationsService) {}

  transform(definitionModel: FieldDefinitionModel): Observable<boolean> {
    return this.relationService.loadingFields$.pipe(map((item) => Boolean(item[definitionModel.id])));
  }
}
