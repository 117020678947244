import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { API_CLIENT_ID_PROVIDER, API_CONFIG, ApiConfig } from './logic/config-token';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModule } from '@vdms-hq/auth';
import { BearerInterceptor } from './logic/interceptor/bearer.interceptor';
import { ClientInterceptor } from './logic/interceptor/client.interceptor';
import { CloudfrontInterceptor } from './logic/interceptor/cloudfront.interceptor';
import { CcSalesforceTokenInterceptor } from './logic/interceptor/cc-salesforce-token.interceptor';
import { CacheControlInterceptor } from './logic/interceptor/cache-control.interceptor';
import { ThrottlingInterceptor } from './logic/interceptor/throttling.interceptor';
import { HeaderEncodingInterceptor } from './logic/interceptor/header-encoding.interceptor';

@NgModule({
  imports: [CommonModule, AuthModule],
})
export class ApiContractModule {
  private static trailingSlashRemover = (config: ApiConfig): ApiConfig => ({
    ...config,
    apiUrl: config.apiUrl?.replace(/\/$/, '') ?? undefined,
    websocketUrl: config.websocketUrl?.replace(/\/$/, '') ?? undefined,
  });

  static forRoot(config: ApiConfig): ModuleWithProviders<ApiContractModule> {
    const providers: Provider[] = [
      {
        provide: API_CONFIG,
        useValue: ApiContractModule.trailingSlashRemover(config),
      },
      { provide: API_CLIENT_ID_PROVIDER, useExisting: config.clientIdProvider },
    ];

    if (config.interceptors.bearer) {
      providers.push({
        provide: HTTP_INTERCEPTORS,
        useClass: BearerInterceptor,
        multi: true,
      });
    }

    if (config.interceptors.client) {
      providers.push({
        provide: HTTP_INTERCEPTORS,
        useClass: ClientInterceptor,
        multi: true,
      });
    }

    if (config.interceptors.cloudfrontCookie) {
      providers.push({
        provide: HTTP_INTERCEPTORS,
        useClass: CloudfrontInterceptor,
        multi: true,
      });
    }

    if (config.interceptors.headerEncoding) {
      providers.push({
        provide: HTTP_INTERCEPTORS,
        useClass: HeaderEncodingInterceptor,
        multi: true,
      });
    }

    if (config.interceptors.ccSalesforceToken) {
      providers.push({
        provide: HTTP_INTERCEPTORS,
        useClass: CcSalesforceTokenInterceptor,
        multi: true,
      });
    }

    if (config.interceptors.cacheControl) {
      providers.push({
        provide: HTTP_INTERCEPTORS,
        useClass: CacheControlInterceptor,
        multi: true,
      });
    }

    if (config.interceptors.throttling) {
      providers.push({
        provide: HTTP_INTERCEPTORS,
        useClass: ThrottlingInterceptor,
        multi: true,
      });
    }

    return {
      ngModule: ApiContractModule,
      providers,
    };
  }
}
