<div class="message" [ngClass]="'message--' + notification.status" [class.message--read]="notification.isRead">
  <div class="message__icon">
    <mat-icon>{{ notification.isSuccess ? 'check_circle' : 'info' }}</mat-icon>
  </div>
  <div class="message__title" *ngIf="notification.title">{{ notification.title | translate }}</div>

  <ng-container *ngIf="notification.launchpadApprovedMessage; let packageMessage">
    <div class="message__content">
      LAUNCHPAD package approved:
      <a href="{{ packageMessage.package_url }}" target="_blank" class="message__content__link">
        {{ packageMessage.package_title ?? 'Unknown name' }}</a
      >
      <div class="message__content__list" *ngIf="detailedView">
        <div *ngFor="let asset of packageMessage.assets | slice: 0 : MAX_FILES_DISPLAYED_INDEX">
          <span>{{ asset.filename }}</span>
        </div>
        <div *ngIf="packageMessage.assets.length > MAX_FILES_DISPLAYED_INDEX">
          <span>(and {{ packageMessage.assets.length - MAX_FILES_DISPLAYED_INDEX }} more)</span>
        </div>

        <div class="message__content__list__link">
          <span [routerLink]="['/browse/all']" [queryParams]="uuidsFromAssetsQuery(packageMessage.assets)">
            Show all approved in VIDA
          </span>
        </div>
      </div>
    </div>

    <div class="message__details">
      <div class="message__details__item" *ngIf="notification.createdAt | daysFrom; let days; else: createdToday">
        <span class="key">{{ days }} </span>
        <span class="label"
          ><vdms-hq-shared-days-ago-displaying [days]="days"></vdms-hq-shared-days-ago-displaying
        ></span>
      </div>
      <div class="message__details__item" *ngIf="packageMessage.assets_count; let totalAssets">
        <span class="key">Total assets: </span>
        <span class="label">{{ totalAssets }}</span>
      </div>
      <div class="message__details__item" *ngIf="packageMessage.package_owner; let packageOwner">
        <span class="key">Owner: </span>
        <span class="label">{{ packageOwner }}</span>
      </div>
      <ng-container *ngIf="detailedView">
        <div class="message__details__item" *ngIf="packageMessage.package_uuid; let uuid">
          <span class="key">Package UUID: </span>
          <a class="label link" href="{{ packageMessage.package_url }}" target="_blank">{{ uuid }}</a>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="notification.launchpadPendingApprovalMessage; let packageMessage">
    <div class="message__content">
      New launchpad package pending approval for:
      <a href="{{ packageMessage.package_url }}" target="_blank" class="message__content__link">
        {{ packageMessage.package_title ?? 'Unknown name' }}</a
      >
      <div class="message__content__list" *ngIf="detailedView">
        <div *ngFor="let asset of packageMessage.assets | slice: 0 : MAX_FILES_DISPLAYED_INDEX">
          <span>{{ asset.filename }}</span>
        </div>
        <div *ngIf="packageMessage.assets_count > MAX_FILES_DISPLAYED_INDEX">
          <span>...</span>
        </div>

        <div class="message__content__list__link" *ngIf="packageMessage.assets_count > MAX_FILES_DISPLAYED_INDEX">
          <a href="{{ packageMessage.package_url }}" target="_blank">Show all ({{ packageMessage.assets_count }})</a>
        </div>
      </div>
    </div>
    <div class="message__details">
      <div class="message__details__item" *ngIf="notification.createdAt | daysFrom; let days; else: createdToday">
        <span class="key">{{ days }} </span>
        <span class="label"
          ><vdms-hq-shared-days-ago-displaying [days]="days"></vdms-hq-shared-days-ago-displaying
        ></span>
      </div>
      <div class="message__details__item" *ngIf="packageMessage.assets_count; let totalAssets">
        <span class="key">Total assets: </span>
        <span class="label">{{ totalAssets }}</span>
      </div>
      <div class="message__details__item" *ngIf="packageMessage.package_owner; let packageOwner">
        <span class="key">Owner: </span>
        <span class="label">{{ packageOwner }}</span>
      </div>
      <ng-container *ngIf="detailedView">
        <div class="message__details__item" *ngIf="packageMessage.package_uuid; let uuid">
          <span class="key">Package UUID: </span>
          <a class="label link" href="{{ packageMessage.package_url }}" target="_blank">{{ uuid }}</a>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="notification.newAssetArrivedMessage; let assetMessage">
    <ng-container *ngFor="let asset of assetMessage.assets">
      <div class="message__content">New asset arrived: {{ asset.filename }}</div>
      <div class="message__details">
        <div class="message__details__item" *ngIf="notification.createdAt | daysFrom; let days; else: createdToday">
          <span class="key">{{ days }} </span>
          <span class="label"
            ><vdms-hq-shared-days-ago-displaying [days]="days"></vdms-hq-shared-days-ago-displaying
          ></span>
        </div>
        <div class="message__details__item" *ngIf="asset.md5; let md5">
          <span class="key">MD5: </span>
          <span class="label">{{ md5 }}</span>
        </div>
        <div class="message__details__item" *ngIf="asset.type; let type">
          <span class="key">Type: </span>
          <span class="label">{{ type }}</span>
        </div>
        <ng-container *ngIf="detailedView">
          <div class="message__details__item" *ngIf="asset.uuid; let uuid">
            <span class="key">UUID: </span>
            <span class="label link" [routerLink]="['/browse/all']" [queryParams]="{ query: 'uuid:' + uuid }">{{
              uuid
            }}</span>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="notification.ingestCompletedMessage; let assetMessage">
    <ng-container *ngFor="let asset of assetMessage.assets">
      <div class="message__content">Asset ingest completed: {{ asset.filename }}</div>
      <div class="message__details">
        <div class="message__details__item" *ngIf="notification.createdAt | daysFrom; let days; else: createdToday">
          <span class="key">{{ days }} </span>
          <span class="label"
            ><vdms-hq-shared-days-ago-displaying [days]="days"></vdms-hq-shared-days-ago-displaying
          ></span>
        </div>
        <div class="message__details__item" *ngIf="asset.md5; let md5">
          <span class="key">MD5: </span>
          <span class="label">{{ md5 }}</span>
        </div>
        <div class="message__details__item" *ngIf="asset.type; let type">
          <span class="key">Type: </span>
          <span class="label">{{ type }}</span>
        </div>
        <ng-container *ngIf="detailedView">
          <div class="message__details__item" *ngIf="asset.uuid; let uuid">
            <span class="key">UUID: </span>
            <span class="label link" [routerLink]="['/browse/all']" [queryParams]="{ query: 'uuid:' + uuid }">{{
              uuid
            }}</span>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
<ng-template #createdToday>
  <div class="message__details__item">
    <span class="key">Today</span>
  </div>
</ng-template>
