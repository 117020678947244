import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { take } from 'rxjs';
import { castTo, isSelectableDataSource, SelectableDataSource } from '@vdms-hq/shared';
import { defaultTrackBy } from '../logic/common-functions';
import { ContextMenuComponent, RowViewConfig } from '@vdms-hq/ui';
import { ConfigActions, ContextMenu } from '../logic/common-config';

@Component({
  template: '',
  standalone: true,
})
/**
 * @description: base class for multiple data presentation view components
 */
export class BaseViewLogicComponent<
  D extends Partial<SelectableDataSource<T>>,
  C extends ContextMenu<T> & ConfigActions<T> & { rows?: RowViewConfig<T> },
  T,
> {
  @Input() dataSource?: D;
  @Input() selectionDisabled = false;

  @Output() action = new EventEmitter<{ key: string; item?: T }>();
  @ViewChild(ContextMenuComponent) contextMenu?: ContextMenuComponent<T>;

  @Input() set configuration(configuration: C | undefined) {
    if (!configuration) {
      return;
    }

    this.configurationData = configuration;
  }
  configurationData?: C;

  get configuration() {
    return this.configurationData;
  }

  protected resizeObserver?: ResizeObserver;
  $castToString = castTo<string>();

  defaultTrackBy = defaultTrackBy;
  isSelectable = isSelectableDataSource;

  isRowDisabled(item: T) {
    return Boolean(this.configuration?.rows?.disabledIf?.condition(item));
  }

  selectItem(item: T) {
    if (this.selectionDisabled) {
      return;
    }
    if (!this.isSelectable(this.dataSource)) {
      return;
    }

    this.dataSource.selection
      .isSelected$(item)
      .pipe(take(1))
      .subscribe((selected) => {
        if (selected) this.dataSource?.selection?.changeOne(item, false, false);
        else this.dataSource?.selection?.changeOne(item, true, false);
      });
  }

  showContextMenu($event: MouseEvent, item: T) {
    if (this.isRowDisabled(item)) {
      return;
    }
    if (!this.configuration?.contextMenu?.actions || this.configuration?.contextMenu?.actions?.length === 0) {
      return;
    }
    if (!this.contextMenu) {
      return;
    }
    $event.preventDefault();
    this.contextMenu.open($event, item);
  }

  handleDoubleClick($event: MouseEvent, item: T) {
    if ($event?.target instanceof Element && $event.target.closest('mat-checkbox')) {
      $event.stopImmediatePropagation();
      return;
    }

    if (this.isRowDisabled(item)) {
      return;
    }

    const existingAction = (this.configuration?.actions ?? []).find((action) => action.onDoubleClick);
    if (!existingAction) {
      return;
    }

    return this.action.emit({
      key: existingAction.key,
      item,
    });
  }
}
