import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '../../models/selector.model';

@Pipe({
  standalone: true,
  name: 'extractLabel',
})
/**
 * @description Extracts the label from a SelectOption array based on the key
 */
export class ExtractLabelPipe implements PipeTransform {
  transform(options: SelectOption[], key: SelectOption['key']): string {
    const option = options.find((opt) => opt.key === key);
    return option ? option.label : '';
  }
}
