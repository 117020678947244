import { inject, Injectable } from '@angular/core';
import { JobsApiService, modelContext } from '@vdms-hq/api-contract';
import { ActiveAssetService } from '@vdms-hq/asset-details';
import { map, switchMap, take } from 'rxjs/operators';
import { filterEmpty } from '@vdms-hq/shared';

@Injectable({ providedIn: 'root' })
export class AssetSubtitlesService {
  private jobsApiService = inject(JobsApiService);
  private activeAssetService = inject(ActiveAssetService);

  #assetSubtitles$ = this.activeAssetService.currentAssetView$.pipe(
    filterEmpty(),
    switchMap((asset) => this.jobsApiService.getAssetJobs(asset.uuid)),
    map((response) =>
      response.jobs
        .filter(
          (job) =>
            job.status === 'completed' &&
            (job.type === modelContext.TRANSLATE_SUBTITLES ||
              job.type === modelContext.TRANSCRIBE ||
              job.type === modelContext.TRANSLATE_VIDEO),
        )
        .sort((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? -1 : 1)),
    ),
  );

  subtitlesSelectedOptions$ = this.#assetSubtitles$.pipe(
    take(1),
    filterEmpty(),
    map((jobs) =>
      jobs.map((job) => ({
        key: job.result_urls.filter((url) => url.type.includes('vtt'))?.[0]?.url,
        label: job.target_language ?? job.name,
      })),
    ),
  );
}
