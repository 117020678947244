import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FieldDefinitionModel } from '@vdms-hq/fields';

@Component({
  selector: 'vdms-hq-form-control-read-only',
  templateUrl: './control-read-only.component.html',
  styleUrls: ['control-read-only.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlReadOnlyComponent {
  @Input() definition!: FieldDefinitionModel;
  @Input() value!: string;
}
