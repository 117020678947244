import { ResultsActions } from '@vdms-hq/asset-results';
import { DataAction, GridAdvancedMetadata } from '@vdms-hq/ui';
import { FlatOrderViewModel } from '../../results/logic/order-results-ds';
import { OrderActionsProperties } from './models';

export const sharedPacksViewConfiguration: {
  actions: DataAction<FlatOrderViewModel>[];
} = {
  actions: [
    {
      key: ResultsActions.DOWNLOAD_ALL_ASPERA,
      label: 'tooltip.download_all_with_aspera',
      icon: 'download',
      disabledIf: (order) =>
        order?.orderExpired ||
        !order?.anyCompleted ||
        order?.hasEmbargoedAsset ||
        order?.orderScheduled ||
        !order?.couldBeDownloaded ||
        order?.isWarmingUp,
    },
    {
      key: ResultsActions.DOWNLOAD_ALL_ASPERA_FOLDERS,
      label: 'tooltip.download_all_with_aspera_in_folders',
      icon: 'cloud_download',
      disabledIf: (order) =>
        order?.orderExpired ||
        !order?.anyCompleted ||
        order?.hasEmbargoedAsset ||
        order?.orderScheduled ||
        !order?.couldBeDownloaded ||
        order?.isWarmingUp,
    },
    {
      key: ResultsActions.PREVIEW,
      icon: 'visibility',
      onDoubleClick: true,
      label: 'common.global.preview',
    },
  ],
};

export const ordersViewConfiguration: {
  actions: DataAction<FlatOrderViewModel>[];
} = {
  actions: [
    {
      key: OrderActionsProperties.SHOW_ORDER_STATUS,
      icon: 'view_timeline',
      onDoubleClick: false,
      label: 'common.orders.order_status_details',
      e2eId: 'order-status-button',
    },
    {
      key: ResultsActions.PREVIEW,
      icon: 'visibility',
      onDoubleClick: true,
      label: 'common.global.preview',
      e2eId: 'order-preview-button',
    },
  ],
};

export const ordersMetadata = (isSFIntegration = false): GridAdvancedMetadata<FlatOrderViewModel>[] => [
  {
    label: 'common.orders.package_title',
    valuePath: 'packageTitle',
  },
  {
    label: 'common.orders.sales_force_name',
    valuePath: 'salesForceName',
  },
  {
    label: 'common.orders.total_assets',
    valuePath: 'totalAssets',
  },
  {
    label: 'common.orders.purchase_order_no',
    valuePath: 'purchaseOrderNo',
  },
  {
    label: 'common.orders.order_sender',
    valuePath: 'orderSender',
  },
  {
    label: 'common.orders.shared_by',
    valuePath: 'sharedBy',
  },
  {
    label: 'common.orders.order_shared',
    valuePath: 'orderShared',
  },
  {
    label: 'common.orders.order_shared',
    valuePath: 'deliveryEmails',
  },
  {
    label: 'common.orders.delivery_date',
    valuePath: 'deliveryDate',
    viewFormat: {
      modifiers: {
        dateFormat: 'dd-MM-yyyy hh:mm',
      },
    },
  },
  {
    label: 'common.orders.expiry_date',
    valuePath: 'expiryDate',
    viewFormat: {
      modifiers: {
        dateFormat: 'dd-MM-yyyy hh:mm',
      },
    },
  },
  {
    label: 'common.orders.media_pulse_id',
    valuePath: 'mediaPulseId',
  },
  {
    label: 'common.orders.status',
    valuePath: 'status',
  },
  {
    label: 'common.orders.used_department',
    valuePath: 'usedDepartment',
  },
  {
    label: 'common.orders.transcode_progress',
    valuePath: 'transcode_progress',
    fullLine: true,
    viewFormat: {
      modifiers: {
        asPercentage: true,
      },
    },
  },
  {
    label: isSFIntegration ? 'common.orders.project_details' : 'common.orders.comment',
    valuePath: 'comment',
    fullLine: true,
  },
];

export const defaultOrdersColumns = [
  'packageTitle',
  'purchaseOrderNo',
  'sharedBy',
  'sharedWith',
  'deliveryEmails',
  'combinedOrderStatus',
  'deliveryDate',
  'totalAssets',
  'usedDepartment',
  'comment',
  'transcode_progress',
  'workflow',
  'workflow_details',
];

export const defaultSalesForceOrdersColumns = [
  'deliveryStatus',
  'salesForceName',
  'packageTitle',
  'combinedOrderStatus',
  'orderSender',
  'orderShared',
  'usedDepartment',
  'comment',
  'transcode_progress',
  'workflow',
  'workflow_details',
];
