import { Validators } from '@angular/forms';
import { Permission } from '@vdms-hq/activated-client';
import { PermissionsComparators } from '@vdms-hq/firebase-contract';
import { SelectorSourceType } from '@vdms-hq/selectors';
import { PillColorEnum, ValueFormat } from '@vdms-hq/shared';
import { FilterType } from '../../models/filter-type';
import { FieldType } from '../../models/field-type';
import { FieldDefinitionModel } from '../../models/field-config.id';

const fieldDefinitionsRecord: Record<string, Omit<FieldDefinitionModel, 'id'>> = {
  deliveryUploadJobStatus: {
    label: 'Job Status',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'statusLabel',
      sortable: false,
      viewFormat: {
        pills: {
          draft: 'white-dashed',
          'pending approval': 'yellow',
          approved: 'done',
          completed: 'done',
          rejected: 'failed',
          error: 'failed',
          deleted: 'failed',
        },
      },
    },
    scope: ['upload-jobs'],
  },
  deliveryUploadJobUpdatedAt: {
    label: 'Updated at',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'updatedAt',
      sortable: false,
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    scope: ['upload-jobs'],
  },
  deliveryPackUuid: {
    label: 'UUID',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'uuid',
      sortable: true,
    },
    scope: ['other-delivery-pack'],
  },

  deliveryPackName: {
    label: 'Name',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'name',
      sortable: true,
    },
    scope: ['other-delivery-pack'],
  },

  deliveryPackOwner: {
    label: 'Owner',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'owner.name',
      sortable: false,
    },
    scope: ['other-delivery-pack'],
  },
  deliveryPackSupplier: {
    label: 'Supplier',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'supplier.name',
      sortable: false,
    },
    scope: ['other-delivery-pack'],
  },

  previewRequestSalesForceName: {
    label: 'Req. Number',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'sales_force_name',
      sortable: true,
    },
    scope: ['other-preview-requests'],
  },
  previewRequestSalesForceTitle: {
    label: 'Title',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'sales_force_title',
      sortable: true,
    },
    scope: ['other-preview-requests'],
  },
  previewRequestOwner: {
    label: 'Owner',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'owner',
      sortable: true,
    },
    scope: ['other-preview-requests'],
  },

  mediaPulseWoNo: {
    label: 'Order No',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'wo_no',
      sortable: false,
    },

    scope: ['other-media-pulse-orders'],
  },
  mediaPulseWoDesc: {
    label: 'Title',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'wo_desc',
      sortable: false,
    },
    scope: ['other-media-pulse-orders'],
  },
  mediaPulseAccountRepDesc: {
    label: 'Account Manager',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'account_rep_desc',
      sortable: false,
    },
    scope: ['other-media-pulse-orders'],
  },
  mediaPulseContact: {
    label: 'Client Contact',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'contact',
      sortable: false,
    },
    scope: ['other-media-pulse-orders'],
  },
  mediaPulsePo: {
    label: 'PO',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'po',
      sortable: false,
    },
    scope: ['other-media-pulse-orders'],
  },
  mediaPulsePhaseCode: {
    label: 'PHASE',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'phase_code',
      sortable: false,
    },
    scope: ['other-media-pulse-orders'],
  },
  mediaPulseStatusNo: {
    label: 'Status',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'status_no',
      sortable: false,
    },
    scope: ['other-media-pulse-orders'],
  },
  mediaPulseWoEndDt: {
    label: 'Due Date',
    format: ValueFormat.DATETIME,
    results2: {
      objectPath: 'wo_end_dt',
      sortable: false,
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    scope: ['other-media-pulse-orders'],
  },
  mediaPulseDateChanged: {
    label: 'Modified Date',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'date_changed',
      sortable: false,
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    scope: ['other-media-pulse-orders'],
  },
  orderCombinedStatus: {
    label: 'common.orders.order_status',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'combinedOrderStatus',
      sortable: false,
      viewFormat: {
        pills: {
          scheduled: 'yellow-dashed',
          processing: 'yellow',
          'ready for download': 'done-light',
          downloaded: 'cold-blue',
          complete: 'done',
          error: 'failed',
          delivering: 'orange',
          default: 'orange',
        },
      },
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  /** @deprecated, use orderCombinedStatus */
  orderDeliveryStatus: {
    label: 'Delivery status (Deprecated)',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'deliveryStatus',
      exportObjectPath: 'deliveryStatus',
      sortable: false,
      viewFormat: {
        pills: {
          'common.orders.delivery.getty_images_failed': 'failed',
          'common.orders.delivery.delivered': 'done',
          'common.orders.delivery.failed': 'failed',
        },
        modifiers: {
          translate: true,
        },
      },
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  /** @deprecated, use orderCombinedStatus */
  orderDownloadStatus: {
    label: 'Download Status (Deprecated)',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'downloadStatus',
      exportObjectPath: 'downloadStatus',
      sortable: false,
      viewFormat: {
        pills: {
          'common.orders.download.downloaded': 'done',
        },
        modifiers: {
          translate: true,
        },
      },
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderPackageTitle: {
    label: 'common.orders.package_title',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'packageTitle',
      exportObjectPath: 'packageTitle',
      sortable: false,
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderSalesForceName: {
    label: 'common.orders.sales_force_name',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'salesForceName',
      exportObjectPath: 'salesForceName',
      sortable: false,
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderDeliveryEmails: {
    label: 'common.orders.delivery_emails',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'deliveryEmails',
      exportObjectPath: 'deliveryEmails',
      sortable: false,
      viewFormat: {
        maxVisibleValues: 3,
      },
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderTotalAssets: {
    label: 'common.orders.total_assets',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'totalAssets',
      exportObjectPath: 'totalAssets',
      sortable: false,
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderUsedDepartment: {
    label: 'common.orders.used_department',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'usedDepartment',
      exportObjectPath: 'usedDepartment',
      sortable: false,
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderPurchaseOrderNo: {
    label: 'common.orders.purchase_order_no',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'purchaseOrderNo',
      exportObjectPath: 'purchaseOrderNo',
      sortable: false,
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderSharedBy: {
    label: 'common.orders.shared_by',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'sharedBy',
      exportObjectPath: 'sharedBy',
      sortable: false,
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderShared: {
    label: 'common.orders.order_shared',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'orderShared',
      exportObjectPath: 'orderShared',
      sortable: false,
      viewFormat: {
        maxVisibleValues: 3,
      },
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderDeliveryDate: {
    label: 'common.orders.delivery_date',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'deliveryDate',
      exportObjectPath: 'deliveryDate',
      sortable: false,
      viewFormat: {
        modifiers: {
          dateFormat: 'dd-MM-yyyy hh:mm',
        },
      },
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderExpiryDate: {
    label: 'common.orders.expiry_date',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'expiryDate',
      exportObjectPath: 'expiryDate',
      sortable: false,
      viewFormat: {
        modifiers: {
          dateFormat: 'dd-MM-yyyy hh:mm',
        },
      },
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderCreatedAt: {
    label: 'common.orders.created_at',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'createdAt',
      exportObjectPath: 'createdAt',
      sortable: false,
      viewFormat: {
        modifiers: {
          dateFormat: 'dd-MM-yyyy hh:mm',
        },
      },
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderMediaPulseId: {
    label: 'common.orders.media_pulse_id',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'mediaPulseId',
      exportObjectPath: 'mediaPulseId',
      sortable: false,
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderStatus: {
    label: 'common.orders.status',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'status',
      exportObjectPath: 'status',
      sortable: false,
      viewFormat: {
        pills: {
          downloaded: 'done',
          delivered: 'done',
          complete: 'done',
          expired: 'orange',
          new: '',
          default: 'yellow',
          failed: 'failed',
        },
        modifiers: {
          translate: true,
        },
      },
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderComment: {
    label: 'common.orders.comment',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'comment',
      exportObjectPath: 'comment',
      sortable: false,
      viewFormat: {
        modifiers: {
          truncateCharacters: 200,
        },
      },
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderUuid: {
    label: 'common.orders.uuid',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'uuid',
      exportObjectPath: 'uuid',
      sortable: false,
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderTotalBytes: {
    label: 'common.orders.total_bytes',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'totalBytes',
      exportObjectPath: 'totalBytes',
      sortable: false,
      viewFormat: {
        modifiers: {
          fileSizeFormat: true,
        },
      },
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderTotalDownloadableAssets: {
    label: 'common.orders.total_downloadable_assets',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'totalDownloadableAssets',
      exportObjectPath: 'totalDownloadableAssets',
      sortable: false,
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderTotalDownloadedAssets: {
    label: 'common.orders.total_downloaded_assets',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'totalDownloadedAssets',
      exportObjectPath: 'totalDownloadedAssets',
      sortable: false,
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderTotalPrice: {
    label: 'common.orders.total_price',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'totalPrice',
      exportObjectPath: 'totalPrice',
      sortable: false,
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderWorkflow: {
    label: 'orders.workflow.label',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'workflow',
      exportObjectPath: 'workflow',
      sortable: false,
      foldValues: false,
      viewFormat: {
        pills: {
          'email delivery': 'dark-blue',
          'ai processing': 'pink',
          'delivery destination': 'cold-blue',
          'warm up': 'done-light',
        },
      },
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },
  orderWorkflowDetails: {
    label: 'orders.workflow.details',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'workflow_details',
      exportObjectPath: 'workflow_details',
      sortable: false,
      foldValues: true,
      viewFormat: {
        maxVisibleValues: 1,
        fallback: 'Empty',
        pills: {},
      },
    },
    scope: ['other-orders', 'other-orders-approval', 'other-shared-packs'],
  },

  partnerName: {
    label: 'common.rights_partners.table.columns.name',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'name',
      sortable: false,
    },
    scope: ['other-rights-partners'],
  },
  partnerUserEmails: {
    label: 'common.rights_partners.table.columns.users_emails',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'users_emails',
      sortable: false,
      foldValues: true,
      viewFormat: {
        pills: {},
      },
    },
    scope: ['other-rights-partners'],
  },
  partnerUserCount: {
    label: 'common.rights_partners.table.columns.users_count',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'users_count',
      sortable: false,
    },
    scope: ['other-rights-partners'],
  },
  partnerExternalId: {
    label: 'common.rights_partners.table.columns.partner_external_id',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'partner_external_id',
      sortable: false,
    },
    scope: ['other-rights-partners'],
  },
  collectionName: {
    label: 'common.collection_name',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'name',
      sortable: true,
      viewFormat: {
        modifiers: {
          truncateCharacters: 50,
        },
      },
    },
    scope: ['other-collections'],
  },
  collectionDescription: {
    label: 'common.description',
    format: ValueFormat.AS_IS,
    results2: {
      sortable: false,
      objectPath: 'description',
      viewFormat: {
        modifiers: {
          truncateCharacters: 50,
        },
      },
    },
    scope: ['other-collections'],
  },
  ownerType: {
    label: 'common.collection_type',
    format: ValueFormat.AS_IS,
    results2: {
      sortable: false,
      objectPath: 'owner_type',
    },
    scope: ['other-collections'],
  },
  ownerName: {
    label: 'common.collection_owner',
    format: ValueFormat.AS_IS,
    results2: {
      sortable: false,
      objectPath: 'owner_name',
    },
    scope: ['other-collections'],
  },
  numberOfAssets: {
    label: 'common.assets_number',
    format: ValueFormat.AS_IS,
    results2: {
      sortable: false,
      objectPath: 'number_of_assets',
      viewFormat: {
        fallback: 'Empty',
      },
    },
    scope: ['other-collections'],
  },
  contractName: {
    label: 'common.rights_contracts.table.columns.name',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'name',
      exportObjectPath: 'name',
      sortable: false,
    },
    scope: ['other-rights-contracts'],
  },
  contractPartnerName: {
    label: 'common.rights_contracts.table.columns.partner',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'partner.name',
      exportObjectPath: 'partner.name',
      sortable: false,
    },
    scope: ['other-rights-contracts'],
  },
  contractPartnerUsersCount: {
    label: 'common.rights_contracts.table.columns.partner_users_count',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'partner.partner_users_count',
      exportObjectPath: 'partner.partner_users_count',
      sortable: false,
    },
    scope: ['other-rights-contracts'],
  },
  contractPackagesCount: {
    label: 'common.rights_contracts.table.columns.licensed_package_count',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'licensed_package_count',
      exportObjectPath: 'licensed_package_count',
      sortable: false,
    },
    scope: ['other-rights-contracts'],
  },
  contractPackageNames: {
    label: 'common.rights_contracts.table.columns.licensed_package_names',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'licensed_package_names',
      exportObjectPath: 'licensed_package_names',
      sortable: false,
      foldValues: true,
      viewFormat: {
        pills: {},
      },
    },
    scope: ['other-rights-contracts'],
  },
  contractId: {
    label: 'common.rights_contracts.table.columns.contract_id',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'contract_id',
      exportObjectPath: 'contract_id',
      sortable: false,
    },
    scope: ['other-rights-contracts'],
  },
  contractStatus: {
    label: 'common.rights_contracts.table.columns.status',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'status',
      exportObjectPath: 'status',
      sortable: false,
      viewFormat: {
        pills: {
          'first notification sent': 'done',
          default: 'orange',
        },
      },
    },
    scope: ['other-rights-contracts'],
  },
  contractStartDate: {
    label: 'common.rights_contracts.table.columns.start_date',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'start_date',
      exportObjectPath: 'start_date',
      sortable: false,
      viewFormat: {
        modifiers: {
          dateFormat: 'date',
        },
      },
    },
    scope: ['other-rights-contracts'],
  },
  contractEndDate: {
    label: 'common.rights_contracts.table.columns.end_date',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'end_date',
      exportObjectPath: 'end_date',
      sortable: false,
      viewFormat: {
        modifiers: {
          dateFormat: 'date',
        },
      },
    },
    scope: ['other-rights-contracts'],
  },
  licensedPackageStatus: {
    label: 'common.license_package.table.columns.status',
    format: ValueFormat.PROCESSING_STATUS,
    results2: {
      objectPath: 'status',
      sortObjectPath: 'licensedPackage.status',
      sortable: true,
      viewFormat: {
        modifiers: {
          removeUnderscores: true,
        },
        pills: {
          active: 'done',
        },
      },
    },
    filters: {
      objectPath: 'processing_status',
      aggregationKey: 'processing_status',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
    scope: ['other-licensed-packages', 'other-licensed-packages-connect2'],
  },
  licensedPackageUUID: {
    label: 'common.license_package.table.columns.uuid',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'uuid',
      sortable: false,
    },
    scope: ['other-licensed-packages', 'other-licensed-packages-connect2'],
  },
  licensedPackageName: {
    label: 'common.license_package.table.columns.name',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'name',
      sortable: true,
      sortObjectPath: 'licensedPackage.name',
    },
    scope: ['other-licensed-packages', 'other-licensed-packages-connect2'],
  },
  licensedPackageDescription: {
    label: 'common.license_package.table.columns.description',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'description',
      sortable: true,
      sortObjectPath: 'licensedPackage.description',
      viewFormat: {
        modifiers: {
          truncateCharacters: 70,
        },
      },
    },
    scope: ['other-licensed-packages', 'other-licensed-packages-connect2'],
  },
  licensedPackageNumberOfAssets: {
    label: 'common.license_package.table.columns.number_of_assets',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'number_of_assets',
      sortObjectPath: 'licensedPackage.numberOfAssets',
      sortable: true,
    },
    scope: ['other-licensed-packages', 'other-licensed-packages-connect2'],
  },
  licensedPackageNumberOfContracts: {
    label: 'common.license_package.table.columns.number_of_contracts',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'number_of_contracts',
      sortable: false,
      viewFormat: {
        fallback: 'Out of Contract',
      },
    },
    scope: ['other-licensed-packages'],
  },
  licensedPackageMaxExpiresAt: {
    label: 'common.license_package.table.columns.max_expires_at',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'max_expires_at',
      sortable: true,
      sortObjectPath: 'licensedPackage.maxExpiresAt',
      viewFormat: {
        modifiers: {
          onlyIfFutureDate: true,
          dateFormat: 'date-time',
        },
        fallback: 'Out of Contract',
      },
    },
    scope: ['other-licensed-packages', 'other-licensed-packages-connect2'],
  },
  licensedPackageMinStartAt: {
    label: 'common.license_package.table.columns.min_start_at',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'min_start_at',
      sortable: false,
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    scope: ['other-licensed-packages'],
  },
  createdBy: {
    label: 'common.license_package.table.columns.created_by',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'owner_name',
      sortable: false,
    },
    scope: ['other-licensed-packages'],
  },
  licensedPackageViewState: {
    label: 'common.license_package.table.columns.view_state',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'view_state',
      sortable: false,
    },
    scope: ['other-licensed-packages'],
  },
  createdAt: {
    label: 'common.license_package.table.columns.created_at',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'created_at',
      sortable: false,
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    scope: ['other-rights-contracts', 'other-collections', 'other-delivery-pack', 'other-preview-requests'],
  },
  licensedPackageCreatedAt: {
    label: 'common.license_package.table.columns.created_at',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'created_at',
      sortable: true,
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
      sortObjectPath: 'licensedPackage.createdAt',
    },
    scope: ['other-licensed-packages', 'other-licensed-packages-connect2'],
  },
  updatedAt: {
    label: 'common.license_package.table.columns.updated_at',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'updated_at',
      sortable: false,
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    scope: ['other-rights-contracts', 'other-collections', 'other-preview-requests', 'other-delivery-pack'],
  },
  licensedPackageUpdatedAt: {
    label: 'common.license_package.table.columns.updated_at',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'updated_at',
      sortable: true,
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
      sortObjectPath: 'licensedPackage.updatedAt',
    },
    scope: ['other-licensed-packages', 'other-licensed-packages-connect2'],
  },
  orderApprovalStatus: {
    label: 'common.asset.general.orderApprovalStatus',
    format: ValueFormat.APPROVAL_STATUS,
    results2: {
      exportObjectPath: 'approval_status',
      objectPath: 'approval_status',
      sortable: false,
      viewFormat: {
        pills: {
          requested: 'orange',
          approved: 'done',
          rejected: 'failed',
        },
      },
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'cart',
      'preview-request',
      'orders',
    ],
  },
  uploadJobApprovalStatus: {
    label: 'common.asset.general.uploadJobApprovalStatus',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.status',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'approval_status',
      objectPath: 'context.status',
      sortable: false,
      viewFormat: {
        pills: {
          approved: 'done',
          completed: 'done',
          pending_approval: 'orange',
          rejected: 'orange-light',
          draft: 'done-light',
          default: '',
        },
      },
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'cart',
      'preview-request',
    ],
  },
  processingStatus: {
    label: 'common.asset.general.assetStatus',
    //sourceListKey: SelectorSourceType.PROCESSING_STATUS,
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.combined_status',
      type: FieldType.TEXT,
      readonly: true,
    },
    results2: {
      objectPath: 'combinedStatus',
      sortObjectPath: 'combined_status',
      exportObjectPath: 'combined_status',
      sortable: false,
      viewFormat: {
        modifiers: {
          removeUnderscores: true,
        },
        greyLineIf: 'placeholder',
        pills: {
          placeholder: 'white-dashed',
          initializing: 'yellow-dashed',
          ready: 'done',
          active: 'done-light',
          error: 'failed',
          deleted: 'failed',
          cold: 'cold-blue',
          default: 'orange',
        },
      },
    },
    filters: {
      objectPath: 'combined_status',
      aggregationKey: 'combined_status',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'cart',
      'preview-request',
      'orders',
      'shared-packs',
    ],
  },
  processingStatusLegacy: {
    label: 'common.asset.general.processingStatusLegacy',
    sourceListKey: SelectorSourceType.PROCESSING_STATUS,
    format: ValueFormat.PROCESSING_STATUS,
    input: {
      objectPath: 'general.processingStatus',
      type: FieldType.SELECT_MULTI,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'processing',
      sortObjectPath: 'processing_status',
      exportObjectPath: 'processing_status',
      sortable: true,
      viewFormat: {
        modifiers: {
          removeUnderscores: true,
        },
        greyLineIf: 'placeholder',
        pills: {
          placeholder: 'white-dashed',
          initializing: 'yellow-dashed',
          analyse: 'orange',
          creating_proxy: 'orange-light',
          creating_stream: 'done-light',
          done: 'done',
          error: 'failed',
          qa_failed: 'failed',
          default: '',
        },
      },
    },
    filters: {
      objectPath: 'processing_status',
      aggregationKey: 'processing_status',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'cart',
      'preview-request',
      'orders',
      'shared-packs',
    ],
  },
  audioTrackLanguage: {
    featureDate: new Date(2023, 12, 13),

    sourceListKey: SelectorSourceType.AUDIO_LANGUAGE,
    label: 'common.asset.general.audio_track_language',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'audio_tracks_language',
      type: FilterType.SELECTOR,
      singleValue: true,
      validFormat: 'keyword',
      aggregationKey: 'audio_tracks_language',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'cart',
      'preview-request',
    ],
  },
  audioTrackLayout: {
    featureDate: new Date(2023, 12, 13),

    sourceListKey: SelectorSourceType.AUDIO_LAYOUT,
    label: 'common.asset.general.audio_track_layout',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'audio_tracks_layout',
      type: FilterType.SELECTOR,
      singleValue: true,
      validFormat: 'keyword',
      aggregationKey: 'audio_tracks_layout',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'cart',
      'preview-request',
    ],
  },
  audioTrackClass: {
    featureDate: new Date(2023, 12, 13),

    sourceListKey: SelectorSourceType.AUDIO_CLASS,
    label: 'common.asset.general.audio_track_class',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'audio_tracks_class',
      type: FilterType.SELECTOR,
      singleValue: true,
      validFormat: 'keyword',
      aggregationKey: 'audio_tracks_class',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'cart',
      'preview-request',
    ],
  },
  uuid: {
    label: 'common.asset.general.uuid',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.uuid',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'uuid',
      objectPath: 'props.uuid',
      sortObjectPath: 'uuid',
      sortable: false,
    },
    sourceListKey: 'uuid',
    filters: {
      objectPath: 'uuid',
      aggregationKey: 'uuid',
      type: FilterType.CHIPS,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'cart',
      'preview-request',
      'orders',
      'shared-packs',
    ],
  },
  batchId: {
    label: 'common.asset.general.batch_id',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.batch_id',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  sObjectId: {
    results2: {
      sortable: false,
      exportObjectPath: 's_object_id',
      objectPath: 'props.s_object_id',
    },
    label: 'common.asset.general.s_object_id',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.s_object_id',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  sObjectIdBk: {
    results2: {
      sortable: false,
      exportObjectPath: 's_object_id_bk',
      objectPath: 'props.s_object_id_bk',
    },
    label: 'common.asset.general.s_object_id_bk',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.s_object_id_bk',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  masterNo: {
    label: 'common.asset.general.master_no',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.master_no',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  mediaKey: {
    label: 'common.asset.general.media_key',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.media_key',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  externalKey: {
    label: 'common.asset.general.external_key',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.external_key',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: true,
      exportObjectPath: 'external_key',
      objectPath: 'props.external_key',
      sortObjectPath: 'external_key',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  uploadSource: {
    label: 'common.asset.general.upload_source',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.upload_source',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      sortable: true,
      exportObjectPath: 'upload_source',
      objectPath: 'props.upload_source',
      sortObjectPath: 'upload_source',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  uploaded: {
    results2: {
      sortable: true,
      sortObjectPath: 'uploaded_at',
      objectPath: 'props.uploaded_at',
      exportObjectPath: 'uploaded_at',
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    label: 'common.asset.general.uploaded_at',
    format: ValueFormat.DATE,
    input: {
      objectPath: 'general.uploaded_at',
      type: FieldType.DATE,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  uploadedBy: {
    label: 'common.asset.general.uploaded_by',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'props.uploaded_by',
      exportObjectPath: 'uploaded_by',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  isThumbnailProcessing: {
    label: 'common.asset.general.thumbnail_processing',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'general.thumbnail_processing',
      type: FieldType.CHECKBOX,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  isVirtual: {
    results2: {
      sortable: false,
      objectPath: 'props.is_virtual',
      exportObjectPath: 'is_virtual',
      viewFormat: {
        modifiers: {
          yesNo: true,
        },
        pills: {
          default: '',
          true: 'done',
        },
      },
    },
    label: 'common.asset.general.is_virtual',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'general.is_virtual',
      type: FieldType.CHECKBOX,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  isProxyOnly: {
    label: 'common.asset.general.is_proxy_only',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'general.is_proxy_only',
      type: FieldType.CHECKBOX,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  timecodeIn: {
    results2: {
      sortable: false,
      objectPath: 'timecodeIn',
    },
    label: 'common.asset.general.timecode_in',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.timecode_in',
      type: FieldType.TIMECODE,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  timecodeOut: {
    results2: {
      sortable: false,
      objectPath: 'timecodeOut',
    },
    label: 'common.asset.general.timecode_out',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.timecode_out',
      type: FieldType.TIMECODE,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  timecodeStart: {
    label: 'common.asset.file_metadata.timecode_start',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.timecode_start',
      type: FieldType.TIMECODE,
      readonly: true,
      validators: [],
    },
    results2: {
      sortable: false,
      objectPath: 'props.timecode_start',
    },
    filters: {
      objectPath: 'timecode_start',
      aggregationKey: 'timecode_start',
      validFormat: 'range',
      type: FilterType.TIMECODE_RANGE,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  cleanAperture: {
    label: 'common.asset.general.clean_aperture',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.clean_aperture',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      sortable: false,
      objectPath: 'props.clean_aperture',
    },
    filters: {
      objectPath: 'clean_aperture',
      aggregationKey: 'clean_aperture',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  containerFormat: {
    label: 'common.asset.general.container_format',
    format: ValueFormat.AS_IS,
    results2: {
      exportObjectPath: 'container_format',
      sortable: false,
      objectPath: 'props.container_format',
    },
    filters: {
      objectPath: 'container_format',
      aggregationKey: 'container_format',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  encodedPixels: {
    label: 'common.asset.general.encoded_pixels',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.encoded_pixels',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      sortable: false,
      exportObjectPath: 'encoded_pixels',
      objectPath: 'props.encoded_pixels',
    },
    filters: {
      objectPath: 'encoded_pixels',
      aggregationKey: 'encoded_pixels',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  fileExt: {
    label: 'common.asset.general.file_ext',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.file_ext',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  tags: {
    label: 'common.asset.general.tags',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.tags',
      type: FieldType.TAGS,
      format: ValueFormat.TAGS,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'tagsValue',
      type: FilterType.CHIPS,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'tags',
      objectPath: 'props.tags',
      sortable: false,
      viewFormat: {
        maxVisibleValues: 3,
        modifiers: {
          valueObjectKey: 'value',
        },
      },
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  clientAssetId: {
    results2: {
      sortable: false,
      exportObjectPath: 'client_asset_id',
      objectPath: 'props.client_asset_id',
    },
    label: 'common.asset.general.client_asset_id',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.client_asset_id',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'client_asset_id',
      type: FilterType.TEXT,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  format: {
    label: 'common.asset.file_metadata.format',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.format',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.format',
      exportObjectPath: 'format',
      sortable: false,
    },
    filters: {
      objectPath: 'format',
      aggregationKey: 'format',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'preview-request',
    ],
  },
  resolution: {
    results2: {
      sortable: false,
      exportObjectPath: 'vida_resolution',
      objectPath: 'props.vida_resolution',
    },
    label: 'common.asset.file_metadata.resolution',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.vida_resolution',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    sourceListKey: 'vida_resolution',
    filters: {
      objectPath: 'vida_resolution',
      aggregationKey: 'vida_resolution',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  duration: {
    label: 'common.asset.file_metadata.duration',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.duration',
      type: FieldType.TIMECODE,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'duration',
      sortable: true,
      sortObjectPath: 'duration',
      exportObjectPath: 'duration',
    },
    filters: {
      objectPath: 'duration',
      aggregationKey: 'duration',
      validFormat: 'range',
      type: FilterType.TIMECODE_RANGE,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  videoDuration: {
    label: 'common.asset.file_metadata.video_duration',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.duration',
      type: FieldType.TIMECODE,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'videoDuration',
      sortObjectPath: 'video_duration',
      exportObjectPath: 'video_duration',
      sortable: true,
    },
    filters: {
      objectPath: 'video_duration',
      aggregationKey: 'video_duration',
      validFormat: 'range',
      type: FilterType.TIMECODE_RANGE,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  containerDuration: {
    label: 'common.asset.file_metadata.container_duration',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.duration',
      readonly: true,
      type: FieldType.TIMECODE,
    },
    results2: {
      sortable: false,
      objectPath: 'containerDuration',
    },
    filters: {
      objectPath: 'container_duration',
      validFormat: 'range',
      type: FilterType.TIMECODE_RANGE,
      aggregationKey: 'container_duration',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'cart',
      'preview-request',
    ],
  },
  width: {
    results2: {
      sortable: false,
      objectPath: 'props.width',
      sortObjectPath: 'width',
      exportObjectPath: 'width',
    },
    label: 'common.asset.file_metadata.video.screen_size.width',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.width',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'width',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
      aggregationKey: 'width',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  height: {
    format: ValueFormat.AS_IS,

    results2: {
      sortable: false,
      objectPath: 'props.height',
      sortObjectPath: 'height',
      exportObjectPath: 'height',
    },
    label: 'common.asset.file_metadata.video.screen_size.height',
    input: {
      objectPath: 'file_metadata.video.screen_size.height',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'height',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
      aggregationKey: 'height',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  videoCodec: {
    sourceListKey: 'video_codec_mapping',
    results2: {
      sortable: false,
      objectPath: 'props.video_codec_mapping',
      sortObjectPath: 'video_codec_mapping',
      exportObjectPath: 'video_codec_mapping',
    },
    label: 'common.asset.file_metadata.video_codec',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video_codec_mapping',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'video_codec_mapping',
      aggregationKey: 'video_codec_mapping',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  videoCodecProfile: {
    results2: {
      sortable: false,
      objectPath: 'props.profile',
      sortObjectPath: 'profile',
      exportObjectPath: 'profile',
    },
    label: 'common.asset.file_metadata.video_codec_profile',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.profile',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'profile',
      aggregationKey: 'profile',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  audioCodec: {
    results2: {
      sortable: false,
      objectPath: 'props.audio_codec',
      exportObjectPath: 'audio_codec',
    },
    label: 'common.global.audio_codec',
    format: ValueFormat.AS_IS,
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'preview-request',
      'cart',
      'orders',
    ],
  },
  videoBitrate: {
    label: 'common.asset.file_metadata.video_bitrate',
    format: ValueFormat.BITRATE_UNIT,
    input: {
      objectPath: 'file_metadata.bitrate',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'bitrate',
      aggregationKey: 'bitrate',
      validFormat: 'range',
      type: FilterType.BITRATE_RANGE,
    },
    results2: {
      exportObjectPath: 'bitrate',
      objectPath: 'props.bitrate',
      sortObjectPath: 'bitrate',
      sortable: true,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  bitrateMode: {
    label: 'common.asset.file_metadata.bitrate_mode',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.bitrate_mode',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.bitrate_mode',
      exportObjectPath: 'bitrate_mode',
      sortable: false,
    },
    filters: {
      objectPath: 'bitrate_mode',
      aggregationKey: 'bitrate_mode',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  videoBitDepth: {
    label: 'common.asset.file_metadata.bit_depth',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.bit_depth',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.bit_depth',
      exportObjectPath: 'bit_depth',
      sortable: false,
    },
    filters: {
      objectPath: 'bit_depth',
      aggregationKey: 'bit_depth',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  interlaceMode: {
    label: 'common.asset.file_metadata.interlace_mode',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.interlace_mode',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.interlace_mode',
      exportObjectPath: 'interlace_mode',
      sortable: false,
    },
    filters: {
      objectPath: 'interlace_mode',
      aggregationKey: 'interlace_mode',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  pixelAspectRatio: {
    label: 'common.asset.file_metadata.pixel_aspect_ratio',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.pixel_aspect_ratio',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.pixel_aspect_ratio',
      exportObjectPath: 'pixel_aspect_ratio',
      sortable: false,
    },
    filters: {
      objectPath: 'pixel_aspect_ratio',
      aggregationKey: 'pixel_aspect_ratio',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  framerate: {
    label: 'common.asset.file_metadata.framerate',
    format: ValueFormat.FRAMERATE,
    sourceListKey: 'framerate',
    input: {
      objectPath: 'file_metadata.framerate',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'framerate',
      aggregationKey: 'framerate',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    results2: {
      objectPath: 'props.framerate',
      exportObjectPath: 'framerate',
      sortable: false,
      sortObjectPath: 'framerate',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  frameRateMode: {
    label: 'common.asset.file_metadata.frame_rate_mode',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.frameRateMode',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.frameRateMode',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  chromaFormat: {
    label: 'common.asset.file_metadata.chroma_format',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.chroma_format',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'chroma_format',
      objectPath: 'props.chroma_format',
      sortable: false,
    },
    filters: {
      objectPath: 'chroma_format',
      aggregationKey: 'chroma_format',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  colorSpace: {
    label: 'common.asset.file_metadata.color_space',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.color_space',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.color_space',
      exportObjectPath: 'color_space',
      sortable: false,
    },
    filters: {
      objectPath: 'color_space',
      aggregationKey: 'color_space',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  colorMatrixCoefficients: {
    label: 'common.asset.file_metadata.color_matrix_coefficients',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.color_matrix_coefficients',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.color_matrix_coefficients',
      exportObjectPath: 'color_matrix_coefficients',
      sortable: false,
    },
    filters: {
      objectPath: 'color_matrix_coefficients',
      aggregationKey: 'color_matrix_coefficients',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  colorPrimaries: {
    label: 'common.asset.file_metadata.color_primaries',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.color_primaries',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.color_primaries',
      exportObjectPath: 'color_primaries',
      sortable: false,
    },
    filters: {
      objectPath: 'color_primaries',
      aggregationKey: 'color_primaries',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  colorTransferCharacteristics: {
    label: 'common.asset.file_metadata.color_transfer_characteristics',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.color_transfer_characteristics',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.color_transfer_characteristics',
      exportObjectPath: 'color_transfer_characteristics',
      sortable: false,
    },
    filters: {
      objectPath: 'color_transfer_characteristics',
      aggregationKey: 'color_transfer_characteristics',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  aspectRatio: {
    label: 'common.asset.file_metadata.aspect_ratio',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.aspect_ratio',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      objectPath: 'props.aspect_ratio',
      exportObjectPath: 'aspect_ratio',
      sortable: true,
      sortObjectPath: 'aspect_ratio',
    },
    filters: {
      objectPath: 'aspect_ratio',
      aggregationKey: 'aspect_ratio',
      validFormat: 'keyword',
      type: FilterType.CHECKBOX_LIST,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  size: {
    label: 'common.asset.file_metadata.file_size',
    format: ValueFormat.FILE_SIZE,
    input: {
      objectPath: 'file_metadata.filesize',
      type: FieldType.FILE_SIZE,
      readonly: true,
      validators: [],
    },
    results2: {
      sortable: true,
      exportObjectPath: 'size',
      objectPath: 'size',
      sortObjectPath: 'size',
      viewFormat: {
        modifiers: {
          fileSizeFormat: true,
        },
      },
    },
    filters: {
      objectPath: 'size',
      validFormat: 'range',
      type: FilterType.SIZE_RANGE,
      aggregationKey: 'size',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  airtableTitle: {
    label: 'common.asset.additional.airtable_title',
    sourceListKey: 'airtable_title',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.airtable_title',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    scope: ['default'],
  },
  episodeName: {
    label: 'common.asset.core.episode_name',
    format: ValueFormat.AS_IS,
    sourceListKey: 'episode_name',
    input: {
      objectPath: 'core.episode_name',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      objectPath: 'props.episode_name',
      exportObjectPath: 'episode_name',
      sortObjectPath: 'episode_name',
      sortable: true,
    },
    filters: {
      objectPath: 'episode_name',
      validFormat: 'keyword',
      aggregationKey: 'episode_name',
      type: FilterType.SELECTOR,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  episodeNamePrefilled: {
    label: 'common.asset.core.episode_name',
    sourceListKey: 'episode_name',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'core.episode_name',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  episodeNumber: {
    results2: {
      exportObjectPath: 'episode_number',
      sortable: true,
      objectPath: 'props.episode_number',
      sortObjectPath: 'episode_number',
      viewFormat: {
        modifiers: {
          asNumberWithZero: true,
        },
      },
    },
    label: 'common.asset.core.episode_number',
    format: ValueFormat.NATURAL_NUMBERS_WITH_ZERO,
    input: {
      objectPath: 'core.episode_number',
      type: FieldType.NUMBER,
      readonly: false,
      validators: [Validators.min(0)],
    },
    filters: {
      objectPath: 'episode_number',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      aggregationKey: 'episode_number',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  seriesName: {
    label: 'common.asset.core.series_name',
    sourceListKey: 'series_name',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'core.series_name',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'series_name',
      objectPath: 'props.series_name',
      sortObjectPath: 'series_name',
      sortable: true,
    },
    filters: {
      objectPath: 'series_name',
      validFormat: 'keyword',
      aggregationKey: 'series_name',
      type: FilterType.SELECTOR,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  seriesNamePrefilled: {
    label: 'common.asset.core.series_name',
    sourceListKey: 'series_name',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'core.series_name',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  seriesNumber: {
    results2: {
      sortable: true,
      exportObjectPath: 'series_number',
      objectPath: 'props.series_number',
      sortObjectPath: 'series_number',
      viewFormat: {
        modifiers: {
          asNumberWithZero: true,
        },
      },
    },
    label: 'common.asset.core.series_number',
    format: ValueFormat.NATURAL_NUMBERS_WITH_ZERO,
    input: {
      objectPath: 'core.series_number',
      type: FieldType.NUMBER,
      readonly: false,
      validators: [Validators.min(0)],
    },
    filters: {
      objectPath: 'series_number',
      type: FilterType.SELECTOR,
      aggregationKey: 'series_number',
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  clientRef: {
    label: 'common.asset.core.client_ref',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'core.client_ref',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: true,
      exportObjectPath: 'client_ref',
      objectPath: 'props.client_ref',
      sortObjectPath: 'client_ref',
    },
    filters: {
      objectPath: 'client_ref',
      type: FilterType.CHIPS,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  programmeNumber: {
    results2: {
      exportObjectPath: 'programme_number',
      sortable: true,
      objectPath: 'props.programme_number',
      sortObjectPath: 'programme_number',
    },
    label: 'common.asset.core.programme_number',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'core.programme_number',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'programme_number',
      validFormat: 'keyword',
      aggregationKey: 'programme_number',
      type: FilterType.SELECTOR,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  facilityOfOrigin: {
    results2: {
      exportObjectPath: 'facility_of_origin',
      sortable: true,
      objectPath: 'props.facility_of_origin',
      sortObjectPath: 'facility_of_origin',
    },
    label: 'common.asset.core.facility_of_origin',
    sourceListKey: 'facility_of_origin',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'core.facility_of_origin',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    filters: {
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      objectPath: 'facility_of_origin',
      aggregationKey: 'facility_of_origin',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  originalFilename: {
    results2: {
      sortable: true,
      exportObjectPath: 'original_filename',
      objectPath: 'props.original_filename',
      sortObjectPath: 'original_filename',
    },
    label: 'common.asset.core.original_filename',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'core.original_filename',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'filename',
      validFormat: 'keyword',
      type: FilterType.CHIPS,
    },
    useAsFilename: true,
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  originalFilenameSharedPacksAssets: {
    results2: {
      sortable: true,
      exportObjectPath: 'original_filename',
      objectPath: 'originalFilenameOrDeliveredFilename',
      sortObjectPath: 'original_filename',
    },
    label: 'common.asset.core.filename',
    format: ValueFormat.AS_IS,
    useAsFilename: true,
    scope: ['shared-packs'],
  },
  language: {
    label: 'common.asset.core.language',
    sourceListKey: SelectorSourceType.LANGUAGE,
    format: ValueFormat.AS_IS,
    input: {
      format: ValueFormat.SELECTOR,
      objectPath: 'user_media_data.language',
      type: FieldType.SELECT,
      readonly: false,
    },
    filters: {
      objectPath: 'language',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
      aggregationKey: 'language',
    },
    results2: {
      exportObjectPath: 'language',
      objectPath: 'props.language',
      sortObjectPath: 'language',
      sortable: true,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  userAspectRatio: {
    label: 'common.asset.user_media_data.aspect_ratio',
    sourceListKey: 'aspect_ratio',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'user_media_data.aspect_ratio',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  imageAspect: {
    label: 'common.asset.user_media_data.image_aspect',
    sourceListKey: 'image_aspect',
    format: ValueFormat.SELECTOR,
    filters: {
      objectPath: 'image_aspect',
      aggregationKey: 'image_aspect',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    results2: {
      sortable: false,
      objectPath: 'props.image_aspect',
      sortObjectPath: 'image_aspect',
      exportObjectPath: 'image_aspect',
    },
    input: {
      objectPath: 'user_media_data.image_aspect',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  framing: {
    label: 'common.asset.user_media_data.framing',
    sourceListKey: 'framing',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'user_media_data.framing',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  elements: {
    results2: {
      sortable: true,
      exportObjectPath: 'elements',
      objectPath: 'props.elements',
      sortObjectPath: 'elements',
    },
    label: 'common.asset.user_media_data.elements',
    sourceListKey: 'elements',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'user_media_data.elements',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'elements',
      aggregationKey: 'elements',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  bars: {
    label: 'common.asset.user_media_data.bars',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'user_media_data.bars',
      type: FieldType.CHECKBOX,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  clockSlate: {
    label: 'common.asset.user_media_data.clock_slate',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'user_media_data.clock_slate',
      type: FieldType.CHECKBOX,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  seamlessSegmented: {
    label: 'common.asset.user_media_data.seamless_segmented',
    sourceListKey: 'seamless_segmented',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'user_media_data.seamless_segmented',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  segment: {
    results2: {
      exportObjectPath: 'segment',
      sortable: false,
      objectPath: 'props.segment',
    },
    label: 'common.global.segment',
    format: ValueFormat.UPPERCASE,
    sourceListKey: SelectorSourceType.SEAMLESS_SEGMENTED,
    filters: {
      objectPath: 'segments',
      aggregationKey: 'segments',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  restoreStatus: {
    results2: {
      sortable: false,
      objectPath: 'restoreStatus',
      viewFormat: {
        pills: {
          'warming up': 'cold-blue',
          warm: 'failed',
        },
      },
    },
    label: 'common.global.restore_status',
    format: ValueFormat.DATE,
    scope: ['orders'],
  },
  retention: {
    label: 'common.asset.user_media_data.retention',
    sourceListKey: 'retention',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'user_media_data.retention',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  coldOnly: {
    label: 'common.global.cold_only',
    sourceListKey: SelectorSourceType.YES_NO,
    format: ValueFormat.YES_NO,
    filters: {
      objectPath: SelectorSourceType.IS_COLD_ONLY,
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'cold_only',
      objectPath: 'props.cold_only',
      sortable: true,
      sortObjectPath: 'cold_only',
      viewFormat: {
        modifiers: {
          yesNo: true,
        },
        pills: {
          default: '',
        },
      },
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  assetMasterType: {
    label: 'common.asset.general.type',
    format: ValueFormat.AS_IS,
    sourceListKey: SelectorSourceType.ASSET_MASTER_TYPE,
    filters: {
      objectPath: 'type',
      type: FilterType.CHECKBOX_LIST,
      validFormat: 'keyword',
      aggregationKey: 'type',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  assetType: {
    label: 'common.asset.general.type',
    format: ValueFormat.AS_IS,
    sourceListKey: 'asset_master_type',
    input: {
      objectPath: 'general.type',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    filters: {
      objectPath: 'type',
      type: FilterType.CHECKBOX_LIST,
      validFormat: 'keyword',
      aggregationKey: 'type',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  mediaInfoType: {
    label: 'common.asset.additional.type',
    sourceListKey: 'asset_type',
    format: ValueFormat.SELECTOR,
    input: {
      // no 'type' key in MediaInfo interface
      // objectPath: 'mediainfo.type',
      objectPath: 'additional.type',
      type: FieldType.SELECT,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  mediaInfoDisposition: {
    label: 'common.asset.additional.disposition',
    sourceListKey: 'asset_type',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.disposition',
      type: FieldType.SELECT,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  mediaInfoSubtype: {
    label: 'common.asset.additional.subtype',
    sourceListKey: 'sub_type',
    format: ValueFormat.SELECTOR,
    input: {
      // no 'subtype' key in MediaInfo interface
      // objectPath: 'mediainfo.subtype',
      objectPath: 'additional.subtype',
      type: FieldType.SELECT,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  disposition: {
    results2: {
      exportObjectPath: 'disposition',
      sortable: true,
      objectPath: 'props.disposition',
      sortObjectPath: 'disposition',
    },
    label: 'common.asset.additional.disposition',
    sourceListKey: 'disposition',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.disposition',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    filters: {
      type: FilterType.CHECKBOX_LIST,
      validFormat: 'keyword',
      objectPath: 'disposition',
      aggregationKey: 'disposition',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  physicalVersionTitle: {
    label: 'common.asset.mediaset.physical_version_title',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'mediaset.physical_version_title',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'physical_version_title',
      sortable: false,
      objectPath: 'props.physical_version_title',
    },
    filters: {
      objectPath: 'physical_version_title',
      type: FilterType.TEXT,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  physicalVersionDescription: {
    label: 'common.asset.mediaset.physical_version_description',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'mediaset.physical_version_description',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'physical_version_description',
      sortable: false,
      objectPath: 'props.physical_version_description',
    },
    filters: {
      objectPath: 'physical_version_description',
      type: FilterType.TEXT,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  parentMediaId: {
    label: 'common.asset.mediaset.parent_media_id',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'mediaset.parent_media_id',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  relationship: {
    label: 'common.asset.mediaset.relationship',
    sourceListKey: 'relationship',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'mediaset.relationship',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  description: {
    label: 'common.asset.additional.description',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.description',
      type: FieldType.TEXTAREA,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: false,
      exportObjectPath: 'description',
      objectPath: 'props.description',
      sortObjectPath: 'description',
      viewFormat: {
        modifiers: {
          truncateCharacters: 80,
        },
      },
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  synopsis: {
    results2: {
      sortable: false,
      exportObjectPath: 'synopsis',
      objectPath: 'props.synopsis',
      viewFormat: {
        modifiers: {
          truncateCharacters: 80,
        },
      },
    },
    label: 'common.asset.additional.synopsis',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.synopsis',
      type: FieldType.TEXTAREA,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  synopsisShort: {
    results2: {
      exportObjectPath: 'synopsis_short',
      sortable: false,
      objectPath: 'props.synopsis_short',
      viewFormat: {
        modifiers: {
          truncateCharacters: 80,
        },
      },
    },
    label: 'common.asset.additional.synopsis_short',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.synopsis_short',
      type: FieldType.TEXTAREA,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  aiSynopsis: {
    label: 'common.asset.additional.synopsis',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.synopsis',
      type: FieldType.AI_TRANSCODE_SUMMARY,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  genre: {
    label: 'common.asset.additional.genre',
    sourceListKey: 'genre',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'additional.genre',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'genre',
      sortable: false,
      objectPath: 'props.genre',
    },
    filters: {
      objectPath: 'genre',
      aggregationKey: 'genre',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  note: {
    label: 'common.asset.additional.note',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.note',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'note',
      validFormat: 'keyword',
      type: FilterType.TEXT,
    },
    results2: {
      objectPath: 'props.note',
      exportObjectPath: 'note',
      sortObjectPath: 'note',
      sortable: true,
      viewFormat: {
        modifiers: {
          truncateCharacters: 80,
        },
      },
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  tapeNo: {
    results2: {
      sortable: true,
      objectPath: 'props.tape_no',
      sortObjectPath: 'tape_no',
    },
    label: 'common.asset.additional.tape_no',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.tape_no',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'tape_no',
      validFormat: 'keyword',
      type: FilterType.CHIPS,
    },
    useAsFilename: true,
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  altNo: {
    label: 'common.asset.additional.alt_no',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.alt_no',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: true,
      objectPath: 'props.alt_no',
      exportObjectPath: 'alt_no',
      sortObjectPath: 'alt_no',
    },
    filters: {
      objectPath: 'alt_no',
      validFormat: 'keyword',
      type: FilterType.CHIPS,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  captions: {
    label: 'common.asset.additional.captions',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'additional.captions',
      type: FieldType.CHECKBOX,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  subtitleLanguage: {
    label: 'common.asset.additional.subtitle',
    format: ValueFormat.SELECTOR,
    sourceListKey: 'language',
    input: {
      objectPath: 'additional.subtitle',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  partNo: {
    label: 'common.asset.additional.part_no',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.part_no',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  partTotal: {
    label: 'common.asset.additional.part_total',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.part_total',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  productionNo: {
    label: 'common.asset.additional.production_no',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.production_no',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  productionCompany: {
    label: 'common.global.production_company',
    format: ValueFormat.AS_IS,
    sourceListKey: SelectorSourceType.PRODUCTION_COMPANY,
    input: {
      objectPath: 'additional.production_company',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: false,
      exportObjectPath: 'production_company',
      objectPath: 'props.production_company',
    },
    filters: {
      objectPath: 'production_company',
      validFormat: 'keyword',
      aggregationKey: 'production_company',
      type: FilterType.SELECTOR,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  shootingStartDate: {
    label: 'common.asset.additional.shooting_start_date',
    format: ValueFormat.DATE,
    input: {
      objectPath: 'additional.shooting_start_date',
      type: FieldType.DATE,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  shootingEndDate: {
    label: 'common.asset.additional.shooting_end_date',
    format: ValueFormat.DATE,
    input: {
      objectPath: 'additional.shooting_end_date',
      type: FieldType.DATE,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  starRating: {
    label: 'common.asset.additional.star_rating',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.star_rating',
      type: FieldType.TEXT,
      readonly: false,
      mask: '0000',
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  userPlayerOffset: {
    label: 'common.asset.user_media_data.player_offset',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'user_media_data.player_offset',
      type: FieldType.TIMECODE,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: false,
      exportObjectPath: 'playerOffset',
      objectPath: 'playerOffset',
    },
    filters: {
      objectPath: 'player_offset',
      validFormat: 'range',
      type: FilterType.TIMECODE_RANGE,
      aggregationKey: 'player_offset',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  metadataType: {
    label: 'common.asset.additional.type',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.type',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  metadataSubType: {
    label: 'common.asset.additional.subtype',
    sourceListKey: 'sub_type',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.subtype',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: true,
      objectPath: 'props.metadata_sub_type',
      exportObjectPath: 'metadata_sub_type',
      sortObjectPath: 'metadata_sub_type',
    },
    filters: {
      aggregationKey: 'metadata_sub_type',
      objectPath: 'metadata_sub_type',
      type: FilterType.TEXT,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  isPreviewAppEnabled: {
    label: 'common.asset.additional.is_preview_app_enabled',
    format: ValueFormat.YES_NO,
    sourceListKey: 'yes_no',
    input: {
      objectPath: 'additional.is_preview_app_enabled',
      type: FieldType.CHECKBOX,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'is_preview_app_enabled',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  contentClass: {
    results2: {
      sortable: true,
      objectPath: 'props.content_class',
      exportObjectPath: 'content_class',
      sortObjectPath: 'content_class',
    },
    label: 'common.asset.additional.content_class',
    sourceListKey: 'content_class',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.content_class',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'content_class',
      aggregationKey: 'content_class',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  contentType: {
    results2: {
      sortable: true,
      exportObjectPath: 'content_type',
      objectPath: 'props.content_type',
      sortObjectPath: 'content_type',
    },
    label: 'common.asset.additional.content_type',
    sourceListKey: 'content_type',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.content_type',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'content_type',
      aggregationKey: 'content_type',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  theme: {
    results2: {
      sortable: true,
      exportObjectPath: 'theme',
      objectPath: 'props.theme',
      sortObjectPath: 'theme',
    },
    label: 'common.asset.additional.theme',
    format: ValueFormat.AS_IS,
    sourceListKey: 'theme',
    input: {
      objectPath: 'additional.theme',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'theme',
      aggregationKey: 'theme',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  themePrefilled: {
    label: 'common.asset.additional.theme',
    sourceListKey: 'theme',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'additional.theme',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: true,
      exportObjectPath: 'theme',
      objectPath: 'props.theme',
      sortObjectPath: 'theme',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  variation: {
    results2: {
      sortable: true,
      exportObjectPath: 'variation',
      objectPath: 'props.variation',
      sortObjectPath: 'variation',
    },
    label: 'common.asset.additional.variation',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.variation',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'variation',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      aggregationKey: 'variation',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  category: {
    results2: {
      sortable: true,
      exportObjectPath: 'category',
      objectPath: 'props.category',
      sortObjectPath: 'category',
    },
    label: 'common.asset.additional.category',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.category',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'category',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      aggregationKey: 'category',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  version: {
    filters: {
      objectPath: 'version',
      type: FilterType.TEXT,
      validFormat: 'keyword',
    },
    results2: {
      sortable: true,
      exportObjectPath: 'version',
      objectPath: 'props.version',
      sortObjectPath: 'version',
    },
    label: 'common.asset.additional.version',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.version',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  metadataFramerate: {
    label: 'common.asset.additional.framerate',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.framerate',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  vidaNumber: {
    results2: {
      exportObjectPath: 'vida_number',
      sortable: false,
      objectPath: 'props.vida_number',
      sortObjectPath: 'vida_number',
    },
    label: 'common.asset.additional.vida_number',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.vida_number',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'vida_number',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      aggregationKey: 'vida_number',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  themeCode: {
    results2: {
      sortable: false,
      exportObjectPath: 'theme_code',
      objectPath: 'props.theme_code',
      sortObjectPath: 'theme_code',
    },
    label: 'common.asset.additional.theme_code',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'additional.theme_code',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  txDate: {
    results2: {
      exportObjectPath: 'tx_date',
      sortable: true,
      objectPath: 'props.tx_date',
      sortObjectPath: 'tx_date',
      viewFormat: {
        modifiers: {
          dateFormat: 'date',
          timeZoneSensitive: true,
        },
      },
    },
    label: 'common.asset.ext_title_info.tx_date',
    format: ValueFormat.TZ_DATE,
    input: {
      objectPath: 'ext_title_info.tx_date',
      type: FieldType.DATE,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'tx_date',
      type: FilterType.DATEPICKER_RANGE,
      validFormat: 'range',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  woDesc: {
    label: 'Title',
    format: ValueFormat.AS_IS,

    results2: {
      exportObjectPath: 'wo_desc',
      objectPath: 'props.wo_desc',
      sortObjectPath: 'wo_desc',
      sortable: true,
    },
    scope: ['orders', 'shared-packs'],
  },
  woNo: {
    label: 'Order number',
    format: ValueFormat.AS_IS,

    results2: {
      exportObjectPath: 'wo_no',
      objectPath: 'props.wo_no',
      sortObjectPath: 'wo_no',
      sortable: true,
    },
    scope: ['orders', 'shared-packs'],
  },
  accountRepDesc: {
    label: 'Account Manager',
    format: ValueFormat.AS_IS,

    results2: {
      exportObjectPath: 'account_rep_desc',
      objectPath: 'props.account_rep_desc',
      sortObjectPath: 'account_rep_desc',
      sortable: true,
    },
    scope: ['orders', 'shared-packs'],
  },
  contact: {
    label: 'Client Contact',
    format: ValueFormat.AS_IS,

    results2: {
      exportObjectPath: 'contact',
      objectPath: 'props.contact',
      sortObjectPath: 'contact',
      sortable: true,
    },
    scope: ['orders', 'shared-packs'],
  },
  po: {
    label: 'PO',
    format: ValueFormat.AS_IS,

    results2: {
      exportObjectPath: 'po',
      objectPath: 'props.po',
      sortObjectPath: 'po',
      sortable: true,
    },
    scope: ['orders', 'shared-packs'],
  },
  phaseCode: {
    label: 'Phase',
    format: ValueFormat.AS_IS,

    results2: {
      exportObjectPath: 'phase_code',
      objectPath: 'phase_code',
      sortObjectPath: 'phase_code',
      sortable: true,
    },
    scope: ['orders', 'shared-packs'],
  },
  dateChanged: {
    label: 'Modified Date',
    format: ValueFormat.DATETIME,

    results2: {
      exportObjectPath: 'date_changed',
      objectPath: 'date_changed',
      sortObjectPath: 'date_changed',
      sortable: true,
    },
    scope: ['orders', 'shared-packs'],
  },
  woEndDt: {
    label: 'Due date',
    format: ValueFormat.DATETIME,

    results2: {
      exportObjectPath: 'wo_end_dt',
      objectPath: 'wo_end_dt',
      sortObjectPath: 'wo_end_dt',
      sortable: true,
    },
    scope: ['orders', 'shared-packs'],
  },
  deliveryStatus: {
    label: 'Delivery status',
    format: ValueFormat.AS_IS,

    results2: {
      exportObjectPath: 'delivery_status',
      objectPath: 'delivery_status',
      sortObjectPath: 'delivery_status',
      sortable: true,
    },
    scope: ['orders', 'shared-packs'],
  },
  statusNo: {
    label: 'Status',
    format: ValueFormat.AS_IS,

    results2: {
      exportObjectPath: 'status_no',
      objectPath: 'status_no',
      sortObjectPath: 'status_no',
      sortable: true,
    },
    scope: ['orders', 'shared-packs'],
  },
  status: {
    label: 'Status',
    format: ValueFormat.AS_IS,

    results2: {
      exportObjectPath: 'status',
      objectPath: 'status',
      sortObjectPath: 'status',
      sortable: true,
    },
    scope: ['orders', 'shared-packs'],
  },
  comment: {
    label: 'Comment',
    format: ValueFormat.AS_IS,

    results2: {
      exportObjectPath: 'comment',
      objectPath: 'comment',
      sortObjectPath: 'comment',
      sortable: true,
    },
    scope: ['orders', 'shared-packs'],
  },
  ingestDate: {
    label: 'common.global.ingest_date',
    format: ValueFormat.DATE,
    filters: {
      objectPath: 'ingest_date',
      type: FilterType.DATEPICKER_RANGE,
      validFormat: 'range',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  librarySalesRights: {
    sourceListKey: SelectorSourceType.YES_NO,
    results2: {
      exportObjectPath: 'library_sales_rights',
      sortable: false,
      objectPath: 'props.library_sales_rights',
      viewFormat: {
        modifiers: {
          yesNo: true,
        },
      },
    },
    label: 'common.asset.ext_title_info.library_sales_rights',
    format: ValueFormat.YES_NO,
    input: {
      objectPath: 'ext_title_info.library_sales_rights',
      type: FieldType.CHECKBOX,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'library_sales_rights',
      aggregationKey: '',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  catalogItemParentUuid: {
    results2: {
      exportObjectPath: 'catalog_item_parent_uuid',
      sortable: false,
      objectPath: 'props.catalog_item_parent_uuid',
      viewFormat: {
        modifiers: {
          yesNo: true,
        },
      },
    },
    label: 'common.asset.general.is_library_attached',
    format: ValueFormat.YES_NO,
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  seasonTitle: {
    label: 'common.asset.ext_title_info.season_title',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_title_info.season_title',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'season_title',
      objectPath: 'props.season_title',
      sortable: false,
    },
    filters: {
      objectPath: 'season_title',
      aggregationKey: 'season_title',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  seasonTitlePrefilled: {
    label: 'common.asset.ext_title_info.season_title',
    sourceListKey: 'season_title',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'ext_title_info.season_title',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  eidrNumber: {
    label: 'common.asset.ext_title_info.eidr_number',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_title_info.eidr_number',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  originalTitle: {
    label: 'common.asset.ext_title_info.original_title',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_title_info.original_title',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  otherTitle: {
    label: 'common.asset.ext_title_info.other_title',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_title_info.other_title',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  releaseYear: {
    label: 'common.asset.ext_title_info.release_year',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_title_info.release_year',
      type: FieldType.TEXT,
      readonly: false,
      mask: '0000',
      validators: [],
    },
    filters: {
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      objectPath: 'release_year',
      aggregationKey: 'release_year',
      mask: '0000',
    },
    results2: {
      exportObjectPath: 'release_year',
      objectPath: 'props.release_year',
      sortObjectPath: 'release_year',
      sortable: true,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  programmeSubtitle: {
    label: 'common.asset.ext_title_info.programme_subtitle',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_title_info.programme_subtitle',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  artist: {
    label: 'common.asset.ext_asset_info.artist',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_asset_info.artist',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  dynamicRange: {
    label: 'common.asset.ext_asset_info.dynamic_range',
    sourceListKey: 'dynamic_range',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'ext_asset_info.dynamic_range',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  inventoryType: {
    label: 'common.asset.additional.inventory_type',
    sourceListKey: 'inventory_type',
    format: ValueFormat.SELECTOR,
    input: {
      objectPath: 'additional.inventory_type',
      type: FieldType.SELECT,
      readonly: false,
      validators: [],
    },
    results2: {
      sortable: true,
      exportObjectPath: 'inventory_type',
      objectPath: 'props.inventory_type',
      sortObjectPath: 'inventory_type',
    },
    filters: {
      objectPath: 'inventory_type',
      aggregationKey: 'inventory_type',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  sequenceNo: {
    label: 'common.asset.ext_asset_info.sequence_no',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_asset_info.sequence_no',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  orderNumber: {
    label: 'common.asset.ext_asset_info.order_number',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_asset_info.order_number',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  barcodeField: {
    label: 'common.asset.ext_asset_info.barcode_field',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_asset_info.barcode_field',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  origin: {
    label: 'common.asset.ext_asset_info.origin',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'ext_asset_info.origin',
      type: FieldType.TEXT,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'origin',
      objectPath: 'props.origin',
      sortable: false,
    },
    filters: {
      objectPath: 'origin',
      aggregationKey: 'origin',
      validFormat: 'keyword',
      type: FilterType.SELECTOR,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  audioTracks: {
    label: 'common.asset.audio',
    format: ValueFormat.AS_IS,
    sourceListKey: 'audio_tracks',
    input: {
      objectPath: 'audioTracks',
      type: FieldType.AUDIO_TRACK,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  audioChannelCount: {
    label: 'common.global.audio_channel_count',
    format: ValueFormat.AS_IS,
    results2: {
      exportObjectPath: 'audio_channel_count',
      sortable: false,
      objectPath: 'props.audio_channel_count',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  audioTracksTable: {
    label: 'common.asset.audio_table',
    format: ValueFormat.VALUES_OF_OBJECT,
    results2: {
      exportObjectPath: 'audio_tracks',
      objectPath: 'audio_tracks',
      sortable: false,
      viewFormat: {
        maxVisibleValues: 2,
      },
    },
    sourceListKey: 'audio_tracks',
    input: {
      objectPath: 'audioTracks',
      type: FieldType.AUDIO_TRACK_TABLE,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  audioTracksTedial: {
    label: 'common.asset.audio_tedial',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'audioTracks',
      type: FieldType.AUDIO_TRACK_TEDIAL,
      readonly: true,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  rating: {
    label: 'common.asset.general.rating_uuids',
    format: ValueFormat.SELECTOR_MULTI,
    sourceListKey: SelectorSourceType.ASSET_GENERAL_RATINGS,
    input: {
      objectPath: 'general.rating_uuids',
      type: FieldType.SELECT_GROUP,
      readonly: false,
      validators: [],
    },
    results2: {
      objectPath: 'ratingsSummary',
      sortable: false,
      viewFormat: {
        maxVisibleValues: 2,
      },
    },
    filters: {
      aggregationKey: 'ratings',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      objectPath: 'ratings',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  multiversion: {
    label: 'common.asset.additional.multiversion',
    format: ValueFormat.SELECTOR_MULTI,
    sourceListKey: 'multiversion',
    input: {
      objectPath: 'additional.multiversion',
      type: FieldType.SELECT_MULTI,
      readonly: false,
      validators: [],
    },
    results2: {
      exportObjectPath: 'multiversion',
      objectPath: 'props.multiversion',
      sortable: false,
    },
    filters: {
      aggregationKey: 'multiversion',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
      objectPath: 'multiversion',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  isDirty: {
    label: 'common.global.is_dirty',
    sourceListKey: SelectorSourceType.IS_DIRTY,
    format: ValueFormat.SELECTOR,
    filters: {
      objectPath: 'is_dirty',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  isOrphan: {
    label: 'common.global.orphan_filter',
    sourceListKey: SelectorSourceType.ORPHAN,
    format: ValueFormat.SELECTOR,
    filters: {
      objectPath: 'orphan_filter',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  isPurged: {
    label: 'common.global.purged_filter',
    format: ValueFormat.YES_NO,
    sourceListKey: 'is_purged',
    filters: {
      objectPath: SelectorSourceType.IS_PURGED,
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  deletedAt: {
    results2: {
      sortable: true,
      sortObjectPath: 'deleted_date',
      objectPath: 'props.deleted_at',
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    label: 'common.asset.general.deleted_at',
    format: ValueFormat.DATE,
    scope: ['deleted', 'browse'],
  },
  purgedAt: {
    results2: {
      sortable: true,
      sortObjectPath: 'purged_date',
      objectPath: 'props.deleted_at',
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    label: 'common.asset.general.purged_at',
    format: ValueFormat.DATE,
    scope: ['deleted'],
  },
  deletedBy: {
    results2: {
      sortable: false,
      sortObjectPath: 'deleted_by',
      objectPath: 'props.deleted_by',
    },
    label: 'common.asset.general.deleted_by',
    format: ValueFormat.AS_IS,
    scope: ['deleted'],
  },
  deleteReason: {
    results2: {
      sortable: false,
      sortObjectPath: 'delete_reason',
      objectPath: 'props.delete_reason',
    },
    label: 'common.asset.general.delete_reason',
    format: ValueFormat.AS_IS,
    scope: ['deleted'],
  },
  deletionBillable: {
    results2: {
      sortable: false,
      sortObjectPath: 'deletion_billable',
      objectPath: 'props.deletion_billable',
      viewFormat: {
        modifiers: {
          yesNo: true,
        },
      },
    },
    label: 'common.asset.general.deletion_billable',
    format: ValueFormat.YES_NO,
    scope: ['deleted'],
  },
  isDeleted: {
    label: 'common.global.deleted_filter',
    sourceListKey: 'is_deleted',
    format: ValueFormat.YES_NO,
    filters: {
      objectPath: SelectorSourceType.IS_DELETED,
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  isQuarantined: {
    label: 'common.global.quarantined_filter',
    sourceListKey: 'yes_no',
    format: ValueFormat.YES_NO,
    filters: {
      objectPath: SelectorSourceType.IS_QUARANTINED,
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'is_quarantined',
      objectPath: 'props.is_quarantined',
      sortable: false,
      viewFormat: {
        pills: {
          true: PillColorEnum.Orange,
          false: PillColorEnum.Done,
        },
        modifiers: {
          yesNo: true,
        },
      },
    },
    input: {
      objectPath: 'general.is_quarantined',
      type: FieldType.CHECKBOX,
      readonly: false,
      validators: [],
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  shotDescription: {
    label: 'common.global.short_description',
    format: ValueFormat.AS_IS,
    results2: {
      exportObjectPath: 'note',
      sortable: false,
      objectPath: 'context.note',
    },
    scope: ['cart', 'orders', 'shared-packs'],
  },
  stage: {
    label: 'common.global.stage',
    sourceListKey: SelectorSourceType.STAGE,
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'stage',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'preview-request',
    ],
  },
  publishProgress: {
    label: 'common.global.publish_progress',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'publish_progress',
      exportObjectPath: 'publish_progress',
      sortable: false,
      viewFormat: {
        modifiers: {
          asPercentage: true,
        },
      },
    },
    scope: ['shared-packs', 'orders'],
  },
  publishStatus: {
    label: 'common.global.publish_status',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'publishStatus',
      sortable: false,
      viewFormat: {
        greyLineIf: '0',
        pills: {
          'in progress': 'orange',
          completed: 'done',
          failed: 'failed',
          default: '',
        },
        pillsMatch: 'include',
      },
    },
    scope: ['shared-packs', 'orders'],
  },
  remainingDownloads: {
    label: 'common.global.remaining_downloads',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'remainingDownloadsColumn',
      sortable: false,
      viewFormat: {
        greyLineIf: '0',
      },
    },
    scope: ['shared-packs', 'orders'],
  },
  deliveredFilename: {
    label: 'common.global.delivered_filename',
    format: ValueFormat.AS_IS,
    results2: {
      objectPath: 'deliveredFilename',
      sortable: false,
    },
    scope: ['orders'],
  },
  is_embargoed: {
    label: 'common.global.embargo',
    format: ValueFormat.YES_NO,
    sourceListKey: SelectorSourceType.YES_NO,
    filters: {
      objectPath: 'is_embargoed',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  embargo: {
    label: 'common.global.embargo',
    format: ValueFormat.SELECTOR,
    sourceListKey: SelectorSourceType.EMBARGO,
    results2: {
      exportObjectPath: 'embargo',
      objectPath: 'embargo',
      sortable: false,
      viewFormat: {
        pills: {
          future: 'orange',
          'embargo infinity': 'orange',
        },
        modifiers: {
          dateFormat: 'date-time',
        },
      },
    },
    input: {
      objectPath: 'general.embargoed_to',
      type: FieldType.DATE_TIME_UNIX_TIMESTAMP_LIMITED,
      format: ValueFormat.DATE_TIME_UNIX_TIMESTAMP_LIMITED,
      readonly: false,
      validators: [],
      writePermission: {
        permissions: [Permission.EDIT_EMBARGO_DATES],
        comparator: PermissionsComparators.EVERY,
      },
      readPermission: {
        permissions: [Permission.BROWSE_ASSETS_EMBARGOED, Permission.EDIT_EMBARGO_DATES],
        comparator: PermissionsComparators.SOME,
      },
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  md5Hash: {
    label: 'common.asset.general.md5_hash',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.md5.hash',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'md5.hash',
      objectPath: 'props.md5.hash',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  legacyIngestDate: {
    label: 'common.asset.additional.legacy_ingest_date',
    format: ValueFormat.DATETIME,
    results2: {
      exportObjectPath: 'legacy_ingest_date',
      objectPath: 'props.legacy_ingest_date',
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
      sortable: true,
      sortObjectPath: 'legacy_ingest_date',
    },
    input: {
      objectPath: 'additional.legacy_ingest_date',
      type: FieldType.DATE,
      readonly: false,
      validators: [],
    },
    filters: {
      objectPath: 'legacy_ingest_date',
      type: FilterType.DATEPICKER_RANGE,
      validFormat: 'range',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'orders',
      'shared-packs',
      'cart',
    ],
  },
  md5Date: {
    label: 'common.asset.general.md5_date',
    format: ValueFormat.DATETIME,
    input: {
      objectPath: 'general.md5.date',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'md5.date',
      objectPath: 'props.md5.date',
      viewFormat: {
        modifiers: {
          dateFormat: 'date-time',
        },
      },
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  blackBordersType: {
    label: 'common.asset.file_metadata.video.screen_size.black_borders.analysis_type',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.black_borders.analysis_type',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'black_borders.analysis_type',
      objectPath: 'props.black_borders.analysis_type',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  blackBordersLeft: {
    label: 'common.asset.file_metadata.video.screen_size.black_borders.left',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.black_borders.left',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'black_borders.left',
      objectPath: 'props.black_borders.left',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  blackBordersRight: {
    label: 'common.asset.file_metadata.video.screen_size.black_borders.right',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.black_borders.right',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'black_borders.right',
      objectPath: 'props.black_borders.right',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  blackBordersTop: {
    label: 'common.asset.file_metadata.video.screen_size.black_borders.top',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.black_borders.top',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'black_borders.top',
      objectPath: 'props.black_borders.top',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  blackBordersBottom: {
    label: 'common.asset.file_metadata.video.screen_size.black_borders.bottom',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.black_borders.bottom',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'black_borders.bottom',
      objectPath: 'props.black_borders.bottom',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  interlacingType: {
    label: 'common.asset.file_metadata.video.screen_size.interlacing.analysis_type',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.analysis_type',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.analysis_type',
      objectPath: 'props.interlacing.analysis_type',
      sortable: false,
    },
    filters: {
      objectPath: 'video_interlacing_analysis_result',
      aggregationKey: 'video_interlacing_analysis_result',
      type: FilterType.CHECKBOX_LIST,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  interlacingResult: {
    label: 'common.asset.file_metadata.video.screen_size.interlacing.analysis_result',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.analysis_result',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.analysis_result',
      objectPath: 'props.interlacing.analysis_result',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  interlacingTff: {
    label: 'common.asset.file_metadata.video.screen_size.interlacing.tff',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.tff',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.tff',
      objectPath: 'props.interlacing.tff',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  interlacingBff: {
    label: 'common.asset.file_metadata.video.screen_size.interlacing.bff',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.bff',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.bff',
      objectPath: 'props.interlacing.bff',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  interlacingProgressive: {
    label: 'common.asset.file_metadata.video.screen_size.interlacing.progressive',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.progressive',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.progressive',
      objectPath: 'props.interlacing.progressive',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  interlacingUndetermined: {
    label: 'common.asset.file_metadata.video.screen_size.interlacing.undetermined',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.undetermined',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.undetermined',
      objectPath: 'props.interlacing.undetermined',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  interlacingRatio: {
    label: 'common.asset.file_metadata.video.screen_size.interlacing.ratio',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.ratio',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.ratio',
      objectPath: 'props.interlacing.ratio',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  interlacingFrameTelecined: {
    label: 'common.asset.file_metadata.video.screen_size.interlacing.is_4th_frame_telecined',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.v',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.is_4th_frame_telecined',
      objectPath: 'props.interlacing.is_4th_frame_telecined',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  interlacingTelecined: {
    label: 'common.asset.file_metadata.video.screen_size.interlacing.is_3_2_telecined',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'file_metadata.video.screen_size.interlacing.is_3_2_telecined',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    results2: {
      exportObjectPath: 'interlacing.is_3_2_telecined',
      objectPath: 'props.interlacing.is_3_2_telecined',
      sortable: false,
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  resolvedDetails: {
    label: 'common.global.resolved_details',
    format: ValueFormat.AS_IS,
    results2: {
      exportObjectPath: 'resolvedDetails',
      objectPath: 'resolvedDetails',
      sortable: false,
      viewFormat: {
        modifiers: {
          truncateCharacters: 50,
        },
      },
    },
    scope: ['upload-jobs-pending'],
  },
  isUploaded: {
    label: 'common.global.is_uploaded',
    format: ValueFormat.YES_NO,
    results2: {
      exportObjectPath: 'is_uploaded',
      objectPath: 'props.is_uploaded',
      sortable: false,
      viewFormat: {
        greyLineIf: 'false',
        pills: {
          true: 'done',
          false: 'white-dashed',
        },
        modifiers: {
          yesNo: true,
        },
      },
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  assetStatus: {
    label: 'common.global.asset_status',
    sourceListKey: SelectorSourceType.ASSET_STATUS,
    format: ValueFormat.SELECTOR,
    filters: {
      objectPath: 'is_uploaded',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  coldStatus: {
    label: 'common.global.storage_label',
    sourceListKey: SelectorSourceType.HOT_COLD,
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'storage',
      type: FilterType.SELECTOR,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'storage',
      objectPath: 'props.storage',
      sortObjectPath: 'storage',
      sortable: true,
      viewFormat: {
        pills: {
          cold: 'cold-blue',
          hot: '',
        },
      },
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
    ],
  },
  storageStatus: {
    label: 'common.global.storage_status',
    format: ValueFormat.AS_IS,
    results2: {
      exportObjectPath: 'storage_status',
      objectPath: 'storageStatus',
      sortable: true,
      sortObjectPath: 'estimated_restore_at',
      viewFormat: {
        pills: {
          completed: '',
          default: 'done-light',
        },
        modifiers: {
          dateFormat: 'date-time',
          dateCountdown: {
            condition: (item: any) =>
              item.props.storage === 'cold' &&
              item.props.storage_status === 'active' &&
              !!item.props.estimated_restore_at,
          },
        },
      },
    },
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'cart',
      'preview-request',
      'shared-packs',
      'orders',
    ],
  },
  transcodeProgress: {
    label: 'common.global.transcode_progress',
    format: ValueFormat.AS_IS,
    results2: {
      exportObjectPath: 'transcode_progress',
      sortable: false,
      objectPath: 'transcode_progress',
      viewFormat: {
        modifiers: {
          asPercentage: true,
        },
      },
    },
    scope: ['orders'],
  },
  transcodeStatus: {
    sourceListKey: SelectorSourceType.TRANSCODE_STATUS,
    label: 'common.global.transcode_status.label',
    format: ValueFormat.AS_IS,
    input: {
      type: FieldType.SELECT,
      readonly: true,
    },
    results2: {
      objectPath: 'transcodeStatus',
      exportObjectPath: 'transcode_status',
      sortObjectPath: 'transcode_status',
      sortable: false,
      viewFormat: {
        modifiers: {
          removeDashes: true,
        },
        pills: {
          not_initialized: 'white-dashed',
          submitted: 'yellow',
          'in-progress': 'yellow',
          inProgress: 'yellow',
          completed: 'done',
          'download ready': 'done',
          default: 'yellow',
          rejected: 'failed',
          failed: 'failed',
        },
      },
    },
    scope: ['shared-packs'],
  },
  clippingStatus: {
    label: 'common.global.clipping_status.label',
    format: ValueFormat.AS_IS,
    results2: {
      exportObjectPath: 'clipping_status',
      objectPath: 'clippingStatus',
      sortable: true,
      sortObjectPath: 'clipping_status',
      viewFormat: {
        pills: {
          not_initialized: 'white-dashed',
          submitted: 'yellow',
          completed: 'done',
          'in-progress': 'yellow',
          'Download Ready': 'done',
          default: 'yellow',
          failed: 'failed',
        },
      },
    },
    scope: ['orders', 'shared-packs'],
  },
  clipSalesforceName: {
    label: 'common.global.clip_salesforce_name',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'clipSalesforceName',
      type: FieldType.TEXT,
      readonly: true,
    },
    results2: {
      exportObjectPath: 'clip_sales_force_name',
      objectPath: 'clipSalesforceName',
      sortable: true,
      sortObjectPath: 'clip_sales_force_name',
    },
    scope: ['orders', 'shared-packs'],
  },
  transcriptions: {
    filters: {
      objectPath: 'transcriptions',
      type: FilterType.CHIPS,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'transcriptions',
      objectPath: 'props.transcriptions',
      sortable: false,
      viewFormat: {
        maxVisibleValues: 1,
      },
    },
    label: 'common.asset.additional.transcriptions',
    format: ValueFormat.AS_IS,
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'preview-request',
    ],
  },
  packageTitleUuid: {
    filters: {
      objectPath: 'licensed_package_uuids',
      aggregationKey: 'licensed_package_uuids',
      type: FilterType.TEXT,
      validFormat: 'keyword',
    },
    label: 'common.asset.additional.package_title',
    format: ValueFormat.AS_IS,
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  assetDeliveryPackName: {
    label: 'common.asset.additional.delivery_pack_name',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'delivery_pack_name',
      aggregationKey: 'delivery_pack_name',
      type: FilterType.TEXT_AUTOCOMPLETE,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'delivery_pack_name',
      objectPath: 'props.delivery_pack_name',
      sortable: false,
    },
    useAsFilename: true,
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  revision: {
    label: 'common.asset.general.revision',
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'revision',
      aggregationKey: 'revision',
      type: FilterType.TEXT,
      validFormat: 'keyword',
    },
    results2: {
      exportObjectPath: 'revision',
      objectPath: 'props.revision',
      sortable: false,
      viewFormat: {
        modifiers: {
          asNumberWithZero: true,
        },
      },
    },
    useAsFilename: true,
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
  revisionParentUuid: {
    label: 'common.asset.general.revision_parent_uuid',
    format: ValueFormat.AS_IS,
    input: {
      objectPath: 'general.revision_parent_uuid',
      type: FieldType.TEXT,
      readonly: true,
      validators: [],
    },
    useAsFilename: true,
    scope: [
      'default',
      'browse',
      'content-corner',
      'quarantined',
      'purged-assets',
      'collections',
      'licensed-package',
      'browse-library',
      'metadata-snapshots',
      'metadata-recognition',
      'upload-jobs',
      'upload-jobs-pending',
      'deleted',
      'shared-packs',
      'orders',
      'cart',
      'preview-request',
    ],
  },
};

/**
 * @internal should not use it directly, we lost the ability to override the fields config
 * @see FieldsFetcherService
 */
export const fieldDefinitions = Object.entries(fieldDefinitionsRecord).map(
  ([id, field]) => <FieldDefinitionModel>{ id, ...field },
);

/**
 * @internal should not use it directly, we lost the ability to override the fields config
 * @see FieldsFetcherService
 */
export const getFieldDefById = (id: string) => {
  const field = fieldDefinitions.find((item) => item.id === id);
  if (!field) {
    throw new Error(`Field with id ${id} not found`);
  }
  return field;
};
