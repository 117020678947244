import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { UILoaderModule } from '../../../ui-loader/ui-loader.module';
import { E2eIdDirective, isLoadableDataSource, LoadableDataSource } from '@vdms-hq/shared';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vdms-hq-ui-loader-ds',
  imports: [UILoaderModule, CommonModule, E2eIdDirective],
  template: `
    @if (isLoadableDataSource(dataSource); as loadableDs) {
      @if (dataSource?.isLoading$ | async) {
        <vdms-hq-ui-loader
          [mode]="'over-parent'"
          [backdrop]="true"
          [sharedE2eId]="'loader'"
          [center]="false"
        ></vdms-hq-ui-loader>
      }
    }
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderDsComponent {
  @Input() dataSource?: Partial<LoadableDataSource>;
  protected readonly isLoadableDataSource = isLoadableDataSource;
}
