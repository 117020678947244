import { inject, Injectable } from '@angular/core';
import { UIConfirmationDialogService } from '@vdms-hq/ui';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ClientPostSavePageRefresher {
  private confirmationDialog = inject(UIConfirmationDialogService);
  private document = inject(DOCUMENT);
  askToRefresh = () => {
    this.confirmationDialog
      .open({
        title: 'Refresh page',
        message: 'New configuration requires page refresh. Do you want to refresh now?',
        okAction: { label: 'Refresh', color: 'primary' },
      })
      .subscribe((value) => {
        if (value) {
          this.document.location.reload();
        }
      });
  };
}
