import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyComponent } from './component/destroy.component';
import { FormatBytesPipe, ReactiveTzDatePipe } from './pipes';

const modules = [CommonModule, TranslateModule];
const components = [DestroyComponent];

@NgModule({
  imports: [modules],
  exports: [...modules, ...components],
  providers: [DatePipe, AsyncPipe, ReactiveTzDatePipe, FormatBytesPipe],
  declarations: [...components],
})
export class SharedModule {}
