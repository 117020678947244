import { inject, Injectable, OnDestroy } from '@angular/core';
import { filter, Observable, of, startWith, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DataAction, UIConfirmationDialogService } from '@vdms-hq/ui';
import { TranslateService } from '@ngx-translate/core';
import {
  AssetSearchFilterParam,
  filterStreamMsgFactory,
  LicensedPackage,
  LicensedPackageCreate,
  LicensedPackageUpdate,
  LicensePackagesService,
  mapToProgressToastMessage,
  PaginationAPIProps,
  ProgressStreamService,
  SimpleType,
  WebsocketAnyNotificationMessage,
  WebsocketNotificationActionEnum,
} from '@vdms-hq/api-contract';
import { generateProgressToastMessage, progressToast, ToastService } from '@vdms-hq/toast';
import { catchError, map } from 'rxjs/operators';
import { CreateEditLicensePackageDialogComponent } from '../details/components/create-edit-license-package-dialog/create-edit-license-package-dialog.component';
import { LicensePackagesResultsMultiDs } from './license-packages-results-multi-ds.service';
import { LicensedPackagesAddDialogComponent } from '../details/components/licensed-packages-add-dialog/licensed-packages-add-dialog.component';
import { LicensePackagesRefreshService } from './license-packages-refresher.service';
import { FileUploadState, S3SignatureUploadService, UploadState } from '@vdms-hq/storage';
import { v4 as uuidv4 } from 'uuid';
import { ImageCacheService } from '@vdms-hq/shared';
import { AssetFlatView2Model } from '@vdms-hq/asset-results';
import { Permission } from '@vdms-hq/firebase-contract';
import { PermissionService } from '@vdms-hq/activated-client';
import { ResultDefinitionModel } from '@vdms-hq/fields';

export type LicensePackageUploadState = FileUploadState<LicensePackageUploadStatus>;

export type LicensePackageUploadStatus =
  | 'package_updating'
  | 'package_updated'
  | 'package_upload_error'
  | 'package_uploading'
  | 'package_done';

export enum LicensePackageUploadStatusEnum {
  UPLOADED = 'uploaded',
  SUCCESS = 'success',
  ERROR = 'error',
}

@Injectable({ providedIn: 'root' })
export class LicensePackagesActionsService implements OnDestroy {
  private licensePackagesService = inject(LicensePackagesService);
  private licensePackagesResultsMultiDs = inject(LicensePackagesResultsMultiDs);
  private licensePackageRefresher = inject(LicensePackagesRefreshService);
  private signatureUploadService = inject(S3SignatureUploadService);
  private matDialog = inject(MatDialog);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private toastService = inject(ToastService);
  private translate = inject(TranslateService);
  private streamService = inject(ProgressStreamService);
  private imageCache = inject(ImageCacheService);
  private permissionService = inject(PermissionService);

  private destroyed$ = new Subject<void>();

  popToast = {
    CREATE_SUCCESS: () =>
      this.toastService.success({
        id: 'create-license-package',
        message: 'common.notifications.license_package.create.success',
      }),
    CREATE_FAILURE: () =>
      this.toastService.error({
        id: 'create-license-package',
        message: 'common.notifications.license_package.create.failure',
      }),
    UPDATE_SUCCESS: () =>
      this.toastService.success({
        id: 'update-license-package',
        message: 'common.notifications.license_package.update.success',
      }),
    UPDATE_FAILURE: (msg?: string) =>
      this.toastService.error({
        id: 'update-license-package',
        message: msg ?? 'common.notifications.license_package.update.failure',
      }),
    DELETE_SUCCESS: () =>
      this.toastService.success({
        id: 'delete-license-package',
        message: 'common.notifications.license_package.delete.success',
      }),
    DELETE_FAILURE: () =>
      this.toastService.error({
        id: 'delete-license-package',
        message: 'common.notifications.license_package.delete.failure',
      }),
    GET_ONE_SUCCESS: () =>
      this.toastService.success({
        id: 'get-one-license-package',
        message: 'common.notifications.license_package.get_one.success',
      }),
    GET_ONE_FAILURE: () =>
      this.toastService.error({
        id: 'get-one-license-package',
        message: 'common.notifications.license_package.get_one.failure',
      }),
    GET_ITEMS_FAILURE: () =>
      this.toastService.error({
        id: 'get-items-license-package',
        message: 'common.notifications.license_package.get_items.failure',
      }),
    ADD_ITEMS_SUCCESS: () =>
      this.toastService.success({
        id: 'add-items-license-package',
        message: 'common.notifications.license_package.add_items.success',
      }),
    ADD_SINGLE_ITEM_SUCCESS: () =>
      this.toastService.success({
        id: 'add-items-license-package',
        message: 'common.notifications.license_package.add_single_item.success',
      }),
    ADD_ITEMS_FAILURE: (msg?: string) =>
      this.toastService.error({
        id: 'add-items-license-package',
        message: msg ?? 'common.notifications.license_package.add_items.failure',
      }),
    ADD_COVER_SUCCESS: () =>
      this.toastService.success({
        id: 'upload-cover-license-package',
        message: 'common.notifications.license_package.upload_cover.success',
      }),
    ADD_COVER_FAILURE: (msg?: string) =>
      this.toastService.error({
        id: 'upload-cover-license-package',
        message: msg ?? 'common.notifications.license_package.upload_cover.failure',
      }),
    WS_DELETE_ITEMS_PROGRESS: (
      id: string,
      percent: number,
      counter?: { processing: number; all: number; errors: WebsocketAnyNotificationMessage },
    ) =>
      this.toastService.processing({
        id,
        message: generateProgressToastMessage(
          'Licensed package updating',
          percent,
          this.imageCache.getImage('assets/common/asset_fallbacks/info.svg'),
          counter,
        ),
      }),
    WS_DELETE_ITEMS_SUCCESS: (id: string) =>
      this.toastService.success({
        id,
        message: 'common.notifications.license_package.delete_items.success',
      }),
    WS_DELETE_ITEMS_FAILURE: (id: string) =>
      this.toastService.error({
        id,
        message: 'common.notifications.license_package.delete_items.failure',
      }),
    EXPORT_SUCCESS: () =>
      this.toastService.success({
        id: 'license_package_export_success',
        message: 'common.notifications.license_package.export.success',
      }),
  };

  readonly connect$ = this.streamService.connect();

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  createDialog = () => {
    this.matDialog.open(CreateEditLicensePackageDialogComponent, { data: { uuid: undefined } });
  };

  editDialog = (uuid: string) => {
    this.matDialog.open(CreateEditLicensePackageDialogComponent, { data: { uuid } });
  };

  deleteDialog = (uuid: string, packageName: string) => {
    this.confirmationDialog
      .openDelete({
        title: 'common.dialogs.license_package.delete.title',
        message: this.translate.instant('common.dialogs.license_package.delete.message', { packageName }),
      })
      .pipe(
        takeUntil(this.destroyed$),
        take(1),
        filter(Boolean),
        tap(() => this.licensePackagesResultsMultiDs.isLoading$.next(true)),
        switchMap(() =>
          this.licensePackagesService.remove(uuid).pipe(
            tap(() => {
              this.licensePackagesResultsMultiDs.isLoading$.next(false);
              this.popToast.DELETE_SUCCESS();
              this.resultsRefresh();
            }),
            catchError((error) => {
              this.popToast.DELETE_FAILURE();
              throw error;
            }),
          ),
        ),
      )
      .subscribe();
  };

  createNew(packageData: Omit<LicensedPackageCreate, 'uuid'>): Observable<LicensedPackage> {
    return this.licensePackagesService.create({
      name: packageData.name,
      description: packageData.description,
    });
  }

  update(uuid: string, packageData: LicensedPackageUpdate): Observable<LicensedPackage> {
    return this.licensePackagesService.patch(uuid, {
      name: packageData.name,
      description: packageData.description,
      custom_cover: packageData.custom_cover,
      custom_cover_filename: packageData.custom_cover_filename,
      status: packageData.status,
      view_state: packageData.view_state,
      contract_uuids: packageData.contract_uuids,
      options: {
        custom_cover_position: packageData.options.custom_cover_position,
      },
    });
  }

  resultsRefresh = () => {
    this.licensePackagesResultsMultiDs.refresh$.next(true);
    this.licensePackageRefresher.refreshLicensePackagesList$.next(true);
  };

  addFiltersToPackage(filters: AssetSearchFilterParam) {
    this.matDialog.open(LicensedPackagesAddDialogComponent, { data: { filters: filters } });
  }

  addAssetsToPackage(assetIds: string[], packages: SimpleType[] = []) {
    this.matDialog.open(LicensedPackagesAddDialogComponent, { data: { selectedIds: assetIds, packages } });
  }

  deleteAssetsFromPackage(assetIds: string[], packageUuid: string, refreshSingle = false, assetName?: string) {
    this.confirmationDialog
      .open({
        title:
          assetIds.length === 1
            ? 'common.dialogs.license_package.delete_assets.title'
            : 'common.dialogs.license_package.delete_assets.title_multiple',
        message:
          assetIds.length === 1 && assetName
            ? this.translate.instant('common.dialogs.license_package.delete_assets.message', {
                selectedAssetName: assetName,
              })
            : this.translate.instant('common.dialogs.license_package.delete_assets.message_multiple', {
                count: assetIds.length,
              }),
        okAction: {
          label: 'common.dialogs.license_package.delete_assets.button_confirm',
          color: 'warn',
        },
        abortAction: {
          label: 'common.dialogs.license_package.delete_assets.button_cancel',
          color: 'secondary',
        },
      })
      .pipe(
        takeUntil(this.destroyed$),
        take(1),
        filter(Boolean),
        tap(() => this.licensePackagesResultsMultiDs.isLoading$.next(true)),
        switchMap(() => this.licensePackagesService.removeItems(packageUuid, assetIds)),
      )
      .subscribe();
  }

  registerWebSocketListener() {
    return this.connect$.pipe(
      filterStreamMsgFactory([WebsocketNotificationActionEnum.LICENSED_PACKAGE_REMOVE]),
      mapToProgressToastMessage(),
      progressToast({
        SUCCESS: (groupId: string) => {
          this.popToast.WS_DELETE_ITEMS_SUCCESS(groupId);
          this.licensePackageRefresher.refreshSingleLicensePackage$.next(true);
          this.licensePackageRefresher.refreshLicensePackagesList$.next(true);
        },
        PROGRESS: this.popToast.WS_DELETE_ITEMS_PROGRESS,
        ERROR: (groupId: string) => {
          this.popToast.WS_DELETE_ITEMS_FAILURE(groupId);
        },
      }),
    );
  }

  updateLicensePackageAndUpload(
    file: File,
    uuid: string,
    packageData: LicensedPackageUpdate,
  ): Observable<LicensePackageUploadState> {
    const initState: LicensePackageUploadState = {
      status: 'package_updating',
      state: UploadState.IN_PROGRESS,
    };

    const updatePackage$ = this.licensePackagesService
      .updateAndGetSignature(uuid, {
        ...packageData,
        custom_cover: true,
        custom_cover_filename: uuidv4() + '.' + file.name.substring(file.name.lastIndexOf('.') + 1),
      })
      .pipe(
        map(
          (item) =>
            ({
              state: UploadState.IN_PROGRESS,
              status: 'package_updated',
              signature: item,
            }) as LicensePackageUploadState,
        ),
        catchError((error) =>
          of({
            state: UploadState.ERROR,
            status: 'package_upload_error',
            error,
          } as LicensePackageUploadState),
        ),
      );

    return updatePackage$.pipe(
      startWith(initState),
      switchMap((state) => {
        if (state.status === 'package_updated' && state.signature) {
          return this.signatureUploadService.uploadSmallFileWithSignature(file, state.signature).pipe(
            map(
              (uploadProcess) =>
                ({
                  ...state,
                  status: uploadProcess.uploaded ? 'package_done' : 'package_uploading',
                  state: uploadProcess.uploaded ? UploadState.COMPLETED : UploadState.IN_PROGRESS,
                  file: uploadProcess,
                }) as LicensePackageUploadState,
            ),
            catchError((error) => {
              this.popToast.ADD_COVER_FAILURE();
              return of({
                ...state,
                status: 'package_upload_error',
                state: UploadState.ERROR,
                error,
              } as LicensePackageUploadState);
            }),
          );
        }
        return of(state);
      }),
    );
  }

  buildAddAssetAction$<T extends AssetFlatView2Model = AssetFlatView2Model>(): Observable<DataAction<T> | null> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_LICENSED_PACKAGES]).pipe(
      take(1),
      map((hasAccess) =>
        hasAccess
          ? {
              key: 'add_to_licensed_package',
              label: 'common.lists.add_to_licensed_package',
              icon: 'folder_special',
              onDoubleClick: false,
            }
          : null,
      ),
    );
  }

  exportLicensePackage(uuid: string, fields?: ResultDefinitionModel[], pagination?: PaginationAPIProps) {
    return this.licensePackagesService.export(uuid, fields, pagination).pipe(
      takeUntil(this.destroyed$),
      tap({
        complete: () => {
          this.popToast.EXPORT_SUCCESS();
        },
      }),
    );
  }
}
