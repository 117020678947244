import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsDataService } from '../../logic/settings-data.service';
import {
  DataPresentationHeaderComponent,
  SidebarItem,
  SidebarNestedItem,
  UIButtonModule,
  UILayoutModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { SettingsFormService } from '../../logic/settings-form.service';
import { AuthService } from '@vdms-hq/auth';
import { TranslateModule } from '@ngx-translate/core';
import { RouterOutlet } from '@angular/router';
import { combineLatest } from 'rxjs';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import { take } from 'rxjs/operators';
import { NotificationSettingsFormService } from '@vdms-hq/notifications';

@Component({
  selector: 'vdms-hq-user-settings-wrapper',
  standalone: true,
  imports: [
    CommonModule,
    DataPresentationHeaderComponent,
    TranslateModule,
    RouterOutlet,
    UILayoutModule,
    UILoaderModule,
    UIButtonModule,
  ],
  templateUrl: './user-settings-wrapper.component.html',
  styleUrls: ['./user-settings-wrapper.component.scss'],
  providers: [SettingsDataService, SettingsFormService],
})
export class UserSettingsWrapperComponent implements OnInit, OnDestroy {
  public authService = inject(AuthService);
  settingsDataService = inject(SettingsDataService);
  public settingsFormService = inject(SettingsFormService);
  public notificationSettingsFormService = inject(NotificationSettingsFormService);
  private permissionsService = inject(PermissionService);
  isFetching$ = this.settingsDataService.isFetching$;
  navItems: (SidebarItem | SidebarNestedItem)[] = [];

  ngOnInit(): void {
    this.settingsDataService.fetch();
    this.#setNavItems();
  }

  ngOnDestroy() {
    this.settingsDataService.destroy();
  }

  #setNavItems(): void {
    combineLatest([
      this.permissionsService.verifyWithOwnedPermissions$([Permission.MANAGE_NOTIFICATION_SUBSCRIPTIONS]),
      this.permissionsService.verifyWithOwnedPermissions$([Permission.BROWSE_NOTIFICATIONS]),
    ])
      .pipe(take(1))
      .subscribe(([canBrowseSubscriptions, canBrowseNotifications]) => {
        const items = [
          {
            name: 'Profile settings',
            routerLink: '/account-settings/profile',
          },
          {
            name: 'VIDA system settings',
            routerLink: '/account-settings/system',
          },
        ];

        canBrowseNotifications &&
          items.push({
            name: 'Notification center',
            routerLink: '/account-settings/notification-center',
          });

        canBrowseSubscriptions &&
          items.push({
            name: 'Notification settings',
            routerLink: '/account-settings/notification-settings',
          });

        this.navItems = items;
      });
  }

  handleHeaderAction($event: { key: string }): boolean {
    switch ($event.key) {
      case 'user-settings.save':
        this.save();
        return true;
      default:
        return false;
    }
  }

  save(): void {
    this.notificationSettingsFormService.save();
    this.settingsDataService.save();
  }
}
