import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatsGridViewModel } from '../logic/models/stats-grid.model';

@Component({
  selector: 'vdms-hq-ui-stats-grid',
  templateUrl: './stats-grid.component.html',
  styleUrls: ['./stats-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsGridComponent {
  @Input()
  statsStaticVM!: StatsGridViewModel;
}
