import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControlValueAccessorComponent } from '@vdms-hq/ui';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputDefinitionModel } from '@vdms-hq/fields';

export type DynamicInputConfig = InputDefinitionModel & { cols?: number };

@Component({
  selector: 'vdms-hq-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DynamicInputComponent),
    },
  ],
})
export class DynamicInputComponent extends FormControlValueAccessorComponent {
  innerFormControl = new UntypedFormControl(null);
  @ViewChild('element') element?: ElementRef;
  @Input() config?: DynamicInputConfig;
}
