import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { isEmptyInputValue } from '@vdms-hq/shared';

export class DeliveryDestinationsValidators {
  static hybrikProfileJsonValidator(form: FormGroup, controlName: string): () => ValidationErrors | null {
    return (): ValidationErrors | null => {
      const burnInText = form.controls[controlName].get('burnInText')?.value;
      const hybrikProfileValue = form.controls[controlName].get('overrideProfile')?.value;

      if (isEmptyInputValue(hybrikProfileValue) || hybrikProfileValue == '' || hybrikProfileValue === null) {
        return null;
      } else {
        if (burnInText && !JSON.stringify(hybrikProfileValue).includes('{{burnin_text}}')) {
          return { hybrikProfileJsonNoBurnInSubstring: true };
        }
        try {
          JSON.parse(hybrikProfileValue);
          return null;
        } catch (e) {
          return { hybrikProfileIsNotJson: true };
        }
      }
    };
  }

  static burnInTextValidator(): (burnInText: AbstractControl) => ValidationErrors | null {
    const ALLOWED_BURNIN_CHARS = new RegExp('^[a-zA-Z0-9!\\-_.@\\/]+$');

    return (burnInText: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(burnInText.value) || burnInText.value == '') return null;
      if (!ALLOWED_BURNIN_CHARS.test(burnInText.value)) {
        return { burnInTextLimited: true };
      }
      return null;
    };
  }
}
