import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionIdentifier,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  ResultsWrapperViewConfiguration,
  UIConfirmationDialogService,
  UIDialogModule,
  UILayoutModule,
  UILoaderModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { VideoCodecsDatasourceService } from '../../logic/video-codecs-datasource.service';
import { VideoCodec, VideoCodecsApiService } from '@vdms-hq/api-contract';
import { MatDialog } from '@angular/material/dialog';
import { VideoCodecsEditDialogComponent } from '../../components/video-codecs-edit-dialog/video-codecs-edit-dialog.component';
import { filter, switchMap } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { VideoCodecsImportCsvDialogComponent } from '../../components/video-codecs-import-csv-dialog/video-codecs-import-csv-dialog.component';

@Component({
  selector: 'vdms-hq-video-codecs',
  templateUrl: './video-codecs.component.html',
  standalone: true,
  imports: [
    CommonModule,
    UILayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    UILoaderModule,
    UIResultsWrapperModule,
    UIDialogModule,
    MultipleDataPresentationComponent,
  ],
  providers: [VideoCodecsDatasourceService, VideoCodecsApiService],
})
export class VideoCodecsComponent implements OnInit {
  viewConfig: MultipleViewConfiguration<VideoCodec> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: true,
    },
    tableAdvanced: {
      actions: [
        {
          key: 'edit',
          icon: 'edit',
          onDoubleClick: false,
          label: 'common.global.edit',
        },
        {
          key: 'delete',
          icon: 'delete',
          onDoubleClick: false,
          label: 'common.global.delete',
        },
      ],
      columns: [
        {
          id: 'uuid',
          label: 'UUID',
          valuePath: 'uuid',
        },
        {
          id: 'video_codec',
          label: 'Video Codec',
          valuePath: 'video_codec',
        },
        {
          id: 'profile',
          label: 'Profile',
          valuePath: 'profile',
        },
        {
          id: 'display_name',
          label: 'Display Name',
          valuePath: 'display_name',
        },
        {
          id: 'actions',
          type: 'actions',
        },
      ],
      columnsEnabled: ['uuid', 'video_codec', 'profile', 'display_name', 'actions'],
    },
  };

  headerConfig: ResultsWrapperViewConfiguration = {
    headerActions: [
      {
        key: 'new',
        name: 'common.video_codecs.new_codec',
        color: 'primary',
      },
      {
        key: 'export_csv',
        name: 'common.video_codecs.export_csv',
        color: 'secondary',
        icon: 'file_download',
        iconOnly: true,
      },
      {
        key: 'import_csv',
        name: 'common.video_codecs.import_csv',
        color: 'secondary',
        icon: 'file_upload',
        iconOnly: true,
      },
    ],
    withLoading: false,
  };
  constructor(
    public dataSource: VideoCodecsDatasourceService,
    private dialog: MatDialog,
    private confirmationDialog: UIConfirmationDialogService,
    private api: VideoCodecsApiService,
    private toast: ToastService,
  ) {}

  ngOnInit() {
    this.#refresh();
  }

  handleAction($event: { key: ActionIdentifier; item?: VideoCodec }) {
    const { key, item } = $event;

    switch (key) {
      case 'edit':
        if (item) this.#edit(item);
        break;

      case 'delete':
        if (item) this.#delete(item);
        break;

      case 'new':
        this.#new();
        break;

      case 'export_csv':
        this.#exportCSV();
        break;

      case 'import_csv':
        this.#importCSV();
        break;
    }
  }

  #new() {
    this.dialog
      .open(VideoCodecsEditDialogComponent)
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.toast.success({ id: 'success', message: 'common.notifications.update.done' });
        this.#refresh();
      });
  }

  #edit(codec: VideoCodec) {
    const { uuid } = codec;
    this.dialog
      .open(VideoCodecsEditDialogComponent, {
        data: {
          uuid,
        },
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.toast.success({ id: 'success', message: 'common.notifications.update.done' });
        this.#refresh();
      });
  }

  #delete(codec: VideoCodec) {
    const { uuid } = codec;
    this.confirmationDialog
      .openDelete()
      .pipe(
        filter(Boolean),
        switchMap(() => this.api.delete(uuid)),
      )
      .subscribe(() => {
        this.toast.success({ id: 'deleted', message: 'common.notifications.remove.done' });
        this.#refresh();
      });
  }

  #exportCSV() {
    this.dataSource.isLoading$.next(true);
    this.api.exportCSV().subscribe({
      next: () => {
        this.toast.success({
          id: 'export',
          message: 'notifications.export.video_codecs_done',
        });
        this.dataSource.isLoading$.next(false);
      },
      error: () => {
        this.toast.error({
          id: 'export',
          message: 'notifications.export.failed',
        });
        this.dataSource.isLoading$.next(false);
      },
    });
  }

  #importCSV() {
    this.dialog
      .open(VideoCodecsImportCsvDialogComponent)
      .afterClosed()
      .subscribe((response: boolean) =>
        this.toast[response ? 'success' : 'error']({
          id: 'csv_import',
          message: response ? 'common.video_codecs.import_csv_success' : 'common.video_codecs.import_csv_failure',
        }),
      );
  }

  #refresh() {
    this.dataSource.refresh();
  }
}
