<vdms-hq-ui-dialog-wrapper>
  <h1 title>
    {{ title | translate }}
  </h1>
  <form [formGroup]="form" content>
    <vdms-hq-ui-form-section [layout]="'single'" [divider]="false">
      <vdms-hq-ui-form-input-text
        formControlName="name"
        [withFooter]="true"
        [label]="'common.transcode_base.types.form.name' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-select
        formControlName="container_uuids"
        [withFooter]="true"
        [multiple]="true"
        [selectOptions]="containersSelectOptions$ | async"
        [loading]="!((containersSelectOptions$ | async)?.length > 0)"
        [label]="'common.transcode_base.types.form.containers' | translate"
      ></vdms-hq-ui-form-input-select>
      <vdms-hq-ui-form-input-select
        formControlName="codec_mapping_uuids"
        [withFooter]="true"
        [multiple]="true"
        [selectOptions]="videoCodecsSelectOptions$ | async"
        [loading]="!((videoCodecsSelectOptions$ | async)?.length > 0)"
        [label]="'common.transcode_base.types.form.video_codecs' | translate"
      ></vdms-hq-ui-form-input-select>
    </vdms-hq-ui-form-section>
  </form>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="closeDialog()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      *ngIf="editMode$ | async as editMode"
      color="primary"
      [loading]="(isLoading$ | async) ?? false"
      (click)="editMode === 'edit' ? update() : create()"
      [disabled]="form.invalid || form.pristine"
    >
      {{ saveButton | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
