import {
  PlayerAction,
  CanvasDrawing,
  CanvasShape,
  PlayerConfiguration,
  PlayerInterface,
  Subtitles,
} from './player.interface';
import { inject, Injectable } from '@angular/core';
import { mergeMap, Observable, ReplaySubject, tap, combineLatest } from 'rxjs';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { AdvancedPlayerService } from './advanced-player.service';
import { SimplePlayerService } from './simple-player.service';
import { HelpDialogComponent } from '../components/help-dialog/help-dialog.component';
import { distinctUntilChanged, map, take, withLatestFrom } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filterEmpty } from '@vdms-hq/shared';
import { AudioPlayerService } from './audio-player.service';

@Injectable({
  providedIn: 'root',
})
/**
 * @external
 */
export class PlayerService {
  private readonly CANVAS_ELEMENT_ID = 'player_canvas';

  private advanced: AdvancedPlayerService = inject(AdvancedPlayerService);
  private simple: SimplePlayerService = inject(SimplePlayerService);
  private audio: AudioPlayerService = inject(AudioPlayerService);
  private dialog: MatDialog = inject(MatDialog);

  #activePlayer: PlayerInterface | null = null;

  #typeSubject = new ReplaySubject<'simple' | 'advanced' | 'audio'>(1);
  type$ = this.#typeSubject.asObservable().pipe(distinctUntilChanged());
  #helpDialog?: MatDialogRef<HelpDialogComponent, unknown> | null;
  #drawingsExistForTimecode: Timecode | null = null;

  get isConfigured() {
    return !!this.#activePlayer;
  }

  get activePlayer() {
    if (!this.#activePlayer) {
      throw new Error('Runtime error. Please player service first');
    }
    return this.#activePlayer;
  }

  get pauseListeners() {
    return this.#activePlayer?.pauseListeners$;
  }

  duration$: Observable<Timecode | null> = this.type$.pipe(
    mergeMap((type) => {
      switch (type) {
        case 'simple':
          return this.simple.duration$;
        case 'advanced':
          return this.advanced.duration$;
        case 'audio':
          return this.audio.duration$;
      }
    }),
  );

  offset$: Observable<Timecode | null> = this.type$.pipe(
    mergeMap((type) => {
      switch (type) {
        case 'simple':
          return this.simple.offset$;
        case 'advanced':
          return this.advanced.offset$;
        case 'audio':
          return this.audio.offset$;
      }
    }),
  );

  currentTimecode$: Observable<Timecode | null> = this.type$.pipe(
    mergeMap((type) => {
      switch (type) {
        case 'simple':
          return this.simple.currentTimecode$;
        case 'advanced':
          return this.advanced.currentTimecode$;
        case 'audio':
          return this.audio.currentTimecode$;
      }
    }),
    tap((currentTimecode) => {
      if (!currentTimecode || !this.#drawingsExistForTimecode) {
        return;
      }
      if (!currentTimecode.equals(this.#drawingsExistForTimecode)) {
        this.#clearDrawings();
      }
    }),
  );
  currentTimecodeWithOffset$: Observable<Timecode | null> = this.currentTimecode$.pipe(
    withLatestFrom(this.offset$),
    map(([timecode, offset]) => (offset ? timecode?.add(offset) : timecode) ?? null),
  );

  loading$ = this.type$.pipe(
    mergeMap((type) => {
      switch (type) {
        case 'simple':
          return this.simple.loading$;
        case 'advanced':
          return this.advanced.loading$;
        case 'audio':
          return this.audio.loading$;
      }
    }),
  );

  state$ = this.type$.pipe(
    mergeMap((type) => {
      switch (type) {
        case 'simple':
          return this.simple.state$;
        case 'advanced':
          return this.advanced.state$;
        case 'audio':
          return this.audio.state$;
      }
    }),
  );

  framerate$ = this.type$.pipe(
    mergeMap((type) => {
      switch (type) {
        case 'simple':
          return this.simple.framerate$;
        case 'advanced':
          return this.advanced.framerate$;
        case 'audio':
          return this.audio.framerate$;
      }
    }),
  );

  boundaries$ = combineLatest([this.duration$.pipe(filterEmpty()), this.offset$, this.framerate$]).pipe(
    map(([durationTimecode$, offset, framerate]) => [
      offset ? offset : Timecode.fromSeconds(0, framerate ?? Framerate.default()),
      offset ? durationTimecode$.add(offset) : durationTimecode$,
    ]),
  );

  drawCanvas = (drawing: CanvasDrawing): void => {
    this.goToTimecode(drawing.timecode);
    this.#drawingsExistForTimecode = drawing.timecode;
    this.#draw(drawing.shapes);
  };

  goToTimecode(timecode: Timecode, withOffset = false): void {
    this.activePlayer.goToTimecode(timecode, withOffset);
  }

  unConfigure() {
    this.#activePlayer = null;
  }

  configure(config: PlayerConfiguration): void {
    // this.#type = config.playerType;

    this.#clearDrawings();
    switch (config.playerType) {
      case 'simple':
        this.#activePlayer = this.simple;
        this.#activePlayer.configure(config);
        break;
      case 'advanced':
        this.#activePlayer = this.advanced;
        this.#activePlayer.configure(config);
        break;
      case 'audio':
        this.#activePlayer = this.audio;
        this.#activePlayer.configure(config);
        break;
    }

    this.#typeSubject.next(config.playerType);
  }

  handleAction(action: PlayerAction, value?: number | string): void {
    this.activePlayer.handleAction(action, value);
  }

  /**
   * todo 5014 its not related to player, so it should be in separate service
   */
  toggleHelp() {
    if (this.#helpDialog) {
      this.#helpDialog.close();
      return;
    }
    this.#helpDialog = this.dialog.open(HelpDialogComponent);
    this.#helpDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.#helpDialog = null;
      });
  }

  hideAllDialogs() {
    this.#helpDialog?.close();
    this.#helpDialog = null;
  }

  changeOffset(offset: Timecode) {
    this.activePlayer.updateOffset(offset);
    this.#updateOffset(offset);
  }

  get canvasRef(): HTMLCanvasElement | null {
    return (document.getElementById(this.CANVAS_ELEMENT_ID) as HTMLCanvasElement) ?? null;
  }

  attachSubtitles(subtitles: Subtitles[]) {
    this.activePlayer.loadSubtitles(subtitles);
  }

  deactivate() {
    this.handleAction('pause');
    this.#activePlayer?.pauseListeners$.next(true);
  }

  activate() {
    this.#activePlayer?.pauseListeners$.next(false);
  }

  #clearDrawings() {
    if (!this.#drawingsExistForTimecode) {
      return;
    }

    const canvas = this.canvasRef;
    const canvasCtx = canvas?.getContext('2d');

    if (!canvasCtx || !canvas) {
      return;
    }

    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
    this.#drawingsExistForTimecode = null;
  }

  #draw(shape: CanvasShape[]) {
    const canvas = this.canvasRef;
    const canvasCtx = canvas?.getContext('2d');

    if (!canvasCtx || !canvas) {
      return;
    }

    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
    shape.forEach((rec) => {
      canvasCtx.beginPath();
      canvasCtx.strokeStyle = '#ee922e';
      canvasCtx.lineWidth = 2;
      canvasCtx.rect(
        rec.left * canvas.width,
        rec.top * canvas.height,
        rec.width * canvas.width,
        rec.height * canvas.height,
      );
      canvasCtx.stroke();
    });
  }

  #updateOffset(offset: Timecode) {
    this.type$.pipe(take(1)).subscribe((type) => {
      switch (type) {
        case 'simple':
          this.simple.updateOffset(offset);
          break;

        case 'advanced':
          this.advanced.updateOffset(offset);
          break;

        case 'audio':
          this.audio.updateOffset(offset);
          break;
      }
    });
  }

  #renderTimecodeByType(
    timecode: Timecode | null | undefined,
    type: 'simple' | 'advanced' | 'audio',
    framerate: Framerate,
  ) {
    if (!timecode) {
      return null;
    }

    if (type === 'audio') {
      return Timecode.fromTimecode(timecode.toString().substring(0, 8), framerate);
    }

    return timecode;
  }
}
