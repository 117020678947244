import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthAdobeService } from '@vdms-hq/auth-adobe';
import { UIButtonModule, UIEmptyResultsModule, UILoaderModule } from '@vdms-hq/ui';
import { switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, tap } from 'rxjs';

@Component({
  selector: 'vdms-hq-paired-devices',
  templateUrl: './paired-devices.component.html',
  styleUrls: ['./paired-devices.component.scss'],
  standalone: true,
  imports: [CommonModule, UIButtonModule, UILoaderModule, UIEmptyResultsModule],
})
export class PairedDevicesComponent {
  private authAdobeService: AuthAdobeService = inject(AuthAdobeService);

  refresh$ = new BehaviorSubject<Date>(new Date());
  loading$ = new BehaviorSubject<boolean>(true);

  pairedDevices$ = combineLatest([this.refresh$]).pipe(
    switchMap(() => this.authAdobeService.getPairDevices()),
    tap(() => this.loading$.next(false)),
  );

  unPairDevice(uuid: string) {
    this.loading$.next(true);
    this.authAdobeService
      .unpairDevice(uuid)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.refresh$.next(new Date());
          this.loading$.next(false);
        },
      });
  }
}
