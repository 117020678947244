import { inject, Pipe, PipeTransform } from '@angular/core';
import { Timecode } from '@vdms-hq/timecode';
import { filter } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';

import { PlayerService } from '../logic/player.service';

@Pipe({
  name: 'offsetAdd',
  pure: true,
  standalone: true,
})
export class OffsetAddPipe implements PipeTransform {
  private previewService: PlayerService = inject(PlayerService);

  transform(value: Timecode | string) {
    return this.previewService.offset$.pipe(
      filter((offset) => !!offset),
      take(1),
      withLatestFrom(this.previewService.type$),
      map(([offset, type]) => {
        const displayFrames = !(type === 'audio');
        const timecode = Timecode.fromTimecode(value?.toString(), offset?.getFps(), displayFrames);
        const offsetTimecode = Timecode.fromTimecode(offset?.toString(), offset?.getFps(), displayFrames);

        if (!timecode) {
          return;
        }

        if (!offsetTimecode) {
          return timecode;
        }

        return timecode.add(offsetTimecode);
      }),
    );
  }
}
