import { FieldsFetcherService, FieldsScopeKey } from '@vdms-hq/fields';

import { SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { resultsAdminConfig, ResultsAdminConfig } from '../../../config';

export type FormSettings = {
  canRemoveFromAvailable: boolean;
  canRevertToDefault: boolean;
  isInheritedFromDefault: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class AdminFieldsConfigService implements Resolve<FieldsScopeKey | null> {
  #fieldsFetcher = inject(FieldsFetcherService);
  #activatedClient = inject(ActivatedClientService);

  #currentScopeKey = new BehaviorSubject<ResultsAdminConfig | null>(null);

  currentScopeKey$ = this.#currentScopeKey
    .asObservable()
    .pipe(filter((key) => key !== null)) as Observable<ResultsAdminConfig>;

  configForTable$: Observable<{
    defaultList: SelectOptionKey[];
    availableList: SelectOptionKey[];
    outsideList: SelectOptionKey[];
    all: SelectOption[];
    settings: FormSettings;
  }> = this.#currentScopeKey.pipe(
    filter(Boolean),
    switchMap((scopeSettings) =>
      this.#fieldsFetcher.getConfiguration$(scopeSettings.key, true).pipe(
        map((data) => {
          const systemList = data.table.getSystemKeys();
          const defaultList = data.table.getClientEnabledKeys();
          const availableList = this.#excludeFromList(data.table.getClientAvailableKeys(), defaultList);
          const outsideList = this.#excludeFromList(systemList, [...defaultList, ...availableList]);

          return {
            defaultList: defaultList,
            availableList: availableList,
            outsideList: outsideList,
            all: data.table.getSystemAsSelectOptions(),
            settings: {
              canRemoveFromAvailable: true,
              canRevertToDefault: !data.table.client.isInherited,
              isInheritedFromDefault: scopeSettings.key !== 'default' && data.table.client.isInherited,
            },
          };
        }),
      ),
    ),
  );

  configForList$: Observable<{
    defaultList: SelectOptionKey[];
    availableList: SelectOptionKey[];
    outsideList: SelectOptionKey[];
    all: SelectOption[];
    settings: FormSettings;
  }> = this.currentScopeKey$.pipe(
    switchMap((scopeSettings) =>
      this.#fieldsFetcher.getConfiguration$(scopeSettings.key, true).pipe(
        map((data) => {
          const systemList = data.list.getSystemKeys();
          const defaultList = data.list.getClientEnabledKeys();
          const availableList = this.#excludeFromList(systemList, defaultList);
          const outsideList = this.#excludeFromList(systemList, [...defaultList, ...availableList]);

          return {
            defaultList,
            availableList: availableList,
            outsideList: outsideList,
            all: data.list.getSystemAsSelectOptions(),
            settings: {
              canRemoveFromAvailable: false,
              canRevertToDefault: !data.list.client.isInherited,
              isInheritedFromDefault: scopeSettings.key !== 'default' && data.list.client.isInherited,
            },
          };
        }),
      ),
    ),
  );

  configForFilters$: Observable<{
    defaultList: SelectOptionKey[];
    availableList: SelectOptionKey[];
    outsideList: SelectOptionKey[];
    all: SelectOption[];
    settings: FormSettings;
  }> = this.#currentScopeKey.pipe(
    filter(Boolean),
    switchMap((scopeSettings) =>
      this.#fieldsFetcher.getConfiguration$(scopeSettings.key, true).pipe(
        map((data) => {
          const systemList = data.filters.getSystemKeys();
          const defaultList = data.filters.getEnabledKeys();
          const availableList = this.#excludeFromList(systemList, defaultList);
          const outsideList = this.#excludeFromList(systemList, [...defaultList, ...availableList]);

          return {
            defaultList,
            availableList: availableList,
            outsideList: outsideList,
            all: data.filters.getSystemAsSelectOptions(),
            settings: {
              canRemoveFromAvailable: false,
              canRevertToDefault: !data.filters.client.isInherited,
              isInheritedFromDefault: scopeSettings.key !== 'browse' && data.filters.client.isInherited,
            },
          };
        }),
      ),
    ),
  );

  saveTable$ = (param: { availableList: SelectOptionKey[]; defaultList: SelectOptionKey[] }) =>
    this.currentScopeKey$.pipe(
      take(1),
      switchMap((scopeSettings) =>
        this.#activatedClient.update({
          columns: {
            [scopeSettings.key]: {
              default: param.defaultList as string[],
              available: [...param.defaultList, ...param.availableList] as string[],
            },
          },
        }),
      ),
    );

  saveList$ = (param: { availableList: SelectOptionKey[]; defaultList: SelectOptionKey[] }) =>
    this.currentScopeKey$.pipe(
      take(1),
      switchMap((scopeSettings) =>
        this.#activatedClient.update({
          vida: {
            grid: {
              metadataV2: {
                [scopeSettings.key]: [...param.defaultList] as string[],
              },
            },
          },
        }),
      ),
    );

  saveFilters$ = (param: { availableList: SelectOptionKey[]; defaultList: SelectOptionKey[] }) =>
    this.currentScopeKey$.pipe(
      take(1),
      switchMap((scopeSettings) =>
        this.#activatedClient.update({
          filters: {
            [scopeSettings.key]: [...param.defaultList] as string[],
          },
        }),
      ),
    );

  resolve(route: ActivatedRouteSnapshot) {
    const configKey = route.params.configKey as FieldsScopeKey;
    const config = resultsAdminConfig.find((item) => item.key === configKey);

    if (!config) {
      return null;
    }
    this.#currentScopeKey.next(config);

    return config.key;
  }

  revertToDefaultList$() {
    return this.currentScopeKey$.pipe(
      take(1),
      switchMap((configKey) => this.#activatedClient.removeUsingPath(`vida.grid.metadataV2.${configKey.key}`)),
    );
  }

  revertToDefaultTable$() {
    return this.currentScopeKey$.pipe(
      take(1),
      switchMap((configKey) => this.#activatedClient.removeUsingPath(`columns.${configKey.key}`)),
    );
  }

  revertToDefaultFilters$() {
    return this.currentScopeKey$.pipe(
      take(1),
      switchMap((configKey) => this.#activatedClient.removeUsingPath(`filters.${configKey.key}`)),
    );
  }

  #excludeFromList(list: string[], exclusions: string[]) {
    return list.filter((item) => !exclusions.includes(item));
  }
}
