import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedClientService, WithPermissions } from '@vdms-hq/activated-client';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogResponse } from '@vdms-hq/shared';
import { CollectionsActionsService } from '../../logic/services/collections-actions.service';
import { Subject, takeUntil, combineLatest, BehaviorSubject } from 'rxjs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  TileSelectableConfig,
  UIButtonModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { CollectionsAddData, CollectionsAddDataSource } from '../../logic/datasources/collections-add-data-source';
import { CollectionModelFlat, CollectionsService, SimpleType } from '@vdms-hq/api-contract';
import { DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { defaultFilters } from '../../pages/collections/config';
import { STATUS_OPTIONS } from '../../logic/utils/collections-filters-provider';
import { MatDividerModule } from '@angular/material/divider';
import { UiSelectableTilesComponent, SelectableWrapperConfig } from '@vdms-hq/selectable-tiles-wrapper';
import { ViewSettingsService } from '@vdms-hq/view-settings';

export interface AddToCollectionResponse {
  status: DialogResponse;
  selected: string[];
}

export interface AddToCollectionInput {
  collection?: CollectionModelFlat;
  selectedIds?: string[];
  selectedSimpleTypes?: SimpleType[];
  collectionUuid?: string;
}

@Component({
  selector: 'vdms-hq-collection-add-dialog',
  templateUrl: './collection-add.component.html',
  styles: [
    '.form-content { display: flex; flex-direction: column; gap: 16px; padding-right: 1rem;} mat-divider { margin-bottom: 8px;}',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    UILoaderModule,
    DynamicFiltersModule,
    UIEmptyResultsModule,
    MatDividerModule,
    UiSelectableTilesComponent,
  ],
})
export class CollectionAddDialogComponent extends WithPermissions() implements OnInit, OnDestroy {
  public dialogRef = inject(MatDialogRef<CollectionAddDialogComponent>);
  public collectionsActions = inject(CollectionsActionsService);
  private collectionsService = inject(CollectionsService);
  public dataSource = inject(CollectionsAddDataSource);
  private translate = inject(TranslateService);
  private activatedClientService = inject(ActivatedClientService);
  private viewSettingsService = inject(ViewSettingsService);
  private data: AddToCollectionInput = inject(MAT_DIALOG_DATA);

  initialized$ = new BehaviorSubject(false);

  form = new FormGroup({
    selected: new FormControl<string[]>([], { nonNullable: true }),
  });

  selectedControl = this.form.get('selected') as FormControl<string[]>;

  private destroyed$: Subject<void> = new Subject<void>();

  collectionsTilesConfig: TileSelectableConfig<CollectionsAddData> = {
    label: 'label',
    key: 'key',
    icon: 'playlist_add',
    disabledIf: {
      condition: (item: CollectionsAddData) => item.access_type === 'dashboard',
      label: () => 'common.collection_not_available',
    },
    metadata: [
      {
        label: 'Owner',
        valuePath: 'owner',
        viewFormat: {
          fallback: 'Unknown',
        },
        hiddenIf: (item) => item.type === 'owned',
      },
      {
        label: 'Number of assets',
        valuePath: 'number_of_assets',
        viewFormat: {
          modifiers: {
            asNumberWithZero: true,
          },
        },
      },
      {
        label: 'Description',
        valuePath: 'description',
        viewFormat: {
          modifiers: {
            truncateCharacters: 50,
          },
        },
      },
    ],
  };

  collectionsSelectableConfig: SelectableWrapperConfig<CollectionsAddData> = {
    fieldsConfig: [],
    tileConfig: this.collectionsTilesConfig,
    selectedList: {
      data: [],
      title: 'Collections to add',
    },
    searchView: {
      placeholder: 'Type name',
      label: 'Search Collections',
      showSearch: true,
    },
    datasourceTitle: 'Collections list',
    emptyMessage: 'No collections found',
    loadingText: this.translate.instant('common.client-teams.dialogs.fields.collections-loading'),
    hasEmptyActionButton: true,
    emptyActionButtonText: 'Create new collection',
    filters: {
      wrap: true,
      withFooter: false,
    },
  };

  ngOnInit() {
    combineLatest([this.activatedClientService.permissions$, this.viewSettingsService.preferredSelectableView$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([permissions, view]) => {
        this.collectionsSelectableConfig.searchView.showSearch = view === 'search';
        this.dataSource.filters.controls['keyword'].reset();
        this.collectionsSelectableConfig.fieldsConfig = defaultFilters;
        if (!permissions.includes(this.Permission.BROWSE_SHARED_COLLECTIONS)) {
          this.collectionsSelectableConfig.fieldsConfig = this.collectionsSelectableConfig.fieldsConfig.map((f) => {
            if (f.id == 'collectionType') {
              return {
                ...f,
                selectOptions: STATUS_OPTIONS.filter((opt) => {
                  return !['all', 'shared'].includes(opt.key);
                }),
              };
            }
            return f;
          });
        }
      });
    if (this.data?.collectionUuid && !this.data?.selectedIds) {
      this.dataSource.currentCollectionUuid$.next(this.data?.collectionUuid);
      this.collectionsService.getCollectionData(this.data.collectionUuid).subscribe((collection) => {
        this.selectedControl.setValue(collection.parents ? collection.parents.map((parent) => parent.uuid) : []);
        this.collectionsSelectableConfig.selectedList.data = collection.parents ?? [];
        this.initialized$.next(true);
      });
    } else {
      this.selectedControl.setValue(this.data?.selectedIds ?? []);
      this.collectionsSelectableConfig.selectedList.data = this.data?.selectedSimpleTypes ?? [];
      this.initialized$.next(true);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  close() {
    this.dataSource.refresh();
    this.dialogRef.close();
  }

  onSubmit() {
    const selectedCollections = this.selectedControl.value.filter((id) => !this.data?.selectedIds?.includes(id));

    this.dataSource.refresh();
    this.dialogRef.close({
      status: DialogResponse.OK,
      selected: selectedCollections,
    });
  }

  createNewCollection() {
    this.collectionsActions.openCreateDialog(this.dataSource.filters.controls.keyword.value, true);
  }

  saveViewSettings(showSearch: boolean) {
    this.viewSettingsService.savePreferredSelectableView(showSearch ? 'search' : 'list');
  }
}
