import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { FieldsFacets } from '@vdms-hq/dynamic-filters';
import {
  Aggregations,
  AssetSearchService,
  isEmbargoAgg,
  isRangeAgg,
  isStatsAgg,
  isValueAgg,
} from '@vdms-hq/api-contract';
import { Timecode } from '@vdms-hq/timecode';
import { FormatBitratePipe, FormatBytesPipe, SelectOptionGroup, underscoreToString } from '@vdms-hq/shared';
import { DataProviderService } from '@vdms-hq/selectors';
import { SearchResultsConfigFieldsService } from './search-results-config-fields.service';
import { FilterType } from '@vdms-hq/fields';

@Injectable({
  providedIn: 'root',
})
export class FacetsService {
  private searchService = inject(AssetSearchService);
  private config = inject(SearchResultsConfigFieldsService);
  private dataProviderService = inject(DataProviderService);

  facets$: Observable<FieldsFacets> = this.searchService.aggregations$.pipe(
    switchMap((aggrs) => this.#transformAggregationsToFacets(aggrs)),
  );
  #notAvailableValues = ['-1', 'null'];

  #filterDefWithProvidedData = this.config.filters$.pipe(
    switchMap((definition) => {
      const hasNotAnyGroupSelector = !definition.some(
        (def) => def.filters.type === FilterType.SELECTOR_GROUP && def?.sourceListKey,
      );
      if (hasNotAnyGroupSelector) {
        return of({ fields: definition });
      }

      const sources$ = {};

      definition.forEach((def) => {
        if (def?.filters.type !== FilterType.SELECTOR_GROUP || !def?.sourceListKey) {
          return;
        }
        sources$[def.sourceListKey] = this.dataProviderService.listForGroupSelectors(def.sourceListKey).pipe(
          catchError(() => {
            return of([]);
          }),
        );
      });

      return forkJoin({
        fields: of(definition),
        ...sources$,
      });
    }),
  );

  #transformAggregationsToFacets(aggregations: Aggregations): Observable<FieldsFacets> {
    return this.#filterDefWithProvidedData.pipe(
      take(1),
      map(({ fields, ...sources }) => {
        const fieldsWithAgregation = fields.filter((field) => !!field.filters?.aggregationKey);
        const facets: FieldsFacets = {};

        Object.values(aggregations).map((aggregation) => {
          const field = fieldsWithAgregation.find((field) => field.filters?.aggregationKey === aggregation.name);
          if (!field) {
            return;
          }

          const range = isRangeAgg(aggregation);
          const value = isValueAgg(aggregation);
          const stats = isStatsAgg(aggregation);
          const embargo = isEmbargoAgg(aggregation);

          const isSizeRange = field.filters.type === FilterType.SIZE_RANGE;
          const isBitrateRange = field.filters.type === FilterType.BITRATE_RANGE;
          const isTimecodeRange = field.filters.type === FilterType.TIMECODE_RANGE;
          const isSelectorGroup = field.filters.type === FilterType.SELECTOR_GROUP && field?.sourceListKey;

          if ((value || embargo) && !isSelectorGroup) {
            const valueOrEmbargo = value ? value : embargo;
            facets[field.id] = {
              data: valueOrEmbargo.data
                .map((data) => ({
                  key: data.value.toString(),
                  label: this.#notAvailableValues.includes(data.value.toString().toLowerCase())
                    ? '[ empty ]'
                    : underscoreToString(data.value.toString()),
                  suffix: `${data.count} ${data.count === 1 ? 'result' : 'results'}`,
                }))
                .sort((prev, next) => {
                  if (!isNaN(+prev.label) && !isNaN(+next.label)) {
                    return Number(prev.label) - Number(next.label);
                  }
                  return prev.label.localeCompare(next.label);
                }),
            };
          }

          if (aggregation.name == 'type' && facets['assetMasterType']) {
            facets['assetMasterType'] = {
              ...facets['assetMasterType'],
              data: facets['assetMasterType'].data.map((data) => {
                if (data.key == 'Subtitle') {
                  return {
                    ...data,
                    label: 'Timed Text',
                  };
                }
                return data;
              }),
            };
          }

          if (isSelectorGroup && sources) {
            facets[field.id] = {
              data: sources[field.sourceListKey]
                .map((option) => ({
                  ...option,
                  options: option.options
                    .filter((opt) =>
                      value.data.some((val) =>
                        this.#notAvailableValues.includes(val.value.toString().toLowerCase())
                          ? opt.label.toLowerCase().includes(val.value.toLowerCase())
                          : true,
                      ),
                    )
                    .map((op) => {
                      const element = value.data.find(
                        (el) => el.value.toUpperCase() === (option.label + ': ' + op.label).toUpperCase(),
                      );
                      if (!element) {
                        return null;
                      }
                      return {
                        ...op,
                        key: `${option.label}: ${op.label}`,
                        suffix: `${element?.count} ${element?.count === 1 ? 'result' : 'results'}`,
                      };
                    })
                    .filter(Boolean),
                }))
                .filter((group) => !!group.options?.length),
            };

            if (value.data.some((val) => this.#notAvailableValues.includes(val.value.toString().toLowerCase()))) {
              const foundEmptyElement = value.data.find((el) =>
                this.#notAvailableValues.includes(el.value.toString().toLowerCase()),
              );
              facets[field.id].data = [
                {
                  key: 'NULL',
                  label: '[ empty ]',
                  options: [
                    {
                      key: foundEmptyElement.value,
                      label: '[ empty ]',
                      suffix: `${foundEmptyElement.count} ${foundEmptyElement.count === 1 ? 'result' : 'results'}`,
                    },
                  ],
                },
                ...facets[field.id].data,
              ] as SelectOptionGroup[];
            }
          }

          if (stats || range) {
            if (!facets[field.id]) {
              facets[field.id] = {
                data: [],
              };
            }
          }

          if (range) {
            facets[field.id].data = range.data
              .filter((data) => data.count > 0)
              .map((data) => {
                if (isBitrateRange) {
                  const fromLabel = `${FormatBitratePipe.transformWithUnit(data.from ?? 0)}`;
                  const toLabel = data.to ? ` - ${FormatBitratePipe.transformWithUnit(data.to)}` : ' +';

                  return {
                    key: {
                      from: data.from ?? null,
                      to: data.to ?? null,
                    },
                    label: `${fromLabel}${toLabel}`,
                    suffix: `${data.count} ${data.count === 1 ? 'result' : 'results'}`,
                  };
                } else if (isSizeRange) {
                  const fromLabel = `${FormatBytesPipe.transformWithUnit(data.from ?? 0)}`;
                  const toLabel = data.to ? ` - ${FormatBytesPipe.transformWithUnit(data.to)}` : ' +';

                  return {
                    key: {
                      from: data.from ?? null,
                      to: data.to ?? null,
                    },
                    label: `${fromLabel}${toLabel}`,
                    suffix: `${data.count} ${data.count === 1 ? 'result' : 'results'}`,
                  };
                } else if (isTimecodeRange) {
                  const tcFrom = Timecode.fromSeconds(data.from, undefined, false);
                  const tcTo = Timecode.fromSeconds(data.to, undefined, false);

                  const fromLabel = `${tcFrom?.toString() ?? '00:00:00:00'}`;
                  const toLabel = tcTo ? ` - ${tcTo.toString()}` : ' +';

                  return {
                    key: {
                      from: tcFrom ?? null,
                      to: tcTo ?? null,
                    },
                    label: `${fromLabel}${toLabel}`,
                    suffix: `${data.count} ${data.count === 1 ? 'result' : 'results'}`,
                  };
                }

                return {
                  key: {
                    from: data.from ?? null,
                    to: data.to ?? null,
                  },
                  label: `${data.from ?? ' N/A'} - ${data.to ?? 'N/A'}`,
                  suffix: `${data.count} ${data.count === 1 ? 'result' : 'results'}`,
                };
              });
          }
        });
        return facets;
      }),
    );
  }
}
