import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { delay, map } from 'rxjs/operators';
import { Theme, ThemeSwitcherService } from '@vdms-hq/theming';
import { UIButtonModule, UIDialogWrapperModule, UILoaderModule } from '@vdms-hq/ui';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'vdms-hq-help-dialog',
  templateUrl: 'help-dialog.component.html',
  styleUrls: ['help-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UIDialogWrapperModule, UILoaderModule, UIButtonModule, TranslateModule],
})
export class HelpDialogComponent {
  src$ = this.theme.theme$.pipe(
    delay(300),
    map((theme) => (theme === Theme.dark ? `/assets/common/help/help_dark.svg` : `/assets/common/help/help_light.svg`)),
  );

  constructor(
    public additionalDataDialogRef: MatDialogRef<unknown>,
    private theme: ThemeSwitcherService,
  ) {}

  cancel() {
    this.additionalDataDialogRef.close();
  }
}
