export const ordersTypeRename = (value: string): string => {
  if (!value) {
    return value;
  }
  switch (value?.toLowerCase()) {
    case 'delivery_destination':
      return 'Delivery Destination';
    case 'email_delivery':
      return 'Email Delivery';
    case 'warm_up':
      return 'Warm Up';
    case 'workflow':
      return 'AI Processing';
    default:
      return value;
  }
};
