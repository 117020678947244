import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RuntimeErrorComponent } from '../../ui-data-presentation/components/runtime-error/runtime-error.component';
import { TranslateModule } from '@ngx-translate/core';
import { MetadataHiddenPipe } from '../../ui-data-presentation/pipes/metadata-hidden.pipe';
import { ValueRendererComponent } from '../../ui-value-renderer';
import { UIPipesModule } from '../../ui-pipes/ui-pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BehaviorSubject } from 'rxjs';
import { E2eIdDirective } from '@vdms-hq/shared';
import { DisableIfRowPipe, GridAdvancedMetadata } from '../../ui-data-presentation';
import { MatIconModule } from '@angular/material/icon';
import { GetTypeIconPipe } from '../../ui-pipes/pipes/get-type-icon.pipe';
import { GetTypeDescriptivePipe } from '../../ui-pipes/pipes/get-type-descriptive.pipe';
import { Action, ActionIdentifier } from '../../ui-data-presentation/logic/common-config';
import { HoverDirective } from '../../directives';
import { NameTransformerPipe } from '../../ui-floating-controls';
import { MatCheckbox } from '@angular/material/checkbox';
import { UIEmptyResultsModule } from '../../ui-empty-results';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

export type SelectableListTilesConfig<T> = {
  label: string;
  icon?: string | ((item: T) => string);
  key: string;
  disabledIf?: {
    label: (item: T) => string;
    icon?: (item: T) => string;
    condition: (item: T) => boolean;
  };
  hiddenIf?: (item: T) => boolean;
  actions?: Action<any>[] | null;
  metadata: GridAdvancedMetadata<T>[];
};

@Component({
  selector: 'vdms-hq-ui-selectable-list-tiles',
  templateUrl: './ui-selectable-list-tiles.component.html',
  styleUrls: ['./ui-selectable-list-tiles.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RuntimeErrorComponent,
    UIPipesModule,
    TranslateModule,
    MetadataHiddenPipe,
    ValueRendererComponent,
    MatTooltipModule,
    DisableIfRowPipe,
    E2eIdDirective,
    MatIconModule,
    GetTypeIconPipe,
    GetTypeDescriptivePipe,
    HoverDirective,
    NameTransformerPipe,
    MatCheckbox,
    UIEmptyResultsModule,
    ReactiveFormsModule,
  ],
})
export class UISelectableListTilesComponent<T> {
  @Input() config: SelectableListTilesConfig<T> | null = null;
  @Input() items: T[] | null = null;
  @Input() set selected(items: T[]) {
    this.selectedItems = items ?? [];
    if (items.length === 0) {
      this.lastSelectedIndex = null;
      this.selectDeselectAllControl.setValue(false);
    }
  }
  @Input() loading$ = new BehaviorSubject<boolean>(false);
  @Output() selectedChange = new EventEmitter<T[]>();
  @Output() selectedAll = new EventEmitter<void>();
  @Output() action = new EventEmitter<{ key: string; item: T }>();

  lastSelectedIndex: number | null = null;
  itemHover: T | null = null;

  selectedItems: T[] = [];

  selectDeselectAllControl = new FormControl<boolean>(false, { nonNullable: true });

  emitAction(event: { key: ActionIdentifier; item: T }, mouseEvent: MouseEvent) {
    mouseEvent.stopPropagation();
    this.action.emit(event);
  }

  emitSelectedChange(item: T) {
    const pointerEvent = event as PointerEvent;
    if (pointerEvent.shiftKey && this.lastSelectedIndex !== null) {
      const start = Math.min(this.lastSelectedIndex, this.items?.indexOf(item) ?? 0);
      const end = Math.max(this.lastSelectedIndex, this.items?.indexOf(item) ?? 0);
      const items = this.items?.slice(start, end + 1) ?? [];

      this.selectedChange.emit([item, ...items]);
      return;
    }
    this.selectedChange.emit([item]);
    this.lastSelectedIndex = this.items?.indexOf(item) ?? null;
  }

  selectDeselectAll() {
    this.selectedAll.emit();
  }
}
