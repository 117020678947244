<div class="chart-wrapper">
  <div class="circled">
    <div class="circled__number">{{ stats.number }}</div>
    <div class="circled__text">{{ stats.numberSubtitle }}</div>
  </div>
  <div class="info">
    <div class="arrow">
      @if (stats.arrow) {
        <mat-icon [class.arrow-down]="stats.arrow === 'down'">straight</mat-icon>
      }
    </div>
    {{ stats.text }}
  </div>
</div>
