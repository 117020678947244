import { inject, Pipe, PipeTransform } from '@angular/core';
import { AsyncPipe, DatePipe } from '@angular/common';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const argOrUndefined = (arg?: string | undefined) => (arg ? arg : undefined);

@Pipe({
  name: 'reactiveTzDate',
  pure: true,
  standalone: true,
})
export class ReactiveTzDatePipe implements PipeTransform {
  private datePipe = inject(DatePipe);
  private asyncPipe = inject(AsyncPipe);

  timezoneSource$: Observable<string> = EMPTY;

  transform(value: Date | string | number, format?: string, locale?: string): string | null {
    return this.asyncPipe.transform(
      this.timezoneSource$.pipe(
        map((timezone: string) =>
          this.datePipe.transform(value, argOrUndefined(format), timezone, argOrUndefined(locale)),
        ),
      ),
    );
  }
}
