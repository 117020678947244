<vdms-hq-ui-dialog-wrapper [size]="'default'">
  <h1 title>{{ title | translate }}</h1>
  <div content class="relative">
    <vdms-hq-ui-form-section layout="single" [divider]="!!(editMode$ | async)" [formGroup]="formService.form">
      <vdms-hq-ui-form-input-text
        formControlName="name"
        [label]="'common.dialogs.rights_partners.create.fields.name' | translate"
        [sharedE2eId]="'rights-partners-dialog-name'"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-text
        formControlName="partner_id"
        [label]="'common.dialogs.rights_partners.create.fields.partner_id' | translate"
        [sharedE2eId]="'rights-partners-dialog-id'"
      ></vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section
      *ngIf="editMode$ | async"
      layout="single"
      [title]="'common.dialogs.rights_partners.edit.fields.users_title' | translate"
      [titleSize]="'medium'"
      [divider]="false"
    >
      <div class="users-action">
        <vdms-hq-ui-form-input-text
          [formControl]="addUser"
          [placeholder]="'common.dialogs.rights_partners.edit.fields.add_hint' | translate"
          [label]="'common.dialogs.rights_partners.edit.fields.users' | translate"
          [sharedE2eId]="'rights-partners-dialog-add-user'"
          (keyup.enter)="!addUser.errors && attachUser(addUser.value)"
          [suffixConfig]="addUserSuffix"
          (clickSuffix)="attachUser(addUser.value)"
        ></vdms-hq-ui-form-input-text>

        <ng-container *ngIf="formService.usersValue.length > 0">
          <vdms-hq-ui-form-section
            [divider]="true"
            [dividerMarginBlock]="true"
            [layout]="'grid-3-columns'"
            [columnSizes]="columnSizes"
          >
            <span>{{ 'common.dialogs.rights_partners.edit.list.email' | translate }}</span>
            <span>{{ 'common.dialogs.rights_partners.edit.list.policies' | translate }}</span>
          </vdms-hq-ui-form-section>
          <vdms-hq-ui-form-section
            *ngFor="let user of formService.usersControls"
            [divider]="false"
            [dividerMarginBlock]="true"
            [layout]="'grid-3-columns'"
            [columnSizes]="columnSizes"
          >
            <div class="align-self-center">{{ user.value.email }}</div>
            <vdms-hq-ui-form-input-select
              [formControl]="user.controls.policies"
              [selectOptions]="restrictedPoliciesOptions"
              [multiple]="true"
              [withFooter]="false"
              [matTooltipDisabled]="!user.controls.policies.disabled"
              [matTooltip]="'Add policy restrictions to enable editing.'"
              class="policies-edit-select"
              label="{{ 'common.dialogs.rights_partners.edit.list.policies_select' | translate }}"
            ></vdms-hq-ui-form-input-select>
            <vdms-hq-ui-button
              class="justify-self-center align-self-center"
              [icon]="'delete'"
              [iconOnly]="true"
              [disabled]="false"
              (click)="detachUser(user.value.email)"
            ></vdms-hq-ui-button>
          </vdms-hq-ui-form-section>
        </ng-container>
        <vdms-hq-ui-empty-results
          *ngIf="formService.usersValue.length === 0"
          [message]="'No users attached'"
          [icon]="'person_search'"
          type="compact"
        ></vdms-hq-ui-empty-results>
      </div>
    </vdms-hq-ui-form-section>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="closeDialog()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      color="primary"
      [loading]="isLoading$ | async"
      [sharedE2eId]="'rights-partners-dialog-submit'"
      (click)="onSubmit()"
      [disabled]="formService.isInvalid"
    >
      {{ saveButton | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
