import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByExcept',
  standalone: true,
})
export class SortByExceptNullPipe implements PipeTransform {
  transform(array: Array<{ [key: string]: any }>, sortBy: string, exceptions: RegExp): Array<{ [key: string]: any }> {
    if (sortBy == '') {
      return array;
    }
    return this.sortArray(array, sortBy, exceptions);
  }

  sortArray(array: Array<{ [key: string]: any }>, sortBy: string, exceptions: RegExp): Array<{ [key: string]: any }> {
    return array.sort((a, b) => {
      if (String(a[sortBy]).match(exceptions) || String(b[sortBy]).match(exceptions)) {
        return 1;
      } else {
        return a?.[sortBy]?.localeCompare(b?.[sortBy]);
      }
    });
  }
}
