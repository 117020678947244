import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService, isMissingUserDataException } from '@vdms-hq/auth';
import { ToastService } from '@vdms-hq/toast';
import { lastValueFrom, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { filterEmpty } from '@vdms-hq/shared';
import { UserApiService } from '@vdms-hq/api-contract';
import { LOGIN_PAGE_CONFIG, LoginPageConfig } from '../../logic/config-tokens';

@Component({
  selector: 'vdms-hq-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  @Input() headerTranslateKey = 'common.auth.header';
  @Input() loginButtonTranslateKey = 'common.auth.sendEmail';
  loading = false;
  passwordLessSent = false;
  error = false;
  isBBC = false;

  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });
  #destroyed = new Subject<void>();

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private userApiService: UserApiService,
    @Inject(LOGIN_PAGE_CONFIG) private loginPageConfig: LoginPageConfig,
  ) {}

  get email(): UntypedFormControl {
    return this.form?.get('email') as UntypedFormControl;
  }

  async sendEmail(): Promise<void> {
    if (this.form?.invalid) {
      return;
    }

    this.loading = true;

    try {
      if (this.loginPageConfig.useCustomPasswordlessEmail) {
        await lastValueFrom(
          this.userApiService.loginPasswordLess({
            email: this.email.value,
            baseUrl: `${location.origin}/login?attemptVerifyEmail=true`,
            app: this.loginPageConfig.app,
          }),
        );
        localStorage.setItem('emailForSignIn', this.email.value);
      } else {
        await this.authService.loginPasswordLess(this.email.value.toLowerCase());
      }

      this.passwordLessSent = true;
    } catch (e) {
      this.passwordLessSent = false;
      this.error = true;
    }

    this.loading = false;
  }

  reset() {
    this.error = false;
    this.passwordLessSent = false;
    this.form.reset();
  }

  ngOnInit(): void {
    const hostname = globalThis.location.hostname;
    if (hostname.includes('bbcmg')) this.isBBC = true;
    this.authService.error$
      .pipe(
        filterEmpty(),
        switchMap((error) => {
          if (isMissingUserDataException(error) && error.email) {
            this.toastService.warning({
              id: 'auth-error',
              message: 'common.auth.userNotConfigured',
              interpolatedParams: {
                email: error.email,
              },
            });
          } else {
            this.toastService.warning({
              id: 'auth-error',
              message: 'common.auth.userUnknownFail',
            });
          }

          return this.authService.logout();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }
}
