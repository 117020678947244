@if (pairedDevices$ | async; as pairedDevices) {
  <section class="paired-devices" [ngClass]="{ 'paired-devices--center': !pairedDevices.length }">
    @if (pairedDevices.length) {
      @for (device of pairedDevices; track device) {
        <span>{{ device.hostname }}</span>
        <span>{{ device.created_at | date: 'dd MMM YYYY' }}</span>
        <vdms-hq-ui-button
          [iconOnly]="true"
          icon="delete"
          color="transparent"
          (click)="unPairDevice(device.uuid)"
        ></vdms-hq-ui-button>
      }
    } @else {
      <vdms-hq-ui-empty-results [type]="'compact'"></vdms-hq-ui-empty-results>
    }
    @if (loading$ | async) {
      <vdms-hq-ui-loader></vdms-hq-ui-loader>
    }
  </section>
}
