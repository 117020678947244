import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FieldsDataSource } from '../../logic/fields-data-source';
import {
  ActionIdentifier,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIConfirmationDialogService,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { MatDialog } from '@angular/material/dialog';
import { EditFieldDialogComponent } from '../../components/edit-field-dialog/edit-field-dialog.component';
import { switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { SelectOption } from '@vdms-hq/shared';
import { FieldsOptionsService } from '@vdms-hq/api-contract';
import { FieldDefinitionModel } from '@vdms-hq/fields';

@Component({
  selector: 'vdms-hq-defined-list-config',
  templateUrl: './defined-fields-list.component.html',
  imports: [UIResultsWrapperModule, MultipleDataPresentationComponent],
  standalone: true,
})
export class DefinedFieldsListComponent implements OnInit, OnDestroy {
  dataSource = inject(FieldsDataSource);
  fieldsOptionsService = inject(FieldsOptionsService);
  matDialogService = inject(MatDialog);
  confirmationDialog = inject(UIConfirmationDialogService);
  #typeSelectOptions: SelectOption[] = [];

  configuration: MultipleViewConfiguration<FieldDefinitionModel> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: true,
      emptyResults: {
        message: 'Could not find any entities',
      },
    },
    tableAdvanced: {
      actions: [
        {
          key: 'edit',
          label: 'Edit',
          icon: 'edit',
          onDoubleClick: true,
        },
        {
          key: 'reset',
          label: 'Reset to default',
          icon: 'restart_alt',
          onDoubleClick: false,
          hiddenIf: (item?) => !(item?.isModified ?? false),
        },
      ],
      columns: [
        {
          id: 'resource',
          label: 'Resource',
          valuePath: 'resource',
          foldValues: true,
        },
        {
          id: 'id',
          label: 'ID',
          valuePath: 'id',
        },
        {
          id: 'label',
          label: 'Label',
          valuePath: 'label',
        },
        {
          id: 'sourceListKey',
          label: 'Source list',
          valuePath: 'sourceListKey',
        },
        {
          id: 'inputType',
          label: 'Input type',
          valuePath: 'input.type',
        },
        {
          id: 'inputReadonly',
          label: 'Is readonly?',
          valuePath: 'input.readonly',
        },
        {
          id: 'outputFormat',
          label: 'Format',
          valuePath: 'format',
        },
        {
          id: 'isModified',
          label: 'Is Modified',
          valuePath: 'isModified',
          viewFormat: {
            pills: {
              true: 'failed',
            },
          },
        },
        {
          id: 'isCustomDefinition',
          label: 'Is Custom Definition',
          valuePath: 'isCustomDefinition',
        },
        {
          id: 'actions',
          type: 'actions',
        },
      ],
      columnsEnabled: ['resource', 'id', 'label', 'isModified', 'actions'],
    },
  };

  handleAction(e: { key: ActionIdentifier; item?: FieldDefinitionModel }) {
    switch (e.key) {
      case 'edit':
        if (e.item) {
          this.matDialogService.open(EditFieldDialogComponent, {
            data: {
              item: e.item,
              autocompleteOptions: this.#typeSelectOptions,
            },
          });
        }
        break;
      case 'reset':
        if (e.item) {
          this.confirmationDialog
            .open()
            .pipe(
              switchMap((status) => {
                if (status && e.item) {
                  return this.dataSource.save(e.item.id);
                } else {
                  return EMPTY;
                }
              }),
            )
            .subscribe();
        }
        break;
    }
  }

  async ngOnInit() {
    this.#typeSelectOptions = await this.fieldsOptionsService.getSelectOptions();
  }

  ngOnDestroy() {
    this.dataSource.clearFilters();
  }
}
