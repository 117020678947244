import { inject, Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import {
  TRANSCODE_BASE_ENDPOINTS,
  TranscodeContainer,
  TranscodeContainerPostPatch,
  TranscodeType,
  TranscodeTypePostPatch,
} from './transcode.model';
import { createErrorContext } from '../../error-handler';
@Injectable({ providedIn: 'root' })
export class TranscodeService {
  private api = inject(ApiService);

  //CONTAINERS
  getAllContainers = () => {
    const context = createErrorContext({ '*': 'common.transcode_base.containers.get_all.failure' }, 'get-containers');
    return this.api
      .get<ApiResponse<TranscodeContainer[]>>(TRANSCODE_BASE_ENDPOINTS.ALL_CONTAINERS, { context })
      .pipe(GetResponseData);
  };

  getOneContainer = (uuid: string) => {
    const context = createErrorContext({ '*': 'common.transcode_base.containers.get_one.failure' }, 'get-container');
    return this.api
      .get<ApiResponse<TranscodeContainer>>(`${TRANSCODE_BASE_ENDPOINTS.CONTAINER}/${uuid}`, {
        context,
      })
      .pipe(GetResponseData);
  };

  createContainer = (payload: TranscodeContainerPostPatch) => {
    const context = createErrorContext({ '*': 'common.transcode_base.containers.create.failure' }, 'create-container');
    return this.api.post(TRANSCODE_BASE_ENDPOINTS.CONTAINER, payload, undefined, context);
  };

  updateContainer = (uuid: string, payload: TranscodeContainerPostPatch) => {
    const context = createErrorContext({ '*': 'common.transcode_base.containers.edit.failure' }, 'edit-container');
    return this.api.patch(`${TRANSCODE_BASE_ENDPOINTS.CONTAINER}/${uuid}`, payload, undefined, context);
  };

  deleteContainer = (uuid: string) => {
    const context = createErrorContext({ '*': 'common.transcode_base.containers.delete.failure' }, 'delete-container');
    return this.api.delete(`${TRANSCODE_BASE_ENDPOINTS.CONTAINER}/${uuid}`, undefined, undefined, undefined, context);
  };

  //TYPES
  getAllTypes = () =>
    this.api.get<ApiResponse<TranscodeType[]>>(TRANSCODE_BASE_ENDPOINTS.ALL_TYPES).pipe(GetResponseData);

  getOneType = (uuid: string) =>
    this.api.get<ApiResponse<TranscodeType>>(`${TRANSCODE_BASE_ENDPOINTS.TYPE}/${uuid}`).pipe(GetResponseData);

  createType = (payload: TranscodeTypePostPatch) => this.api.post(TRANSCODE_BASE_ENDPOINTS.TYPE, payload);

  updateType = (uuid: string, payload: TranscodeTypePostPatch) =>
    this.api.patch(`${TRANSCODE_BASE_ENDPOINTS.TYPE}/${uuid}`, payload);

  deleteType = (uuid: string) => this.api.delete(`${TRANSCODE_BASE_ENDPOINTS.TYPE}/${uuid}`);
}
