import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EmbargoDates } from '@vdms-hq/api-contract';
import { SelectOption } from '@vdms-hq/shared';
import { FormControlValueAccessorComponent } from '@vdms-hq/ui';
import moment from 'moment';
import { BehaviorSubject, distinctUntilChanged, of, take } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DataProviderService } from '../../logic/data-provider.service';
import { SourceListKey } from '@vdms-hq/fields';

type OuterValue = string | number | Date | null | undefined;
type InnerValue = string | null;
type EmbargoDatesType = (typeof EmbargoDates)[keyof typeof EmbargoDates];
@Component({
  selector: 'vdms-hq-ui-form-input-date-time-unix-timestamp-limited',
  templateUrl: './form-input-date-time-unix-timestamp-limited.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputDateTimeUnixTimestampLimitedComponent),
    },
  ],
})
export class FormInputDateTimeUnixTimestampLimitedComponent
  extends FormControlValueAccessorComponent<OuterValue, InnerValue>
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() triggerDatePickerValue = EmbargoDates.CUSTOM;
  @Input() sourceType?: SourceListKey;

  selectOptions$ = of<SelectOption[]>([]);
  innerFormControl = new FormControl<InnerValue>(null);
  selectorFormControl = new FormControl<EmbargoDatesType | null>(null);
  triggerDatePicker$ = new BehaviorSubject(false);

  constructor(
    injector: Injector,
    cdr: ChangeDetectorRef,
    private dps: DataProviderService,
  ) {
    super(injector, cdr);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.innerFormControl.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
      if (this.innerFormControl.disabled) {
        this.selectorFormControl.disable();
        this.selectorFormControl.markAsUntouched();
        this.selectorFormControl.markAsPristine();
        this.innerFormControl.markAsUntouched();
        this.innerFormControl.markAsPristine();
      } else {
        this.selectorFormControl.enable();
        this.selectorFormControl.markAsTouched();
        this.selectorFormControl.markAsDirty();
        this.innerFormControl.markAsTouched();
        this.innerFormControl.markAsDirty();
      }
    });
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.#initializeSelectorFormControl();

    this.selectorFormControl.valueChanges
      .pipe(
        takeUntil(this.destroy),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      )
      .subscribe((value) => {
        if (value === this.triggerDatePickerValue) {
          this.triggerDatePicker$.next(true);
          this.innerFormControl.setValue(moment().format('YYYY-MM-DDTHH:mm:ss'), { emitEvent: false });
        } else {
          this.triggerDatePicker$.next(false);
          this.innerFormControl.setValue(value);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sourceType && changes.sourceType.currentValue !== changes.sourceType.previousValue) {
      this.selectOptions$ = this.dps.listForSelectors(changes.sourceType.currentValue).pipe(
        take(1),
        map((items) => items.filter(({ label }) => label !== 'N/A')),
      );
    }
  }

  #initializeSelectorFormControl() {
    if (this.selectorFormControl.value) {
      return;
    }

    this.selectOptions$.pipe(take(1)).subscribe((options) => {
      const selectedOptionAvailable = this.outerValue ? options.map(({ key }) => key).includes(this.outerValue) : false;

      if (!selectedOptionAvailable) {
        this.selectorFormControl.setValue(this.triggerDatePickerValue);
        this.triggerDatePicker$.next(true);
        return;
      }

      const option = options.find(({ key }) => key === this.outerValue)?.key as EmbargoDatesType | null;
      this.selectorFormControl.setValue(option);
      this.changeDetectorRef.detectChanges();
    });
  }
}
