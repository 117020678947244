import { camelCase } from 'lodash';

export const camelToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const snakeCaseToCamel = (str: string) => {
  return camelCase(str);
};

export const snakeToString = (str: string) => {
  return str.replace(/_/g, ' ');
};

export const snakeToCapitalizedString = (str: string) => {
  const snakedString = snakeToString(str);
  return snakedString.charAt(0).toUpperCase() + snakedString.slice(1);
};

export const dashedToString = (str: string) => {
  return str.replace(/-/g, ' ');
};

export const dashedToCapitalizedString = (str: string | string[]) => {
  if (Array.isArray(str)) {
    str = str.join(', ');
  }
  const dashedString = dashedToString(str.toLowerCase());
  return dashedString.charAt(0).toUpperCase() + dashedString.slice(1);
};

export const underscoreToString = (str: string) => {
  return str.replace(/_/g, ' ');
};

export const underscoreToCapitalizedString = (str: string) => {
  const underscoredString = underscoreToString(str);
  return underscoredString.charAt(0).toUpperCase() + underscoredString.slice(1);
};

export const camelCaseToWords = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1').toLowerCase();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const stringToUnderscore = (str: string) => {
  return str.replace(/ /g, '_');
};

export const capitalize = (str: string) => {
  const lowered = str.toLowerCase();
  return lowered.charAt(0).toUpperCase() + lowered.slice(1);
};
