import { inject, Injectable } from '@angular/core';
import { ImageCacheService } from '@vdms-hq/shared';
import {
  filterStreamMsgFactory,
  mapToProgressToastMessage,
  ProgressStreamService,
  WebsocketAnyNotificationMessage,
  WebsocketNotificationActionEnum,
} from '@vdms-hq/api-contract';
import { generateProgressToastMessage, progressToast, ToastService } from '@vdms-hq/toast';

@Injectable({ providedIn: 'root' })
export class OrderActionsRetryWsService {
  private imageCache = inject(ImageCacheService);
  private streamService = inject(ProgressStreamService);
  private toastService = inject(ToastService);

  popToast = {
    WS_RESTART_PROGRESS: (
      id: string,
      percent: number,
      counter?: {
        processing: number;
        all: number;
        errors: WebsocketAnyNotificationMessage;
      },
    ) =>
      this.toastService.processing({
        id,
        message: generateProgressToastMessage(
          'Retrying delivery destinations',
          percent,
          this.imageCache.getImage('assets/common/asset_fallbacks/info.svg'),
          counter,
        ),
      }),
    WS_RESTART_SUCCESS: (id: string) =>
      this.toastService.success({
        id,
        message: `Retrying delivery destinations success`,
      }),
    WS_RESTART_ERROR: (id: string) =>
      this.toastService.error({
        id,
        message: `Retrying delivery destinations error`,
      }),
  };

  readonly connect$ = this.streamService.connect();

  #initToastElement() {
    this.imageCache.cacheImage('assets/common/asset_fallbacks/info.svg');
  }

  registerWebSocketListener() {
    this.#initToastElement();
    return this.connect$.pipe(
      filterStreamMsgFactory([WebsocketNotificationActionEnum.RETRY_DD_JOBS]),
      mapToProgressToastMessage(),
      progressToast({
        SUCCESS: this.popToast.WS_RESTART_SUCCESS,
        PROGRESS: this.popToast.WS_RESTART_PROGRESS,
        ERROR: this.popToast.WS_RESTART_ERROR,
      }),
    );
  }
}
