import { Pipe, PipeTransform } from '@angular/core';
import { filterIsObject } from '../utils/filters-type.utils';
import { FilterValue } from '@vdms-hq/api-contract';
import { FilterDefinitionModel } from '@vdms-hq/fields';

@Pipe({
  name: 'appliedFilterLabel',
})
export class AppliedFilterLabelPipe implements PipeTransform {
  transform(filters: { filterDef: FilterDefinitionModel; value: FilterValue }, index: number): string {
    if (!filterIsObject(filters.value)) {
      return `${filters?.filterDef?.label ?? ''}`.trim();
    }
    const key = Object.entries(filters.value).filter(([k, v]) => !!v)[index][0] ?? '';
    return `${filters?.filterDef?.label ?? ''}${key ? '(' + key + ')' : ''}`.trim();
  }
}
