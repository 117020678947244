import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedClientModule } from '@vdms-hq/activated-client';
import { ElementsRendererComponent, FormBuilderComponent, FormBuilderModule } from '@vdms-hq/dynamic-form';
import { ToastModule } from '@vdms-hq/toast';
import {
  PaginatorComponent,
  UIButtonModule,
  UIDialogModule,
  UIDialogWrapperModule,
  UIEmptyResultsModule,
  UIFormModule,
  UILayoutModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { BatchActionDialogComponent } from './components/batch-action-dialog/batch-action-dialog.component';
import { AssetResultsActionsComponent } from './components/vdms-hq-asset-results-actions/vdms-hq-asset-results-actions.component';
import { ContextMenuActionsService } from './logic/services/context-menu-actions.service';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  imports: [
    CommonModule,
    ToastModule,
    TranslateModule,
    UIResultsWrapperModule,
    UIDialogModule,
    UIButtonModule,
    UIFormModule,
    UIDialogWrapperModule,
    UIEmptyResultsModule,
    ActivatedClientModule,
    UILayoutModule,
    PaginatorComponent,
    FormBuilderModule,
    ElementsRendererComponent,
    MatTooltipModule,
    MatMenuModule,
    MatDividerModule,
    FormBuilderComponent,
  ],
  declarations: [AssetResultsActionsComponent, BatchActionDialogComponent],
  exports: [AssetResultsActionsComponent],
  providers: [ContextMenuActionsService],
})
export class AssetResultsModule {
  static forRoot(options: MatTooltipDefaultOptions): ModuleWithProviders<AssetResultsModule> {
    return {
      ngModule: AssetResultsModule,
      providers: [
        ContextMenuActionsService,
        {
          provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
          useValue: options,
        },
      ],
    };
  }
}
