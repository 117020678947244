<ng-container *ngIf="clientForm$ | async as clientForm; else loading">
  <form [formGroup]="clientForm">
    <vdms-hq-ui-form-section [layout]="'single'" [divider]="false">
      <vdms-hq-ui-form-input-text
        formControlName="name"
        required="true"
        [label]="'pages.clients.client_name' | translate"
      ></vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section [title]="'pages.clients.sections.custom_features' | translate" [layout]="'single'">
      <vdms-hq-ui-form-input-checkbox
        formControlName="fa_is_required"
        [label]="'common.admin.main_form.require_2FA' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        formControlName="custom_languages_enabled"
        [label]="'pages.clients.custom_languages_enabled' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        class="d-block"
        formControlName="ai_processing_checkout_enabled"
        [label]="'common.admin.main_form.video_analysis_checkout_enabled' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        formControlName="enable_cold_storage"
        [label]="'pages.clients.custom_cold_storage' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        formControlName="enable_stay_in_hot"
        [label]="'pages.clients.custom_stay_in_hot' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        formControlName="asset_qa_enabled"
        [label]="'pages.clients.custom_asset_qa_enabled' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        formControlName="enable_directly_cold"
        [label]="'pages.clients.custom_enable_directly_cold' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        formControlName="transcribe_uploaded_assets"
        [label]="'pages.clients.enable_transcribe_uploaded_assets' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        formControlName="auto_audio_language_detection"
        [label]="'pages.clients.enable_auto_audio_language_detection' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        formControlName="event_based_ingest_enabled"
        [label]="'pages.clients.event_based_ingest_enabled' | translate"
      ></vdms-hq-ui-form-input-checkbox>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section [title]="'pages.clients.sections.aws' | translate" [layout]="'single'">
      <vdms-hq-ui-form-input-checkbox
        formControlName="detect_labels_image"
        [label]="'pages.clients.detect_labels_image' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        formControlName="detect_labels_video"
        [label]="'pages.clients.detect_labels_video' | translate"
      ></vdms-hq-ui-form-input-checkbox>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section [title]="'pages.clients.sections.vida_bucket' | translate">
      <vdms-hq-ui-form-input-text
        formControlName="internal_bucket_name"
        [label]="'pages.clients.vida_s3.internal_bucket_name' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-text
        formControlName="internal_bucket_region"
        [label]="'pages.clients.vida_s3.internal_bucket_region' | translate"
      ></vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section [layout]="sqsUrlColumns" [title]="'pages.clients.sections.client_url' | translate">
      <div header class="mb-large section-header">
        <vdms-hq-ui-button (click)="syncQueue()" [color]="'secondary'">
          {{ 'pages.clients.sqs.sync_media_pulse' | translate }}
        </vdms-hq-ui-button>
      </div>
      <vdms-hq-ui-form-input-text
        formControlName="mediapulse_download_sqs_queue"
        [label]="'pages.clients.sqs.media_pulse_sqs_url' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-text
        formControlName="mediapulse_download_sqs_queue_la"
        [label]="'pages.clients.sqs.mediapulse_download_sqs_queue_la' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-text
        formControlName="mediapulse_download_sqs_queue_1mp"
        [label]="'pages.clients.sqs.mediapulse_download_sqs_queue_1mp' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-text
        formControlName="tedial_xml_sqs_queue"
        [label]="'pages.clients.sqs.tedial_xml_sqs_url' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-text
        formControlName="tedial_xml_sqs_queue_la"
        [label]="'pages.clients.sqs.tedial_xml_sqs_url_la' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-text
        formControlName="tedial_xml_sqs_queue_1mp"
        [label]="'pages.clients.sqs.tedial_xml_sqs_queue_1mp' | translate"
      ></vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section
      [layout]="sqsPriorityColumns"
      [title]="'pages.clients.sections.sqs_priority' | translate"
      formGroupName="tedial_sqs_priority"
    >
      <vdms-hq-ui-form-input-number
        formControlName="new_asset"
        [label]="'pages.clients.sqs.new_asset' | translate"
        [min]="0"
      >
      </vdms-hq-ui-form-input-number>
      <vdms-hq-ui-form-input-number
        formControlName="edit_single_asset"
        [label]="'pages.clients.sqs.edit_single_asset' | translate"
        [min]="0"
      ></vdms-hq-ui-form-input-number>
      <vdms-hq-ui-form-input-number
        formControlName="edit_batch_assets"
        [label]="'pages.clients.sqs.edit_batch_assets' | translate"
        [min]="0"
      ></vdms-hq-ui-form-input-number>
      <vdms-hq-ui-form-input-number
        formControlName="tedial_import"
        [label]="'pages.clients.sqs.tedial_import' | translate"
        [min]="0"
      ></vdms-hq-ui-form-input-number>
      <vdms-hq-ui-form-input-number
        formControlName="csv_batch_update"
        [label]="'pages.clients.sqs.csv_batch_update' | translate"
        [min]="0"
      ></vdms-hq-ui-form-input-number>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section [title]="'pages.clients.sections.xytech' | translate">
      <vdms-hq-ui-form-input-text
        formControlName="client_id"
        [label]="'pages.clients.xytech.client_id' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-text
        formControlName="job_id"
        [label]="'pages.clients.xytech.job_id' | translate"
      ></vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section [layout]="'single'" [title]="'pages.clients.sections.global_notification' | translate">
      <vdms-hq-ui-form-input-chips
        formControlName="external_supervisor_emails"
        [label]="'pages.clients.global_notification.supervisor_order_notifications' | translate"
      ></vdms-hq-ui-form-input-chips>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section
      [layout]="'grid-2-columns'"
      [divider]="true"
      [title]="'pages.clients.sections.credentials' | translate"
    >
      <vdms-hq-ui-form-input-select
        formControlName="external_bucket_credential_uuid"
        [label]="'pages.clients.external_bucket_credentials' | translate"
        [selectOptions]="externalBucketCredentialsOptions"
      ></vdms-hq-ui-form-input-select>

      <vdms-hq-ui-form-input-select
        formControlName="aspera_upload_credential_uuid"
        [label]="'pages.clients.aspera_upload_credentials' | translate"
        [selectOptions]="asperaCredentialsOptions"
      ></vdms-hq-ui-form-input-select>

      <vdms-hq-ui-form-input-select
        formControlName="aspera_download_credential_uuid"
        [label]="'pages.clients.aspera_download_credentials' | translate"
        [selectOptions]="asperaCredentialsOptions"
      ></vdms-hq-ui-form-input-select>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section
      [divider]="true"
      [layout]="'grid-2-columns'"
      [title]="'pages.clients.proxy_filename_source_property' | translate"
    >
      <vdms-hq-ui-form-input-select
        formControlName="proxy_filename_source_property"
        [label]="'pages.clients.proxy_filename_source_property' | translate"
        [selectOptions]="proxyFilenameOptions"
      ></vdms-hq-ui-form-input-select>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section
      [divider]="true"
      [layout]="'grid-2-columns'"
      [title]="'pages.clients.default_application_policies' | translate"
    >
      <ng-container formGroupName="default_policies">
        <div *ngFor="let policyControlName of defaultPoliciesControlsKeys$ | async">
          <vdms-hq-ui-form-input-select
            [matTooltip]="'clients-editor.tooltips.' + policyControlName | translate"
            [formControlName]="policyControlName"
            [label]="'clients-editor.labels.' + policyControlName | translate"
            [selectOptions]="policiesOptions"
          ></vdms-hq-ui-form-input-select>
        </div>
      </ng-container>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section
      [divider]="true"
      [layout]="'grid-2-columns'"
      [title]="'pages.clients.wizard_available_policies' | translate"
    >
      <ng-container formGroupName="wizard_policies">
        <div *ngFor="let policyControlName of availablePoliciesControlsKeys$ | async">
          <vdms-hq-ui-form-input-select
            [formControlName]="policyControlName"
            [label]="'clients-editor.labels.' + policyControlName | translate"
            [selectOptions]="policiesOptions"
            [multiple]="true"
          ></vdms-hq-ui-form-input-select>
        </div>
      </ng-container>
    </vdms-hq-ui-form-section>
    <ng-container *ngFor="let message of messages$ | async">
      <vdms-hq-ui-info-bar
        *ngIf="message?.id === 'defaultPolicies'"
        [type]="message.type"
        [message]="message.body"
      ></vdms-hq-ui-info-bar>
    </ng-container>

    <vdms-hq-ui-form-section
      [layout]="'grid-2-columns'"
      [title]="'pages.clients.sections.other' | translate"
      [divider]="false"
    >
      <vdms-hq-ui-form-input-number
        formControlName="librarian_group_id"
        [label]="'pages.clients.other.librarian_group_id' | translate"
        [min]="0"
      ></vdms-hq-ui-form-input-number>
      <vdms-hq-ui-form-input-number
        formControlName="purge_assets_safety_net_days"
        [label]="'pages.clients.other.purge' | translate"
        [min]="0"
      ></vdms-hq-ui-form-input-number>
    </vdms-hq-ui-form-section>

    <vdms-hq-ui-form-section [divider]="true" [layout]="'single'" [title]="'pages.clients.download_limit' | translate">
      <vdms-hq-ui-form-input-number
        formControlName="download_limit"
        [label]="'pages.clients.download_limit_label' | translate"
        [min]="-1"
        [matTooltip]="downloadLimitValue === -1 ? ('pages.clients.download_limit_tooltip' | translate) : ''"
      ></vdms-hq-ui-form-input-number>
    </vdms-hq-ui-form-section>
  </form>
</ng-container>

<ng-template #loading>
  <vdms-hq-ui-loader></vdms-hq-ui-loader>
</ng-template>
