import { inject, Injectable } from '@angular/core';
import { capitalize } from '@vdms-hq/shared';
import { CollectionAccessTypeEnum, CollectionAccessTypes, CollectionModelFlat } from '@vdms-hq/api-contract';
import { FlatCollectionViewModel } from '@vdms-hq/collections';
import { StorageUrlService } from '@vdms-hq/storage';

@Injectable({ providedIn: 'root' })
export class ResponseToFlatCollectionTransformer {
  private storageUrlService = inject(StorageUrlService);

  transformCollection(accessTypes: CollectionAccessTypes[], collection: CollectionModelFlat): FlatCollectionViewModel {
    const accessType =
      accessTypes.find(({ uuid }) => collection.uuid === uuid)?.access_type ?? CollectionAccessTypeEnum.OWNED;
    const thumb = collection.thumbnails.length > 0;
    const thumbnail =
      (thumb ? collection.thumbnails[0].url : collection.custom_cover_path) + '?' + Date.now().toString();
    const owner_type = capitalize(accessType);
    return {
      ...collection,
      access_type: accessType,
      owner_type,
      custom_cover_path: this.storageUrlService.updateCdn(thumbnail ?? undefined),
      views: collection.views ?? collection.totalViews,
      viewsRanking: collection.viewsRanking ?? collection.totalViewsRanking,
    };
  }
}
