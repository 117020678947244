import { Injectable } from '@angular/core';
import { ClientModel, ClientRef, ClientVidaLibrarySchema, FirebaseClientModel } from './client.model';
import { from, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PersistenceEndpointsModel } from './../persistence-endpoints.model';
import firebase from 'firebase/compat/app';
import { AssetViewConfig } from './asset-view.model';
import { snakeCaseToCamel } from '@vdms-hq/shared';
import { LaunchpadSettings } from './client-launchpad.model';
import { FieldConfigId } from '@vdms-hq/fields';

@Injectable({
  providedIn: 'root',
})
export class ClientContractService {
  constructor(private angularFirestore: AngularFirestore) {}

  #allClients$: Observable<ClientModel[]> = this.angularFirestore
    .collection<FirebaseClientModel>(PersistenceEndpointsModel.CLIENTS_COLLECTION)
    .valueChanges()
    .pipe(
      shareReplay(1),
      map((items) => items.map(this.#preTransform)),
    );

  #preTransform = (client: FirebaseClientModel): ClientModel => {
    const deleted = client.filters?.deleted ?? client.filters?.purged ?? client.filters?.browse ?? [];

    const clientSchemas = client.libraryConfigs;
    const legacySchema = client.library;

    let libraryConfigs = <ClientVidaLibrarySchema[]>[];

    if (clientSchemas && clientSchemas.length) {
      libraryConfigs = clientSchemas;
    } else if (legacySchema?.structure) {
      libraryConfigs = [
        {
          id: 'default',
          name: 'Client default',
          structure: legacySchema.structure ?? [],
        },
      ];
    }

    return this.#transformFields({
      ...client,
      libraryUpload: client.libraryUpload ?? 'multipart',
      columnsConfig: {
        default: client.columnsConfig?.default ?? [],
        available: client.columnsConfig?.available ?? [],
      },
      integrations: client.integrations ?? {},
      fields: client.fields ?? [],
      hostnames: client.hostnames ?? [],
      vida: {
        displayUploadDate: client.vida?.displayUploadDate ?? client.display_upload_date ?? false,
        assetDefaultView: client?.vida?.assetDefaultView ?? 'table',
        tableDefaultView: client?.vida?.tableDefaultView ?? 'table',
        dateDefaultTimeZone: client?.vida?.dateDefaultTimeZone ?? 'local',
        sharedPacks: client?.vida?.sharedPacks ?? false,
        hideUsersList: client?.vida?.hideUsersList ?? false,
        newTranscode: client?.vida?.newTranscode ?? false,
        advancedCheckoutEnabled: client?.vida?.advancedCheckoutEnabled ?? false,
        aiProcessingCheckoutEnabled: client?.vida?.aiProcessingCheckoutEnabled ?? false,
        clientDiscountEnabled: client?.vida?.clientDiscountEnabled ?? false,
        advancedCheckoutDepartmentRequired: client?.vida?.advancedCheckoutDepartmentRequired ?? false,
        coldStorageEnabled: client?.vida?.coldStorageEnabled ?? false,
        ...client.vida,
      },
      assetView: client.assetView ?? {},
      search: {
        filters: client?.search?.filters ?? [],
      },
      library: {
        structure: client?.library?.structure ?? [],
      },
      libraryConfigs,
      filters: {
        ...client.filters,
        deleted,
      },
    });
  };

  findClients$ = (compareFn: (client: ClientModel) => boolean) => {
    return this.#allClients$.pipe(map((items) => items.filter(compareFn)));
  };

  update(clientId: ClientRef, client: Partial<ClientModel>) {
    return from(
      this.angularFirestore
        .collection<Partial<ClientModel>>(PersistenceEndpointsModel.CLIENTS_COLLECTION)
        .doc(clientId)
        .set(client, {
          merge: true,
        }),
    );
  }

  removeUsingPath(clientId: string, path: string) {
    return from(
      this.angularFirestore
        .collection<Partial<ClientModel>>(PersistenceEndpointsModel.CLIENTS_COLLECTION)
        .doc(clientId)
        .update({ [path]: firebase.firestore.FieldValue.delete() }),
    );
  }

  getSnapshotCollection(clientId: string): Observable<ClientModel[]> {
    return this.angularFirestore
      .collection(`/${PersistenceEndpointsModel.CLIENTS_COLLECTION}/${clientId}/snapshots`, (ref) =>
        ref.limit(25).orderBy('version', 'desc'),
      )
      .valueChanges() as Observable<ClientModel[]>;
  }

  removeSnapshotFromCollection(clientId: string, version: number): Observable<unknown> {
    return from(
      this.angularFirestore
        .collection(`/${PersistenceEndpointsModel.CLIENTS_COLLECTION}/${clientId}/snapshots`)
        .ref.where('version', '==', version)
        .get(),
    ).pipe(
      tap((querySnapshot: any) => {
        // todo: any - right now I can't find correct type
        querySnapshot.forEach((doc: any) => doc.ref.delete());
      }),
    );
  }

  getLatestSnapshotVersion(clientId: string): Observable<ClientModel> {
    return this.angularFirestore
      .collection<ClientModel>(`/${PersistenceEndpointsModel.CLIENTS_COLLECTION}/${clientId}/snapshots`, (ref) =>
        ref.limit(1).orderBy('version', 'desc'),
      )
      .valueChanges()
      .pipe(map((items) => items[0] ?? null));
  }

  #transformFields(clientModel: ClientModel): ClientModel {
    const assetView: Record<string, AssetViewConfig> = {};

    if (clientModel.assetView) {
      Object.entries(clientModel.assetView).forEach(([key, value]) => {
        assetView[key] = {
          ...value,
          fields: (value.fields ?? []).map((field) => ({
            ...field,
            definition: snakeCaseToCamel(field.definition),
          })),
        };
      });
    }

    let launchpad: LaunchpadSettings | undefined = undefined;

    if (clientModel.launchpad && clientModel.launchpad.defaultMetadata) {
      launchpad = {
        defaultMetadata:
          clientModel.launchpad.defaultMetadata && snakeCaseToCamel(clientModel.launchpad.defaultMetadata),
        quickViewMetadata: clientModel.launchpad?.quickViewMetadata?.map(snakeCaseToCamel),
        deliveryFieldsConfig: {
          grid: clientModel.launchpad.deliveryFieldsConfig.grid.map((field) => ({
            ...field,
            key: snakeCaseToCamel(field.key),
          })),
        },
        uploadFieldsConfig: clientModel.launchpad.uploadFieldsConfig.map((field) => ({
          ...field,
          key: snakeCaseToCamel(field.key),
        })),
      };
    }

    return {
      ...clientModel,
      assetView,
      launchpad,
      columnsConfig: {
        default: clientModel.columnsConfig.default.map((item) => this.#transformLegacy(item)).map(snakeCaseToCamel),
        available: clientModel.columnsConfig.available.map((item) => this.#transformLegacy(item)).map(snakeCaseToCamel),
      },
      fields: clientModel.fields.map((field) => ({
        ...field,
        id: snakeCaseToCamel(field.id),
      })),
      search: {
        filters: clientModel.search.filters.map(snakeCaseToCamel),
      },
    };
  }

  /** @description this map legacy open to actions, can be removed as soon as each client will be modified in admin panel */
  #transformLegacy = (item: FieldConfigId) => {
    if (item === 'open') {
      return 'actions';
    } else if (item === 'original_spoken_language') {
      return 'language';
    } else {
      return item;
    }
  };
}
