import { Component, Input } from '@angular/core';
import { AnyFileComponent } from '../any-file.component';

@Component({
  selector: 'vdms-hq-ui-img-support',
  templateUrl: './img-support.component.html',
  styleUrls: ['./img-support.component.scss', './../any-file.component.scss'],
})
export class ImgSupportComponent extends AnyFileComponent {
  static supportsExtensions = [
    // list taken from https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
    'bmp',
    'ico',
  ] as const;

  @Input() mode: 'fit-to-parent' | 'as-is' = 'as-is';

  onLoad() {
    this.loaded();
  }
}
