import { SelectOption } from '@vdms-hq/shared';
import { FieldDefinitionModel } from '../models/field-config.id';

export const excludeFromList = <T extends { id: ID }, ID = string>(list: T[], exclude: T[]): T[] => {
  return list.filter((rightItem) => !exclude.find((leftItem) => rightItem.id === leftItem.id));
};

export const pairIdsWithDefinitions = <T extends { id: ID }, ID = string>(ids: ID[], array: T[]): T[] => {
  return ids.map((id) => array.find((item) => item.id === id)).filter((item) => !!item) as T[];
};

export const definitionsAsSelectOption = <T extends FieldDefinitionModel>(definitions: T[]): SelectOption[] => {
  return definitions.map((item) => ({
    key: item.id,
    label: item.label,
  }));
};

export const definitionsAsSelectOptionWithId = <T extends FieldDefinitionModel>(definitions: T[]): SelectOption[] => {
  return definitions.map((item) => ({
    key: item.id,
    label: `${item.label} (${item.id})`,
  }));
};
