@if (loading) {
  <vdms-hq-ui-loader mode="default" [backdrop]="true" [centerPadding]="true"></vdms-hq-ui-loader>
} @else {
  <cdk-virtual-scroll-viewport
    #scrollViewport
    [itemSize]="88"
    minBufferPx="100"
    maxBufferPx="400"
    [style.height]="folders.length | calculateFoldersContainerHeight: perRow"
    class="virtual-scrolling-grid material-custom segments"
  >
    <div class="row" *cdkVirtualFor="let row of folderRows">
      <div class="segment" *ngFor="let folder of row" (click)="clickSegment.emit(folder)">
        <div class="segment__body" #segmentBody>
          @if (customIconPath) {
            <img [src]="customIconPath" alt="Folder icon" />
          } @else {
            <mat-icon class="icon">folder_open</mat-icon>
          }
          <div
            class="segment__content"
            [matTooltip]="
              folder.title !== undefined && !['null', '-1', 0].includes(folder.title.toString().toLowerCase())
                ? folder.title
                : '[ empty ]'
            "
          >
            @if (folders.length > 0) {
              <span
                class="title"
                [class.title--empty]="
                  folder.title === undefined || ['null', '-1'].includes(folder.title.toString().toLowerCase())
                "
                >{{
                  folder.title !== undefined && !['null', '-1', 0].includes(folder.title.toString().toLowerCase())
                    ? (folder.title.toString() | truncate: (segmentBody.offsetWidth - 50) * 0.1)
                    : '[ empty ]'
                }}</span
              >
            }
            @if (folder.subtitle) {
              <span class="subtitle">{{ folder.subtitle }}</span>
            }
          </div>
          @if (actions?.length) {
            <vdms-hq-ui-floating-controls-v2
              class="segment__body__controls"
              [item]="folder"
              [actions]="actions ?? []"
              (action)="emitAction($event.key, folder)"
            ></vdms-hq-ui-floating-controls-v2>
          }
        </div>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
}
