import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { SidenavState, SidenavStatusEnum, UISidenavConfig } from '../logic/models/sidenav.model';
import { BdcWalkPopupComponent } from 'bdc-walkthrough';
import { SidenavService } from '../logic/services/sidenav.service';
import { take } from 'rxjs/operators';
import { UI_CONFIG, UIConfig } from '../../models/configure-ui';

export interface TourConfig {
  [key: string]: BdcWalkPopupComponent;
}

@Component({
  selector: 'vdms-hq-ui-sidenav',
  templateUrl: './sidenav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UISidenavComponent implements OnInit {
  private uiConfig = inject<UIConfig>(UI_CONFIG);
  private sidenavService = inject(SidenavService);
  private cdr = inject(ChangeDetectorRef);
  state$ = this.sidenavService.sidenavStatus$;

  @Input() isLoading = false;
  @Input() isSalesForceEnabled = false;
  @Input() isExternalSupport = false;
  @Input() branding = true;
  version = this.uiConfig.appVersion;
  @Input() tourConfig: TourConfig = {};
  @Input() config: UISidenavConfig = [];

  open: (status?: SidenavStatusEnum) => void = this.sidenavService.openDrawer;
  close: () => void = this.sidenavService.closeDrawer;
  minimize: () => void = this.sidenavService.minimizeDrawer;

  ngOnInit() {
    this.state$.pipe(take(1)).subscribe((state) => {
      switch (state) {
        case SidenavStatusEnum.Expanded:
          this.open();
          break;
        case SidenavStatusEnum.Collapsed:
          this.minimize();
          break;
        case SidenavStatusEnum.Closed:
          this.close();
      }
      this.cdr.detectChanges();
    });
  }

  onMouseOver(state: SidenavState): void {
    if (state === SidenavStatusEnum.Expanded || state === SidenavStatusEnum.Hovered) {
      return;
    }
    this.open(SidenavStatusEnum.Hovered);
    this.cdr.detectChanges();
  }

  onMouseLeave(state: SidenavState): void {
    if (state === SidenavStatusEnum.Expanded) {
      return;
    }
    this.sidenavService.minimizeDrawer();
    this.cdr.detectChanges();
  }
}
