import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicInputComponent } from './components/dynamic-input/dynamic-input.component';
import { DynamicValueComponent } from './components/dynamic-value/dynamic-value.component';
import { UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { SelectorsModule } from '@vdms-hq/selectors';
import { DynamicValueFormatPipe } from './components/dynamic-value/dynamic-value-format.pipe';

const components = [DynamicFormComponent, DynamicInputComponent, DynamicValueComponent];

@NgModule({
  imports: [CommonModule, UIFormModule, TranslateModule, SelectorsModule],
  declarations: [...components, DynamicValueFormatPipe],
  exports: [components, DynamicValueFormatPipe],
})
export class DynamicFormModule {}
