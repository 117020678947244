import { Component, Inject, Input, Optional } from '@angular/core';
import { AuthMfaService, AuthService, ProviderType } from '@vdms-hq/auth';
import { LOGIN_PAGE_CONFIG, LoginPageConfig, ProvidersSettings } from '../../logic/config-tokens';
import { UserApiService } from '@vdms-hq/api-contract';
import { take } from 'rxjs/operators';
import { LoginWithProviderError } from '../../logic/provider.model';
import { MatDialog } from '@angular/material/dialog';
import { MfaCodeDialogComponent, MfaCodeDialogInput } from '../mfa-code-dialog/mfa-code-dialog.component';
import { MultiFactorError } from '@firebase/auth/dist/node-esm';
import { ToastService } from '@vdms-hq/toast';

@Component({
  selector: 'vdms-hq-provider-buttons',
  templateUrl: './provider-buttons.component.html',
  styleUrls: ['./provider-buttons.component.scss'],
})
export class ProviderButtonsComponent {
  loadingGoogle = false;
  loadingMicrosoft = false;
  loadingOkta = false;
  @Input() settings: ProvidersSettings;

  constructor(
    @Optional() @Inject(LOGIN_PAGE_CONFIG) private loginPageConfig: LoginPageConfig,
    private authService: AuthService,
    private mfaService: AuthMfaService,
    private apiService: UserApiService,
    private matDialog: MatDialog,
    private toast: ToastService,
  ) {
    this.settings = loginPageConfig?.providers ?? {
      microsoft: false,
      google: true,
      okta: false,
    };
  }

  async loginViaGoogle() {
    this.loadingGoogle = true;
    try {
      await this.authService.loginWithProvider('google');
    } catch (error) {
      await this.askForMfaCodeIfPossible(error as MultiFactorError);
      await this.linkIfPossibleAndRetry(error as LoginWithProviderError, 'google');
    }
    this.loadingGoogle = false;
  }

  async loginViaMicrosoft() {
    this.loadingMicrosoft = true;
    try {
      await this.authService.loginWithProvider('microsoft');
    } catch (error) {
      await this.askForMfaCodeIfPossible(error as MultiFactorError);
      await this.linkIfPossibleAndRetry(error as LoginWithProviderError, 'microsoft');
    }
    this.loadingMicrosoft = false;
  }

  async loginViaOkta() {
    this.loadingOkta = true;
    try {
      await this.authService.loginWithProvider('okta');
    } catch (error) {
      await this.askForMfaCodeIfPossible(error as MultiFactorError);
      await this.linkIfPossibleAndRetry(error as LoginWithProviderError, 'okta');
    }
    this.loadingOkta = false;
  }

  private async askForMfaCodeIfPossible(error: MultiFactorError) {
    if (error.code === 'auth/multi-factor-auth-required') {
      this.matDialog.open<MfaCodeDialogComponent, MfaCodeDialogInput>(MfaCodeDialogComponent, { data: { error } });
    }
  }

  private async linkIfPossibleAndRetry(error: LoginWithProviderError, provider: ProviderType) {
    if (error.code === 'auth/account-exists-with-different-credential') {
      this.apiService
        .linkAccount({
          token: error.credential.idToken,
          provider: error.credential.providerId,
        })
        .pipe(take(1))
        .subscribe(() => {
          return this.authService.loginWithProvider(provider);
        });
    }
  }
}
