<vdms-hq-ui-dialog-wrapper size="wide">
  <ng-container title>
    <h1>{{ 'common.filters_config_dialog.header' | translate }}</h1>
    <p>{{ 'common.filters_config_dialog.short_desc' | translate }}</p>
  </ng-container>
  <form content [formGroup]="formGroup" class="list">
    <vdms-hq-ui-form-sortable-checkbox-list
      formControlName="listSortableCheckboxValue"
      [columns]="4"
      [available]="availableList"
      [alwaysEnabled]="alwaysEnabledList"
    >
    </vdms-hq-ui-form-sortable-checkbox-list>
  </form>

  <ng-container footer>
    <div class="buttons-group">
      <vdms-hq-ui-button (click)="revertDefaults()" color="secondary" [disabled]="!isUserConfig"
        >{{ 'common.global.revert_defaults' | translate }}
      </vdms-hq-ui-button>
      <vdms-hq-ui-button (click)="deselectAll()" color="secondary"
        >{{ 'common.global.deselect_all' | translate }}
      </vdms-hq-ui-button>
      <vdms-hq-ui-button (click)="selectAll()" color="secondary"
        >{{ 'common.global.select_all' | translate }}
      </vdms-hq-ui-button>
    </div>
    <div class="buttons-group">
      <vdms-hq-ui-button (click)="cancel()" color="secondary"
        >{{ 'common.global.cancel' | translate }}
      </vdms-hq-ui-button>
      <vdms-hq-ui-button
        (click)="onSubmit()"
        [disabled]="!listSortableCheckboxValues?.value?.length || (isSaving$ | async) === true"
        [loading]="(isSaving$ | async) === true"
        color="primary"
        >{{ 'common.global.save' | translate }}
      </vdms-hq-ui-button>
    </div>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
