import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AssetActionsService,
  assetResultsMenuConfig2,
  ContextMenuActionsService,
  ResultsActions,
} from '@vdms-hq/asset-results';
import { CartAssetViewModel } from '@vdms-hq/cart-core';
import { CollectionsActionsService } from '@vdms-hq/collections';
import { ActionContextLess, DataAction } from '@vdms-hq/ui';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CartDataSource } from '../../logic/data-sources/cart-data-source';
import { CartActionsService } from '../../logic/services/cart-actions.service';
import { CartSubmitService } from '../../logic/services/cart-submit.service';
import { TABLE_TYPE } from '@vdms-hq/view-settings';

import { FieldsScopeKey } from '@vdms-hq/fields';

@Component({
  selector: 'vdms-hq-cart',
  templateUrl: './cart.component.html',
})
export class CartComponent implements OnDestroy, OnInit {
  protected readonly assetResultsMenuConfig2 = assetResultsMenuConfig2;
  protected readonly TABLE_TYPE = TABLE_TYPE;
  readonly scopeName: FieldsScopeKey = 'cart';

  actions$: Observable<DataAction<CartAssetViewModel>[]> = combineLatest([
    this.collectionsActionsService.buildAddAssetToCollectionAction$<CartAssetViewModel>(),
    this.cartActionsService.buildRemoveAction$<CartAssetViewModel>(),
    this.assetActionsService.buildPreviewAction$<CartAssetViewModel>(),
    this.assetActionsService.buildQAResultsAction(),
  ]).pipe(map((actions) => actions.filter((action): action is DataAction<CartAssetViewModel> => action !== null)));

  headerActions$: Observable<ActionContextLess[]> = combineLatest([
    this.collectionsActionsService.buildAddCartCollection$(),
  ]).pipe(map((actions) => actions.filter((action): action is ActionContextLess => action !== null)));

  constructor(
    public dataSource: CartDataSource,
    private cartSubmitService: CartSubmitService,
    private collectionsActionsService: CollectionsActionsService,
    private cartActionsService: CartActionsService,
    private assetActionsService: AssetActionsService,
    private contextMenuActions: ContextMenuActionsService,
  ) {}

  ngOnInit() {
    this.dataSource.changePageIndex$.next(0);
  }

  ngOnDestroy() {
    this.dataSource.selection.clear();
  }

  handleFooterActions($event: ResultsActions) {
    if (!$event) {
      return;
    }

    this.handleAction({ key: $event });
  }

  handleAction($event: { key: string; item?: CartAssetViewModel }) {
    this.assetActionsService.handlePreviewAction<CartAssetViewModel>($event);
    this.collectionsActionsService.handleAddAssetToCollectionAction<CartAssetViewModel>($event);
    this.cartActionsService.handleRemoveAction<CartAssetViewModel>($event);
    this.collectionsActionsService.handleAddCartCollection($event);
    this.contextMenuActions.handleAction($event);
    this.assetActionsService.handleQAResultsAction($event);

    switch ($event.key) {
      case 'submit_cart':
        this.cartSubmitService.submitCart();
        break;
      case 'add_to_collection':
        this.dataSource.selection.entities$
          .pipe(
            take(1),
            map((cartEntities) => cartEntities.map((cartEntity) => cartEntity.props.uuid)),
          )
          .subscribe((assetUuids) => {
            this.collectionsActionsService.addAssetsToCollection(assetUuids);
          });
        break;
      case 'remove_all':
        this.cartActionsService.removeAllItems();
        break;
      case 'delete':
        this.cartActionsService.removeCartItems(this.dataSource.selection.identifiers$.value.map(String));
        break;
      case 'deselect_all':
        this.dataSource.selection.clear();
        break;
    }
  }
}
