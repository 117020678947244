import { Injectable } from '@angular/core';
import { AnalyticsLogActionEnum, CollectionsService, GetCollectionAnalytics } from '@vdms-hq/api-contract';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  Observable,
  switchMap,
  shareReplay,
  filter,
  take,
  EMPTY,
} from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { SingleCollectionResolverService } from './single-collection-resolver.service';
import { AuthService } from '@vdms-hq/auth';
import { CollectionMetadataTransformerSnippet } from '../utils/collection.metadata-transformer';
import { CollectionsRefresh } from '@vdms-hq/collections-public';
import { AssetActionsService } from '@vdms-hq/asset-results';
import { ClientAccessChecker } from '@vdms-hq/clients';
import { Router } from '@angular/router';
import { SingleCollectionViewModel } from '../models/collection-view.model';
import { StatsChartViewModelV2, StatsConfig, StatsType } from '@vdms-hq/ui';
import { StatsCounterViewModel } from '../../../../../ui/src/lib/ui-stats/stats-counter/stats-counter.component';
import moment from 'moment/moment';

@Injectable({ providedIn: 'root' })
export class SingleCollectionService {
  isLoading$ = new BehaviorSubject<boolean>(true);

  #uuid$ = new BehaviorSubject<string | null>(null);
  uuid$ = this.#uuid$.asObservable().pipe(filter(Boolean));
  userAuth$ = this.authService.authDefinite$;
  refresh$ = this.collectionsRefresher.refreshAssets$;
  total$ = new BehaviorSubject<number>(0);

  collectionData$: Observable<SingleCollectionViewModel> = combineLatest([
    this.uuid$,
    this.refresh$,
    this.assetActions.finishBatchUpdate$,
  ]).pipe(
    filter(([uuid]) => !!uuid),
    withLatestFrom(this.userAuth$),
    tap(() => this.isLoading$.next(true)),
    switchMap(([[uuid], userData]) => {
      const userId = userData.id;
      if (!this.#uuid$.value) {
        return EMPTY;
      }

      return this.collectionsService.getCollectionData(uuid).pipe(
        map((data) => this.#collectionToViewModel(data, userId)),
        map((data: SingleCollectionViewModel) => {
          this.total$.next(data.number_of_assets);
          data.children = data.children.map((child) => this.#collectionToViewModel(child, <string>userId));
          return data;
        }),
        tap(() => this.isLoading$.next(false)),
        catchError((err) => {
          if (err.status === 404) {
            this.router.navigate(['/error/404']);
          }

          if (err.error?.error === 'Invalid group') {
            return this.clientAccessChecker.checkAccess({ clientId: err.error.data }).pipe(
              take(1),
              tap(() => this.refresh$.next(true)),
              switchMap(() => EMPTY),
            );
          }
          throw err;
        }),
      );
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  subAllData$ = combineLatest([this.refresh$, this.collectionData$]).pipe(
    map(([, collectionData]) => {
      return collectionData.children;
    }),
  );

  subCollectionsOnlyView$ = combineLatest([this.total$, this.subAllData$]).pipe(
    map(([assets, subCollections]) => {
      const assetsEmpty = !assets;
      const subCollectionsEmpty = !subCollections.length;

      return assetsEmpty && !subCollectionsEmpty;
    }),
  );

  analytics$ = this.uuid$.pipe(
    switchMap((uuid) => {
      return this.collectionsService.getCollectionAnalytics(uuid);
    }),
    map((collectionAnalytics) => {
      const viewDifference = collectionAnalytics.viewsCount30Days - collectionAnalytics.viewsCountPrevious30Days;
      const counterConfig: StatsConfig = {
        type: StatsType.COUNTER,
        title: 'Total views for the last 30 days',
        icon: 'preview',
        viewModel: {
          number: collectionAnalytics.viewsCount30Days,
          numberSubtitle: collectionAnalytics.viewsCount30Days > 1 ? 'Views' : 'View',
          text:
            viewDifference != 0
              ? `${Math.abs(viewDifference)} ${viewDifference > 0 ? 'more' : 'less'} than last 30 days`
              : 'No difference from last 30 days',
          arrow: viewDifference == 0 ? false : viewDifference > 0 ? 'up' : 'down',
        } as StatsCounterViewModel,
      };

      const lineChart = StatsChartViewModelV2.createLineChart(
        collectionAnalytics.chart.chartDataSets.map((ds) => ds.data),
        collectionAnalytics.chart.labels,
      );
      const lineConfig = new StatsConfig(StatsType.CHART_LINE, 'Daily views stats', 'upload', lineChart);

      const gridConfig = {
        type: StatsType.GRID,
        title: 'Latest views log',
        icon: 'person',
        viewModel: {
          columnsHeaders: ['Timestamp', 'User name', 'Email address'],
          rows: collectionAnalytics.logs.map((log) => ({
            fields: [
              {
                value: moment(log.logDate).format('YYYY-MM-DD HH:mm:ss'),
              },
              {
                value: log.user.name,
              },
              {
                value: log.user.email,
              },
            ],
          })),
        },
      };

      return {
        gridConfig,
        lineConfig,
        counterConfig,
      };
    }),
  );

  constructor(
    private collectionIdParamResolver: SingleCollectionResolverService,
    private collectionsService: CollectionsService,
    private authService: AuthService,
    private collectionsRefresher: CollectionsRefresh,
    private metadataTransformer: CollectionMetadataTransformerSnippet,
    private assetActions: AssetActionsService,
    private clientAccessChecker: ClientAccessChecker,
    private router: Router,
  ) {}

  destroy() {
    this.#uuid$.next(null);
    this.collectionIdParamResolver.currentId$.next(null);
  }

  setUuid(uuid: string) {
    this.#uuid$.next(uuid);
  }

  #collectionToViewModel(collection: any, userId: string): any {
    return this.metadataTransformer.transformCollectionWithMetadata(collection, userId);
  }

  getIcon(logType: AnalyticsLogActionEnum) {
    switch (logType) {
      case AnalyticsLogActionEnum.VIEW:
        return { key: 'visibility' };
      case AnalyticsLogActionEnum.CREATE:
        return { key: 'edit' };
      default:
        return { key: 'info', circular: true };
    }
  }
}
