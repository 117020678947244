import { inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '@vdms-hq/activated-client';
import {
  AssetSearchFilters,
  CollectionsService,
  filterStreamMsgFactory,
  mapToProgressToastMessage,
  PaginationAPIProps,
  ProgressStreamService,
  SimpleType,
  WebsocketAnyNotificationMessage,
  WebsocketNotificationActionEnum,
} from '@vdms-hq/api-contract';
import { AssetFlatView2Model } from '@vdms-hq/asset-results';
import { Permission } from '@vdms-hq/firebase-contract';
import { ImageCacheService, RefreshService } from '@vdms-hq/shared';
import { generateProgressToastMessage, progressToast, ToastService } from '@vdms-hq/toast';
import { ActionContextLess, BreadCrumb, DataAction, DialogResponse, UIConfirmationDialogService } from '@vdms-hq/ui';
import {
  catchError,
  combineLatest,
  EMPTY,
  filter,
  forkJoin,
  from,
  map,
  Observable,
  ObservableInput,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  AddToCollectionInput,
  AddToCollectionResponse,
  CollectionAddDialogComponent,
} from '../../components/collection-add-dialog/collection-add.component';
import { CollectionCreateDialogComponent } from '../../components/collection-create-dialog/collection-create-dialog.component';
import {
  CollectionCreateShareFolderDialogComponent,
  CreateShareFolderInput,
  CreateShareFolderResponse,
} from '../../components/collection-create-share-folder/collection-create-share-folder.component';
import {
  CollectionEditDialogComponent,
  CollectionEditDialogResponse,
} from '../../components/collection-edit-dialog/collection-edit-dialog.component';
import { CollectionsAddDataSource } from '../datasources/collections-add-data-source';
import { CollectionsMultiDs } from '../datasources/collections-multi-ds.service';
import { CollectionUploadStatusEnum } from '../models/statuses-type.model';
import { CollectionsRefresh } from '@vdms-hq/collections-public';
import { SingleCollectionImportService } from './single-collection-import.service';
import { SingleCollectionResolverService } from './single-collection-resolver.service';
import { SingleCollectionService } from './single-collection.service';
import { AddToTeamsResponse, TeamsAddDialogComponent } from '@vdms-hq/teams-shared';
import { COLLECTIONS_ROUTER_BASE } from '../../pages/collections-routes';
import { Router } from '@angular/router';
import { UsersShareDialogComponent } from '@vdms-hq/users';
import { FlatCollectionViewModel, SingleCollectionViewModel } from '../models/collection-view.model';
import { ResultDefinitionModel } from '@vdms-hq/fields';

@Injectable({ providedIn: 'root' })
export class CollectionsActionsService implements OnDestroy {
  private matDialog = inject(MatDialog);
  private collectionsService = inject(CollectionsService);
  private collectionsRefresher = inject(CollectionsRefresh);
  private refreshService = inject(RefreshService);
  private toastService = inject(ToastService);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private translate = inject(TranslateService);
  private collectionsUploadService = inject(SingleCollectionImportService);
  private streamService = inject(ProgressStreamService);
  private imageCache = inject(ImageCacheService);
  private permissionService = inject(PermissionService);
  private collectionIdParamResolver = inject(SingleCollectionResolverService);
  public dataSource = inject(CollectionsMultiDs);
  public dataSourceSingle = inject(SingleCollectionService);
  public addDataSource = inject(CollectionsAddDataSource);
  public router = inject(Router);

  private destroyed$ = new Subject<void>();
  private currentCollectionAccessType$ = this.collectionIdParamResolver.currentIdDefinite$.pipe(
    switchMap((uuid) => this.collectionsService.getCollectionAccessType([uuid])),
    map(([{ access_type }]) => ({ access_type })),
  );

  readonly #ACTION_KEY = {
    ADD_CART_TO_COLLECTION: 'collection.add_cart',
    ADD_ASSETS_TO_COLLECTION: 'collection.add_assets',
  };

  popToast = {
    CREATE_SUCCESS: () =>
      this.toastService.success({
        id: 'create_field_success',
        message: 'common.notifications.collections.create.success',
      }),
    UPDATE_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.update.success',
      }),
    ADD_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.add.success',
      }),
    ADD_FOLDER_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.add_folder.success',
      }),
    REMOVE_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.remove.success',
      }),
    REMOVE_ASSET_FAILURE: () =>
      this.toastService.error({
        id: 'update_field_failure',
        message: 'common.notifications.collections.remove_asset.failure',
      }),
    REMOVE_ASSETS_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.remove_assets.success',
      }),
    REMOVE_ASSETS_FAILURE: () =>
      this.toastService.error({
        id: 'update_field_failure',
        message: 'common.notifications.collections.remove_assets.failure',
      }),
    DELETE_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.delete.success',
      }),
    DELETE_FAILURE: () =>
      this.toastService.error({
        id: 'update_field_failure',
        message: 'common.notifications.collections.delete.failure',
      }),
    DELETE_OWNED_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.delete.success_owned',
      }),
    DELETE_OWNED_FAILURE: () =>
      this.toastService.error({
        id: 'update_field_failure',
        message: 'common.notifications.collections.delete.failure_owned',
      }),
    EXPORT_SUCCESS: () =>
      this.toastService.success({
        id: 'collection_export_success',
        message: 'common.notifications.collections.export.success',
      }),
    IMPORT_SUCCESS: () =>
      this.toastService.success({
        id: 'import_success',
        message: 'common.notifications.collections.import.success',
      }),
    SHARE_SUCCESS: () =>
      this.toastService.success({
        id: 'update_field_success',
        message: 'common.notifications.collections.share.success',
      }),

    WS_ADD_TO_COLLECTION_PROGRESS: (
      id: string,
      percent: number,
      counter?: { processing: number; all: number; errors: WebsocketAnyNotificationMessage },
    ) =>
      this.toastService.processing({
        id,
        message: generateProgressToastMessage(
          'Collection updating',
          percent,
          this.imageCache.getImage('assets/common/asset_fallbacks/info.svg'),
          counter,
        ),
      }),
    WS_ADD_TO_COLLECTION_SUCCESS: (id: string) =>
      this.toastService.success({
        id,
        message: `Collection updated`,
      }),
    WS_ADD_TO_COLLECTION_ERROR: (id: string) =>
      this.toastService.error({
        id,
        message: `Collection update error`,
      }),
  };
  readonly connect$ = this.streamService.connect();
  isBrowse = false;

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  buildEditCollectionAction$<T extends AssetFlatView2Model = AssetFlatView2Model>(): Observable<ActionContextLess> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_COLLECTIONS]),
      this.currentCollectionAccessType$,
    ]).pipe(
      map(([hasPermission, collection]) => ({
        key: 'collection.edit',
        label: 'common.global.edit',
        icon: 'edit',
        color: 'secondary',
        hiddenIf: () => !hasPermission || !['owned'].includes(collection.access_type),
      })),
    );
  }

  buildExportMetadataAction$<T extends AssetFlatView2Model = AssetFlatView2Model>(): Observable<DataAction<T>> {
    return combineLatest([this.dataSourceSingle.collectionData$]).pipe(
      map(([collection]) => {
        return {
          key: 'collection.export_csv',
          label: 'common.import_export.export_button',
          icon: 'forward_to_inbox',
          color: 'primary',
          disabled: collection?.number_of_assets === 0,
          iconOnly: true,
        };
      }),
    );
  }

  buildDeleteAssetFromCollectionAction$<T extends AssetFlatView2Model = AssetFlatView2Model>(
    additionalCondition = false,
  ): Observable<DataAction<T>> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_COLLECTIONS]).pipe(
      take(1),
      map((hasPermission) => ({
        key: 'asset.delete',
        label: 'common.global.remove_from_collection',
        icon: 'delete',
        hiddenIf: () => !hasPermission || additionalCondition,
      })),
    );
  }

  buildAddToCollectionAction$<T extends AssetFlatView2Model = AssetFlatView2Model>(
    additionalCondition = false,
  ): Observable<DataAction<T>> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_COLLECTIONS]).pipe(
      take(1),
      map((hasPermission) => ({
        key: 'asset.collection',
        label: 'common.global.add_to_collection',
        icon: 'playlist_add',
        hiddenIf: () => !hasPermission || additionalCondition,
      })),
    );
  }

  openCreateDialog(name = '', createFromShare = false) {
    const dialogRef = this.matDialog.open(CollectionCreateDialogComponent, { data: { name: name } });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((dialogResponse) => !!dialogResponse),
        switchMap((dialogResponse) => {
          if (dialogResponse.action === 'import') {
            return from(this.collectionsUploadService.confirmUpload(dialogResponse.uploadParams)).pipe(
              tap((response) => {
                if (response.status === CollectionUploadStatusEnum.SUCCESS) {
                  this.popToast.IMPORT_SUCCESS();
                }
              }),
            );
          }

          if (dialogResponse.action === 'create') {
            dialogResponse.collection.uuid = uuidv4();
            return this.collectionsService.create(dialogResponse.collection).pipe(
              tap(() => {
                this.popToast.CREATE_SUCCESS();
                this.refreshService.refresh();
                this.addDataSource.refresh$.next(true);
                this.collectionsRefresher.addToCollection$.next(true);
                if (createFromShare) {
                  this.addDataSource.filters.controls.keyword.setValue(dialogResponse.collection.name);
                }
              }),
            );
          }
          return EMPTY;
        }),
      )
      .subscribe();
  }

  openEditDialog(collection: SingleCollectionViewModel | FlatCollectionViewModel, singleView = false) {
    const dialogRef = this.matDialog.open(CollectionEditDialogComponent, { data: { collection: collection } });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap((updated) => {
          if (updated.status === CollectionEditDialogResponse.SUCCESS) {
            this.dataSource.refresh();
            if (singleView) {
              this.router.navigate([COLLECTIONS_ROUTER_BASE.COLLECTIONS]);
            }
            this.popToast.UPDATE_SUCCESS();
          }
        }),
      )
      .subscribe();
  }

  openShareDialog({ collection }: { collection: FlatCollectionViewModel | SingleCollectionViewModel }): void {
    const dialogRef = this.matDialog.open(UsersShareDialogComponent, {
      data: {
        collectionUuid: collection.uuid,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((dialogResponse) => !!dialogResponse),
        switchMap((dialogResponse) => {
          return this.collectionsService
            .update(collection.uuid, {
              users: dialogResponse.userIds,
            })
            .pipe(
              tap(() => this.popToast.SHARE_SUCCESS()),
              tap(() => this.dataSource.refresh()),
            );
        }),
      )
      .subscribe();
  }

  addToCollection(collectionUuid: string) {
    const dialogData: AddToCollectionInput = {
      collectionUuid: collectionUuid,
    };

    const dialogRef = this.matDialog.open(CollectionAddDialogComponent, { data: dialogData });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((dialogResponse: AddToCollectionResponse) => !!dialogResponse),
        switchMap((dialogResponse) => {
          const parentIds = dialogResponse.selected.map((collection) => ({ uuid: collection, attached: true }));
          const selectedCollections = { parentIds: parentIds };
          return this.collectionsService.update(collectionUuid, selectedCollections).pipe(
            tap(() => this.popToast.ADD_SUCCESS()),
            tap(() => this.dataSource.refresh()),
          );
        }),
      )
      .subscribe();
  }

  addFolderAssetsToCollection(folderQuery: AssetSearchFilters) {
    let submitted = false;
    const dialogRef = this.matDialog.open(CollectionAddDialogComponent, { data: { selectedIds: [] } });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((dialogResponse: AddToCollectionResponse) => !!dialogResponse),
        switchMap((dialogResponse) => {
          if (dialogResponse.status === DialogResponse.OK) {
            submitted = true;
          }

          return this.collectionsService
            .addItemsFromFolder({ query: { text: '', filters: folderQuery }, collections: dialogResponse.selected })
            .pipe(
              tap(() => {
                this.popToast.ADD_FOLDER_SUCCESS();
                this.refreshService.refresh();
                this.dataSource.refresh();
              }),
            );
        }),
      )
      .subscribe(() => {
        if (submitted) {
          this.collectionsRefresher.clearSelection$.next(true);
        }
      });
  }

  createCollectionFromFolder(
    folderQuery: AssetSearchFilters,
    breadcrumbs: BreadCrumb[],
    userEmail: string,
    numberOfAssets: number,
  ) {
    const dialogData: CreateShareFolderInput = {
      folderQuery: folderQuery,
      breadcrumbs: breadcrumbs,
      userEmail: userEmail,
      numberOfAssets: numberOfAssets,
    };

    let submitted = false;
    const dialogRef = this.matDialog.open(CollectionCreateShareFolderDialogComponent, { data: dialogData });

    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((dialogResponse: CreateShareFolderResponse) => !!dialogResponse),
        switchMap((dialogResponse) => {
          if (dialogResponse.status === DialogResponse.OK) {
            return this.collectionsService
              .addItemsFromFolder({
                query: { text: '', filters: folderQuery },
                collections: [],
                collection_name: dialogResponse.collection_name,
                users: dialogResponse.users,
              })
              .pipe(
                tap(() => {
                  this.popToast.ADD_FOLDER_SUCCESS();
                  this.dataSource.refresh();
                  this.refreshService.refresh();
                  submitted = true;
                }),
              );
          } else {
            return EMPTY;
          }
        }),
      )
      .subscribe(() => {
        if (submitted) {
          this.collectionsRefresher.clearSelection$.next(true);
        }
      });
  }

  addAssetsToCollection(
    assetIds: string[],
    assetCollections: SimpleType[] | undefined = undefined,
    collection?: FlatCollectionViewModel,
    browse = false,
  ) {
    this.addAssetsToCollection$(assetIds, assetCollections, collection, browse).subscribe();
  }

  addAssetsToCollection$(
    assetIds: string[],
    assetCollections: SimpleType[] | undefined = undefined,
    collection?: FlatCollectionViewModel,
    browse = false,
  ) {
    this.isBrowse = browse;
    const dialogData: AddToCollectionInput = {
      selectedIds: [],
      selectedSimpleTypes: assetCollections ?? [],
      collectionUuid: collection?.uuid,
    };
    const assetsTmpObj: { asset_uuid?: string; item_uuid?: string; type?: string }[] = [];
    if (assetIds.length === 1 && assetIds[0] === 'cart2collection') {
      assetsTmpObj.push({
        type: 'cart',
      });
      assetIds = [];
    }
    if (collection && !assetCollections) {
      const selectedItems = collection.items?.filter((item) => assetIds.includes(item.asset_uuid));
      selectedItems?.forEach((item) => {
        if (!assetsTmpObj.find((asset) => asset.asset_uuid === item.asset_uuid)) {
          assetsTmpObj.push({ asset_uuid: item.asset_uuid, item_uuid: item.item_uuid });
        }
        item.collections?.forEach((collection) => {
          if (!dialogData.selectedIds?.includes(collection.uuid)) {
            dialogData.selectedIds?.push(collection.uuid);
          }
        });
      });
    } else if (assetCollections) {
      assetCollections.forEach((collection) => {
        if (!dialogData.selectedIds?.includes(collection.uuid)) {
          dialogData.selectedIds?.push(collection.uuid);
        }
      });
    }

    assetIds.forEach((assetUuid) => {
      if (!assetsTmpObj.find((asset) => asset.asset_uuid === assetUuid)) {
        assetsTmpObj.push({ asset_uuid: assetUuid });
      }
      if (dialogData.selectedIds && !assetCollections && !dialogData.selectedIds.find((asset) => asset === assetUuid)) {
        dialogData.selectedIds.push(assetUuid);
      }
    });

    let submitted = false;

    const dialogRef = this.matDialog.open(CollectionAddDialogComponent, { data: dialogData });
    return dialogRef.afterClosed().pipe(
      take(1),
      filter((dialogResponse: AddToCollectionResponse) => !!dialogResponse),
      switchMap((dialogResponse) => {
        if (dialogResponse.status === DialogResponse.OK) {
          submitted = true;
        }

        const itemsArray$: ObservableInput<any>[] = [];

        dialogResponse.selected.forEach((collection) => {
          if (assetsTmpObj.length === 1 && assetsTmpObj[0].type === 'cart') {
            const itemsCart$ = this.collectionsService.addItemsFromCart(collection).pipe(take(1));
            itemsArray$.push(itemsCart$);
          } else {
            const items$ = this.collectionsService
              .addItems(collection, {
                items: assetsTmpObj.map((item) => ({
                  asset_uuid: item.asset_uuid,
                  item_uuid: item.item_uuid ?? uuidv4(),
                })),
              })
              .pipe(take(1));
            itemsArray$.push(items$);
          }
        });

        return forkJoin(itemsArray$);
      }),
      tap(() => {
        if (submitted) {
          this.collectionsRefresher.clearSelection$.next(true);
        }
      }),
    );
  }

  removeSubCollection(collection: SingleCollectionViewModel, parentCollectionUuid: string) {
    this.confirmationDialog
      .open({
        title: this.translate.instant('common.notifications.collections.remove.title') + collection.name,
        message: 'common.notifications.collections.remove.question',
      })
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() =>
          this.collectionsService
            .deleteSubcollection(parentCollectionUuid, { collection_uuids: [collection.uuid] })
            .pipe(
              tap(() => {
                this.popToast.REMOVE_SUCCESS();
                this.collectionsRefresher.refreshAssets$.next(true);
                this.collectionsRefresher.refreshSubCollectionsPagination$.next(true);
              }),
            ),
        ),
      )
      .subscribe();
  }

  exportCollection(uuid: string, fields?: ResultDefinitionModel[], pagination?: PaginationAPIProps) {
    return this.collectionsService.export(uuid, fields, pagination).pipe(
      takeUntil(this.destroyed$),
      tap({
        complete: () => {
          this.popToast.EXPORT_SUCCESS();
        },
      }),
    );
  }

  deleteCollection(uuid: string, name: string, owned: boolean) {
    this.confirmationDialog
      .openDelete({
        title:
          this.translate.instant(
            owned
              ? 'common.notifications.collections.delete.title_owned'
              : 'common.notifications.collections.delete.title',
          ) + name,
        message: owned
          ? 'common.notifications.collections.delete.question_owned'
          : 'common.notifications.collections.delete.question',
      })
      .pipe(
        take(1),
        filter(Boolean),
        tap(() => this.dataSource.isLoading$.next(true)),
        switchMap(() =>
          this.collectionsService.delete(uuid).pipe(
            tap(() => this.dataSource.refresh()),
            tap(() => (owned ? this.popToast.DELETE_OWNED_SUCCESS() : this.popToast.DELETE_SUCCESS())),
            catchError((error) => {
              owned ? this.popToast.DELETE_OWNED_FAILURE() : this.popToast.DELETE_FAILURE();
              throw error;
            }),
          ),
        ),
      )
      .subscribe(() => {
        this.collectionsRefresher.clearSelection$.next(true);
      });
  }

  removeAssets(collectionUuid: string, assetIds: string[]) {
    this.confirmationDialog
      .openDelete({
        title:
          assetIds.length > 1
            ? 'common.notifications.collections.remove_assets.title'
            : 'common.notifications.collections.remove_asset.title',
        message:
          assetIds.length > 1
            ? this.translate.instant('common.notifications.collections.remove_assets.question', {
                count: assetIds.length,
              })
            : 'common.notifications.collections.remove_asset.question',
        okAction: {
          label: 'common.global.remove',
          color: 'warn',
        },
      })
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() =>
          this.collectionsService.removeItems(collectionUuid, assetIds).pipe(
            catchError((error) => {
              assetIds.length > 1 ? this.popToast.REMOVE_ASSETS_FAILURE() : this.popToast.REMOVE_ASSET_FAILURE();
              throw error;
            }),
          ),
        ),
      )
      .subscribe(() => {
        this.collectionsRefresher.clearSelection$.next(true);
      });
  }

  registerWebSocketListener() {
    this.#initToastElement();

    return this.connect$.pipe(
      filterStreamMsgFactory([
        WebsocketNotificationActionEnum.COLLECTION_REMOVE,
        WebsocketNotificationActionEnum.COLLECTION_ADD,
      ]),
      mapToProgressToastMessage(),
      progressToast(
        {
          SUCCESS: this.popToast.WS_ADD_TO_COLLECTION_SUCCESS,
          PROGRESS: this.popToast.WS_ADD_TO_COLLECTION_PROGRESS,
          ERROR: this.popToast.WS_ADD_TO_COLLECTION_ERROR,
        },
        () => this.#collectionRefresh(),
      ),
    );
  }

  addOrderAssetsToCollections(selectedIds: string[], orderUuid: string) {
    return this.matDialog
      .open(CollectionAddDialogComponent, {
        data: {
          selectedIds,
        },
      })
      .afterClosed()
      .pipe(
        filter((data) => !!data?.selected?.length),
        switchMap(({ selected }) => {
          const collections$ = selected.map((collectionUuid: string) =>
            this.collectionsService.addAssetsFromOrder(orderUuid, collectionUuid),
          );
          return combineLatest(collections$);
        }),
      );
  }

  buildAddCartCollection$(): Observable<ActionContextLess | null> {
    return combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_COLLECTIONS]),
      this.currentCollectionAccessType$,
    ]).pipe(
      map(([hasAccess, collection]) =>
        hasAccess
          ? {
              key: this.#ACTION_KEY.ADD_CART_TO_COLLECTION,
              label: 'common.global.add_to_collection',
              hiddenIf: () => collection.access_type === 'dashboard',
            }
          : null,
      ),
    );
  }

  handleAddCartCollection($event: { key: string }): void {
    if ($event.key !== this.#ACTION_KEY.ADD_CART_TO_COLLECTION) {
      return;
    }

    this.#addCartAssetsToCollections().subscribe();
  }

  buildAddAssetToCollectionAction$<T extends AssetFlatView2Model = AssetFlatView2Model>(): Observable<DataAction<T>> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_COLLECTIONS]).pipe(
      take(1),
      map((canEditCollections) =>
        canEditCollections
          ? ({
              key: this.#ACTION_KEY.ADD_ASSETS_TO_COLLECTION,
              label: 'common.global.add_to_collection',
              icon: 'playlist_add',
              onDoubleClick: false,
              disabledIf: (item: T) => !item.canAddToCollection,
            } as DataAction<T>)
          : ({} as DataAction<T>),
      ),
    );
  }

  handleAddAssetToCollectionAction<T extends AssetFlatView2Model = AssetFlatView2Model>($event: {
    key: string;
    item?: T;
  }): void {
    if ($event.key !== this.#ACTION_KEY.ADD_ASSETS_TO_COLLECTION || !$event.item) {
      return;
    }
    this.addAssetsToCollection([$event.item.props.uuid], $event.item.props?.collections ?? undefined);
  }

  #collectionRefresh() {
    this.collectionsRefresher.addToCollection$.next(true);
    this.collectionsRefresher.refreshAssets$.next(true);
    this.refreshService.refresh();
  }

  #initToastElement() {
    this.imageCache.cacheImage('assets/common/asset_fallbacks/info.svg');
  }

  #addCartAssetsToCollections() {
    return this.matDialog
      .open(CollectionAddDialogComponent)
      .afterClosed()
      .pipe(
        take(1),
        filter((data) => !!data?.selected?.length),
        switchMap(({ selected }) => {
          const collections$ = selected.map((collectionUuid: string) =>
            this.collectionsService.addAssetsFromCart(collectionUuid),
          );
          return combineLatest(collections$);
        }),
      );
  }

  addCollectionToTeams(
    uuid: string,
    collectionName: string,
    shared_to_teams?: {
      name: string;
      uuid: string;
    }[],
  ) {
    return this.matDialog
      .open(TeamsAddDialogComponent, {
        data: {
          collectionUuid: uuid,
          shared_to_teams,
          collectionName,
        },
      })
      .afterClosed()
      .pipe(filter((dialogResponse: AddToTeamsResponse) => !!dialogResponse))
      .subscribe((response) => {
        if (response.status === DialogResponse.OK) {
          this.refreshService.refresh();
        }
      });
  }

  open(collectionId: string) {
    this.router.navigate(['/', COLLECTIONS_ROUTER_BASE.COLLECTIONS, collectionId]);
  }
}
