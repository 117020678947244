import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { UIDialogWrapperModule } from '../../../ui-dialog-wrapper';
import { PortalsToRender } from '../../logic/drawer-dialog.service';
import { PortalModule } from '@angular/cdk/portal';

export interface DialogDrawerData {
  portals: PortalsToRender;
}

@Component({
  selector: 'vdms-hq-ui-dialog-for-dialog',
  templateUrl: './dialog-drawer-for-dialog.component.html',
  styleUrls: ['./dialog-drawer-for-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, UIDialogWrapperModule, PortalModule, MatDialogModule],
})
export class DialogDrawerForDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDrawerData) {}
}
