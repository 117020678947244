import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, Observable, take, combineLatest } from 'rxjs';
import { JobsApiService, VideoAnalysisJob, modelContext, VideoAnalysisResultsUrl, Locks } from '@vdms-hq/api-contract';
import { Framerate } from '@vdms-hq/timecode';
import { AssetViewComponent } from '@vdms-hq/firebase-contract';
import { PlayerMetadataListSource } from './metadata-list.model';
import { SelectOption } from '@vdms-hq/shared';
import { TranslateService } from '@ngx-translate/core';
import { GroupingLine, PlayerService, TimelineMixedSourceService } from '@vdms-hq/player';
import { Analysis, ChartAnalysis, Constants } from '@vdms-hq/omakase-player';
import { MetadataLockService } from './metadata-lock.service';
import { MetadataRefreshService } from './metadata-refresh.service';

export type MetadataListConfigParams = {
  assetUuid: string;
  framerate: Framerate;
  defaultStream: (PlayerMetadataListSource | string)[];
  enabledStreams: (PlayerMetadataListSource | string)[];
  enabledComponents: AssetViewComponent[];
  locks: Locks[];
};

export type MetadataListConfig = MetadataListConfigParams & {
  loadedAt: number;
  jobsJsonUrl?: string;
  types: SelectOption<PlayerMetadataListSource | string>[];
};

@Injectable({ providedIn: 'root' })
export class MetadataListConfigService {
  private jobsApiService = inject(JobsApiService);
  private playerService = inject(PlayerService);
  private translateService = inject(TranslateService);
  private timelineMixedSourceService = inject(TimelineMixedSourceService);
  private metadataLockService = inject(MetadataLockService);
  private metadataRefreshService = inject(MetadataRefreshService);

  #config$ = new BehaviorSubject<MetadataListConfig | null>(null);
  #detectSeamlessResultAsset$ = new BehaviorSubject<string | null>(null);

  config$ = this.#config$.asObservable().pipe(distinctUntilChanged((a, b) => a?.loadedAt === b?.loadedAt));
  configDefinite$ = this.config$.pipe(filter((item) => !!item)) as Observable<MetadataListConfig>;
  detectSeamlessResultAsset$ = this.#detectSeamlessResultAsset$.asObservable();

  load(params: MetadataListConfigParams) {
    if (this.#config$.value?.assetUuid === params.assetUuid) {
      return;
    }

    this.#config$.next(null);
    this.#detectSeamlessResultAsset$.next(null);
    this.timelineMixedSourceService.cleanTimeline();
    this.metadataLockService.lockedAsset$.next(
      params.locks.filter(
        (lock) => this.metadataLockService.filterLocksByType(lock) && this.metadataLockService.filterLocksByUser(lock),
      )[0] ?? null,
    );

    const loggingType: SelectOption<PlayerMetadataListSource | string>[] = params.enabledStreams.includes(
      modelContext.LOGGING,
    )
      ? [
          {
            key: PlayerMetadataListSource.LOGGING,
            label: this.translateService.instant('common.player_metadata_list.stream_type_label.logging'),
          },
        ]
      : [];

    combineLatest([
      this.jobsApiService.getAssetJobs(params.assetUuid),
      this.metadataRefreshService.reloadTable$,
    ]).subscribe(([jobsResponse]) => {
      if (params.enabledStreams.length > 0 && jobsResponse.jobs.length > 0) {
        const sorted = jobsResponse.jobs
          .filter((item) => item.status === 'completed')
          .sort((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? -1 : 1));

        const unique = sorted.reduce((a, b) => {
          const existInAccumulator =
            a.findIndex(
              (item) => item.type === b.type && item.target_language === b.target_language && item?.name === b?.name,
            ) !== -1;

          if (!existInAccumulator) {
            a.push(b);
          }

          return a;
        }, [] as VideoAnalysisJob[]);

        const types: MetadataListConfig['types'] = unique
          .filter((job) => {
            const isTypeSupported = Object.values(PlayerMetadataListSource).includes(
              job.type as unknown as PlayerMetadataListSource,
            );

            if (!isTypeSupported) {
              return false;
            }

            if (job?.name.includes('Hybrik Detect Black')) {
              return false;
            }

            return true;
          })
          .map((job) => {
            if (job.type === modelContext.TRANSLATE_SUBTITLES && job.target_language) {
              return {
                key: job.target_language,
                label: this.translateService.instant(
                  'common.player_metadata_list.stream_type_label.transcribe-video-translated',
                  {
                    lang: job.target_language,
                  },
                ),
              };
            }

            return {
              key: job.type,
              label: this.translateService.instant('common.player_metadata_list.stream_type_label.' + job.type),
            };
          });

        const languages = jobsResponse.jobs.filter((job) => job.target_language).map((job) => job.target_language);

        const languagesStreams = (
          params.enabledStreams.includes(PlayerMetadataListSource.TRANSLATE_SUBTITLES)
            ? languages.map((language) => language)
            : []
        ) as string[];

        // todo make sure there are no problems with memory leaks (unsubscribe all subscriptions!)
        const hasEnabledTranscription = types.some(({ key }) => key === 'transcribe');
        const availableTypes = types.filter(({ key, label }) => {
          if (hasEnabledTranscription && label.toLowerCase().includes('transcription')) {
            return true;
          }

          return [...params.enabledStreams, ...params.defaultStream].includes(key);
        });

        this.#populateTimeline(unique);
        this.#populatePlayer(unique);
        this.#isSeamlessDetectJob(unique);

        this.#config$.next({
          ...params,
          enabledStreams: [...params.enabledStreams, ...languagesStreams],
          jobsJsonUrl: jobsResponse.merged_results_url,
          loadedAt: Date.now(),
          types: [...availableTypes, ...loggingType],
        });
      } else {
        this.#config$.next({
          ...params,
          loadedAt: Date.now(),
          types: [...loggingType],
        });
      }
    });
  }

  unload() {
    //
  }

  reload() {
    if (!this.#config$.value) {
      return;
    }

    this.#config$.next({
      ...this.#config$.value,
      loadedAt: Date.now(),
    });
  }

  #populateTimeline(uniqueJobs: VideoAnalysisJob[]) {
    const groups: GroupingLine[] = [];

    const getFileByType = (job?: VideoAnalysisJob, type: VideoAnalysisResultsUrl['type'] = 'vtt') => {
      return job?.result_urls.find((url) => url.type === type);
    };

    const bitrate = uniqueJobs.find((job) => job?.name?.includes('Bitrate'));
    const bitrateVttFile = getFileByType(bitrate);
    const blockiness = uniqueJobs.find((job) => job?.name?.includes('Blockiness'));
    const blockinessVttFile = getFileByType(blockiness);
    const blackDetection = uniqueJobs.find((job) => job?.name?.includes('Video Black Detection'));
    const blackDetectionVttFile = getFileByType(blackDetection);

    if (bitrateVttFile || blockinessVttFile || blackDetectionVttFile) {
      const lanes: Analysis[] = [];

      if (bitrateVttFile) {
        lanes.push({
          id: 'bitrate',
          type: 'chart',
          name: 'Bitrate',
          visualization: 'line',
          url: bitrateVttFile.url,
        });
      }

      if (blackDetectionVttFile) {
        lanes.push({
          id: 'bitrate',
          type: 'chart',
          name: 'Black detection',
          visualization: 'point',
          url: blackDetectionVttFile.url,
        });
      }

      if (blockinessVttFile) {
        lanes.push(<ChartAnalysis>{
          id: 'blockiness',
          type: 'chart',
          name: 'Blockiness',
          visualization: 'bar',
          url: blockinessVttFile.url,
          y_max: 0.01,
          y_min: 0,
        });
      }
      groups.push({
        type: 'normal',
        groupingName: 'Video statistics',
        lanes,
      });
    }

    const levels = uniqueJobs.find((job) => job.name?.includes('Levels'));
    const levelsVttFile = getFileByType(levels);
    const ebu = uniqueJobs.find((job) => job.name?.includes('EBU R128'));
    const ebuVttFile = getFileByType(ebu);
    const audioSilenceDetection = uniqueJobs.find((job) => job?.name?.includes('Audio Silence Detection'));
    const audioSilenceDetectionVttFile = getFileByType(audioSilenceDetection);

    if (levelsVttFile || ebuVttFile || audioSilenceDetectionVttFile) {
      const lanes: Analysis[] = [];
      // in case you need min max: this.playerService.activePlayer.config?.file_metadata?.audio_charts

      if (levelsVttFile) {
        lanes.push({
          id: 'levels',
          type: 'chart',
          name: 'Levels',
          visualization: 'line',
          url: levelsVttFile.url,
        });
      }

      if (ebuVttFile) {
        lanes.push({
          id: 'ebu_r128',
          type: 'chart',
          name: 'EBU R128',
          visualization: 'line',
          url: ebuVttFile.url,
        });
      }

      if (audioSilenceDetectionVttFile) {
        lanes.push({
          id: 'silence',
          type: 'chart',
          name: 'Silence detection',
          visualization: 'line',
          url: audioSilenceDetectionVttFile.url,
        });
      }

      groups.push({
        type: 'normal',
        groupingName: 'Audio statistics',
        lanes,
      });
    }

    const pse = uniqueJobs.find((job) => job.name?.includes('PSE'));

    if (pse) {
      const pseVttFiles = pse.result_urls.filter((url) => url.type === 'vtt');

      if (pseVttFiles.length > 0) {
        const lanes = pseVttFiles.map((pseVttFile) => {
          let name = '';
          switch (true) {
            case pseVttFile.label?.includes('red_flashed'):
              name = `Red flashes`;
              break;
            case pseVttFile.label?.includes('extended_flashes'):
              name = `Extended flashes`;
              break;
            case pseVttFile.label?.includes('luminance_flashes'):
              name = `Luminance flashes`;
              break;
            case pseVttFile.label?.includes('pattern'):
              name = `Patterns`;
              break;
          }

          return <Analysis>{
            id: 'luminance_flashes',
            type: 'chart',
            name: name,
            visualization: 'line',
            url: pseVttFile.url,
          };
        });

        const psePdfFile = getFileByType(pse, 'pdf');

        groups.push({
          type: 'normal',
          groupingName: 'PSE',
          lanes,
          button: psePdfFile
            ? {
                icon: Constants.IMAGE_BUTTONS['download'],
                onClick: () => {
                  const url = psePdfFile.url;

                  window.open(url, '_blank');
                },
              }
            : undefined,
        });
      }
    }

    const originalTranscription = uniqueJobs.find((job) => job.type === modelContext.TRANSCRIBE);
    const originalTranscriptionVttFile = getFileByType(originalTranscription);
    const languageTranscriptions = uniqueJobs.filter((job) => job.type === modelContext.TRANSLATE_SUBTITLES);

    if (originalTranscription || languageTranscriptions.length) {
      if (originalTranscriptionVttFile) {
        groups.push({
          type: 'subtitle',
          groupingName: 'Transcription',
          index: 0,
          lanes: [
            {
              id: 'transcription_original',
              type: 'chart',
              name: 'Original',
              visualization: 'marker',
              url: originalTranscriptionVttFile.url,
              color: Constants.VARIABLES.entitiesColors[7],
            },
          ],
        });
      }

      languageTranscriptions.forEach((transcription, index) => {
        const transcriptionVttFile = getFileByType(transcription);
        if (transcriptionVttFile) {
          groups.push({
            type: 'subtitle',
            groupingName: transcription.target_language ?? 'Translated transcription',
            index: index + 1,
            lanes: [
              {
                id: 'transcription_' + index,
                type: 'chart',
                name: transcription.target_language ?? '',
                visualization: 'marker',
                url: transcriptionVttFile.url,
                color: Constants.VARIABLES.entitiesColors[7],
              },
            ],
          });
        }
      });
    }

    console.log('JOBS USED (latest completed & unique): ', uniqueJobs);
    console.log('TIMELINE ROWS: ', groups);
    this.timelineMixedSourceService.lanes$.next(groups);
  }

  #populatePlayer(unique: VideoAnalysisJob[]) {
    const subtitles = unique.filter(
      (job) => job.type === modelContext.TRANSLATE_SUBTITLES || job.type === modelContext.TRANSCRIBE,
    );

    this.playerService.state$
      .pipe(
        filter((state) => state.state === 'ready'),
        take(1),
      )
      .subscribe(() => {
        console.log('PLAYER SUBTITLES: ', subtitles);

        this.playerService.attachSubtitles(
          subtitles.map((sub) => ({
            path: sub.result_urls.filter((url) => url.type === 'vtt')?.[0]?.url ?? '',
            language: sub.target_language || sub.name || 'Transcription',
            usageType: 'transcriptions',
          })),
        );
      });
  }

  #isSeamlessDetectJob(unique: VideoAnalysisJob[]) {
    const detectSeamlessJob = unique.filter((job) => job.type === modelContext.DETECT_SEAMLESS);

    if (!detectSeamlessJob.length) {
      return;
    }

    if (!detectSeamlessJob[0]?.target_asset_uuid) {
      return;
    }

    this.#detectSeamlessResultAsset$.next(detectSeamlessJob[0]?.target_asset_uuid);
  }
}
