import { inject, Injectable } from '@angular/core';
import { OrderService, OrderItemTypeEnum, PostFastDownloadInterface } from '@vdms-hq/api-contract';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from '@vdms-hq/auth';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest, EMPTY } from 'rxjs';
import moment from 'moment';

type itemUuid = string;

export interface FastOrderLoadingState {
  downloadNowLoading?: boolean;
  sendToDownloadsLoading?: boolean;
}

@Injectable({ providedIn: 'root' })
export class FastOrdersService {
  private orderService = inject(OrderService);
  private authService = inject(AuthService);
  private activatedClientService = inject(ActivatedClientService);

  currentUserEmail$ = this.authService.email$;
  isSharedPackEnabled$ = this.activatedClientService.clientDefinite$.pipe(
    map((client) => client?.vida?.sharedPacks ?? false),
  );

  fastDownload(items: itemUuid[], orderItemType: OrderItemTypeEnum) {
    const title = `Fast Download - ${items.length} Asset(s) - ${moment(new Date()).utc(true).format('YYYY-MM-DD HH:mm:ss')}`;
    return combineLatest([this.isSharedPackEnabled$, this.currentUserEmail$]).pipe(
      map(
        ([sendAsSharedPack, currentUserEmail]) =>
          ({
            uuid: uuidv4(),
            items: items.map((item: itemUuid) => ({ type: orderItemType, entity_uuid: item })),
            package_title: title,
            subject: `Fast Download - ${items.length} Asset(s) - ${moment(new Date()).utc(true).format('YYYY-MM-DD HH:mm:ss')}`,
            delivery_emails: [currentUserEmail],
            send_as_shared_pack: sendAsSharedPack,
          }) as PostFastDownloadInterface,
      ),
      switchMap((payload) => this.orderService.fastDownload(payload)),
    );
  }
}
