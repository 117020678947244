<div class="multi-data-container">
  <vdms-hq-ui-loader
    mode="over-parent"
    [backdrop]="true"
    *ngIf="(dataSource.isLoadingPackageDetails$ | async) && (dataSource.isLoading$ | async) === false"
  ></vdms-hq-ui-loader>
  <vdms-hq-ui-inner-header
    *ngIf="licensedPackageData$ | async as licensedPackageData"
    [breadcrumbs]="licensedPackageData.name"
    [subheader]="licensedPackageData.subtitle"
    [status]="{
      label: licensedPackageData.status.toUpperCase(),
      color: 'common.status_color.' + licensedPackageData.status | translate,
    }"
    [action]="headerAction"
    (clickAction)="editLicensePackageDetails()"
    [sharedE2eId]="'single-license-package-header'"
  >
    <vdms-hq-ui-simple-details-list
      class="grid-list-template"
      [listConfig]="licensedPackageData.details"
      [sharedE2eId]="'single-license-package-details'"
    ></vdms-hq-ui-simple-details-list>
    <ng-container right>
      <vdms-hq-ui-button
        [icon]="'forward_to_inbox'"
        [color]="'primary'"
        [iconOnly]="true"
        [tooltip]="'pages.assets_list.export' | translate"
        *requiresPermissions="[Permission.BROWSE_ASSETS_METADATA_EXPORT]"
        (click)="exportMetadataCSV()"
      ></vdms-hq-ui-button>
      <ng-container *ngIf="dataSource.total$ | async as total">
        <vdms-hq-ui-button
          [icon]="'folder_special'"
          [color]="'primary'"
          [iconOnly]="true"
          [tooltip]="
            (total <= 500
              ? 'tooltip.send_filter_to_licensed_package'
              : 'tooltip.send_filter_to_licensed_package_limited'
            ) | translate
          "
          [disabled]="total === 0 || total > 500"
          (click)="addToLicensedPackage()"
        ></vdms-hq-ui-button>
        <vdms-hq-ui-button
          [icon]="'ac_unit'"
          [color]="'primary'"
          [iconOnly]="true"
          [tooltip]="
            (total <= 500 ? 'tooltip.set_filtered_as_cold' : 'tooltip.set_filtered_as_cold_limited') | translate
          "
          [disabled]="total === 0 || total > 500"
          [hidden]="(coldStorageEnabled$ | async) === false"
          *requiresPermissions="[Permission.SET_ASSETS_AS_COLD_ONLY]"
          (click)="setAsColdOnly()"
        ></vdms-hq-ui-button>
      </ng-container>
    </ng-container>
  </vdms-hq-ui-inner-header>

  <ng-container *ngIf="dataSource.packageDetailsLoaded$ | async">
    <vdms-hq-ui-multiple-data-presentation
      *ngIf="multiConfig$ | async; let config"
      [dataSource]="dataSource"
      [configuration]="config"
      (action)="customActionHandler($event)"
    >
    </vdms-hq-ui-multiple-data-presentation>
  </ng-container>
</div>

<vdms-hq-asset-results-actions
  *ngIf="dataSource.selection.selected$ | async"
  [enabledActions]="batchActions"
  [actionLabels]="batchActionLabels"
  [selectionManager]="dataSource.selection"
  [isDownloadLoading]="isDownloadLoading | async"
  (actions)="batchActionHandler({ key: $event })"
>
</vdms-hq-asset-results-actions>
