import { inject, Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Timecode } from '@vdms-hq/timecode';
import { PlayerConfiguration, PlayerInterface, Subtitles } from './player.interface';
import videojs, { VideoJsPlayer } from 'video.js';
import VTTConverter from 'srt-webvtt';
import 'videojs-offset';
import { TimecodesService } from './timecodes.service';
import { SharedPlayerService } from './shared-player.service';
import { PLAYBACK_RATES } from './advanced-player';
import 'videojs-contrib-quality-levels';
import { QualityLevelList } from 'videojs-contrib-quality-levels';
import 'videojs-http-source-selector';
import { ToQualityLevelLabel } from './player.helpers';
import { StorageUrlService } from '@vdms-hq/storage';

export interface Player extends VideoJsPlayer {
  offset?(data: { start: number; end: number; restart_beginning: boolean }): void;
}

@Injectable({
  providedIn: 'root',
})
/**
 * @internal
 */
export class SimplePlayerService extends SharedPlayerService implements PlayerInterface {
  public readonly VIDEO_DOM_ID = `simple-player-window--${Date.now()}`;

  #previousTimeCode: Timecode | null = null;
  #currentTimeCode: Timecode | null = null;
  #requestAnimationFrameId?: number;
  #timecodes = inject(TimecodesService);
  #storageUrlService = inject(StorageUrlService);
  #config?: PlayerConfiguration;
  #latestVolume?: number;
  #loadedSubtitles: Subtitles[] = [];
  #videoPlayer?: Player;
  #qualityLevels?: QualityLevelList;

  goToTime(time: number) {
    if (!this.#videoPlayer) {
      return;
    }

    this.pause();
    this.#videoPlayer.currentTime(time);
  }

  load = () => {
    if (!this.config) {
      this.setError('Config not passed to simple-player.service.ts');
      throw new Error('Player must by configured first');
    }
    this.stateSubject$.next({ state: 'loading' });

    this.waitForElementAndLoad(this.VIDEO_DOM_ID, this.#tryLoad.bind(this));
  };

  #tryLoad() {
    try {
      this.#videoPlayer = videojs(
        this.VIDEO_DOM_ID,
        {
          controls: true,
          autoplay: false,
          preload: 'auto',
          bigPlayButton: true,
          html5: {
            vhs: {
              withCredentials: this.config?.withCredentials,
              overrideNative: !videojs.browser.IS_ANY_SAFARI,
              useBandwidthFromLocalStorage: true,
            },
          },
          plugins: {
            httpSourceSelector: {
              default: 'high',
            },
          },
        },
        () => this.#initQualities(),
      );

      let type;

      switch (this.config?.file.extension) {
        case 'mp4':
          type = 'video/mp4';
          break;
        case 'webm':
          type = 'video/webm';
          break;
        case 'ogv':
          type = 'video/ogv';
          break;
        case 'm3u8':
          type = 'application/x-mpegURL';
          break;
      }

      const src = this.config?.file.url;
      if (!src) {
        this.setError('File url is empty');
        return;
      }

      this.#videoPlayer.src({
        src,
        type,
      });

      const clip = this.config.clip;
      if (clip && this.#videoPlayer?.offset) {
        this.#videoPlayer.offset({
          start: clip.startAt.countSeconds(),
          end: clip.endAt.countSeconds(),
          restart_beginning: true,
        });
      }

      this.#videoPlayer?.on('loadedmetadata', () => {
        this.#emitTime();
        setTimeout(() => {
          if (!this.#videoPlayer) {
            return;
          }
          this.#initSubtitles();
          this.#setInitialAudioTrack();
          this.duration$.next(Timecode.fromSeconds(this.#videoPlayer.duration(), this.config?.framerate, false));
          this.stateSubject$.next({ state: 'ready' });
        }, 100);
      });

      this.#videoPlayer?.on('volumechange', () => {
        this.currentVolumeLevel$.next(this.#videoPlayer?.volume() ?? 1);
      });
    } catch (e: unknown | Error) {
      if (!(e instanceof Error)) {
        this.setError('Unable to initialize player');
        return;
      }

      switch (true) {
        case e.message?.includes('Cannot read properties of null'):
          this.setError('Unable to initialize player, did you forget to initialize PlayerComponent BEFORE load()?');
          return;
      }

      this.setError('Unable to initialize player');
    }
  }

  unload = () => {
    this.#requestAnimationFrameId && cancelAnimationFrame(this.#requestAnimationFrameId);
    this.pause();
    this.#qualityLevels?.dispose();
    this.#videoPlayer?.dispose();
    this.#videoPlayer = undefined;
    this.#loadedSubtitles = [];
    this.currentTimecode$.next(null);
    this.duration$.next(null);
    this.currentPlaybackRate$.next(1);
    this.currentVolumeLevel$.next(1);
    this.#timecodes.reset();
    super.unloadShared();
  };

  #emitTime() {
    if (!this.#videoPlayer) {
      return;
    }

    this.#currentTimeCode = Timecode.fromSeconds(this.#currentTime, this.config?.framerate, false);

    if (!this.#previousTimeCode || !this.#currentTimeCode?.equals(this.#previousTimeCode)) {
      this.#previousTimeCode = this.#currentTimeCode;
      this.currentTimecode$.next(this.#currentTimeCode);
    }

    this.#requestAnimationFrameId = window.requestAnimationFrame(this.#emitTime.bind(this));
  }

  get #currentTime() {
    return this.#videoPlayer?.currentTime() ?? 0;
  }

  get #duration() {
    return this.#videoPlayer?.duration() ?? 0;
  }

  protected togglePlayPause() {
    if (this.#videoPlayer?.paused()) {
      this.play();
    } else {
      this.pause();
    }
  }
  protected pause() {
    this.#videoPlayer?.pause();
  }

  protected changeQualityLevel(index: number) {
    return;
  }

  protected togglePictureInPicture() {
    return;
  }

  protected override toggleHelpDialog() {
    return;
  }

  protected play() {
    this.#videoPlayer?.play();
  }

  protected seekSeconds(seconds: number) {
    const defaultFramerate = this.framerateSubject.value.value;
    this.seekFrames(Number((seconds as number) * (this.config?.framerate?.value ?? defaultFramerate)));
  }

  protected seekFrames(frames: number) {
    if (!this.#currentTimeCode) {
      return;
    }

    this.pause();
    const newTc = this.#currentTimeCode.addFrames(frames, false)?.countSeconds() ?? 0;
    this.#videoPlayer?.currentTime(Math.min(this.#duration, newTc));
  }

  protected toggleMute() {
    if (!this.#videoPlayer) {
      return;
    }

    const current = this.#videoPlayer.volume();

    if (current && current > 0) {
      this.#latestVolume = current;
      this.#videoPlayer.volume();
    } else {
      this.#videoPlayer.volume(this.#latestVolume ?? 1);
    }
  }

  protected updateVolumeUp(change: number) {
    if (!this.#videoPlayer) {
      return;
    }

    const current = this.#videoPlayer.volume();

    const next = Math.max(0, Math.min(1, current + change));

    this.#videoPlayer.volume(next);
  }

  protected updateVolume() {
    return;
  }

  protected toggleFullScreen() {
    if (!this.#videoPlayer) {
      return;
    }

    this.#videoPlayer.isFullscreen() ? this.#videoPlayer.cancelFullScreen() : this.#videoPlayer.requestFullscreen();
  }

  async loadSubtitles(subtitles: Subtitles[]) {
    for (const subtitle of subtitles) {
      await this.#loadSubtitle(subtitle);
    }
  }

  async #initSubtitles() {
    if (!this.config?.subtitles) {
      return;
    }

    for (const subtitle of this.config.subtitles) {
      await this.#loadSubtitle(subtitle);
    }
  }

  async #loadSubtitle(subtitle: Subtitles) {
    const subtitleLang = subtitle.language;
    const subtitlePath = subtitle.path;
    if (!subtitlePath) {
      return;
    }

    const alreadyLoaded = this.#loadedSubtitles.find((loadedSubtitle) => loadedSubtitle.language === subtitleLang);
    if (alreadyLoaded) {
      return;
    }
    const subtitles = await fetch(subtitlePath, {
      method: 'GET',
      credentials: this.config?.includeCookiesInRequest ? 'include' : undefined,
    });

    const vttConverter = new VTTConverter(await subtitles.blob());
    const vttUrl = await vttConverter.getURL();

    this.#videoPlayer?.addRemoteTextTrack(
      {
        src: vttUrl,
        srclang: subtitle.language,
        label: subtitle.language,
        kind: 'subtitles',
      },
      true,
    );

    this.#enabledSelectedTextTrack(subtitle.language);

    this.#loadedSubtitles.push(subtitle);
  }

  #enabledSelectedTextTrack(language: string) {
    [].forEach.call(this.#videoPlayer?.remoteTextTracks(), (track: TextTrack) => {
      track.mode = 'hidden';
      if (track.language === language) {
        track.mode = 'showing';
      }
    });
  }

  protected increasePlaybackRate() {
    const currentPlaybackValue = this.currentPlaybackRate$.value;

    const index = PLAYBACK_RATES.indexOf(currentPlaybackValue) || 0;

    const nextIndex = index === PLAYBACK_RATES.length ? PLAYBACK_RATES[0] : PLAYBACK_RATES[index + 1];

    this.#videoPlayer?.playbackRate(nextIndex);
    this.currentPlaybackRate$.next(nextIndex);
  }

  protected decreasePlaybackRate() {
    const currentPlaybackValue = this.currentPlaybackRate$.value;

    const index = PLAYBACK_RATES.indexOf(currentPlaybackValue) || 0;

    const nextIndex = index === 0 ? PLAYBACK_RATES[PLAYBACK_RATES.length - 1] : PLAYBACK_RATES[index - 1];

    this.#videoPlayer?.playbackRate(nextIndex);
    this.currentPlaybackRate$.next(nextIndex);
  }

  protected setPlaybackRate(rate: number) {
    this.#videoPlayer?.playbackRate(rate);
    this.currentPlaybackRate$.next(rate);
  }

  protected resetPlaybackRate() {
    this.#videoPlayer?.playbackRate(1);
    this.currentPlaybackRate$.next(1);
  }

  protected changeAudioTrack(audioTrackId: number): void {
    return;
  }

  protected changeSubtitles(subtitle?: Subtitles): void {
    return;
  }

  protected inToPlayhead(): void {
    const currentTime = this.#videoPlayer?.currentTime();
    currentTime && this.#timecodes.setTcIn(Timecode.fromSeconds(currentTime) as Timecode);
  }

  protected outToPlayhead(): void {
    const currentTime = this.#videoPlayer?.currentTime();
    currentTime && this.#timecodes.setTcIn(Timecode.fromSeconds(currentTime) as Timecode);
  }

  protected playToIn(): void {
    this.#timecodes.timecodes$
      .pipe(
        take(1),
        map(([tcIn]) => tcIn?.countSeconds()),
      )
      .subscribe((tcIn) => tcIn && this.goToTime(tcIn));
  }

  protected playToOut(): void {
    this.#timecodes.timecodes$
      .pipe(
        take(1),
        map(([, tcOut]) => tcOut?.countSeconds()),
      )
      .subscribe((tcOut) => tcOut && this.goToTime(tcOut));
  }

  #setInitialAudioTrack() {
    const preferredAudioIndex = this.config?.preferredAudioIndex;
    if (typeof preferredAudioIndex !== 'number') {
      return;
    }

    const audioTracks = Array.from(this.#videoPlayer?.audioTracks() ?? []);
    const selectedAudioTrack = audioTracks[preferredAudioIndex];

    if (selectedAudioTrack) {
      selectedAudioTrack.enabled = true;
    }
  }

  #initQualities() {
    this.#qualityLevels = this.#videoPlayer?.qualityLevels();
    this.#qualityLevels?.on('addqualitylevel', (event: unknown) => {
      const qualityLevel = (event as AddQualityLevelEvent).qualityLevel;
      const label = ToQualityLevelLabel(qualityLevel.id, qualityLevel.height);

      qualityLevel.height = label as string;
    });
  }
}

interface AddQualityLevelEvent extends videojs.EventTarget.Event {
  qualityLevel: { id: string; height: string | number };
}
