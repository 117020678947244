import { Component, Inject, inject, OnDestroy, OnInit } from '@angular/core';
import { WithPermissions } from '@vdms-hq/activated-client';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PaginationProps,
  PaginatorComponent,
  ProcessView,
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
  UIInfinityLoaderModule,
  UiProcessViewComponent,
} from '@vdms-hq/ui';
import { AnalyticsLogActionEnum, CollectionsService } from '@vdms-hq/api-contract';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatFormField, MatOption, MatSelect } from '@angular/material/select';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { combineLatest, map, Observable, startWith, Subject, takeUntil, tap } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import moment from 'moment/moment';
import { CollectionsAnalyticsLogDataSource } from '../../logic/datasources/collections-analytics-log-data-source';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { ValueFormat } from '@vdms-hq/shared';
import { FilterType } from '@vdms-hq/fields';
import { ToastService } from '@vdms-hq/toast';

export interface ActivityLog {
  author: string;
  type: AnalyticsLogActionEnum;
  date: string;
  description?: string;
}

export interface CollectionActivityDialogData {
  collectionName: string;
  collectionUuid: string;
}

export const SORT_BY = [
  {
    key: 'viewed_30',
    label: 'Views from last 30 days',
    props: <PaginationProps>{
      orderBy: 'collection_views',
      orderDir: 'desc',
    },
  },
  {
    key: 'viewed_7',
    label: 'Views from last 7 days',
    props: <PaginationProps>{
      orderBy: 'collection_views',
      orderDir: 'desc',
    },
  },
];

@Component({
  selector: 'vdms-hq-collection-activity-dialog',
  templateUrl: './collection-activity-dialog.component.html',
  styleUrl: './collection-activity-dialog.component.scss',
  standalone: true,
  imports: [
    UIButtonModule,
    UIDialogWrapperModule,
    MatIconModule,
    TranslateModule,
    MatSelect,
    MatFormField,
    MatOption,
    UIFormModule,
    CommonModule,
    UiProcessViewComponent,
    UIInfinityLoaderModule,
    PaginatorComponent,
    DynamicFiltersModule,
  ],
})
export class CollectionActivityDialogComponent extends WithPermissions() implements OnInit, OnDestroy {
  public dialogRef = inject(MatDialogRef<CollectionActivityDialogData>);
  data = inject<CollectionActivityDialogData>(MAT_DIALOG_DATA);
  translateService = inject(TranslateService);
  analyticsLogDataSource = inject(CollectionsAnalyticsLogDataSource);
  collectionsService = inject(CollectionsService);
  toastService = inject(ToastService);

  #destroy = new Subject<void>();

  filters: DynamicFilterInput[] = [
    {
      id: 'sort',
      label: '',
      format: ValueFormat.AS_IS,
      filters: {
        validFormat: 'keyword',
        objectPath: 'sort',
        type: FilterType.SELECTOR,
      },
      selectOptions: SORT_BY,
      scope: ['other-collections'],
    },
  ];

  filtersGroup = new FormGroup({
    sort: new FormControl<string>(SORT_BY[0].key),
  });

  processView$: Observable<ProcessView> = this.analyticsLogDataSource.processView$;

  processViewOrLoading$ = combineLatest({
    processView: this.processView$.pipe(startWith(undefined)),
    loading: this.analyticsLogDataSource.isLoading$.pipe(startWith(true)),
  });

  ngOnInit() {
    this.filtersGroup.valueChanges
      .pipe(takeUntil(this.#destroy), startWith(this.filtersGroup.value))
      .subscribe(({ sort }) => {
        if (sort == 'viewed_30') {
          this.analyticsLogDataSource.filters$.next({
            'views-date-from': moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD HH:MM:SS'),
            'views-date-to': moment(new Date()).format('YYYY-MM-DD HH:MM:SS'),
          });
          this.analyticsLogDataSource.pageIndex$.next(0);
        }
        if (sort == 'viewed_7') {
          this.analyticsLogDataSource.filters$.next({
            'views-date-from': moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD HH:MM:SS'),
            'views-date-to': moment(new Date()).format('YYYY-MM-DD HH:MM:SS'),
          });
          this.analyticsLogDataSource.pageIndex$.next(0);
        }
      });
  }

  ngOnDestroy() {
    this.#destroy.next();
    this.#destroy.complete();
  }

  exportingLoader = false;
  exportCSV() {
    this.exportingLoader = true;
    this.collectionsService
      .exportAnalyticsLog(
        this.data.collectionUuid,
        ['id', 'logAction', 'logDate', 'user.name'],
        this.analyticsLogDataSource.filters$.value,
      )
      .pipe(
        tap(() => {
          this.toastService.success({
            id: 'export-collection-logs-csv-success',
            message: 'A CSV with analytics logs will be sent to you shortly.',
          });
          this.exportingLoader = false;
        }),
      )
      .subscribe();
  }
}
