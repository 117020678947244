import { ValueDisplayFormatConfig, ValueFormat } from '@vdms-hq/shared';
import { FilterType } from './filter-type';
import { FieldType } from './field-type';
import { ValidatorFn } from '@angular/forms';

export type FieldConfigId = string;
export type SourceListKey = string;
export type FilterObjectPath = string;
export type FieldsScopeKey =
  | '_admin-all-fields'
  | '_any'
  | 'default' // all metadata
  | 'metadata-snapshots'
  | 'metadata-recognition'
  | 'deleted'
  | 'quarantined'
  | 'browse'
  | 'cart'
  | 'collections'
  | 'shared-packs'
  | 'orders'
  | 'upload-jobs' // launchpad
  | 'upload-jobs-pending' // additional resolved reason column
  | 'content-corner' // assets in connect2
  | 'licensed-package'
  | 'preview-request'
  | 'purged-assets'
  | 'browse-library'
  | 'map'

  // below are non assets views
  | 'other-rights-partners'
  | 'other-rights-contracts'
  | 'other-licensed-packages'
  | 'other-licensed-packages-connect2'
  | 'other-collections'
  | 'other-suppliers'
  | 'other-users'
  | 'other-teams'
  | 'other-orders'
  | 'other-orders-approval'
  | 'other-systems'
  | 'other-field-options'
  | 'other-ratings'
  | 'other-preview-requests'
  | 'other-policies'
  | 'other-logs'
  | 'other-delivery-destinations'
  | 'other-discounts'
  | 'other-dashboards'
  | 'other-clients'
  | 'other-shared-packs'
  | 'other-media-pulse-orders'
  | 'other-delivery-pack'; // launchpad
interface BaseDefinitionModel {
  featureDate?: Date;
  id: FieldConfigId;
  isModified?: boolean;
  isCustomDefinition?: boolean;
  scope: FieldsScopeKey[];
  sourceListKey?: SourceListKey;
  label: string;
  format: ValueFormat;
  input?: FieldDefinitionInput;
  results2?: FieldDefinitionResults2;
  filters?: ClientDefinitionFilters;
  appliedFilters?: ClientDefinitionFilters;
  useAsFilename?: boolean;
}

export interface InputDefinitionModel extends BaseDefinitionModel {
  input: FieldDefinitionInput;
}

export interface FilterDefinitionModel extends BaseDefinitionModel {
  filters: ClientDefinitionFilters;
}

export interface ResultDefinitionModel extends BaseDefinitionModel {
  results2: FieldDefinitionResults2;
}

export type FieldDefinitionModel = FilterDefinitionModel | ResultDefinitionModel | InputDefinitionModel;
export const isVisibleInTable = (field: FieldDefinitionModel): field is ResultDefinitionModel =>
  'results2' in field && !!field['results2'];
export const isVisibleInForm = (field: FieldDefinitionModel): field is InputDefinitionModel =>
  'input' in field && !!field['input'];
export const isVisibleSearch = (field: FieldDefinitionModel): field is FilterDefinitionModel =>
  'filters' in field && !!field['filters'];

interface FieldDefinitionInput {
  objectPath?: string;
  type: FieldType;
  readonly: boolean;
  mask?: string;
  validators?: ValidatorFn[];
  format?: ValueFormat;
  readPermission?: {
    permissions: string[];
    comparator: 'some' | 'every';
  };
  writePermission?: {
    permissions: string[];
    comparator: 'some' | 'every';
  };
}

interface FieldDefinitionResults2 {
  objectPath: string;
  exportObjectPath?: string;
  sortObjectPath?: string;
  sortable: boolean;
  foldValues?: boolean;
  viewFormat?: ValueDisplayFormatConfig;
}

interface ClientDefinitionFilters {
  objectPath: FilterObjectPath;
  aggregationKey?: string;
  validFormat: 'keyword' | 'range';
  type: FilterType;
  mask?: string;
  singleValue?: boolean;
}
