<div class="outer">
  <button class="container" [class.selected]="selected" [class]="'container__' + size">
    <div class="content-wrapper">
      @if (imgUrl) {
        <img
          [ngSrc]="imgUrl"
          [alt]="imgAlt"
          [height]="imgHeight"
          [width]="imgWidth"
          [class.img-invert-color]="imgInvertColor"
        />
      }
      @if (icon) {
        <mat-icon class="icon">{{ icon }}</mat-icon>
      }

      @if (!this.imageOnly) {
        <div class="data-container">
          <h2>{{ title | translate }}</h2>
          <span>{{ subtitle | translate }}</span>
        </div>
      }
    </div>
    @if (checked) {
      <mat-icon class="icon-color--done">check_circle_outline</mat-icon>
    }
  </button>
</div>
