import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { WebsocketNotificationInterface } from './websockes.model';

export function filterStreamMsgFactory<T extends WebsocketNotificationInterface<B>, B>(
  streamActions: string[],
): (source$: Observable<T>) => Observable<T> {
  return (source$) =>
    source$.pipe(
      filter((msg) => {
        if (msg.data?.action) {
          return streamActions.includes(msg.data.action);
        }
        return false;
      }),
    ) as Observable<T>;
}
