import { GroupCurrencyISO } from '@vdms-hq/shared';

export interface Chart {
  chartDataSets: { data: number[]; label: string }[];
  labels: string[];
}

export interface EgressChart {
  [key: string]: Chart;

  egress: Chart;
}

export interface EgressTotals {
  bytes_egressed_last_30days: number;
  egress_since_last_bill: number;
  files_delivered_since_last_bill: number;
  daily_cost: number;
  currency: GroupCurrencyISO;
}

export type EgressStats = {
  charts: EgressChart;
  totals: EgressTotals;
};

export type EgressStatsResponse = {
  data: {
    charts: EgressChart;
    totals: EgressTotals;
  };
  status: string;
};

export interface BaseTransferListItem {
  status: string;
  method: string;
  application: string;
  transfer_start: string;
  transfer_end: string;
}

export interface OrderTransferListItem extends BaseTransferListItem {
  order_title: string;
  purchase_order_number?: string;
  sales_force_name?: string;
  user_email: string;
  destination_ip: string;
}

export interface Connect2TransferListItem extends BaseTransferListItem {
  contract_name: string;
  contract_id: string;
  user_email: string;
  destination_ip: string;
}

export interface S3PublishTransferListItem extends BaseTransferListItem {
  order_title: string;
  purchase_order_number: string;
  s3_publish_sender_email: string;
  s3_publish_region: string;
  s3_bucket_name: string;
}

export type EgressListItem = OrderTransferListItem | Connect2TransferListItem | S3PublishTransferListItem;

export class EgressListItemViewModel {
  static fromEgressListItem(item: EgressListItem): EgressListItemViewModel {
    return new EgressListItemViewModel(item);
  }

  constructor(public props: EgressListItem) {}

  get orderTitle(): string {
    if ('order_title' in this.props) {
      return this.props.order_title ?? '';
    } else if ('contract_name' in this.props) {
      return this.props.contract_name ?? '';
    }
    return '';
  }

  get purchaseOrderNumber(): string {
    if ('purchase_order_number' in this.props) {
      return String(this.props.purchase_order_number ?? '');
    } else if ('contract_id' in this.props) {
      return this.props.contract_id ?? '';
    }
    return '';
  }

  get userEmail(): string {
    if ('user_email' in this.props) {
      return this.props.user_email ?? '';
    }

    if ('s3_publish_sender_email' in this.props) {
      return this.props.s3_publish_sender_email ?? '';
    }

    return '';
  }

  get destination(): string {
    if ('destination_ip' in this.props) {
      return this.props.destination_ip ?? '';
    }
    if ('s3_bucket_name' in this.props) {
      return this.props.s3_bucket_name ?? '';
    }

    return '';
  }

  get method(): string {
    return this.props.method ?? '';
  }

  get application(): string {
    return this.props.application ?? '';
  }

  get transferStart(): string {
    return this.props.transfer_start ?? '';
  }

  get transferEnd(): string {
    return this.props.transfer_end ?? '';
  }

  get status(): string {
    return this.props.status;
  }
}
