import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { isCompositeItem, isSeparator, Separator, SidebarItem, SidebarNestedItem } from '../../models/sidebar.model';
import { castTo } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-ui-inner-sidebar-menu',
  templateUrl: './inner-sidebar-menu.component.html',
  styleUrls: ['./inner-sidebar-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerSidebarMenuComponent {
  items: (SidebarItem | SidebarNestedItem)[] = [];
  expandedIndexes: boolean[] = [];
  allowExpand = true;

  @Input() set navItems(navItems: (SidebarItem | SidebarNestedItem)[]) {
    this.items = navItems.filter((item) => item !== undefined);

    if (navItems.length < 2) {
      this.allowExpand = false;
    }
    this.expandedIndexes = new Array(navItems.length).fill(false);
  }
  @Input() loading = false;
  @Input() actionLink: { label: string; key: string } | null = null;
  @Output() action = new EventEmitter<{ key: string }>();
  $composite = castTo<SidebarNestedItem>();
  isNestedItem = isCompositeItem;

  trackBy = (index: number, item: SidebarItem | Separator | undefined) => {
    if (!item) {
      return index;
    }

    return isSeparator(item) ? item : item.name + item.routerLink;
  };

  isExpanded(index: number) {
    return this.expandedIndexes[index];
  }

  toggle(index: number) {
    if (!this.expandedIndexes[index]) {
      // close all other expanded items
      this.expandedIndexes = this.expandedIndexes.map(() => false);
    }
    return (this.expandedIndexes[index] = !this.expandedIndexes[index]);
  }
}
