import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from './../../functions/case-transformer';

@Pipe({
  name: 'capitalize',
  standalone: true,
})
export class CapitalizePipe implements PipeTransform {
  transform = CapitalizePipe.transform;

  static transform = (value: string) => {
    if (!value) {
      return value;
    }
    return capitalize(value);
  };
}
