import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaysDisplayingComponent } from '@vdms-hq/ui';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DaysFromPipe, SharedModule } from '@vdms-hq/shared';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { NotificationMessageViewModel } from '../../logic/notification-message-view-model';
import { AssetDetailsForNotification } from '@vdms-hq/api-contract';

@Component({
  selector: 'vdms-hq-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    SharedModule,
    TranslateModule,
    MatTooltipModule,
    RouterLink,
    OverlayModule,
    DaysFromPipe,
    DaysDisplayingComponent,
  ],
  providers: [],
})
export class NotificationMessageComponent {
  @Input() notification!: NotificationMessageViewModel;
  @Input() detailedView = false;

  MAX_FILES_DISPLAYED_INDEX = 3;

  uuidsFromAssetsQuery(assets: AssetDetailsForNotification[]) {
    return { query: 'uuid:' + assets.map((asset) => asset.uuid).join(',') };
  }
}
