import { Prefix } from '../pipes/math/bytes-calculator';

export type FilesizeUnit = 'B' | 'kB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB';

export const getPrefixFromFileSizeUnit = (unit: FilesizeUnit): Prefix => {
  switch (unit) {
    case 'B':
      return '';
    case 'kB':
    case 'MB':
    case 'GB':
    case 'TB':
    case 'PB':
    case 'EB':
    case 'ZB':
    case 'YB':
      return unit.slice(0, 1) as Prefix;
  }
};
