import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ActionIdentifier,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  ResultsWrapperViewConfiguration,
  UIConfirmationDialogService,
  UILoaderModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { DiscountsDsService } from '../../logic/discounts-ds.service';
import { DISCOUNTS_SORT_BY, DISCOUNTS_STATUS, DiscountsService, GetDiscountCodeInterface } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import { MatDialog } from '@angular/material/dialog';
import { DiscountCreateEditDialogComponent } from '../../components/discount-create-edit-dialog/discount-create-edit-dialog.component';
import { combineLatest, filter, switchMap, take, tap } from 'rxjs';
import { DestroyComponent, ValueFormat } from '@vdms-hq/shared';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { FilterType } from '@vdms-hq/fields';

@Component({
  selector: 'vdms-hq-discounts-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    UIResultsWrapperModule,
    MultipleDataPresentationComponent,
    UILoaderModule,
    DynamicFiltersModule,
  ],
  templateUrl: './discounts-list.component.html',
})
export class DiscountsListComponent extends DestroyComponent implements OnInit {
  headerConfig: ResultsWrapperViewConfiguration = {
    headerActions: [],
    withLoading: false,
  };

  viewConfig: MultipleViewConfiguration<GetDiscountCodeInterface> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: true,
      pagination: true,
      emptyResults: {
        message: 'No discounts found',
      },
    },
    tableAdvanced: {
      actions: [],
      layout: {
        headers: true,
      },
      columns: [
        {
          id: 'code',
          label: 'Discount Code',
          valuePath: 'code',
        },
        {
          id: 'name',
          label: 'Name',
          valuePath: 'name',
        },
        {
          id: 'active',
          label: 'Active',
          valuePath: 'is_active',
          viewFormat: {
            pills: {
              true: 'done',
              false: 'orange',
            },
            modifiers: {
              yesNo: true,
            },
          },
        },
        {
          id: 'egress',
          label: 'Egress per GB',
          valuePath: 'multiplier.egress_price_per_gb',
          viewFormat: {
            modifiers: {
              discountValue: true,
            },
          },
        },
        {
          id: 'restoration',
          label: 'Restoration per GB',
          valuePath: 'multiplier.restoration_price_per_gb',
          viewFormat: {
            modifiers: {
              discountValue: true,
            },
          },
        },
        {
          id: 'processing',
          label: 'Asset processing',
          valuePath: 'multiplier.asset_processing_fee_price_per_asset',
          viewFormat: {
            modifiers: {
              discountValue: true,
            },
          },
        },
        {
          id: 'actions',
          type: 'actions',
        },
      ],
      columnsEnabled: ['code', 'name', 'egress', 'restoration', 'processing', 'active', 'actions'],
    },
  };

  filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      format: ValueFormat.AS_IS,
      label: 'Search by Name or Code',
      filters: {
        objectPath: 'keyword',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
      scope: ['other-discounts'],
    },
    {
      id: 'active',
      format: ValueFormat.AS_IS,
      label: 'Status',
      filters: {
        validFormat: 'keyword',
        objectPath: 'is_active',
        type: FilterType.SELECTOR,
      },
      selectOptions: DISCOUNTS_STATUS,
      enableClear: true,
      scope: ['other-discounts'],
    },
    {
      id: 'sort',
      label: 'common.orders.filters.sort',
      format: ValueFormat.AS_IS,
      filters: {
        validFormat: 'keyword',
        objectPath: 'sort',
        type: FilterType.SELECTOR,
      },
      selectOptions: DISCOUNTS_SORT_BY,
      scope: ['other-discounts'],
    },
  ];

  constructor(
    public discountsDsService: DiscountsDsService,
    private discountsService: DiscountsService,
    private permissionService: PermissionService,
    private confirmationDialog: UIConfirmationDialogService,
    private toastService: ToastService,
    private translate: TranslateService,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.#setActions();
  }

  handleAction($event: { key: ActionIdentifier; item?: GetDiscountCodeInterface }) {
    const { key, item } = $event;
    switch (key) {
      case 'create':
        this.#new();
        break;

      case 'edit':
        if (item) this.#edit(item);
        break;

      case 'delete':
        if (item) this.#delete(item);
        break;
    }
  }

  #setActions() {
    combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.CREATE_DISCOUNTS]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_DISCOUNTS]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.DELETE_DISCOUNTS]),
    ])
      .pipe(take(1))
      .subscribe(([canCreate, canEdit, canDelete]) => {
        if (canCreate && this.headerConfig.headerActions) {
          this.headerConfig.headerActions.push({
            key: 'create',
            name: 'pages.clients.discounts.create_btn',
            color: 'primary',
          });
        }

        if (this.viewConfig.tableAdvanced) {
          this.viewConfig.tableAdvanced.actions = [
            {
              key: 'edit',
              icon: 'edit',
              onDoubleClick: false,
              label: 'pages.clients.discounts.edit_btn',
              hiddenIf: () => !canEdit,
            },
            {
              key: 'delete',
              icon: 'delete',
              onDoubleClick: false,
              label: 'pages.clients.discounts.delete_btn',
              hiddenIf: () => !canDelete,
            },
          ];
        }
      });
  }

  #new() {
    this.dialog.open(DiscountCreateEditDialogComponent, { data: { item: undefined } });
  }

  #edit(item: GetDiscountCodeInterface) {
    this.dialog.open(DiscountCreateEditDialogComponent, { data: { item } });
  }

  #delete(item: GetDiscountCodeInterface) {
    const { uuid, code } = item;
    const title = this.translate.instant('pages.clients.discounts.dialogs.delete.title', { code });
    const message = this.translate.instant('pages.clients.discounts.dialogs.delete.message');

    this.confirmationDialog
      .openDelete({ title, message })
      .pipe(
        filter(Boolean),
        take(1),
        tap(() => this.discountsDsService.isLoading$.next(true)),
        switchMap(() => this.discountsService.delete(uuid)),
      )
      .subscribe({
        next: () => {
          this.toastService.success({
            id: 'delete_discount',
            message: 'pages.clients.discounts.dialogs.delete.notifications.delete_success',
          });
          this.#refresh();
        },
        error: (error) => {
          this.discountsDsService.isLoading$.next(false);
          this.toastService.error({
            id: 'delete_discount',
            message: 'pages.clients.discounts.dialogs.delete.notifications.delete_failure',
          });
          throw error;
        },
      });
  }

  #refresh() {
    this.discountsDsService.refresh();
  }
}
