import { Pipe, PipeTransform } from '@angular/core';
import { pairWithSelectOption, SelectOption } from '@vdms-hq/shared';
import { FieldsFetcherService } from '@vdms-hq/fields';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ClientContentStructure } from '@vdms-hq/firebase-contract';

@Pipe({
  name: 'enabledList',
  standalone: true,
})
export class EnabledListPipe implements PipeTransform {
  constructor(private activatedClientConfigService: FieldsFetcherService) {}

  transform(structure: ClientContentStructure): Observable<SelectOption[]> {
    return this.activatedClientConfigService.getConfiguration$('default').pipe(
      take(1),
      map((all) => pairWithSelectOption(structure?.fields, all.filters.getSystemWithAggregationsAsSelectOptions(true))),
    );
  }
}
