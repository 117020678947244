import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export class DateValidator {
  static dayNotPast(): ValidatorFn {
    const validationError: ValidationErrors = { ['dayIsPast']: true };
    const invalidDateFormat: ValidationErrors = { ['invalidDate']: true };

    return (control: AbstractControl): ValidationErrors | null => {
      let dateValue = control.value;
      if (!(dateValue instanceof Date)) {
        return invalidDateFormat;
      }

      dateValue = moment.utc(moment(dateValue).toISOString(true).substr(0, 10)).startOf('day').toISOString();

      return moment(dateValue).isBefore(moment(), 'day') ? validationError : null;
    };
  }

  static dateNotPast(providedDate?: string | Date): ValidatorFn {
    const validationError: ValidationErrors = { ['dateIsPast']: true };
    const invalidDateFormat: ValidationErrors = { ['invalidDate']: true };

    return (control: AbstractControl): ValidationErrors | null => {
      const dateValue = new Date(control.value);
      if (dateValue.toString() === 'Invalid Date') {
        return invalidDateFormat;
      }

      return moment(dateValue).isBefore(providedDate ? moment(providedDate) : moment()) ? validationError : null;
    };
  }
}
