import { underscoreToCapitalizedString } from '@vdms-hq/shared';

export const generateProgressToastMessage = (
  message: string,
  percent: number,
  imgSrc?: string,
  counter?: {
    processing: number;
    all: number;
    errors: { [value in string]?: number };
  },
  finished?: boolean,
) => {
  const errorsData: string[] = [];

  Object.entries(counter?.errors ?? {}).forEach((error) => {
    if ((error[1] as number) > 0) {
      errorsData.push(
        `<div class="d-flex align-center flex-gap">
          <img src="${imgSrc}" alt="loading"> ${error[1]}/${counter?.all} ${underscoreToCapitalizedString(error[0])}
        </div>`,
      );
    }
  });
  return `
        ${message} ${finished ? 'finished' : percent + ' %'}  ${
          counter ? `(${counter?.processing}/${counter?.all} processed)` : ''
        }
        ${errorsData.length > 0 ? '<hr>' : ''}
        ${errorsData.join('')}
        `;
};
