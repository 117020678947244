import { AssetFlat, OrderAssetCombinedStatus } from '../asset';
import { ApprovalStatus } from './order-status.model';
import { modelContext } from '../cart/models/cart.model';
import { JobStatus } from '../jobs/jobs.model';

export interface OrderAssetModel {
  expires_at: string | null;
  uuid: string;
  asset: AssetFlat;
  approval_status: ApprovalStatus;
  download_uuid: string;
  downloaded?: boolean;
  combined_status: OrderAssetCombinedStatus;
  delivery_destinations?: DeliveryDestination[];
  workflow_jobs: GetOrderItemWorkflowJob[];
  clipping_status: string;
  clip_sales_force_name: string;
  sales_force_uri: string;
  note: string;
  timecode_in: string;
  timecode_out: string;
  transcode_status?: TranscodeStatuses;
  transcode_progress: number | null;
  external_id?: string;
  publish_status: string;
  publish_progress: number | null;
  remaining_downloads: number;
  delivered_filename?: string;
}

export interface DeliveryDestination {
  name: string;
  uuid: string;
  configs: DeliveryDestinationConfig[];
  summary?: string;
}

export interface DeliveryDestinationConfig {
  name: string;
  path: string;
  jobs: GetOrderItemDeliveryDestinationJob[];
  uuid: string;
}

export interface GetOrderItemDeliveryDestinationJob {
  progress: number;
  type: DeliveryDestinationJobTypeEnum;
  status: DeliveryDestinationJobStatusEnum;
  extra?: DeliveryDestinationJobExtra;
}

export interface DeliveryDestinationJobExtra {
  newAssetUuids: string[];
}

export enum DeliveryDestinationJobTypeEnum {
  TRANSCODE = 'transcode',
  PUBLISH = 'publish',
}
export enum DeliveryDestinationJobStatusEnum {
  SUBMITTED = 'submitted',
  STARTED = 'started',
  FAILED = 'failed',
  COMPLETED = 'completed',
  NOT_INITIALIZED = 'not_initialized',
}

export interface GetOrderItemWorkflowJob {
  type: modelContext;
  status: JobStatus;
  name: string;
  start_date: string;
  end_date: string;
}

export enum DownloadTypeEnum {
  ASPERA = 'ASPERA',
  HTTPS = 'HTTPS',
}

export type OrderDownloadPayload = {
  download_uuids: string[];
  keep_folders_structure: boolean;
  config_uuid?: string;
  download_type?: keyof typeof DownloadTypeEnum;
};

export interface OrderPatchItemModel {
  uuid: string;
  status: 'approved' | 'rejected';
}

export interface RetryDeliveryDestinationJob {
  item_uuid: string;
  config_uuid: string;
  status: 'success' | 'error';
  message: string;
}

export interface RetryDeliveryDestination {
  jobs: Omit<RetryDeliveryDestinationJob, 'status' | 'message'>[];
}

export type TranscodeStatuses = 'submitted' | 'in-progress' | 'completed' | 'failed';
