import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import {
  ConnectableDataSource,
  E2eIdDirective,
  SelectableDataSource,
  SortableDataSource,
  ValueDisplayFormatConfig,
} from '@vdms-hq/shared';
import { CommonModule } from '@angular/common';
import { RuntimeErrorComponent } from '../components/runtime-error/runtime-error.component';
import { ConfigActions, ContextMenu, TranslatableName } from '../logic/common-config';
import { TileImageComponent } from '../components/tile-image/tile-image.component';
import { ValueRendererComponent } from '../../ui-value-renderer';
import { FloatingControlsV2Component } from '../components/floating-controls-v2/floating-controls-v2.component';
import { TranslateModule } from '@ngx-translate/core';
import { SelectOneComponent } from '../components/select-one/select-one.component';
import { ContextMenuComponent } from '../components/context-menu/context-menu.component';
import { IsRowTooltipPipe } from '../pipes/is-row-tooltip.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MetadataHiddenPipe } from '../pipes/metadata-hidden.pipe';
import { ValueByPathPipe } from '../pipes/value-by-path.pipe';
import { DisableIfRowPipe } from '../pipes/disable-if-row.pipe';
import { RowViewConfig } from '../logic/models';
import { BaseViewLogicComponent } from '../base-view-logic/base-view-logic.component';

export type GridAdvancedDataSource<T> = ConnectableDataSource<T> &
  Partial<SortableDataSource> &
  Partial<SelectableDataSource<T>>;

export type GridAdvancedMetadata<T> = {
  label: TranslatableName;
  viewFormat?: ValueDisplayFormatConfig;
  valuePath: string;
  fullLine?: boolean;
  hiddenIf?: (item: T) => boolean;
  icon?: (item: T) => string;
};

export type GridAdvancedAdditionalInfo = {
  label?: TranslatableName;
  valuePath: string;
  viewFormat?: ValueDisplayFormatConfig;
};

export type GridAdvancedConfig<T> = ContextMenu<T> &
  ConfigActions<T> & {
    rows?: RowViewConfig<T>;
    content?: Partial<{
      iconPath: string;
      titlePath: string;
      additionalInfo?: GridAdvancedAdditionalInfo;
      metadata: GridAdvancedMetadata<T>[];
      pin?: {
        pinValue1Path?: string;
        pinValue2Path?: string;
        pinSuccessIf?: (item: T) => boolean;
        pinLabel?: (item: T) => string;
      };
    }>;
    layout?: {
      columns?: 'up-to-2';
    };
    image?: Partial<{
      bgPath: string;
      bgHoverPath: string;
    }>;
  };

@Component({
  selector: 'vdms-hq-ui-grid-advanced',
  templateUrl: './grid-advanced.component.html',
  styleUrls: ['./grid-advanced.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RuntimeErrorComponent,
    TileImageComponent,
    ValueByPathPipe,
    ValueRendererComponent,
    FloatingControlsV2Component,
    TranslateModule,
    SelectOneComponent,
    ContextMenuComponent,
    IsRowTooltipPipe,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    MetadataHiddenPipe,
    DisableIfRowPipe,
    E2eIdDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridAdvancedComponent<T>
  extends BaseViewLogicComponent<GridAdvancedDataSource<T>, GridAdvancedConfig<T>, T>
  implements AfterViewInit, OnDestroy
{
  @ViewChild('gridAdvanced') gridAdvanced?: ElementRef;

  ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver((entries) => {
      const parentElement = entries[0].target as HTMLDivElement;
      const parentWidth = parentElement.clientWidth;
      const changeToOneColumnWidth = 1100;

      if (parentWidth < changeToOneColumnWidth) {
        parentElement.classList.replace('grid-advanced--up-to-2', 'grid-advanced--up-to-1');
      } else {
        parentElement.classList.replace('grid-advanced--up-to-1', 'grid-advanced--up-to-2');
      }
    });
    this.resizeObserver.observe(this.gridAdvanced?.nativeElement);
  }

  ngOnDestroy() {
    if (!this.gridAdvanced?.nativeElement) {
      return;
    }
    this.resizeObserver?.unobserve(this.gridAdvanced?.nativeElement);
  }
}
