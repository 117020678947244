import { signal } from '@angular/core';
import { FieldConfigId } from '@vdms-hq/fields';

export class EnabledFieldsVo {
  #enabledList = signal([] as FieldConfigId[]);

  isEnabled = (fieldId: FieldConfigId): boolean => {
    return this.#enabledList().includes(fieldId);
  };

  isDisabled = (fieldId: FieldConfigId): boolean => {
    return !this.#enabledList().includes(fieldId);
  };

  toggle = (id: FieldConfigId) => {
    const currentState = this.#enabledList();

    if (currentState.includes(id)) {
      this.#enabledList.set(currentState.filter((item) => item !== id));
    } else {
      this.#enabledList.set([...currentState, id]);
    }
  };

  enable = (fieldId: FieldConfigId): void => {
    this.#enabledList.update((current) => {
      const next = current.filter((item) => item !== fieldId);

      return [...next, fieldId];
    });
  };

  disable = (fieldId: FieldConfigId): void => {
    this.#enabledList.update((current) => {
      const next = current.filter((item) => item !== fieldId);

      return [...next];
    });
  };

  enabledList = (): FieldConfigId[] => {
    return this.#enabledList();
  };

  reset = (): void => {
    this.#enabledList.set([]);
  };
}
