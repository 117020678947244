import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { SelectOption } from '@vdms-hq/shared';
import { SelectorSourceType } from '../selector-source.type';
import { map, tap } from 'rxjs/operators';
import { LanguagesApiService } from '@vdms-hq/api-contract';
import { SourceProvider } from '../source-provider';

@Injectable({
  providedIn: 'root',
})
export class LanguagesSourceService implements SourceProvider {
  #isLoadingSubject$ = new BehaviorSubject(true);
  isLoading$ = this.#isLoadingSubject$.asObservable();

  #all = this.languagesApiService
    .getList()
    .pipe(shareReplay(1))
    .pipe(
      tap(() => {
        this.#isLoadingSubject$.next(false);
      }),
    );

  supports(type: SelectorSourceType): boolean {
    return type == SelectorSourceType.LANGUAGE || false;
  }

  constructor(private languagesApiService: LanguagesApiService) {}

  listByType(): Observable<SelectOption[]> {
    return this.#all.pipe(
      map((items) =>
        items.map((item) => {
          const { code } = item;

          const additional = code ? ` (${code})` : '';

          return {
            ...item,
            key: item.uuid,
            label: `${item.name}${additional}`,
          };
        }),
      ),
    );
  }
}
