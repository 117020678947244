import { Routes } from '@angular/router';
import { AuthAdobeComponent } from './components/auth-adobe/auth-adobe.component';

export const AUTH_ADOBE_ROUTING: Routes = [
  {
    path: '',
    component: AuthAdobeComponent,
  },
  {
    path: '**',
    redirectTo: `/adobe`,
    pathMatch: 'full',
  },
];
