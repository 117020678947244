import { inject, Injectable } from '@angular/core';
import { AssetApiService, AssetPatch } from '@vdms-hq/api-contract';
import { FormBuilderService } from '@vdms-hq/dynamic-form';
import { FieldRelationsServiceV2 } from '@vdms-hq/dynamic-form';
import { replaceEmptyStringWithNull } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { BehaviorSubject, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActiveAssetService } from './active-asset.service';
import { AssetViewModel } from '../models';
import { AssetQuickPreviewFormService } from './asset-quick-preview-form.service';

@Injectable({ providedIn: 'root' })
export class ActiveAssetActionService {
  assetApiService = inject(AssetApiService);
  toastService = inject(ToastService);
  activeAsset = inject(ActiveAssetService);
  assetQuickPreviewForm = inject(AssetQuickPreviewFormService);
  formBuilder = inject(FormBuilderService<AssetViewModel>);
  fieldRelationsService = inject(FieldRelationsServiceV2);

  popToast = {
    UPDATE_ASSET_PROCESSING: () =>
      this.toastService.processing({
        id: 'update_asset',
        message: `common.notifications.save_asset.processing`,
      }),
    UPDATE_ASSET_SUCCESS: () =>
      this.toastService.success({
        id: 'update_asset',
        message: 'common.notifications.save_asset.done',
      }),
  };

  id$ = this.activeAsset.currentIdDefinite$;
  isSaving$ = new BehaviorSubject<boolean>(false);

  save(params: { withFinish: boolean; withLock?: boolean; newFormBuilder?: boolean }) {
    if (this.assetQuickPreviewForm.formGroup.invalid) {
      this.assetQuickPreviewForm.formGroup.markAllAsTouched();
      return;
    }
    // todo: Remove this line after global implementation of formBuilder Component
    if (this.formBuilder.formGroup?.invalid) {
      this.formBuilder.formGroup?.markAllAsTouched();
      return;
    }

    //todo remove formBuilder service and params.newFormBuilder after global implementation of formBuilder Component
    const value = params.newFormBuilder
      ? this.assetQuickPreviewForm.formGroup.controls.assetData.value
      : this.formBuilder.modifiedValue;
    let asset = value;

    if (params.withFinish) {
      asset = {
        ...value,
        general: {
          ...value?.general,
          stage: 1,
        },
      } as AssetViewModel;
    }

    const payload = replaceEmptyStringWithNull(asset) as AssetPatch;

    this.isSaving$.next(true);
    this.updateAsset(
      payload,
      () => {
        this.isSaving$.next(false);
      },
      () => {
        this.isSaving$.next(false);
      },
    );
    this.fieldRelationsService.resetFilters();

    // todo: Remove this line after global implementation of formBuilder Component
    this.formBuilder.reset(this.formBuilder.value, params.withLock ?? false);
  }

  updateAsset(json: AssetPatch, callbackSuccess?: () => void, callbackFail?: () => void) {
    this.popToast.UPDATE_ASSET_PROCESSING();

    this.id$
      .pipe(
        take(1),
        switchMap((uuid) => this.assetApiService.updateAsset(json, uuid)),
      )
      .subscribe({
        next: () => {
          this.popToast.UPDATE_ASSET_SUCCESS();
          callbackSuccess && callbackSuccess();
        },
        error: () => {
          callbackFail && callbackFail();
        },
      });
  }
}
