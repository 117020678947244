import { Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { FlatOrderViewModel, OrdersDataSource } from '../../logic/order-results-ds';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { FiltersOrderForm } from '../../logic/filters-order-form';
import {
  ActionContextLess,
  DataAction,
  DataPresentationHeaderComponent,
  DefaultMultiViewType,
  GridAdvancedAdditionalInfo,
  GridAdvancedMetadata,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIButtonModule,
  UIConfirmationDialogService,
  UILayoutModule,
} from '@vdms-hq/ui';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, defer, Observable, Subject, switchMap } from 'rxjs';
import { ResultsActions } from '@vdms-hq/asset-results';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { ToastService } from '@vdms-hq/toast';
import {
  defaultOrdersColumns,
  defaultSalesForceOrdersColumns,
  ordersMetadata,
} from '../../../details/logic/view-configurations';
import { TABLE_TYPE, ViewSettingsService } from '@vdms-hq/view-settings';
import { ActivatedClientModule, ActivatedClientService, Permission, WithPermissions } from '@vdms-hq/activated-client';
import { salesForceFilters, salesForceFiltersOnlyMine } from '../../../logic/config';
import { OrderService, OrdersType, PaginationAPIModel as Pagination } from '@vdms-hq/api-contract';
import { OrderActionsProperties } from '../../../details/logic/models';
import { OrderActionsService } from '../../../details/logic/order-actions.service';
import { FieldsFetcherService, FieldsScopeKey } from '@vdms-hq/fields';

@Component({
  selector: ' vdms-hq-orders-results[tableType][columnsSettingsScope]',
  templateUrl: './results.component.html',
  imports: [
    CommonModule,
    DynamicFiltersModule,
    DataPresentationHeaderComponent,
    MultipleDataPresentationComponent,
    TranslateModule,
    UILayoutModule,
    UIButtonModule,
    ActivatedClientModule,
  ],
  standalone: true,
})
export class ResultsComponent extends WithPermissions() implements OnDestroy {
  dataSource = inject(OrdersDataSource);
  filtersForm = inject(FiltersOrderForm);
  #confirmationDialog = inject(UIConfirmationDialogService);
  #toastService = inject(ToastService);
  #activatedClientService = inject(ActivatedClientService);
  #viewSettingsService = inject(ViewSettingsService);
  #fieldsConfigService = inject(FieldsFetcherService);
  #orderApiService = inject(OrderService);
  #orderActionsService = inject(OrderActionsService);

  @Input() actions: DataAction<FlatOrderViewModel>[] = [];
  @Input() tableType!: TABLE_TYPE;
  @Input() columnsSettingsScope!: FieldsScopeKey;
  @Input() filtersConfig: DynamicFilterInput[] = [];
  @Input() title = 'common.orders.title';
  @Output() changeDefaultView: EventEmitter<DefaultMultiViewType> = new EventEmitter<DefaultMultiViewType>();

  @Output() action = new EventEmitter<{
    item?: FlatOrderViewModel;
    key: string;
  }>();
  #destroy$ = new Subject<void>();

  protected readonly OrderActionsProperties = OrderActionsProperties;
  protected readonly ResultsActions = ResultsActions;

  configuration$: Observable<MultipleViewConfiguration<FlatOrderViewModel>> = combineLatest({
    client: this.#activatedClientService.clientDefinite$,
    permissions: this.#activatedClientService.permissions$,
    defaultView: defer(() => this.#viewSettingsService.defaultViewFor$(this.tableType)),
    fieldsConfig: defer(() => this.#fieldsConfigService.getConfiguration$(this.columnsSettingsScope)),
  }).pipe(
    map(({ client, permissions, defaultView, fieldsConfig }) => {
      let metadata: GridAdvancedMetadata<FlatOrderViewModel>[] = [];
      let additionalInfo: GridAdvancedAdditionalInfo | undefined = undefined;
      if (client.integrations.salesforce) {
        this.filtersConfig = salesForceFiltersOnlyMine;
        if (
          permissions.some((perm) =>
            [Permission.EDIT_ORDERS, Permission.APPROVE_ORDERS, Permission.BROWSE_SHARED_ORDERS].includes(perm),
          )
        ) {
          this.filtersConfig = salesForceFilters;
        }
        metadata = defaultSalesForceOrdersColumns
          .map((metadata) => ordersMetadata(true).find((item) => item.valuePath === metadata))
          .filter((item): item is GridAdvancedMetadata<FlatOrderViewModel> => !!item);

        additionalInfo = {
          valuePath: 'combinedOrderStatus',
          viewFormat: {
            pills: {
              scheduled: 'yellow-dashed',
              processing: 'yellow',
              'ready for download': 'done-light',
              downloaded: 'cold-blue',
              complete: 'done',
              error: 'failed',
              delivering: 'orange',
              default: 'orange',
            },
            modifiers: {
              translate: true,
            },
          },
        };
      } else {
        metadata = defaultOrdersColumns
          .map((metadata) => ordersMetadata().find((item) => item.valuePath === metadata))
          .filter((item): item is GridAdvancedMetadata<FlatOrderViewModel> => !!item);

        additionalInfo = {
          valuePath: 'combinedOrderStatus',
          viewFormat: {
            pills: {
              scheduled: 'yellow-dashed',
              processing: 'yellow',
              'ready for download': 'done-light',
              downloaded: 'cold-blue',
              complete: 'done',
              error: 'failed',
              delivering: 'orange',
              default: 'orange',
            },
          },
        };
      }
      const enabledActions = this.actions?.filter((item) => !!item) as DataAction<FlatOrderViewModel>[];

      return {
        multiView: {
          defaultView: defaultView,
        },
        tableAdvanced: {
          actions: enabledActions,
          columnsEnabled: fieldsConfig.table.getVisibleKeys(),
          columns: fieldsConfig.table.getColumns(),
          settingsAction: {
            id: 'settings',
            label: 'common.global.edit_columns',
            icon: 'settings',
            color: 'transparent',
          },
        },
        gridAdvanced: {
          content: {
            titlePath: 'title',
            metadata: metadata,
            additionalInfo: additionalInfo ?? undefined,
          },
          image: {
            bgPath: 'background',
            bgHoverPath: 'background',
          },
          actions: enabledActions,
        },
        gridTiles: {
          layout: {
            columns: 'up-to-6',
          },
          image: {
            bgPath: 'background',
            bgHoverPath: 'background',
          },
          content: {
            titlePath: 'title',
          },
          actions: enabledActions,
        },
      };
    }),
  );

  headerActions: ActionContextLess[] = [
    {
      key: ResultsActions.EXPORT,
      label: 'pages.assets_list.export',
      icon: 'forward_to_inbox',
    },
  ];

  downloadManyDisabled$ = this.dataSource.selection.entities$.pipe(
    filter((selection) => !!selection),
    map((orders) => !orders.some((order) => order.isDelivered)),
  );

  downloadManyLoading$ = this.#orderActionsService.downloadManyLoading$;

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  emitAction($event: { item: FlatOrderViewModel; key: string }) {
    this.action.emit($event);
  }

  handleHeaderAction($event: { key: string; item?: FlatOrderViewModel }) {
    switch ($event.key) {
      case ResultsActions.EXPORT:
        this.#confirmationDialog
          .open({
            title: 'Please confirm export action',
            message: 'If you confirm, we’ll send you a CSV with applied filters via email shortly.',
          })
          .pipe(
            take(1),
            filter((confirmed) => confirmed),
            withLatestFrom(this.#fieldsConfigService.getConfiguration$(this.columnsSettingsScope)),
            map(([, config]) => config.table.getForExport()),
            withLatestFrom(this.dataSource.filtersValues$, this.dataSource.pageIndex$, this.dataSource.pageSize$),
            switchMap(([fields, filters, pageIndex, pageSize]) => {
              const filtersObject = {
                'delivery-status': filters.deliveryStatus,
                'name-subject': filters.nameSubject,
                'download-status': filters.downloadStatus,
                'orders-type': filters.ordersType as OrdersType,
                'date-from': filters.txDate?.from.toISOString(),
                'date-to': filters.txDate?.to.toISOString(),
                status: filters.status,
              };
              const paginationObject = Pagination.create({
                page: pageIndex,
                perPage: pageSize,
                ...filters.sort,
              });
              return this.#orderApiService.export(fields, filtersObject, paginationObject);
            }),
            tap(() =>
              this.#toastService.success({
                id: 'export',
                message: 'notifications.export.done',
              }),
            ),
          )
          .subscribe();
        break;
    }
  }
}
