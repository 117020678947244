<vdms-hq-ui-dialog-wrapper>
  <h1 title>CONNECT2 email settings</h1>

  <div content class="content">
    <vdms-hq-ui-loader *ngIf="formService.initializing$ | async; else form" mode="over-parent"> </vdms-hq-ui-loader>

    <ng-template #form>
      <mat-tab-group
        class="material-custom-tab material-custom-accordion-admin"
        [selectedIndex]="selectedTab"
        [formGroup]="formService.form"
      >
        <ng-container *ngFor="let eventType of formService.events">
          <mat-tab>
            <ng-template mat-tab-label>
              {{ eventType }}
            </ng-template>
            <ng-container [formArrayName]="eventType" *ngIf="formService.form.controls[eventType]; let policies">
              <vdms-hq-ui-form-section
                [layout]="'single'"
                [divider]="false"
                title="Policy {{ policyIndex + 1 }}"
                *ngFor="let policyControl of policies.controls; let policyIndex = index"
                [formGroupName]="policyIndex"
              >
                <vdms-hq-ui-button
                  action
                  icon="delete"
                  [iconOnly]="true"
                  color="warn"
                  (click)="formService.removePolicy(eventType, policyIndex)"
                ></vdms-hq-ui-button>
                <vdms-hq-ui-form-input-select
                  label="Restricted to policy"
                  formControlName="policy_uuid"
                  class="policy-selector"
                  [loading]="(formService.policiesLoading$ | async) ?? true"
                  [selectOptions]="(formService.emptyPolicies$ | async) ?? []"
                  [withFooter]="false"
                ></vdms-hq-ui-form-input-select>

                <div class="filters" formArrayName="filters">
                  <mat-accordion
                    class="material-custom-accordion-v2"
                    *ngFor="let filter of policyControl.controls.filters.controls; let filterIndex = index"
                    [formGroupName]="filterIndex"
                  >
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title> Filters #{{ filterIndex + 1 }} </mat-panel-title>
                        <mat-panel-description>
                          <mat-icon
                            (click)="
                              $event.stopPropagation(); formService.removeFilterInPolicy(policyControl, filterIndex)
                            "
                          >
                            delete
                          </mat-icon>
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <div>
                        <vdms-hq-ui-form-input-select
                          formControlName="key"
                          label="Filter"
                          [selectOptions]="(formService.filtersDefinitionsForEmail$ | async) ?? []"
                          [isSortedByLabel]="true"
                          [withFooter]="false"
                        ></vdms-hq-ui-form-input-select>
                        <vdms-hq-ui-form-input-chips
                          formControlName="values"
                          label="Filter values"
                          [withFooter]="false"
                        ></vdms-hq-ui-form-input-chips>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <vdms-hq-ui-button color="secondary" (click)="formService.addNewFilterInPolicy(policyControl)">
                    Add new filter
                  </vdms-hq-ui-button>
                </div>
              </vdms-hq-ui-form-section>
            </ng-container>
            <vdms-hq-ui-button color="secondary" (click)="formService.addNewPolicy(eventType)"
              >Add new policy rule
            </vdms-hq-ui-button>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </ng-template>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="cancel()">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button color="primary" (click)="save()" [disabled]="formService.form.invalid"
      >{{ 'common.global.save' | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
