export interface TextToSpeechBodyModel {
  text: string;
  model_id: string;
  voice_settings?: VoiceSettings;
}

export interface VoiceSettings {
  stability: number;
  similarity_boost: number;
  style?: number;
  use_speaker_boost?: boolean;
}

export interface VoicesResponse {
  voices: Voice[];
}

export interface Voice {
  voice_id: string;
  name: string;
  samples: null;
  category: Category;
  fine_tuning: FineTuning;
  labels: Labels;
  description: null;
  preview_url: string;
  available_for_tiers: unknown;
  settings: null;
  sharing: null;
  high_quality_base_model_ids: HighQualityBaseModelID[];
}

enum Category {
  Premade = 'premade',
}

interface FineTuning {
  language: null;
  is_allowed_to_fine_tune: boolean;
  fine_tuning_requested: boolean;
  finetuning_state: FinetuningState;
  verification_attempts: null;
  verification_failures: unknown;
  verification_attempts_count: number;
  slice_ids: null;
  manual_verification: null;
  manual_verification_requested: boolean;
}

enum FinetuningState {
  NotStarted = 'not_started',
}

enum HighQualityBaseModelID {
  ElevenMultilingualV1 = 'eleven_multilingual_v1',
}

interface Labels {
  accent: string;
  description?: string;
  age: Age;
  gender: Gender;
  'use case'?: string;
  'description '?: string;
  usecase?: string;
}

enum Age {
  MiddleAged = 'middle aged',
  Old = 'old',
  Young = 'young',
}

enum Gender {
  Female = 'female',
  Male = 'male',
}
