import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryDestinationConfig, DeliveryDestinationJobStatusEnum } from '@vdms-hq/api-contract';
import { OrderAssetViewModel } from '../../logic/models';
import { isEmbargoActive } from '@vdms-hq/shared';

@Pipe({
  name: 'canRefreshDelivery',
  pure: true,
  standalone: true,
})
export class RefreshDeliveryDestinationPipe implements PipeTransform {
  transform(config: DeliveryDestinationConfig): boolean {
    return config.jobs.some(({ status }) => status === DeliveryDestinationJobStatusEnum.FAILED);
  }
}
