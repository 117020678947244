import { AssetFlat } from '../asset/models/asset-flat.model';
import { AssetPatch } from '../asset';

/**
 * @remarks Be aware of changing keys of the DeliveryUploadJobStatus - it's being used as the source of TotalStats
 * @see TotalStats
 */
export enum DeliveryUploadJobStatus {
  DRAFT = 'draft',
  PENDING = 'pending_approval',
  APPROVED = 'approved',
  COMPLETED = 'completed',
  APPROVED_COMPLETED = 'approved|completed',
  REJECTED = 'rejected',
  ERROR = 'error',
  DELETED = 'deleted',
  ALL = 'draft|pending_approval|approved|completed|rejected|error',
}

export interface DeliveryUploadJobFilterParams {
  status: DeliveryUploadJobStatus;
  asCoordinator: boolean;
  sortBy: string;
}

type EmptyObject = Record<string, never>;

type MetadataAssetObject =
  | {
      rejected_by?: string;
      rejected_details?: string;
      rejected_reason?: string;
      owner_name?: string;
      owner_email?: string;
      name?: string;
    }
  | EmptyObject;

export interface DeliveryUploadJobPost {
  metadata: MetadataAssetObject;
  asset: {
    internalFilename: string;
    originalFilename: string;
  };
}

export interface AsperaFiles {
  uuid: string;
  catalog_item?: string;
  originalFilename: string;
  internalFilename: string;
  batchId: string;
  sourcePath: string;
  embargoed_to?: string;

  /** @deprecated, use batchUpdate instead */
  series_title?: string;
  /** @deprecated, use batchUpdate instead */
  theme?: string;
  /** @deprecated, use batchUpdate instead */
  contentClass?: string;
}

export interface DeliveryUploadJobAsperaPost {
  deliveryPackUuid?: string;
  files?: AsperaFiles[];
  batchUpdate?: Partial<AssetPatch>;
}

export interface DeliveryUploadJobReUploadPatch {
  deliveryPackUuid?: string;
  file?: AsperaFiles;
  asset?: AssetFlat;
}

export interface DeliveryUploadJobAsperaPostResponse {
  token: string;
  cookie: string;
  uploadJobs: DeliveryUploadJobGetFlat[];
  destinationRoot: string;
  remoteHost?: string;
  remoteUser?: string;
  sshPort?: number;
}

export interface DeliveryUploadJobAsperaPatchResponse {
  token: string;
  cookie: string;
  destinationRoot: string;
  uploadJobs: DeliveryUploadJobGetFlat[];
  remoteHost?: string;
  remoteUser?: string;
  sshPort?: number;
}

export type DeliveryUploadJobGetFlat = DeliveryUploadJob;

export interface DeliveryUploadJobGet {
  data: DeliveryUploadJob;
  status: 'success';
  total: number;
}

export interface DeliveryUploadJobResolveDetails {
  resolved_at?: null | string;
  resolved_details?: null | string;
  resolved_reason: null | string;
}

export interface DeliveryUploadJobRejectReasonDetails {
  rejected_by?: string;
  rejected_details?: string;
  rejected_reason?: string;
  rejected_at?: string;
  resolving_method?: string;
}

export interface DeliveryUploadJobAsset extends DeliveryUploadJobRejectReasonDetails, DeliveryUploadJobResolveDetails {
  asset: AssetFlat;
}

export interface DeliveryUploadJob extends DeliveryUploadJobRejectReasonDetails, DeliveryUploadJobResolveDetails {
  asset_history: DeliveryUploadJobAsset[];
  created_at: Date;
  error?: any;
  is_reuploaded?: boolean;
  is_updated?: boolean;
  latest_asset: DeliveryUploadJobAsset;
  metadata?: MetadataAssetObject;
  status: DeliveryUploadJobStatus;
  updated_at: Date;
  upload_access?: {
    Bucket: string;
    Key: string;
    UploadId: string;
  };
  uuid: string;
}
