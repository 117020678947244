import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { MatIconModule } from '@angular/material/icon';
import { UIModuleConfiguration } from './models/module-config.interface';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatButtonToggleDefaultOptions } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { configureValueRenderer } from './ui-value-renderer/configure-value-renderer';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorDefaultOptions } from '@angular/material/paginator';
import { configureUI } from './models/configure-ui';

const materialModules = [LayoutModule, MatIconModule, MatButtonModule, MatDividerModule, MatDialogModule];

@NgModule({
  imports: [materialModules, RouterModule],
  exports: materialModules,
})
export class UIModule {
  static forRoot(configuration?: UIModuleConfiguration): ModuleWithProviders<UIModule> {
    const providers: Provider[] = [
      {
        provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
        useValue: <MatPaginatorDefaultOptions>{ formFieldAppearance: 'fill' },
      },
      ...configureUI(configuration?.params ?? {}),
    ];

    const tzDatePipe = configuration?.timezoneDatePipe;
    if (tzDatePipe) {
      providers.push(
        ...configureValueRenderer({
          timezoneDatePipe: tzDatePipe,
        }),
      );
    }

    if (!configuration) {
      const matFormFieldOptions: MatFormFieldDefaultOptions = { appearance: 'outline' };
      const matButtonToggleOptions: MatButtonToggleDefaultOptions = { appearance: 'standard' };
      configuration = {
        material: {
          MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS: { appearance: matButtonToggleOptions.appearance },
          MAT_FORM_FIELD_DEFAULT_OPTIONS: { appearance: matFormFieldOptions.appearance },
        },
      };
    }

    const entries = configuration.material ? Object.entries(configuration.material) : [];

    entries.forEach((entry) => {
      providers.push({ provide: entry[0], useValue: entry[1] });
    });

    return {
      ngModule: UIModule,
      providers,
    };
  }
}
