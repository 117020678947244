<div class="stats" [class.full-width]="fullWidth" [class.without-padding]="withoutPadding">
  <p class="stats__header">
    <mat-icon class="stats__icon">{{ statsConfig.icon }}</mat-icon>
    <span class="stats__header__title">{{ statsConfig.title }}</span>
    @if (actionLabel) {
      <span class="stats__header__title--action" (click)="action.emit()">{{ actionLabel }}</span>
    }
  </p>
  <mat-divider></mat-divider>
  <div class="stats__content">
    @switch (statsConfig.type) {
      @case (StatsType.STATIC) {
        <vdms-hq-ui-stats-static [statsStaticVM]="$any(statsConfig.viewModel)"></vdms-hq-ui-stats-static>
      }
      @case (StatsType.CHART_BAR_CSS) {
        <vdms-hq-ui-stats-css-chart-bar [chartData]="$any(statsConfig.viewModel)"></vdms-hq-ui-stats-css-chart-bar>
      }
      @case (StatsType.CHART_LINE) {
        <vdms-hq-ui-stats-chart-line
          style="height: 100%; display: block"
          [statsChartVM]="$any(statsConfig.viewModel)"
        ></vdms-hq-ui-stats-chart-line>
      }
      @case (StatsType.CHART_V2) {
        <vdms-hq-ui-stats-chart-v2
          style="height: 100%; display: block"
          [statsChartVM]="$any(statsConfig.viewModel)"
        ></vdms-hq-ui-stats-chart-v2>
      }
      @case (StatsType.CHART_PIE) {
        <vdms-hq-ui-stats-chart-pie [statsChartVM]="$any(statsConfig.viewModel)"></vdms-hq-ui-stats-chart-pie>
      }
      @case (StatsType.COUNTER) {
        <vdms-hq-ui-stats-counter [stats]="$any(statsConfig.viewModel)"> </vdms-hq-ui-stats-counter>
      }
      @case (StatsType.GRID) {
        <vdms-hq-ui-stats-grid [statsStaticVM]="$any(statsConfig.viewModel)"> </vdms-hq-ui-stats-grid>
      }
    }

    <ng-content></ng-content>
  </div>
</div>
