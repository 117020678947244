import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { UIDialogWrapperModule } from '../../../ui-dialog-wrapper';
import { PortalsToRender } from '../../logic/drawer-dialog.service';
import { PortalModule } from '@angular/cdk/portal';

@Component({
  selector: 'vdms-hq-ui-dialog-drawer-for-drawer',
  templateUrl: './dialog-drawer-for-drawer.component.html',
  styleUrls: ['./dialog-drawer-for-drawer.component.scss'],
  standalone: true,
  imports: [CommonModule, UIDialogWrapperModule, PortalModule, MatDialogModule],
})
export class DialogDrawerForDrawerComponent {
  cdr = inject(ChangeDetectorRef);

  @Input() portals?: PortalsToRender;

  detectChanges() {
    this.cdr.markForCheck();
  }
}
