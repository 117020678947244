<vdms-hq-ui-dialog-wrapper>
  <div title>
    <span>{{ 'common.global.batch_update' | translate }}</span>
  </div>
  <div content class="dialog-content dialog-content-bigger">
    <form [formGroup]="formGroup">
      @if (containers$ | async; as containers) {
        @for (container of containers; track container.containerId; let isLast = $last) {
          <div class="content">
            <div class="container__content-title">
              <h2>{{ container.containerName }}</h2>
            </div>
            <div class="container__content-fields">
              <vdms-hq-form-builder
                [formControlName]="container.containerId"
                [definitions]="container.elements"
                [content]="{ enabled: 'common.global.overwrite' }"
                [enabledFields]="enabledFields"
              ></vdms-hq-form-builder>
            </div>
            @if (!isLast) {
              <mat-divider></mat-divider>
            }
          </div>
        }
        @if (containers.length === 0) {
          <vdms-hq-ui-empty-results
            icon="warning"
            [message]="'pages.assets_list.missing_config' | translate"
          ></vdms-hq-ui-empty-results>
        }
      } @else {
        <vdms-hq-ui-loader mode="default" [centerPadding]="true" [backdrop]="true" [center]="true"></vdms-hq-ui-loader>
      }
    </form>
  </div>

  <ng-container footer>
    <div class="buttons-group">
      <vdms-hq-ui-button (click)="close()" color="secondary">Close</vdms-hq-ui-button>
      <vdms-hq-ui-button (click)="clear()" color="warn">Clear</vdms-hq-ui-button>
    </div>
    @if (containers$ | async) {
      <vdms-hq-ui-button (click)="save()" color="primary" [disabled]="formGroup.invalid"
        >Save ({{ data.assetsCounter }})</vdms-hq-ui-button
      >
    }
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
