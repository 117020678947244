<vdms-hq-ui-dialog-wrapper>
  <h1 title>
    {{ title | translate }}
  </h1>
  <form [formGroup]="form" content>
    <vdms-hq-ui-form-section [layout]="'single'" [divider]="false">
      <vdms-hq-ui-form-input-text
        formControlName="label"
        [withFooter]="true"
        [label]="'common.transcode_base.containers.form.label' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-text
        formControlName="kind"
        [withFooter]="true"
        [label]="'common.transcode_base.containers.form.kind' | translate"
      ></vdms-hq-ui-form-input-text>
    </vdms-hq-ui-form-section>
  </form>
  <ng-container footer>
    <vdms-hq-ui-button color="secondary" (click)="closeDialog()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      *ngIf="editMode$ | async as editMode"
      color="primary"
      [loading]="(isLoading$ | async) ?? false"
      (click)="editMode === 'edit' ? update() : create()"
      [disabled]="form.invalid || form.pristine"
    >
      {{ saveButton | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
