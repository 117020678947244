import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatsChartViewModelV2 } from '../logic/models/stats-chart-view.model';
import { NgChartsModule } from 'ng2-charts';
import { NgStyle } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

export interface StatsCounterViewModel {
  number: number;
  numberSubtitle: string;
  text: string;
  arrow: 'up' | 'down' | false;
}

@Component({
  selector: 'vdms-hq-ui-stats-counter',
  templateUrl: './stats-counter.component.html',
  styleUrls: ['./stats-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgChartsModule, NgStyle, MatIcon],
  standalone: true,
})
export class StatsCounterComponent {
  @Input({ required: true }) stats!: StatsCounterViewModel;
}
