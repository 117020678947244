import { Injectable } from '@angular/core';
import { startWith, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RefreshService {
  private defaultKey = 'default';
  private refreshMap = new Map<string, Subject<boolean>>([[this.defaultKey, new Subject<boolean>()]]);

  refresh$ = this.getRefreshObservable().pipe(startWith(true));

  getRefreshObservable(key: string = this.defaultKey) {
    if (!this.refreshMap.has(key)) {
      this.refreshMap.set(key, new Subject<boolean>());
    }
    return this.refreshMap.get(key)!.asObservable().pipe(startWith(true));
  }

  refresh(key: string = this.defaultKey) {
    if (!this.refreshMap.has(key)) {
      this.refreshMap.set(key, new Subject<boolean>());
    }
    this.refreshMap.get(key)!.next(true);
  }

  /**
   *
   * @param key identifier for refresher
   * @returns **true** if new identifier has been added
   * @returns **false** if provided key is already registered in refresher's map
   */
  addRefreshKey(key: string) {
    if (!this.refreshMap.has(key)) {
      this.refreshMap.set(key, new Subject<boolean>());
      return true;
    }
    return false;
  }

  removeRefreshKey(key: string) {
    if (key === this.defaultKey) {
      return false;
    }
    this.refreshMap.delete(key);
    return true;
  }
}
