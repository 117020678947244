export const SORT_BY = [
  {
    key: 'name_desc',
    label: 'common.sort.name_az',
  },
  {
    key: 'name_asc',
    label: 'common.sort.name_za',
  },
  {
    key: 'created_desc',
    label: 'common.sort.created_newest_first',
  },
  {
    key: 'created_asc',
    label: 'common.sort.created_latest_first',
  },
];
