import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FieldDefinitionModel, FieldsFetcherService } from '@vdms-hq/fields';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'definitionForSelectedId',
})
export class DefinitionForSelectedIdPipe implements PipeTransform {
  constructor(private fields: FieldsFetcherService) {}

  transform(id?: string | null): Observable<FieldDefinitionModel | null> {
    if (!id) {
      return of(null);
    }
    return this.fields
      .getAssetViewConfiguration$('default')
      .pipe(map((config) => config.definitions.find((input) => input.id === id) ?? null));
  }
}
