import { SelectOption } from '@vdms-hq/shared';
import { EmbargoDates } from '@vdms-hq/api-contract';

const transform = (items: string[]) => {
  return items.map((item) => ({
    key: item,
    label: item,
  }));
};

const sort = (items: { key: string; label: string }[]) => {
  return items.sort((left, right) => left.label.localeCompare(right.label));
};

const transformAndSort = (items: string[]): SelectOption[] => {
  return sort(transform(items));
};

export const IsDirty = [
  { key: '0', label: 'Registered' },
  { key: '1', label: 'Unregistered' },
];

export const YesNo = [
  { key: '1', label: 'Yes' },
  { key: '0', label: 'No' },
];

export const HotCold = [
  { key: 'cold', label: 'Cold' },
  { key: 'hot', label: 'Hot' },
];

export const Orphan = [
  { key: 'all', label: 'All assets' },
  { key: 'orphan', label: 'Orphan assets only' },
];

export const isPurged = [
  { key: '0', label: 'No' },
  { key: '1', label: 'Yes' },
];
export const isDeleted = [
  { key: '0', label: 'No' },
  { key: '1', label: 'Yes' },
];

export const Stage = [
  { key: '0', label: 'All assets' },
  { key: '1', label: 'Archive' },
];

export const AssetStatus = [
  { key: '', label: 'All assets' },
  { key: '0', label: 'Placeholder' },
  { key: '1', label: 'Verified Asset' },
];

export const ProcessingStatus = [
  { key: 'placeholder', label: 'Placeholder' },
  { key: 'created', label: 'Created' },
  { key: 'analyse', label: 'Analyse' },
  { key: 'creating_proxy', label: 'Creating Proxy' },
  { key: 'creating_stream', label: 'Creating Stream' },
  { key: 'awaiting_analyse', label: 'Awaiting Stream' },
  { key: 'qa_failed', label: 'QA Failed' },
  { key: 'done', label: 'Done' },
];

export const HybrikContentClass = transformAndSort([
  'After Effects Archive',
  'Audio Description',
  'Audio Stem',
  'Certificate',
  'Clean Master',
  'Closed Captions',
  'Commentary',
  'Credits',

  'Delivery Schedule',
  'Dialogue',
  'Dialogue Script',
  'Effects',
  'End Card',
  'Full Mix',
  'Localised Insert',
  'Mix Minus Narration',
  'Music and Effects',
  'None',
  'Sound On Tape',
]);

export const MediaInsightsSourceLanguages = [
  { key: 'ar-AE', label: 'Arabic, Gulf' },
  { key: 'ar-SA', label: 'Arabic, Modern Standard' },
  { key: 'zh-CN', label: 'Chinese Mandarin' },
  { key: 'nl-NL', label: 'Dutch' },
  { key: 'en-AU', label: 'English, Australian' },
  { key: 'en-GB', label: 'English, British' },
  { key: 'en-IN', label: 'English, Indian-accented' },
  { key: 'en-IE', label: 'English, Irish' },
  { key: 'en-AB', label: 'English, Scottish' },
  { key: 'en-US', label: 'English, Welsh' },
  { key: 'fr-FR', label: 'French' },
  { key: 'fr-CA', label: 'French, Canadian' },
  { key: 'de-DE', label: 'German' },
  { key: 'de-CH', label: 'German, Swiss' },
  { key: 'he-IL', label: 'Hebrew' },
  { key: 'hi-IN', label: 'Hindi' },
  { key: 'id-ID', label: 'Indonesian' },
  { key: 'it-IT', label: 'Italian' },
  { key: 'ja-JP', label: 'Japanese' },
  { key: 'ko-KR', label: 'Korean' },
  { key: 'ms-MY', label: 'Malay' },
  { key: 'pt-PT', label: 'Portuguese' },
  { key: 'pt-BR', label: 'Portuguese, Brazilian' },
  { key: 'ru-RU', label: 'Russian' },
  { key: 'es-ES', label: 'Spanish' },
  { key: 'es-US', label: 'Spanish, US' },
  { key: 'ta-IN', label: 'Tamil' },
  { key: 'tr-TR', label: 'Turkish' },
];

export const MediaInsightsTargetLanguages = [
  { key: 'af', label: 'Afrikaans' },
  { key: 'sq', label: 'Albanian' },
  { key: 'am', label: 'Amharic' },
  { key: 'ar', label: 'Arabic' },
  { key: 'az', label: 'Azerbaijani' },
  { key: 'bn', label: 'Bengali' },
  { key: 'bs', label: 'Bosnian' },
  { key: 'bg', label: 'Bulgarian' },
  { key: 'zh', label: 'Chinese' },
  { key: 'hr', label: 'Croatian' },
  { key: 'cs', label: 'Czech' },
  { key: 'da', label: 'Danish' },
  { key: 'dv', label: 'Divehi, Dhivehi, Maldivian' },
  { key: 'nl', label: 'Dutch, Flemish' },
  { key: 'et', label: 'Estonian' },
  { key: 'en', label: 'English' },
  { key: 'fi', label: 'Finnish' },
  { key: 'fr', label: 'French' },
  { key: 'ka', label: 'Georgian' },
  { key: 'de', label: 'German' },
  { key: 'el', label: 'Greek' },
  { key: 'ha', label: 'Hausa' },
  { key: 'hi', label: 'Hindi' },
  { key: 'hu', label: 'Hungarian' },
  { key: 'id', label: 'Indonesian' },
  { key: 'it', label: 'Italian' },
  { key: 'ja', label: 'Japanese' },
  { key: 'ko', label: 'Korean' },
  { key: 'lv', label: 'Latvian' },
  { key: 'ms', label: 'Malay' },
  { key: 'no', label: 'Norwegian' },
  { key: 'ps', label: 'Pashto, Pushto' },
  { key: 'fa', label: 'Persian' },
  { key: 'pl', label: 'Polish' },
  { key: 'pt', label: 'Portuguese' },
  { key: 'rm', label: 'Romanian' },
  { key: 'ru', label: 'Russian' },
  { key: 'sr', label: 'Serbian' },
  { key: 'sk', label: 'Slovak' },
  { key: 'sl', label: 'Slovenian' },
  { key: 'so', label: 'Somali' },
  { key: 'es', label: 'Spanish' },
  { key: 'sw', label: 'Swahili' },
  { key: 'sv', label: 'Swedish' },
  { key: 'tl', label: 'Tagalog' },
  { key: 'ta', label: 'Tamil' },
  { key: 'th', label: 'Thai' },
  { key: 'tr', label: 'Turkish' },
  { key: 'tk', label: 'Turkmen' },
  { key: 'uk', label: 'Ukrainian' },
  { key: 'ur', label: 'Urdu' },
  { key: 'vi', label: 'Vietnamese' },
];

export const AssetMasterType = [
  { key: 'video', label: 'Video' },
  { key: 'audio', label: 'Audio' },
  { key: 'image', label: 'Images' },
  { key: 'subtitle', label: 'Timed Text' },
  { key: 'archive', label: 'Archives' },
  { key: 'document', label: 'Documents' },
  { key: 'other', label: 'Other' },
];

export const EmbargoTypes = [
  { label: 'Embargo unset', key: EmbargoDates.EMBARGO_UNSET },
  { label: 'Embargo infinity', key: EmbargoDates.EMBARGO_INFINITY },
  { label: 'Custom embargo date', key: 'custom' },
];

const clientIds = {
  BBC: ['6ec24f81-55bc-4432-8216-142652924bbc', '5e260134-4320-4995-b259-d2f6f9ceeb20'],
  Mattel: ['04cf5d33-007c-49e8-8e6c-f3e9eaa1a89b', 'cd902e99-311d-474d-a3c5-5e3afdbb7087'],
  Smithsonian: ['55562db7-7cfb-40d0-a138-f14d629b098e'],
};

export const BillingReportTypes = [
  { label: 'Archive Assets ScoreCard by ContentType', key: 'assetScoreCardMigratedAssets' },
  { label: 'BBC MG - Active Users with No Orders', key: 'activeUsersWithNoOrdersBBCMG', client: clientIds.BBC },
  { label: 'BBC MG - Ingest Bill', key: 'ingestBillBBCMG', client: clientIds.BBC },
  { label: 'BBC MG - Master Clips Bill', key: 'masterRequestClipsOrderReportBBCMG', client: clientIds.BBC },
  { label: 'BBC MG - Most Clipped Programs', key: 'mostClippedProgramsBBCMG', client: clientIds.BBC },
  { label: 'BBC MG - Transactions Bill', key: 'transactionBillBBCMG', client: clientIds.BBC },
  { label: 'BBC MG - Un-Processed Carts', key: 'unprocessedCartsBBCMG', client: clientIds.BBC },
  { label: 'Billing Aspera Egress', key: 'billingAsperaEgress' },
  { label: 'Billing Deleted Items', key: 'billingDeletedItems' },
  { label: 'Billing Ordered Items', key: 'billingOrderedItems' },
  { label: 'MD5 Duplicate Assets', key: 'assetsWithDuplicateMD5Hash' },
  { label: 'VIDA Ingest Fee Mattel', key: 'vidaIngestFeeMattel', client: clientIds.Mattel },
  { label: 'VIDA Ingest Fee Smithsonian', key: 'vidaIngestFeeSmithsonian', client: clientIds.Smithsonian },
];
