import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaginatorComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import {
  AnalyzeMetadataPickInput,
  AssetFlat,
  FieldsOptionsService,
  MetadataRecognitionApiService,
  MetadataRestartInput,
  POSSIBLE_KEYS,
  PossibleKeyType,
} from '@vdms-hq/api-contract';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { castTo, DestroyComponent, RelationalOption, SelectOption } from '@vdms-hq/shared';
import { KeyToTitlePipe } from '../../logic/key-to-full-title.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { ToastService } from '@vdms-hq/toast';
import { HttpErrorResponse } from '@angular/common/http';
import { METADATA_MINIMUM_PERCENT_ACCURACY } from '../../logic/filename-conventions.validator';
import { MatTooltipModule } from '@angular/material/tooltip';
import objectPath from 'object-path';
import { MetadataRecognitionDatasource } from '../../logic/metadata-recognition.datasource';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MetadataRecognitionModel } from '../../logic/metadata-recognition.model';
import { KeyTypeToOptionTypeKey } from '../../logic/metadata-recognition';
import { MetadataRecognitionService } from '../../logic/metadata-recognition.service';
import { FieldDefinitionModel, FieldsFetcherService, FieldsScopeKey } from '@vdms-hq/fields';
import { SelectorSourceType, SelectorsModule, LanguagesSourceService } from '@vdms-hq/selectors';

@Component({
  selector: 'vdms-hq-metadata-recognition-dialog',
  templateUrl: './metadata-recognition-dialog.component.html',
  styleUrls: ['./metadata-recognition-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    UIDialogWrapperModule,
    AsyncPipe,
    NgForOf,
    ReactiveFormsModule,
    UIFormModule,
    NgIf,
    KeyToTitlePipe,
    UIButtonModule,
    TranslateModule,
    MatTooltipModule,
    MatSortModule,
    MatTableModule,
    PaginatorComponent,
    SelectorsModule,
  ],
})
export class MetadataRecognitionDialogComponent extends DestroyComponent implements OnInit {
  private readonly languageDataSource = inject(LanguagesSourceService);
  private percentAccuracy = inject<number>(METADATA_MINIMUM_PERCENT_ACCURACY);
  private metadataRecognitionApi = inject(MetadataRecognitionApiService);
  private cdr = inject(ChangeDetectorRef);
  private ref = inject(MatDialogRef<MetadataRecognitionDialogComponent>);
  private fieldsOptionsService = inject(FieldsOptionsService);
  private fieldsConfigService = inject(FieldsFetcherService);
  private toastService = inject(ToastService);
  private metadataRecognitionService = inject(MetadataRecognitionService);

  public data = inject<{ packageId: string }>(MAT_DIALOG_DATA);
  public readonly dataSource = inject(MetadataRecognitionDatasource);

  #languages: SelectOption[] = [];

  scope: FieldsScopeKey = 'metadata-recognition';
  form = new FormGroup({
    entities: new FormArray<FormControl>([]),
    metadata: new FormArray<FormGroup>([]),
  });
  selectAll = new FormControl(false);
  selectDataSources: Record<string, Record<string, Array<SelectOption & { percents: number }>>> = {};
  fieldsOptionTypes$ = this.fieldsOptionsService.getTypes().pipe(shareReplay(1));
  selectedEntities$ = this.form.controls.entities.valueChanges.pipe(
    map((entities) => entities.filter(Boolean)?.length ?? 0),
    shareReplay(1),
  );
  saveInProgress$ = new BehaviorSubject(false);

  readonly columns: PossibleKeyType[] = ['select', 'filename'];
  readonly readonlyColumns: PossibleKeyType[] = ['select', 'filename'];

  $castToMetadataModel = castTo<MetadataRecognitionModel>();
  $castToMetadataModelKey = castTo<keyof MetadataRecognitionModel['props']>();
  $castToString = castTo<string>();
  $castToControl = castTo<FormControl>();

  #viewInitialized = false;
  #definitions = new BehaviorSubject<FieldDefinitionModel[]>([]);
  #EMPTY_VALUE = 'EMPTY';

  get formArray() {
    return this.form.controls.metadata;
  }

  get formEntitiesArray() {
    return this.form.controls.entities;
  }

  ngOnInit(): void {
    this.dataSource.isLoading$.next(true);
    this.dataSource.connection$
      .pipe(
        this.takeUntilDestroyed(),
        tap(() => this.dataSource.isLoading$.next(true)),
        debounceTime(500),
      )
      .subscribe(() => this.#init());

    this.languageDataSource
      .listByType()
      .pipe(
        this.takeUntilDestroyed(),
        tap((languages) => (this.#languages = languages)),
      )
      .subscribe();
  }

  skip() {
    this.ref.close();
  }

  enableSave() {
    return this.form.value?.entities?.some((e) => e);
  }

  restartRecognition() {
    const { packageId } = this.data;

    this.metadataRecognitionService.analyzeMetadataRecognition$
      .pipe(
        switchMap((conventions) => {
          const metadataConventions = conventions
            .filter(
              (convention) =>
                convention.defaultFor.length &&
                convention.defaultFor.filter((df) => df.default_for_type === 'analyze-metadata'),
            )
            .map((convention) => ({
              uuid: convention.uuid,
              defaultFor: convention.defaultFor.map((df) => df.asset_type),
            }));
          this.saveInProgress$.next(true);
          const payload = <MetadataRestartInput>{
            jobUuids: this.formArray.controls
              .map((control) => (control.enabled ? control.value.uuid : null))
              .filter(Boolean),
            conventions: metadataConventions,
          };
          return this.metadataRecognitionApi.restartMetadataRecognition(packageId, payload);
        }),
        take(1),
      )
      .subscribe({
        next: () => {
          this.ref.close();
          this.toastService.success({ id: 'restartRecognition', message: 'Metadata recognition has been restarted. ' });
        },
        error: () => {
          this.ref.close();
          this.toastService.error({ id: 'restartRecognition', message: 'Metadata recognition cannot be restarted. ' });
        },
      });
  }

  save() {
    this.saveInProgress$.next(true);

    const payload: AnalyzeMetadataPickInput['data'] = this.formArray.controls
      .map((control) => (control.enabled ? control.value : null))
      .filter(Boolean)
      ?.map((item) => ({ jobUuid: item.uuid, pickedData: this.#toPayload(item) }))
      ?.filter(({ pickedData }) => Object.keys(pickedData)?.length);

    const { packageId } = this.data;

    this.metadataRecognitionApi
      .savePickedData(packageId, { data: payload })
      .pipe(
        take(1),
        catchError((err: HttpErrorResponse) => {
          this.dataSource.isLoading$.next(false);
          this.cdr.detectChanges();
          this.ref.close();
          return of(err);
        }),
      )
      .subscribe(() => {
        this.dataSource.isLoading$.next(false);
        this.cdr.detectChanges();
        this.toastService.success({ id: 'success', message: 'Metadata recognition has been saved. ' });
        this.ref.close();
      });
  }

  #init() {
    this.dataSource.isLoading$.next(true);
    this.#saveDefinitions();
    this.metadataRecognitionService.filenameConventions$
      .pipe(
        switchMap((conventions): Observable<[MetadataRecognitionModel[], number]> => {
          return combineLatest([
            this.dataSource.connection$,
            // todo minimumPercentageAccuracy
            // of(conventions?.minimumPercentageAccuracy ?? this.percentAccuracy),
            of(this.percentAccuracy),
          ]).pipe(take(1));
        }),
      )
      .subscribe((data: [MetadataRecognitionModel[], number]) => {
        const [metadata, accuracy] = data;
        this.#generateForm(metadata, accuracy);
        this.cdr.detectChanges();
      });
  }

  #generateForm(metadata: MetadataRecognitionModel[], accuracy: number) {
    for (const entity of metadata) {
      const controlExist = this.formArray.controls.find(({ value }) => value.uuid === entity.props.uuid);
      if (controlExist) {
        continue;
      } else {
        const controls: Record<string, FormControl> = {
          uuid: new FormControl({ value: entity.props.uuid, disabled: true }),
          filename: new FormControl({ value: entity.props.filename, disabled: true }),
        };

        for (const match of entity.props.matches ?? []) {
          for (const part of match.parts) {
            !this.columns.includes(part.notableField) &&
              POSSIBLE_KEYS.includes(part.notableField) &&
              part.match &&
              part.percents >= accuracy &&
              this.columns.push(part.notableField);

            if (!this.selectDataSources?.[entity.props.uuid]) {
              this.selectDataSources[entity.props.uuid] = {};
            }

            if (part.match && (part?.percents ?? 0) >= accuracy) {
              if (!this.selectDataSources?.[entity.props.uuid]?.[part.notableField]) {
                this.selectDataSources[entity.props.uuid][part.notableField] = [
                  { label: part.match, key: part.match, percents: part.percents ?? 0 },
                ];
              }

              if (!this.selectDataSources[entity.props.uuid][part.notableField].some(({ key }) => key === part.match)) {
                this.selectDataSources[entity.props.uuid][part.notableField].push({
                  label: part.match,
                  key: part.match,
                  percents: part.percents ?? 0,
                });
              }

              controls[part.notableField] = new FormControl({ value: undefined, disabled: true });
            }
          }
        }

        this.formEntitiesArray.push(new FormControl(false));
        this.formArray.push(new FormGroup(controls));
      }
    }

    this.#sortAndMatchDataSources();
    this.#listenEntitiesChange();
    this.#listenSelectAllEntities();
    this.#viewInitialized = true;
  }

  #listenEntitiesChange() {
    if (this.#viewInitialized) {
      return;
    }

    this.formEntitiesArray.valueChanges
      .pipe(
        this.takeUntilDestroyed(),
        filter(({ length }) => !!length),
      )
      .subscribe((states: boolean[]) => {
        states?.forEach((state, index) => {
          const control = this.formArray.at(index);
          if ((state && control?.enabled) || (!state && control?.disabled) || !control) {
            return;
          }
          control[state ? 'enable' : 'disable']({ emitEvent: false });
        });
        this.selectAll.setValue(states.every(Boolean), { emitEvent: false });
      });
  }

  #listenSelectAllEntities() {
    if (this.#viewInitialized) {
      return;
    }

    this.selectAll.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((state) => {
      const page = this.dataSource.pageIndex$.value;
      const size = this.dataSource.pageSize$.value;

      this.formEntitiesArray.controls.slice(page * size, (page + 1) * size).forEach((entity) => entity.setValue(state));
      this.formArray.controls
        .slice(page * size, (page + 1) * size)
        .forEach((form) => (state ? form.enable() : form.disable()));
    });
  }

  #sortAndMatchDataSources() {
    this.fieldsOptionTypes$.pipe(take(1)).subscribe((type) => {
      Object.entries(this.selectDataSources).forEach(([uuid, value]) => {
        Object.entries(value).forEach(([key, selectOptions]) => {
          const source = type.find(({ name }) => name === KeyTypeToOptionTypeKey[key])?.fields;
          if (this.selectDataSources[uuid][key]?.length) {
            const matchingData = this.#rawOptionsToExistingOptions(
              selectOptions as unknown as Array<SelectOption & { percents: number }>,
              source,
            );
            if (matchingData?.length) {
              this.selectDataSources[uuid][key] = [...matchingData];
              if (!this.selectDataSources[uuid][key].some(({ key }) => key === this.#EMPTY_VALUE || key === 'N/A')) {
                this.selectDataSources[uuid][key].push({
                  key: this.#EMPTY_VALUE,
                  label: 'N/A',
                  percents: 0,
                });
              }
            } else {
              delete this.selectDataSources[uuid][key];
            }
          } else {
            delete this.selectDataSources?.[uuid]?.[key];
          }
        });
      });

      this.#setHighestRatedOption();
      setTimeout(() => {
        this.dataSource.isLoading$.next(false);
        this.cdr.detectChanges();
      });
    });
  }

  #rawOptionsToExistingOptions(
    selectOptions: Array<SelectOption & { percents: number }>,
    source?: RelationalOption[],
  ): Array<SelectOption & { percents: number }> {
    return (
      selectOptions
        .map((selectOption) => {
          let matchingOption;
          if (source) {
            matchingOption = source.find(
              ({ key }) => key.toLowerCase() === String(selectOption?.key ?? '')?.toLowerCase(),
            );
          }

          if (!matchingOption || !source) {
            const option = selectOption.label
              .split(' ')
              .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
              .join(' ');
            return {
              key: option,
              label: option,
            };
          }

          return { label: matchingOption?.label, key: matchingOption?.key, percents: selectOption.percents };
        })
        .filter(Boolean) as Array<SelectOption & { percents: number }>
    ).sort((a, b) => a?.percents + b?.percents);
  }

  #setHighestRatedOption() {
    this.formArray.controls.forEach((control) => {
      const uuid = control.value['uuid'];
      this.columns.forEach((column) => {
        if (this.readonlyColumns.includes(column) || !this.selectDataSources[uuid]) {
          return;
        }

        const value = this.selectDataSources[uuid][column]?.[0]?.key;

        if (!value) {
          return;
        }

        if (column === 'language') {
          const languageCode = this.#extractLanguageCode(value as string);
          const language = this.#languages.find((language) => (language as any).code === languageCode) || null;

          control.get(column)?.setValue(language?.key);

          return;
        }

        control.get(column)?.setValue(value);
      });
    });
  }

  #toPayload(item: { [key: PossibleKeyType[number]]: string }): Partial<AssetFlat> {
    const asset = <Partial<AssetFlat>>{};
    const defs = this.#definitions.value;
    Object.entries(item).forEach(([key, value]) => {
      if (key === 'uuid' || key === 'filename' || !value) {
        return;
      }

      const path = defs.find(({ id }) => id === key)?.input?.objectPath;
      if (!path) {
        return;
      }

      const correctValue = value === this.#EMPTY_VALUE ? null : value;
      objectPath.set(asset, path, correctValue);
    });
    return asset;
  }

  #saveDefinitions() {
    if (this.#definitions.value.length) {
      return;
    }

    this.fieldsConfigService
      .getConfiguration$(this.scope)
      .pipe(take(1))
      .subscribe((defs) => this.#definitions.next(defs.system));
  }

  protected readonly SelectorSourceType = SelectorSourceType;

  #extractLanguageCode(language: string) {
    return language.toLowerCase().match('\\(([^()]+)\\)')?.[1];
  }
}
