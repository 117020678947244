import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'vdms-hq-ui-button-advanced',
  templateUrl: './button-advanced.component.html',
  styleUrls: ['./button-advanced.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, NgOptimizedImage, MatIconModule],
})
export class ButtonAdvancedComponent {
  @Input() iconOnly = false;
  @Input() icon?: string;
  @Input() imageOnly = false;
  @Input() imgUrl = '';
  @Input() imgAlt?: string;
  @Input() title = 'Title';
  @Input() subtitle = 'SubTitle';
  @Input() selected = false;
  @Input() checked = false;
  @Input() size: 'default' | 'wide' = 'default';
  @Input() outerHeight?: string;
  @Input() imgWidth = 36;
  @Input() imgHeight = 36;
  @Input() imgInvertColor = false;
}
