import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ClientCurrencyPipe, GroupCurrencyISO, SharedModule } from '@vdms-hq/shared';

@Component({
  selector: 'vdms-hq-ui-price',
  templateUrl: './ui-price.component.html',
  styleUrls: ['./ui-price.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, SharedModule, ClientCurrencyPipe],
})
export class UiPriceComponent {
  @Input() price!: number;
  @Input() currency!: GroupCurrencyISO;
  @Input() discountPrice?: number;
  @Input() discountTotal?: number;
}
