<mat-form-field
  appearance="outline"
  class="material-custom-field"
  [class.text-prefix]="!!prefixText"
  [class.button-suffix]="!!suffixConfig"
  [class.alternative-appearance]="alternativeAppearance"
  [class.hide-footer]="!withFooter"
  [class.with-touched-indicator]="withTouchedIndicator"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <span matPrefix class="prefix" *ngIf="prefixIcon">
    <mat-icon>{{ prefixIcon }}</mat-icon>
  </span>

  <span matPrefix class="prefix" *ngIf="prefixText">
    {{ prefixText }}
  </span>

  <mat-icon
    *ngIf="suffixConfig?.onlyIcon && suffixConfig?.icon && suffixConfig?.mode !== 'text'"
    class="suffix-icon"
    matSuffix
    (click)="clickSuffix.emit()"
  >
    {{ suffixConfig?.icon }}
  </mat-icon>

  <vdms-hq-ui-button
    matSuffix
    [color]="suffixConfig.type"
    class="suffix-button"
    @enterAnimation
    *ngIf="
      suffixConfig &&
        !suffixConfig.onlyIcon &&
        suffixConfig.mode !== 'text' &&
        innerFormControl.valid &&
        innerFormControl.value !== '';
      else fallbackSuffixIconTemplate
    "
    (click)="clickSuffix.emit()"
    [sharedE2eId]="'suffix-button'"
  >
    <ng-container *ngIf="suffixConfig.text">
      {{ suffixConfig.text }}
    </ng-container>
    <mat-icon *ngIf="suffixConfig.icon">{{ suffixConfig.icon }}</mat-icon>
  </vdms-hq-ui-button>

  <span matSuffix class="suffix-text" *ngIf="suffixConfig && suffixConfig.mode === 'text' && suffixConfig.text">
    {{ suffixConfig.text }}
  </span>

  <ng-template #fallbackSuffixIconTemplate>
    <span
      matSuffix
      class="suffix"
      *ngIf="innerFormControl.valid && suffixConfig && (!suffixConfig.onFocus || focused)"
      (click)="clickSuffix.emit(); $event.stopPropagation()"
    >
      <mat-icon>{{ fallbackSuffixIcon }}</mat-icon>
    </span>
  </ng-template>
  <ng-container *ngIf="!autocompleteList">
    <input
      #passwordInput
      [type]="enableShowPasswordIcon ? 'password' : type"
      matInput
      (focus)="onFocus()"
      [formControl]="innerFormControl"
      (blur)="onBlur(); propagateTouch()"
      [placeholder]="placeholder"
      autocomplete="disable-autocomplete"
      class="mat-form-field"
      [mask]="mask"
      [dropSpecialCharacters]="dropSpecialCharacters"
      [showMaskTyped]="showMaskTyped"
      [required]="isRequired"
    />
    <mat-icon
      *ngIf="enableShowPasswordIcon"
      class="icon password-icon"
      (mouseover)="passwordInput.type = 'text'"
      (mouseout)="passwordInput.type = 'password'"
      >remove_red_eye
    </mat-icon>
  </ng-container>
  <ng-container *ngIf="autocompleteList">
    <input
      [type]="type"
      matInput
      (focus)="onFocus()"
      [formControl]="innerFormControl"
      (blur)="onBlur(); propagateTouch()"
      [placeholder]="placeholder"
      (keyup.enter)="onEnter()"
      autocomplete="off"
      class="mat-form-field"
      [sharedE2eId]="'input-autocomplete'"
      [required]="isRequired"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngIf="autocompleteIncludeNull">-&#45;&#45;</mat-option>
      <mat-option
        class="autocomplete"
        *ngFor="let option of autocompleteList"
        [value]="option.label"
        (click)="clickAutocompleteItem.emit(option.key)"
      >
        @if (option.icon) {
          <mat-icon>{{ option.icon }}</mat-icon>
        }
        {{ option.label }}
        <vdms-hq-ui-button
          *ngIf="autocompleteListRemoveItemButton"
          [iconOnly]="true"
          (click)="clickAutocompleteRemoveItem.emit(option); $event.stopPropagation()"
          color="transparent"
          icon="clear"
        ></vdms-hq-ui-button>
      </mat-option>
    </mat-autocomplete>
  </ng-container>
  <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="withFooter">
    <span *ngFor="let error of errors"
      >{{ error.transLang | translate: error.contextParams }}{{ errors.length > 1 ? ', ' : '' }}</span
    >
  </mat-error>
  <vdms-hq-ui-loader matSuffix [size]="20" *ngIf="innerFormControl.pending"></vdms-hq-ui-loader>
</mat-form-field>
