import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FieldRelationsServiceV2 } from '@vdms-hq/dynamic-form';
import { FieldDefinitionModel } from '@vdms-hq/fields';

@Pipe({
  name: 'isRelationLoading',
  standalone: true,
})
export class IsRelationLoadingPipe implements PipeTransform {
  constructor(private relationService: FieldRelationsServiceV2) {}

  transform(definitionModel: FieldDefinitionModel): Observable<boolean> {
    return this.relationService.loadingFields$.pipe(map((item) => Boolean(item[definitionModel.id])));
  }
}
