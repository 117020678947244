<div class="asset-form-footer">
  <vdms-hq-ui-button
    [isSubmit]="true"
    (click)="save({ withFinish: false, withLock: false, newFormBuilder: true })"
    [loading]="(isSaving$ | async) ?? false"
    [disabled]="formGroup.invalid || ((isSaving$ | async) ?? false)"
    color="primary"
    [icon]="formGroup.invalid ? 'block' : 'check_circle'"
  >
    {{ 'common.global.update' | translate }}
  </vdms-hq-ui-button>
</div>
