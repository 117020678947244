import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';
import { FieldsFetcherService, FieldsScopeKey } from '@vdms-hq/fields';

@Injectable({
  providedIn: 'root',
})
export class SearchResultsConfigFieldsService implements Resolve<FieldsScopeKey> {
  private fieldsFetcherService = inject(FieldsFetcherService);

  #scope: FieldsScopeKey = 'browse';

  get currentScope(): FieldsScopeKey {
    return this.#scope;
  }

  currentFieldsConfig$ = this.fieldsFetcherService.getConfiguration$(this.#scope);

  table$ = this.currentFieldsConfig$.pipe(
    map((config) => config.table.visible),
    shareReplay(1),
  );

  filters$ = this.currentFieldsConfig$.pipe(
    map((config) => {
      const keyword = config.filters.system.find((filter) => filter.id === 'text');

      return [keyword, ...config.filters.visible.filter((item) => item.id !== 'text')];
    }),
    shareReplay(1),
  );

  export$ = this.currentFieldsConfig$.pipe(
    map((config) => config.table.getForExport()),
    shareReplay(1),
  );

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): FieldsScopeKey {
    if (state.url.includes('browse/quarantined')) {
      this.#scope = 'quarantined';
    } else if (state.url.includes('browse/deleted')) {
      this.#scope = 'deleted';
    } else {
      this.#scope = 'browse';
    }

    return this.#scope;
  }
}
