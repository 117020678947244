import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, last, Observable, of, switchMap } from 'rxjs';
import { CollectionsUploadService } from '../../logic/services/collections-upload.service';
import { ThumbComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import {
  CollectionModelFlat,
  CollectionsService,
  PaginationAPIModel as Pagination,
  UserApiService,
} from '@vdms-hq/api-contract';
import { FileUploadState, StorageAttachmentsModule, UploadState } from '@vdms-hq/storage';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { MatButtonModule } from '@angular/material/button';
import { CollectionUploadStatus } from '../../logic/models/statuses-type.model';
import {
  DestroyComponent,
  E2eIdDirective,
  emailPatternValidator,
  hasOwnerUuidValidator,
  SelectionIdentifier,
  SelectOption,
} from '@vdms-hq/shared';
import { map, tap } from 'rxjs/operators';
import { transformUserToSelectOptionMail } from '@vdms-hq/users';
import { FlatCollectionViewModel, SingleCollectionViewModel } from '../../logic/models/collection-view.model';
import { omit, pick } from 'lodash';

export enum CollectionEditDialogResponse {
  ABORT = 0,
  SUCCESS = 1,
  ERROR = 2,
}
@Component({
  selector: 'vdms-hq-collection-edit-dialog',
  templateUrl: './collection-edit-dialog.component.html',
  standalone: true,
  imports: [
    UIDialogWrapperModule,
    TranslateModule,
    ReactiveFormsModule,
    UIFormModule,
    UIButtonModule,
    CommonModule,
    ThumbComponent,
    StorageAttachmentsModule,
    MatButtonModule,
    E2eIdDirective,
  ],
})
export class CollectionEditDialogComponent extends DestroyComponent {
  collection: FlatCollectionViewModel | SingleCollectionViewModel;
  collectionForm: FormGroup;
  loading = false;
  file: File | null = null;
  uploadState: FileUploadState<CollectionUploadStatus> | null = null;
  protected readonly State = UploadState;

  filteredOptions$: Observable<SelectOption<SelectionIdentifier>[]>;

  constructor(
    public dialogRef: MatDialogRef<CollectionEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isOwner: boolean;
      collection: FlatCollectionViewModel | SingleCollectionViewModel;
    },
    public activatedClientService: ActivatedClientService,
    private uploadService: CollectionsUploadService,
    private userApiService: UserApiService,
    private collectionsService: CollectionsService,
  ) {
    super();
    this.collection = data.collection;
    this.collectionForm = new FormGroup({
      owner: new FormControl<string | null>(null, [emailPatternValidator()]),
      ownerUuid: new FormControl<string | null>(null),
      name: new FormControl<string>(this.collection.name, Validators.required),
      description: new FormControl<string>(this.collection.description ?? ''),
      coverPath: new FormControl<string>(this.collection.custom_cover_path ?? ''),
    });

    this.filteredOptions$ = this.collectionForm.controls['owner'].valueChanges.pipe(
      filter((value: string) => value.length > 2),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((value: string) => this.filterOptions(value)),
    );

    this.activatedClientService.userListHidden$.pipe().subscribe((value) => {
      if (value) {
        this.checkIfUserExists();
      }
    });
  }

  checkIfUserExists() {
    this.collectionForm.controls['owner'].valueChanges
      .pipe(
        filter((value: string) => value.length > 5),
        debounceTime(500),
        distinctUntilChanged(),
        tap((value) => {
          this.collectionForm.controls['ownerUuid'].setValue(null);
          this.collectionForm.controls['owner'].setValidators([emailPatternValidator()]);
          this.collectionForm.controls['owner'].updateValueAndValidity();
          this.collectionForm.markAllAsTouched();
          if (this.collectionForm.controls['owner'].valid) {
            this.collectionForm.controls['owner'].markAsPending();
            this.userApiService
              .getUsersShare(
                { mail: value },
                Pagination.create({
                  page: 0,
                  perPage: 1,
                }),
              )
              .pipe(this.takeUntilDestroyed())
              .subscribe((response) => {
                if (response.data.length !== 1 || response.data[0].email !== value) {
                  this.collectionForm.controls['ownerUuid'].setValue(null);
                } else {
                  this.collectionForm.controls['ownerUuid'].setValue(response.data[0].uuid);
                }
                this.collectionForm.controls['owner'].setValidators([
                  emailPatternValidator(),
                  hasOwnerUuidValidator(this.collectionForm.controls['ownerUuid'].value),
                ]);
                this.collectionForm.controls['owner'].updateValueAndValidity();
                this.collectionForm.markAllAsTouched();
              });
          }
        }),
      )
      .subscribe();
  }

  filterOptions(value: string): Observable<SelectOption<SelectionIdentifier>[]> {
    this.collectionForm.controls['ownerUuid'].setValue(null);
    this.collectionForm.controls['owner'].setValidators([emailPatternValidator()]);
    this.collectionForm.markAllAsTouched();
    this.collectionForm.controls['owner'].updateValueAndValidity();
    this.collectionForm.controls['owner'].markAsPending();
    return this.userApiService.getUsersShare({ text: value }).pipe(
      map((response) => response.data.map(transformUserToSelectOptionMail)),
      map((users) => users.filter((user) => user.label.includes(value))),
      tap((users) => {
        if (users.length !== 1 || users[0].label !== value) {
          this.collectionForm.controls['ownerUuid'].setValue(null);
        } else {
          this.collectionForm.controls['ownerUuid'].setValue(users[0].key);
        }
        this.collectionForm.controls['owner'].setValidators([
          emailPatternValidator(),
          hasOwnerUuidValidator(this.collectionForm.controls['ownerUuid'].value),
        ]);
        this.collectionForm.markAllAsTouched();
        this.collectionForm.controls['owner'].updateValueAndValidity();
      }),
    );
  }

  onSelectedUser($event: string) {
    this.collectionForm.controls['ownerUuid'].setValue($event);
  }

  closeDialog(): void {
    this.dialogRef.close({
      status: CollectionEditDialogResponse.ABORT,
    });
  }

  onSubmit() {
    if (this.collectionForm.invalid) {
      return;
    }

    this.loading = true;
    const patchDataFull = {
      name: this.collectionForm.controls['name']?.value,
      description: this.collectionForm.controls['description']?.value,
      custom_cover: !!this.collectionForm.controls['coverPath'].value || !!this.file,
      owner: this.collectionForm.controls['ownerUuid'].value ?? undefined,
    };
    const patchDataOwnerless = omit(patchDataFull, 'owner');
    const patchDataOwner = pick(patchDataFull, 'owner');

    const generateThumbnail = this.collectionsService.generateThumbnail(this.collection.uuid);
    of(this.file)
      .pipe(
        switchMap((file) => {
          if (file) {
            return this.uploadService.updateCollectionAndUpload(file, this.collection.uuid, patchDataOwnerless).pipe(
              tap((uploadState) => {
                this.uploadState = uploadState;
              }),
              last(),
              switchMap(() => generateThumbnail),
              switchMap(() => {
                return this.collectionsService.update(this.collection.uuid, patchDataOwner);
              }),
            );
          }
          return this.collectionsService.update(this.collection.uuid, patchDataFull).pipe(
            switchMap(() => {
              if (
                this.collection.thumbnails.length === 0 &&
                this.collection.custom_cover_path !== null &&
                this.collection.custom_cover_path.length > 0
              ) {
                return generateThumbnail;
              }

              return of(false);
            }),
          );
        }),
      )
      .subscribe({
        next: () => {
          this.loading = false;
          this.dialogRef.close({
            status: CollectionEditDialogResponse.SUCCESS,
          });
        },
        error: () => {
          this.loading = false;
          this.dialogRef.close({
            status: CollectionEditDialogResponse.ERROR,
          });
        },
      });
  }
}
