import { SelectOption } from '@vdms-hq/shared';
import { DataColumn, GridAdvancedMetadata } from '@vdms-hq/ui';
import { definitionsAsSelectOption, definitionsAsSelectOptionWithId, excludeFromList } from './helpers';
import { AllListComponentConfig, AssetViewConfig, ConfigLessComponent } from '@vdms-hq/firebase-contract';
import { FilterType } from '../models/filter-type';
import {
  FieldDefinitionModel,
  FilterDefinitionModel,
  InputDefinitionModel,
  ResultDefinitionModel,
} from '../models/field-config.id';

/**
 * "system" means: that the field is declared in code,
 * "client" means:
 * - available: the field is available to be enabled by the admin
 * - enabled: the field is enabled by the admin and visible to the end user
 * "user": means that the field is enabled by the end user
 */
export interface OutputConfiguration {
  table: TableFieldsConfiguration;
  list: ListFieldsConfiguration;
  filters: FilterFieldsConfiguration;

  allAsSelectOptions(): SelectOption[];
}

export interface TableFieldsConfiguration {
  system: ResultDefinitionModel[];
  client: {
    isInherited: boolean;
    available: ResultDefinitionModel[];
    enabled: ResultDefinitionModel[];
  };
  user: ResultDefinitionModel[] | null;
  visible: ResultDefinitionModel[];

  /**
   * Returns the list of available fields, with enabled fields first
   */
  getEnabledWithAvailable(): ResultDefinitionModel[];

  getColumns(disableSorting?: boolean): DataColumn[];

  getClientEnabledKeys(): string[];

  getVisibleKeys(): string[];

  getClientAvailableKeys(): string[];

  getSystemKeys(): string[];

  getForExport(): ResultDefinitionModel[];

  getSystemAsSelectOptions(): SelectOption[];
}

export interface AssetViewConfiguration {
  definitions: InputDefinitionModel[];
  viewConfig: AssetViewConfig;

  getContainersWithGroupedElements(): {
    containerId: string;
    containerName: string;
    elements: (InputDefinitionModel | AllListComponentConfig | ConfigLessComponent)[];
  }[];

  getGroupedElementsForContainer(
    containerId: string,
  ): (InputDefinitionModel | AllListComponentConfig | ConfigLessComponent)[];
}

export interface ListFieldsConfiguration {
  system: ResultDefinitionModel[];
  client: {
    isInherited: boolean;
    enabled: ResultDefinitionModel[];
  };
  visible: ResultDefinitionModel[];

  getMetadata<T>(): GridAdvancedMetadata<T>[];

  getVisibleKeys(): string[];

  getClientEnabledKeys(): string[];

  getSystemKeys(): string[];

  getSystemAsSelectOptions(): SelectOption[];
}

export interface FilterFieldsConfiguration {
  system: FilterDefinitionModel[];
  client: {
    enabled: FilterDefinitionModel[];
    isInherited: boolean;
  };
  user: FilterDefinitionModel[] | null;
  visible: FilterDefinitionModel[];

  /**
   * Returns the list of available fields, with enabled fields first
   */
  getEnabledWithAvailable(): FilterDefinitionModel[];

  getVisibleKeys(): string[];

  getEnabledKeys(): string[];

  getSystemKeys(): string[];

  getSystemAsSelectOptions(): SelectOption[];

  getEnabledAsSelectOptions(additionalData?: boolean): SelectOption[];

  getSystemWithAggregations(additionalData?: boolean): FilterDefinitionModel[];
  getSystemWithAggregationsAsSelectOptions(additionalData?: boolean): SelectOption[];
}

export class FilterFieldsConfigurationModel implements FilterFieldsConfiguration {
  constructor(
    public system: FilterFieldsConfiguration['system'],
    public client: FilterFieldsConfiguration['client'],
    public user: FilterFieldsConfiguration['user'],
    public visible: FilterFieldsConfiguration['visible'],
  ) {}

  getEnabledWithAvailable(): FilterDefinitionModel[] {
    return [...this.visible, ...excludeFromList(this.client.enabled, this.visible)];
  }

  getSystemKeys(): string[] {
    return this.system.map((def) => def.id);
  }

  getVisibleKeys(): string[] {
    return this.visible.map((def) => def.id);
  }

  getEnabledKeys(): string[] {
    return this.client.enabled.map((def) => def.id);
  }

  getSystemAsSelectOptions(): SelectOption[] {
    return definitionsAsSelectOption(this.system);
  }

  getEnabledAsSelectOptions(additionalData?: boolean): SelectOption[] {
    if (additionalData) {
      return definitionsAsSelectOptionWithId(this.system);
    }
    return definitionsAsSelectOption(this.client.enabled);
  }

  getSystemWithAggregations() {
    return this.system.filter(
      (defs) =>
        !!defs.filters.aggregationKey &&
        [
          FilterType.SELECTOR,
          FilterType.TEXT_AUTOCOMPLETE,
          FilterType.SELECTOR_GROUP,
          FilterType.CHECKBOX_LIST,
        ].includes(defs.filters.type),
    );
  }

  getSystemWithAggregationsAsSelectOptions() {
    return definitionsAsSelectOption(this.getSystemWithAggregations());
  }
}

export class ListFieldsConfigurationModel implements ListFieldsConfiguration {
  constructor(
    public system: ListFieldsConfiguration['system'],
    public client: ListFieldsConfiguration['client'],
    public visible: ListFieldsConfiguration['visible'],
  ) {}

  getMetadata<T>(): GridAdvancedMetadata<T>[] {
    return [...(this?.visible ?? [])].map((def) => ({
      label: def.label,
      valuePath: def.results2.objectPath,
      viewFormat: def.results2.viewFormat,
    }));
  }

  getSystemKeys(): string[] {
    return this.system.map((def) => def.id);
  }

  getVisibleKeys(): string[] {
    return this.visible.map((def) => def.id);
  }

  getClientEnabledKeys(): string[] {
    return this.client.enabled.map((def) => def.id);
  }

  getSystemAsSelectOptions(): SelectOption[] {
    return definitionsAsSelectOption(this.system);
  }
}

export class TableFieldsConfigurationModel implements TableFieldsConfiguration {
  constructor(
    public system: TableFieldsConfiguration['system'],
    public client: TableFieldsConfiguration['client'],
    public user: TableFieldsConfiguration['user'],
    public visible: TableFieldsConfiguration['visible'],
  ) {}

  getEnabledWithAvailable(): ResultDefinitionModel[] {
    return [...this.visible, ...excludeFromList(this.client.available, this.visible)];
  }

  getColumns(disableSorting?: boolean): DataColumn[] {
    return [...(this.visible ?? [])].map((def) => {
      if (def.id === 'actions') {
        return {
          id: def.id,
          type: 'actions',
        };
      }

      if (def.id === 'select') {
        return {
          id: def.id,
          type: 'select',
        };
      }

      return {
        id: def.id,
        label: def.label,
        sortable: disableSorting ? false : def.results2.sortable,
        sortObjectPath: def.results2.sortObjectPath,
        valuePath: def.results2.objectPath,
        viewFormat: def.results2.viewFormat,
        foldValues: def.results2.foldValues,
      };
    });
  }

  getVisibleKeys(): string[] {
    return this.visible.map((def) => def.id);
  }

  getSystemKeys(): string[] {
    return this.system.map((def) => def.id);
  }

  getClientAvailableKeys(): string[] {
    return this.client.available.map((def) => def.id);
  }

  getClientEnabledKeys(): string[] {
    return this.client.enabled.map((def) => def.id);
  }

  getForExport(): ResultDefinitionModel[] {
    return this.visible.filter((column) => column.results2.exportObjectPath);
  }

  getSystemAsSelectOptions(): SelectOption[] {
    return definitionsAsSelectOption(this.system);
  }
}

export class AssetViewConfigurationModel implements AssetViewConfiguration {
  constructor(
    public definitions: AssetViewConfiguration['definitions'],
    public viewConfig: AssetViewConfiguration['viewConfig'],
  ) {}

  getContainersWithGroupedElements(): {
    containerId: string;
    containerName: string;
    elements: (InputDefinitionModel | AllListComponentConfig | ConfigLessComponent)[];
  }[] {
    return this.viewConfig.containers.map((container) => {
      return {
        containerId: container.id,
        containerName: container.label,
        elements: this.getGroupedElementsForContainer(container.id),
      };
    });
  }

  getGroupedElementsForContainer(
    containerId: string,
  ): (InputDefinitionModel | AllListComponentConfig | ConfigLessComponent)[] {
    const transformedElementsList: (InputDefinitionModel | AllListComponentConfig | ConfigLessComponent)[] = [];

    this.viewConfig.elements.forEach((element) => {
      if (element.place === containerId) {
        if ('definition' in element && element.definition) {
          const matchedDefinition = this.definitions.find((def) => def.id === element.definition);
          if (matchedDefinition) {
            transformedElementsList.push(matchedDefinition);
          }
        } else {
          transformedElementsList.push(element as AllListComponentConfig | ConfigLessComponent);
        }
      }
    });

    return transformedElementsList;
  }
}

export class FieldsConfigurationModel implements OutputConfiguration {
  constructor(
    public system: FieldDefinitionModel[],
    public table: TableFieldsConfiguration,
    public list: ListFieldsConfiguration,
    public filters: FilterFieldsConfiguration,
  ) {}

  allAsSelectOptions(additionalData?: boolean): SelectOption[] {
    if (additionalData) {
      return definitionsAsSelectOptionWithId(this.system);
    }
    return definitionsAsSelectOption(this.system);
  }
}
