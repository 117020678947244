<vdms-hq-ui-results-wrapper
  [dataSource]="dataSource"
  [breadcrumbs]="[{ name: ('Video Codecs' | translate) }]"
  [configuration]="headerConfig"
  (headerAction)="handleAction($event)"
>
  <vdms-hq-ui-multiple-data-presentation
    [dataSource]="dataSource"
    [configuration]="viewConfig"
    (action)="handleAction($event)"
  >
  </vdms-hq-ui-multiple-data-presentation>
</vdms-hq-ui-results-wrapper>
