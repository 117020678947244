import { Component, inject, OnInit } from '@angular/core';
import { MatAccordion, MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import {
  ProcessListView,
  UIButtonModule,
  UIConfirmationDialogService,
  UIDialogWrapperModule,
  UiFieldsetComponent,
  UIFormModule,
  UiProcessViewComponent,
} from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { MatTab, MatTabGroup, MatTabLabel } from '@angular/material/tabs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DeliveryDestination,
  DeliveryDestinationConfig,
  DeliveryDestinationJobStatusEnum,
  DownloadTypeEnum,
  GetOrderItemDeliveryDestinationJob,
  GetOrderItemWorkflowJob,
  OrderService,
} from '@vdms-hq/api-contract';
import { BehaviorSubject, switchMap, take } from 'rxjs';
import { OrderActionsService, OrderAssetViewModel2 } from '@vdms-hq/orders';
import { ActivatedClientModule, Permission } from '@vdms-hq/activated-client';
import { RefreshDeliveryDestinationPipe } from '../transcode-details-dialog/refresh-validator.pipe';
import { filter, tap } from 'rxjs/operators';
import { ToastService } from '@vdms-hq/toast';

interface ItemDeliveryDestinationConfig {
  name: string;
  uuid: string;
  anyConfigJobFailed: boolean;
  configs: {
    name: string;
    path: string;
    jobs: GetOrderItemDeliveryDestinationJob[];
    transcodeStatus: string;
    anyJobFailed: boolean;
    process: ProcessListView[];
    uuid: string;
  }[];
  summary?: string;
}

@Component({
  selector: 'vdms-hq-order-item-status-dialog',
  standalone: true,
  imports: [
    UIDialogWrapperModule,
    TranslateModule,
    UIButtonModule,
    UIFormModule,
    CommonModule,
    MatDivider,
    MatTabGroup,
    MatTab,
    MatAccordion,
    MatExpansionModule,
    UiFieldsetComponent,
    UiProcessViewComponent,
    MatTabLabel,
    ActivatedClientModule,
    RefreshDeliveryDestinationPipe,
  ],
  viewProviders: [MatExpansionPanel],
  templateUrl: './order-item-status-dialog.component.html',
  styleUrls: ['./order-item-status-dialog.component.scss'],
})
export class OrderItemStatusDialogComponent implements OnInit {
  private dialogRef = inject(MatDialogRef<OrderItemStatusDialogComponent>);
  private orderActionsService = inject(OrderActionsService);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private toast = inject(ToastService);
  protected readonly Permission = Permission;

  protected loading$ = new BehaviorSubject(false);
  downloadInProgress$ = this.orderActionsService.downloadInProgress$;

  protected data: {
    item: OrderAssetViewModel2;
    orderType: string;
    orderUuid: string;
    orderActive: boolean;
    isEmbargoActive: boolean;
    disableDownload: boolean;
    hideDownload: boolean;
    deliveryDestinationsConfig?: ItemDeliveryDestinationConfig[];
    aiProcessingConfig?: ProcessListView[];
  } = inject(MAT_DIALOG_DATA);

  metadataPillsConfig = [
    { value: 'completed', color: 'completed' },
    { value: 'in_progress', color: 'done-light' },
    { value: 'failed', color: 'failed' },
    { value: 'started', color: 'orange' },
    { value: 'created', color: 'orange' },
    { value: 'scheduled', color: 'yellow-dashed' },
    { value: 'submitted', color: 'yellow' },
    { value: 'not_initialized', color: 'yellow-dashed' },
  ];

  title = '';
  isLoading$ = new BehaviorSubject(false);

  ngOnInit() {
    this.title = 'Order Item: ' + this.data.item.originalFilenameOrDeliveredFilename;
    if (this.data.item.deliveryDestinations) {
      this.setDeliveryDestinationsConfig(this.data.item.deliveryDestinations);
    }

    if (this.data.item.workflowJobs) {
      this.setAiProcessingConfig(this.data.item.workflowJobs);
    }
  }

  setDeliveryDestinationsConfig(deliveryDestinations: DeliveryDestination[]): void {
    this.data.deliveryDestinationsConfig = deliveryDestinations.map((destination) => ({
      name: destination.name,
      uuid: destination.uuid,
      anyConfigJobFailed: destination.configs.some((config) => this.anyJobFailed(config)),
      configs: destination.configs.map((config) => ({
        name: config.name,
        path: config.path,
        jobs: config.jobs,
        transcodeStatus: this.getTranscodeJobsStatus(config.jobs),
        anyJobFailed: this.anyJobFailed(config),
        process: this.createConfigProcess(config),
        uuid: config.uuid,
      })),
    }));
  }

  private setAiProcessingConfig(workflowJobs: GetOrderItemWorkflowJob[]) {
    this.data.aiProcessingConfig = workflowJobs.map((job) => ({
      state: job.status === 'completed' ? 'completed' : 'incomplete',
      line: 'hidden',
      icon: job.status === 'completed' ? 'check_circle' : job.status === 'failed' ? 'priority_high' : 'empty',
      title: job.name,
      error: job.status === 'failed' ? { message: '' } : undefined,
      metadata: [
        {
          label: 'Process finished',
          value: job.end_date ?? 'N/A',
        },
        {
          value: job.status,
          pills: this.metadataPillsConfig,
        },
      ],
    }));
  }

  download(configUuid: string) {
    this.orderActionsService.download(this.data.orderUuid, [this.data.item], {
      keep_folders_structure: false,
      config_uuid: configUuid,
      download_type: DownloadTypeEnum.ASPERA,
    });
  }

  downloadHTTPS(configUuid: string) {
    this.orderActionsService.download(this.data.orderUuid, [this.data.item], {
      keep_folders_structure: false,
      config_uuid: configUuid,
      download_type: DownloadTypeEnum.HTTPS,
    });
  }

  retryDeliveryDestination(config: DeliveryDestinationConfig) {
    const { jobs } = config;

    const cannotRetry =
      jobs.every(({ status }) => status === DeliveryDestinationJobStatusEnum.COMPLETED) ||
      jobs.some(({ status }) => status === DeliveryDestinationJobStatusEnum.NOT_INITIALIZED);

    if (!jobs?.length || cannotRetry) {
      return;
    }

    this.#retryDelivery(config);
  }

  #retryDelivery(config: DeliveryDestinationConfig) {
    const message = config.jobs.some((job) =>
      [DeliveryDestinationJobStatusEnum.SUBMITTED, DeliveryDestinationJobStatusEnum.STARTED].includes(job.status),
    )
      ? 'common.delivery_destinations.retry_dd.already_retried'
      : 'common.delivery_destinations.retry_dd.retry';

    this.confirmationDialog
      .open({
        message,
      })
      .pipe(
        take(1),
        filter(Boolean),
        filter(() => !!this.data.orderUuid && !!this.data.item.orderUuid),
        tap(() => this.loading$.next(true)),
        switchMap(() => {
          const payload = [{ item_uuid: this.data.item.orderUuid, config_uuid: config.uuid }];
          return this.orderActionsService.retryDeliveryDestinations(this.data.orderUuid, { jobs: payload });
        }),
        tap(() => this.loading$.next(false)),
      )
      .subscribe({
        next: () =>
          this.toast.success({ id: 'dd_retry', message: 'common.delivery_destinations.retry_dd.retry_success' }),
      });
  }

  close() {
    this.dialogRef.close();
  }

  createConfigProcess(config: DeliveryDestinationConfig): ProcessListView[] {
    return config.jobs.map((job) => ({
      title: job.type,
      state: job.status === 'completed' ? 'completed' : 'incomplete',
      icon: job.status === 'completed' ? 'check_circle' : job.status === 'failed' ? 'priority_high' : 'empty',
      line: job.status === 'completed' ? 'highlighted' : 'default',
      error: job.status === 'failed' ? { message: '' } : undefined,
      metadata: [
        {
          value: job.status,
          pills: this.metadataPillsConfig,
        },
      ],
    }));
  }

  getTranscodeJobsStatus(jobs: GetOrderItemDeliveryDestinationJob[]) {
    const status = 'completed';

    const transcodeJobs = jobs.filter((job) => job.type === 'transcode');
    if (transcodeJobs.length === 0) {
      return status;
    }

    return transcodeJobs[0].status;
  }

  anyJobFailed(config: DeliveryDestinationConfig) {
    return config.jobs.some((job) => job.status === 'failed');
  }
}
