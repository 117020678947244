import { Pipe, PipeTransform } from '@angular/core';
import { AllListComponentConfig, ConfigLessComponent } from '@vdms-hq/firebase-contract';
import { InputDefinitionModel } from '@vdms-hq/fields';

@Pipe({
  name: 'inputDefinition',
  standalone: true,
})
export class InputDefinitionPipe implements PipeTransform {
  transform(
    definition: InputDefinitionModel | ConfigLessComponent | AllListComponentConfig,
  ): InputDefinitionModel | null {
    if (definition && 'input' in definition) {
      return definition as InputDefinitionModel;
    }
    return null;
  }
}
