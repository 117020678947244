import { inject, Injectable } from '@angular/core';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { catchError, combineLatest, delay, EMPTY, finalize, Observable, of, tap } from 'rxjs';
import { StatsChartViewModel, StatsConfig, StatsType } from '@vdms-hq/ui';
import { BaseConnectableDataSource, filterEmpty, FormatBytesPipe, Loadable, LoadableDataSource } from '@vdms-hq/shared';
import { EgressStatsAdminService } from './egress-stats-admin.service';
import { StatsService } from '@vdms-hq/api-contract';
import { EgressStatsForm } from './egress-stats.form';

@Injectable({
  providedIn: 'root',
})
export class EgressStatsDataSource
  extends Loadable(BaseConnectableDataSource<StatsConfig>)
  implements LoadableDataSource
{
  private activatedClient = inject(ActivatedClientService);
  private egressStatsSettingsService = inject(EgressStatsAdminService);
  private statsService = inject(StatsService);
  private formatBytesPipe = inject(FormatBytesPipe);
  private egressStatsForm = inject(EgressStatsForm);

  clientInfo$ = this.activatedClient.clientDefinite$.pipe(map(({ name }) => name)).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  showStorageDailyCost$ = this.egressStatsSettingsService
    .getStatsSettings()
    .pipe(map((settings) => settings?.enableDailyCost ?? false));

  fillConfig = [
    {
      target: 'origin',
      above: 'hsla(222, 100%, 60%, 0.25)',
    },
    {
      target: 'origin',
      above: 'hsla(331, 100%, 60%, 0.25)',
    },
  ];

  chartConfig = {
    type: StatsType.CHART_LINE,
    title: 'Transfers',
    icon: 'show_chart',
  };

  viewModel: StatsChartViewModel = {
    labelsTextColor: '#A4A9B7',
    beginAtZero: false,
    legend: false,
    maxHeight: '30vh',
    labels: [],
    chartDataSets: [],
    yAxisCallback: (value: number) => this.#transformBytes(value),
  };

  dataSetConfig = {
    borderWidth: 1,
    hoverBorderWidth: 5,
    borderColor: 'hsla(306, 100%, 60%, 0.4)',
    pointStyle: 'circle',
    pointRadius: 0,
    pointBackgroundColor: 'hsla(306, 100%, 60%, 0.4)',
    pointBorderColor: 'hsla(306, 100%, 60%, 0.4)',
    pointHoverRadius: 1,
    hitRadius: 10,
    cubicInterpolationMode: 'monotone',
  };

  response$ = this.egressStatsForm.formValue$.pipe(
    delay(200),
    switchMap(({ selectedPeriod, transferSources }) =>
      this.statsService
        .getEgressStats({
          lookbackPeriod: selectedPeriod,
          transferSources,
        })
        .pipe(
          tap((data) => {
            this.startLoading();
          }),
        ),
    ),
    catchError(() => EMPTY),
    filterEmpty(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  charts$ = this.response$.pipe(map(({ charts }) => charts));

  totals$ = this.response$.pipe(map(({ totals }) => totals));

  emptyChartResults$ = this.charts$.pipe(
    map((charts) => {
      const allData = charts.egress.chartDataSets.flatMap((dataSet) => dataSet.data);
      const totalSum = allData.reduce((sum, value) => sum + value, 0);
      return charts.egress.labels.length <= 1 || totalSum === 0;
    }),
  );

  override connection$: Observable<StatsConfig[]> = combineLatest([
    of({
      ...this.chartConfig,
      viewModel: this.viewModel,
    }),
    this.charts$,
    this.totals$,
  ]).pipe(
    map(([config, charts, totals]) => {
      const datasets = charts.egress.chartDataSets.map((dataSet, index) => {
        const fillConfigIndex = index === 0 ? 1 : 0;
        return {
          ...dataSet,
          ...this.dataSetConfig,
          fill: this.fillConfig[fillConfigIndex],
        };
      });
      const chartData = {
        ...config.viewModel,
        chartDataSets: datasets,
        labels: charts.egress.labels,
      };
      return [
        {
          ...config,
          viewModel: chartData,
          totals: totals,
        },
      ];
    }),
    tap((data) => {
      this.stopLoading();
    }),
    finalize(() => this.stopLoading()),
  );

  #transformBytes(value: number): string {
    return this.formatBytesPipe.transform(value);
  }
}
