import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionIdentifier,
  DialogResponse,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  ResultsWrapperViewConfiguration,
  UIConfirmationDialogService,
  UILoaderModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranscodeContainersDsService } from '../../logic/transcode-containers-ds.service';
import { combineLatest, filter, switchMap, take } from 'rxjs';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import { TranscodeContainer, TranscodeService } from '@vdms-hq/api-contract';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '@vdms-hq/toast';
import { TranscodeContainerCreateEditDialogComponent } from '../../components/container-create-edit-dialog/transcode-container-create-edit-dialog.component';

@Component({
  selector: 'vdms-hq-transcode-containers',
  standalone: true,
  imports: [CommonModule, MultipleDataPresentationComponent, TranslateModule, UILoaderModule, UIResultsWrapperModule],
  templateUrl: './transcode-containers.component.html',
})
export class TranscodeContainersComponent implements OnInit {
  protected containersDS = inject(TranscodeContainersDsService);
  private transcodeService = inject(TranscodeService);
  private permissionService = inject(PermissionService);
  private translateService = inject(TranslateService);
  private dialog = inject(MatDialog);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private toastService = inject(ToastService);

  popToast = {
    CREATE: () => {
      this.toastService.success({
        id: 'create-container',
        message: 'common.transcode_base.containers.create.success',
      });
    },
    EDIT: () => {
      this.toastService.success({
        id: 'edit-container',
        message: 'common.transcode_base.containers.edit.success',
      });
    },
    DELETE: () => {
      this.toastService.success({
        id: 'delete-container',
        message: 'common.transcode_base.containers.delete.success',
      });
    },
  };

  headerConfig: ResultsWrapperViewConfiguration = {
    headerActions: [],
    withLoading: true,
  };

  viewConfig: MultipleViewConfiguration<TranscodeContainer> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: false,
      emptyResults: {
        message: 'Could not find any containers',
      },
    },
    tableAdvanced: {
      actions: [],
      layout: {
        headers: true,
      },
      columns: [
        {
          id: 'uuid',
          label: 'UUID',
          valuePath: 'uuid',
        },
        {
          id: 'label',
          label: 'Label',
          valuePath: 'label',
        },
        {
          id: 'kind',
          label: 'Kind',
          valuePath: 'kind',
        },
        {
          id: 'actions',
          type: 'actions',
        },
      ],
      columnsEnabled: ['uuid', 'label', 'kind', 'actions'],
    },
  };

  ngOnInit() {
    combineLatest([
      this.permissionService.verifyWithOwnedPermissions$([Permission.PERMISSION_CREATE_TRANSCODE_CONTAINERS]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.PERMISSION_EDIT_TRANSCODE_CONTAINERS]),
      this.permissionService.verifyWithOwnedPermissions$([Permission.PERMISSION_DELETE_TRANSCODE_CONTAINERS]),
    ])
      .pipe(take(1))
      .subscribe({
        next: ([canCreate, canEdit, canDelete]) => {
          if (canCreate) {
            this.headerConfig.headerActions?.push({
              key: 'new',
              name: 'common.transcode_base.containers.actions.create',
              color: 'primary',
            });
          }
          if (this.viewConfig.tableAdvanced) {
            if (canEdit) {
              this.viewConfig.tableAdvanced.actions?.push({
                key: 'edit',
                icon: 'edit',
                onDoubleClick: false,
                label: 'common.transcode_base.containers.actions.edit',
              });
            }
            if (canDelete) {
              this.viewConfig.tableAdvanced.actions?.push({
                key: 'delete',
                icon: 'delete',
                onDoubleClick: false,
                label: 'common.transcode_base.containers.actions.delete',
              });
            }
          }
        },
      });
  }

  handleAction($event: { key: ActionIdentifier; item?: TranscodeContainer }) {
    const { key, item } = $event;

    switch (key) {
      case 'new':
        this.#new();
        break;
      case 'edit':
        if (!item) {
          return;
        }
        this.#edit(item);
        break;
      case 'delete':
        if (!item) {
          return;
        }
        this.#delete(item);
        break;
    }
  }

  #new() {
    this.dialog
      .open(TranscodeContainerCreateEditDialogComponent, { data: { item: undefined }, disableClose: true })
      .afterClosed()
      .pipe(
        take(1),
        filter(({ status }) => status === DialogResponse.OK),
      )
      .subscribe(() => {
        this.popToast.CREATE();
        this.containersDS.refresh();
      });
  }

  #edit(item: TranscodeContainer) {
    this.dialog
      .open(TranscodeContainerCreateEditDialogComponent, { data: { item } })
      .afterClosed()
      .pipe(
        take(1),
        filter(({ status }) => status === DialogResponse.OK),
      )
      .subscribe(() => {
        this.popToast.EDIT();
        this.containersDS.refresh();
      });
  }

  #delete(item: TranscodeContainer) {
    this.confirmationDialog
      .openDelete({
        title: this.translateService.instant('common.transcode_base.containers.delete.title', {
          container_label: item.label,
        }),
        message: 'common.transcode_base.containers.delete.message',
      })
      .pipe(
        filter(Boolean),
        switchMap(() => this.transcodeService.deleteContainer(item.uuid)),
      )
      .subscribe({
        next: () => {
          this.popToast.DELETE();
          this.containersDS.refresh();
        },
        error: () => {
          this.toastService.error({
            id: 'delete-container',
            message: 'common.transcode_base.containers.delete.failure',
          });
        },
      });
  }
}
