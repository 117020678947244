import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ContentChildren, forwardRef, inject, Input, QueryList } from '@angular/core';
import { castTo, ObjectPathPipe, SharedModule } from '@vdms-hq/shared';
import { UIEmptyResultsModule, UIFormModule } from '@vdms-hq/ui';
import { SelectorsModule, SelectorSourceType, TagsSelectorComponent } from '@vdms-hq/selectors';
import { AudioTracksModule } from '@vdms-hq/audio-tracks';
import { DynamicFormModule } from '../../../dynamic-form.module';
import { FieldRelationsServiceV2 } from '@vdms-hq/dynamic-form';
import { Framerate } from '@vdms-hq/timecode';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ContentSettings } from '../../../form-builder/logic/content.model';
import { AssetType } from '@vdms-hq/api-contract';
import { TranscriptionModule } from '@vdms-hq/transcription';
import { noop } from 'rxjs';
import { cloneDeep } from 'lodash';
import objectPath from 'object-path';
import { AllListComponentConfig, ConfigLessComponent } from '@vdms-hq/firebase-contract';
import { CustomElementDirective } from '../../directives/custom-element.directive';
import { CustomElementPipe, InputDefinitionPipe, IsRelationLoadingPipe, TypeDefinitionPipe } from '../../pipes';
import { EnabledFieldsVo } from '../../logic/enabled-fields.vo';
import { FieldType, InputDefinitionModel } from '@vdms-hq/fields';

@Component({
  selector: 'vdms-hq-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormBuilderComponent),
      multi: true,
    },
  ],
  imports: [
    UIFormModule,
    CommonModule,
    ObjectPathPipe,
    SelectorsModule,
    SharedModule,
    TagsSelectorComponent,
    UIEmptyResultsModule,
    DynamicFormModule,
    TranscriptionModule,
    AudioTracksModule,
    CustomElementPipe,
    InputDefinitionPipe,
    TypeDefinitionPipe,
    IsRelationLoadingPipe,
  ],
})
export class FormBuilderComponent<Model extends Record<string, unknown>> implements ControlValueAccessor {
  @Input() definitions: (InputDefinitionModel | AllListComponentConfig | ConfigLessComponent)[] = [];

  get #inputDefs() {
    return this.definitions.filter(
      (item) => 'input' in item && (item as InputDefinitionModel),
    ) as InputDefinitionModel[];
  }

  @Input() viewMode: 'form' | 'read-only' = 'form';
  @Input() orientation: 'vertical' | 'horizontal' = 'vertical';
  @Input() framerate?: Framerate;
  @Input() columns = 1;
  @Input() withFooter = true;
  @Input() withoutStateToggle = false;
  @Input() content?: Partial<ContentSettings>;
  @Input() withNullValue = true;
  @Input() assetType?: AssetType;
  @Input() enabledFields?: EnabledFieldsVo;

  model?: Model;
  types = FieldType;
  withTouchedIndicator = false;
  #lastEmitValueSerialized = '';
  $sourceListKeyToSourceType = castTo<SelectorSourceType>();
  @ContentChildren(CustomElementDirective) customElements!: QueryList<CustomElementDirective>;

  fieldRelationsService = inject(FieldRelationsServiceV2);
  cdr = inject(ChangeDetectorRef);

  onChange: (value: Model) => void = noop;
  onTouched = noop;

  updateModelData(value: string, definition: InputDefinitionModel): void {
    if (!this.model || !definition.input.objectPath) {
      return;
    }
    objectPath.set(this.model, definition.input.objectPath, value);
    this.#emitValue();
  }

  writeValue(obj: object): void {
    if (obj && typeof obj === 'object' && Object.keys(obj).length > 0) {
      this.model = cloneDeep(obj as Model);
    } else {
      this.model = {} as Model;
    }

    this.onChange(obj as Model);
    this.cdr.detectChanges();
  }

  registerOnChange(fn: (value: Model) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setInputEnabledState(isEnabled: boolean, definitionModel: InputDefinitionModel): void {
    if (!this.enabledFields || !this.model) {
      return;
    }

    if (isEnabled) {
      this.enabledFields.enable(definitionModel.id);
    } else {
      this.enabledFields.disable(definitionModel.id);
    }

    this.#emitValue();
  }

  #emitValue() {
    const nextModel = this.model;
    if (!nextModel) {
      return;
    }

    if (this.enabledFields && !this.withoutStateToggle) {
      // filter model by enabled defs
      const enabledFields = this.enabledFields.enabledList();
      const filteredModel: Model = {} as Model;

      enabledFields.forEach((item) => {
        const inputDef = this.#inputDefs.find((def) => def.id === item);

        if (!inputDef || !inputDef.input.objectPath) {
          return;
        }

        objectPath.set(
          filteredModel,
          inputDef.input.objectPath,
          objectPath.get(nextModel, inputDef.input.objectPath) ?? null,
        );
      });
      this.#emitChangedValue(filteredModel);
    } else {
      //emit entire model
      this.#emitChangedValue(nextModel);
    }
  }

  #emitChangedValue(model: Model) {
    const serialize = JSON.stringify(model);
    if (this.#lastEmitValueSerialized === serialize) {
      return;
    }
    this.#lastEmitValueSerialized = serialize;
    this.onChange(model);
  }
}
