import { inject, Injectable } from '@angular/core';
import { OrderModel, OrderService } from '@vdms-hq/api-contract';
import { BehaviorSubject, EMPTY, Observable, switchMap, combineLatest, distinctUntilChanged } from 'rxjs';
import { catchError, filter, map, shareReplay, take, tap } from 'rxjs/operators';
import { OrderViewModel } from './models';
import { OrderMetadataTransformerService } from '../../logic/order-metadata-transformer';
import { filterEmpty, LoadableDataSource } from '@vdms-hq/shared';
import { UIConfirmationDialogService } from '@vdms-hq/ui';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ActivatedClientService } from '@vdms-hq/activated-client';

@Injectable({ providedIn: 'root' })
export class CurrentOrderService implements LoadableDataSource {
  private orderService = inject(OrderService);
  private metadataTransformerService = inject(OrderMetadataTransformerService);
  private confirmationDialog = inject(UIConfirmationDialogService);
  private router = inject(Router);
  private activatedClientService = inject(ActivatedClientService);

  isLoading$ = new BehaviorSubject<boolean>(true);
  #enabledMetadata: string[] = [];

  #id$ = new BehaviorSubject<string | null | undefined>(null);
  currentId$ = this.#id$.asObservable();
  currentIdDefinite$ = this.#id$.pipe(filterEmpty(), distinctUntilChanged());
  refresh$ = new BehaviorSubject<boolean>(false);

  data$: Observable<OrderViewModel> = combineLatest([
    this.#id$.pipe(filterEmpty(), distinctUntilChanged()),
    this.refresh$,
  ]).pipe(
    switchMap(([id]) => this.orderService.getOrder(id).pipe(catchError((err) => this.#errorHandler(err)))),
    tap(() => this.isLoading$.next(false)),
    map((data) => this.#orderToViewModel(data)),
    shareReplay(1),
  );

  updateOrderId(id: string | null) {
    this.#id$.next(id);
  }

  #orderToViewModel(order: OrderModel): OrderViewModel {
    return {
      ...order,
      ...this.metadataTransformerService.getMetadata(order, this.#enabledMetadata),
    };
  }

  #errorHandler(err: HttpErrorResponse): Observable<never> {
    if ('data' in err.error && err.error?.error?.includes('group')) {
      return this.#popClientChangeDialog(err);
    }
    return EMPTY;
  }

  configure(metadata: string[]) {
    this.#enabledMetadata = metadata;
  }

  #popClientChangeDialog(err: HttpErrorResponse) {
    return this.confirmationDialog
      .open({
        title: 'Please confirm',
        message: 'The resource exists in a different client, would you like to switch there?',
      })
      .pipe(
        take(1),
        map((dialogResponse) => ({
          isConfirmed: dialogResponse,
        })),
        tap(({ isConfirmed }) => {
          if (!isConfirmed) {
            this.router.navigate(['/']);
          }
        }),
        filter(({ isConfirmed }) => isConfirmed),
        tap(() => {
          this.activatedClientService.setActivatedClientId(err.error.data);
          this.refresh$.next(!this.refresh$.value);
        }),
        switchMap(() => EMPTY),
      );
  }
}
