import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  standalone: true,
  selector: '[visibleArea]',
})
export class VisibleAreaDirective<T> implements OnInit, OnDestroy {
  @Input() visibleArea?: boolean;
  private resizeObserver?: ResizeObserver;

  constructor(
    private el: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.resizeObserver = new ResizeObserver((entries) => {
      const htmlElement = entries[0].target as HTMLElement;
      const boundingClientRect = htmlElement?.getBoundingClientRect();
      const pageWrapperPadding = 24;
      const footerRef = this.document.querySelector('vdms-hq-ui-actions-footer');
      const footerPadding = footerRef?.getBoundingClientRect().height ?? 0;
      const minHeight = 500;
      const visibleAreaSize = window.innerHeight - boundingClientRect.top - pageWrapperPadding - footerPadding;

      if (!this.visibleArea) {
        return;
      }

      const canUseVisibleArea = visibleAreaSize > minHeight;

      if (canUseVisibleArea) {
        this.el.nativeElement.style.height = `calc(100vh - ${boundingClientRect.top}px - ${pageWrapperPadding}px - ${footerPadding}px)`;
      } else {
        this.el.nativeElement.style.maxHeight = `calc(100vh - ${pageWrapperPadding}px - ${footerPadding}px)`;
      }
    });

    this.resizeObserver.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver?.unobserve(this.el.nativeElement);
  }
}
