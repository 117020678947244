<ng-container *ngIf="config; else missingData">
  @if (items && items.length > 0) {
    <div class="tile-container">
      <div class="filenames-header">
        <mat-checkbox [formControl]="selectDeselectAllControl" (change)="selectDeselectAll()"></mat-checkbox>
        <div class="filenames-header__title">Filename</div>
        <div class="filenames-header__title">Filename State</div>
      </div>
      <ng-container *ngFor="let item of items">
        <ng-container *ngIf="config | disableIfRow: item; let disabledRowState">
          <div
            class="tile-container__item__wrapper"
            *ngIf="item | objectValueByPath: config.key; let itemValue"
            [class.disabled]="disabledRowState.disabled"
            [matTooltip]="disabledRowState.disabled ? (config.disabledIf?.label(item) ?? '' | translate) : ''"
            [sharedE2eId]="'add-dialog-selectable-tile'"
          >
            <div class="tile-container__item" vdmsHqUiHover>
              <mat-checkbox
                [checked]="selectedItems.includes(item)"
                (change)="emitSelectedChange(item)"
                [disabled]="disabledRowState.disabled"
              ></mat-checkbox>
              <ng-container *ngIf="config.label">
                <mat-icon
                  class="tile-container__item__icon"
                  *ngIf="config.icon"
                  [class.blocked]="disabledRowState.disabled"
                  >{{ disabledRowState.disabled ? 'block' : config.icon }}
                </mat-icon>
                <span
                  *ngIf="config.label && item | objectValueByPath: config.label; let title"
                  class="tile-container__title"
                  #titleContainer
                  [matTooltip]="title"
                  [matTooltipDisabled]="titleContainer.offsetWidth >= titleContainer.scrollWidth"
                  [matTooltipClass]="'selectable-title-tooltip'"
                  >{{ item | objectValueByPath: config.label }}</span
                ></ng-container
              >
              <div class="metadata-wrapper">
                <div class="tile-container__metadata">
                  <ng-container *ngFor="let metadata of config.metadata">
                    <div *ngIf="!(metadata | metadataHidden: item)" [class.w-full]="metadata.fullLine">
                      <span class="tile-container__label" *ngIf="metadata.label"
                        >{{ metadata.label | translate }}:</span
                      >
                      <span class="tile-container__icon"
                        ><mat-icon>{{ $any(item).type | getTypeIcon }}</mat-icon></span
                      >
                      <vdms-hq-ui-value-renderer
                        [value]="item | objectValueByPath: metadata.valuePath"
                        [format]="metadata.viewFormat"
                      ></vdms-hq-ui-value-renderer>
                      <span></span>
                    </div>
                  </ng-container>
                </div>
                <div class="tile-container__suffix">
                  {{ $any(item).original_filename }}
                </div>
                <div class="tile-container__suffix">
                  {{ $any(item).type | getTypeDescriptive }}
                </div>
              </div>
              <div class="tile-container__status">
                @if ($any(item).override_filename_convention.value) {
                  <div class="pill cold-blue">Modified</div>
                } @else {
                  <div class="pill done">Convention applied</div>
                }
              </div>
              <div class="tile-container__actions">
                <ng-container *ngFor="let action of config.actions">
                  <div
                    *ngIf="action && !action.hiddenIf?.(item) && !action.disabledIf?.(item)"
                    class="tile-container__actions action"
                    (click)="emitAction({ key: action.key, item }, $event)"
                    [matTooltip]="action.label | nameTransformer: item | translate"
                  >
                    <mat-icon [class.hover]="itemHover === item">{{ action.icon }}</mat-icon>
                  </div>
                  <div
                    *ngIf="action && !action.hiddenIf?.(item) && action.disabledIf?.(item)"
                    class="tile-container__actions action"
                  >
                    <mat-icon>{{ action.icon }}</mat-icon>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  } @else {
    <vdms-hq-ui-empty-results message="No data for selected filters"></vdms-hq-ui-empty-results>
  }
</ng-container>
<ng-template #missingData>
  <vdms-hq-ui-runtime-error message="Missing configuration"></vdms-hq-ui-runtime-error>
</ng-template>
