import { Component, OnInit, inject } from '@angular/core';
import { ActivatedClientService } from '../../logic/services/activated-client.service';
import { combineLatest, EMPTY, Observable, firstValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ClientKeyLabel, ClientRef } from '../../logic/models/client.model';
import { ClientSwitchViewModel } from '../../logic/models/client-switch.view-model';
import { Router } from '@angular/router';
import { ACTIVATED_CLIENT_CONFIG_TOKEN } from '../../logic/models/activated-client-config.model';
// todo 9567
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UIConfirmationDialogService } from '@vdms-hq/ui';

@Component({
  selector: 'vdms-hq-ac-client-switch',
  templateUrl: './client-switch.component.html',
})
export class ClientSwitchComponent implements OnInit {
  private activatedClientService = inject(ActivatedClientService);
  private router = inject(Router);
  private activatedClientConfig = inject(ACTIVATED_CLIENT_CONFIG_TOKEN);

  public viewModel$: Observable<ClientSwitchViewModel> = EMPTY;
  #allClientsKeyLabels$: Observable<ClientKeyLabel[]> = EMPTY;
  #activatedClientId$: Observable<ClientRef> = EMPTY;

  ngOnInit(): void {
    this.#allClientsKeyLabels$ = this.activatedClientService.authorizedClientsOptions$;
    this.#activatedClientId$ = this.activatedClientService.clientIdDefinite$;
    this.viewModel$ = combineLatest([this.#allClientsKeyLabels$, this.#activatedClientId$]).pipe(
      map(([selectOptions, activatedClientId]: [ClientKeyLabel[], ClientRef]) => ({
        clientsSelectOptions: selectOptions.sort((a, b) => a.label.localeCompare(b.label)),
        selectedClientId: activatedClientId,
      })),
    );
  }

  public async changeClient(id: ClientRef) {
    await this.activatedClientService.reloadAuthState();
    const isAuthorized = await firstValueFrom(this.activatedClientService.isAuthorizedForClient$(id).pipe(take(1)));

    if (!isAuthorized) {
      await this.router.navigateByUrl(`/${this.activatedClientConfig.routing.insufficientPermissionsRoute}`);
      return;
    }

    await this.router.navigateByUrl(`/${this.activatedClientConfig.routing.clientSwitchRoute || '/'}`);
    await firstValueFrom(this.activatedClientService.setActivatedClientId$(id));
  }
}
