@if (model && definitions.length > 0) {
  <div class="fields fields__columns-{{ columns }}">
    @for (definition of definitions; track definition; let index = $index) {
      @if (definition | inputDefinition; as def) {
        <ng-container *ngTemplateOutlet="controlElement; context: { definition: def, index: index }"></ng-container>
      }
      @if (definition | typeDefinition; as def) {
        @if (customElements && customElements.length > 0) {
          @if (def.type | customElement: customElements; as customElement) {
            <ng-container [ngTemplateOutlet]="customElement.templateRef"></ng-container>
          } @else {
            <vdms-hq-ui-empty-results
              icon="warning"
              message="Runtime error: Unknown custom element {{ def.type }}, did you forget to register it?"
            ></vdms-hq-ui-empty-results>
          }
        }
      }
    }
  </div>

  <ng-template #controlElement let-definition="definition" let-index="index">
    @if (definition.input.objectPath) {
      @switch (definition.input.type) {
        @case (types.AUDIO_TRACK_TABLE) {
          <vdms-hq-audio-tracks-table
            [ngModel]="model | objectPath: definition.input.objectPath"
            (ngModelChange)="updateModelData($event, definition)"
            [readonly]="definition.input.readonly || viewMode !== 'form'"
          >
          </vdms-hq-audio-tracks-table>
        }
        @case (types.AUDIO_TRACK) {
          <vdms-hq-audio-tracks-legacy
            [ngModel]="model | objectPath: definition.input.objectPath"
            (ngModelChange)="updateModelData($event, definition)"
            [readonly]="definition.input.readonly || viewMode !== 'form'"
          >
          </vdms-hq-audio-tracks-legacy>
        }
        @case (types.AUDIO_TRACK_TEDIAL) {
          <vdms-hq-audio-tracks-legacy
            [type]="types.AUDIO_TRACK_TEDIAL"
            [ngModel]="model | objectPath: definition.input.objectPath"
            (ngModelChange)="updateModelData($event, definition)"
            [readonly]="definition.input.readonly || viewMode !== 'form'"
          >
          </vdms-hq-audio-tracks-legacy>
        }
        @default {
          @if (viewMode !== 'form') {
            <span [class]="'read-only-' + orientation">
              <span class="label">{{ definition.label | translate }}:</span>
              @if (model | objectPath: definition.input.objectPath | dynamicValueFormat: definition | async) {
                <span class="value value--fallback">
                  {{ model | objectPath: definition.input.objectPath | dynamicValueFormat: definition | async }}
                </span>
              } @else {
                <div class="value value--fallback">N/A</div>
              }
            </span>
          } @else {
            <div class="field-row">
              @if (enabledFields && !withoutStateToggle) {
                <vdms-hq-ui-form-input-toggle
                  class="toggle"
                  [ngModel]="model | objectPath: definition.input.objectPath"
                  (ngModelChange)="setInputEnabledState($event, definition)"
                  [label]="
                    enabledFields.isEnabled(definition.id)
                      ? (content?.enabled ?? 'common.global.enabled')
                      : (content?.disabled ?? 'common.global.disabled')
                  "
                  (blur)="onTouched()"
                ></vdms-hq-ui-form-input-toggle>
              }
              <div class="field">
                @switch (definition.input.type) {
                  @case (types.SELECT) {
                    <vdms-hq-selector
                      [ngModel]="model | objectPath: definition.input.objectPath"
                      (ngModelChange)="updateModelData($event, definition)"
                      [virtualScroll]="true"
                      [label]="definition.label"
                      [disabled]="
                        (withoutStateToggle
                          ? enabledFields?.isEnabled(definition.id)
                          : enabledFields?.isDisabled(definition.id)) ?? false
                      "
                      [nullValue]="withNullValue"
                      [withTouchedIndicator]="withTouchedIndicator"
                      class="material-custom-form"
                      [assetType]="assetType"
                      [filterMethod]="
                        definition.sourceListKey ? fieldRelationsService.filters[definition.sourceListKey] : undefined
                      "
                      [sourceType]="$sourceListKeyToSourceType(definition.sourceListKey)"
                      (blur)="onTouched()"
                      name="{{ definition.sourceListKey }}"
                    ></vdms-hq-selector>
                  }
                  @case (types.SELECT_MULTI) {
                    <vdms-hq-selector
                      [ngModel]="model | objectPath: definition.input.objectPath"
                      (ngModelChange)="updateModelData($event, definition)"
                      [label]="definition.label"
                      [disabled]="
                        (withoutStateToggle
                          ? enabledFields?.isEnabled(definition.id)
                          : enabledFields?.isDisabled(definition.id)) ?? false
                      "
                      [nullValue]="withNullValue"
                      [withTouchedIndicator]="withTouchedIndicator"
                      [multiple]="true"
                      class="material-custom-form"
                      [sourceType]="$sourceListKeyToSourceType(definition.sourceListKey)"
                      (blur)="onTouched()"
                    ></vdms-hq-selector>
                  }
                  @case (types.DATE_TIME_UNIX_TIMESTAMP_LIMITED) {
                    <vdms-hq-ui-form-input-date-time-unix-timestamp-limited
                      [ngModel]="model | objectPath: definition.input.objectPath"
                      (ngModelChange)="updateModelData($event, definition)"
                      [label]="definition.label"
                      [disabled]="
                        (withoutStateToggle
                          ? enabledFields?.isEnabled(definition.id)
                          : enabledFields?.isDisabled(definition.id)) ?? false
                      "
                      [sourceType]="definition.sourceListKey"
                      [withTouchedIndicator]="withTouchedIndicator"
                      (blur)="onTouched()"
                    ></vdms-hq-ui-form-input-date-time-unix-timestamp-limited>
                  }
                  @case (types.DATE) {
                    <vdms-hq-ui-form-input-date
                      [ngModel]="model | objectPath: definition.input.objectPath"
                      (ngModelChange)="updateModelData($event, definition)"
                      [label]="definition.label"
                      [disabled]="
                        (withoutStateToggle
                          ? enabledFields?.isEnabled(definition.id)
                          : enabledFields?.isDisabled(definition.id)) ?? false
                      "
                      [withFooter]="withFooter"
                      [withTouchedIndicator]="withTouchedIndicator"
                      (blur)="onTouched()"
                    ></vdms-hq-ui-form-input-date>
                  }
                  @case (types.TEXTAREA) {
                    <vdms-hq-ui-form-input-text-area
                      [ngModel]="model | objectPath: definition.input.objectPath"
                      (ngModelChange)="updateModelData($event, definition)"
                      [label]="definition.label"
                      [disabled]="
                        (withoutStateToggle
                          ? enabledFields?.isEnabled(definition.id)
                          : enabledFields?.isDisabled(definition.id)) ?? false
                      "
                      [withFooter]="withFooter"
                      [withTouchedIndicator]="withTouchedIndicator"
                      (blur)="onTouched()"
                    ></vdms-hq-ui-form-input-text-area>
                  }
                  @case (types.TIMECODE) {
                    <vdms-hq-form-input-timecode
                      [ngModel]="model | objectPath: definition.input.objectPath"
                      (ngModelChange)="updateModelData($event, definition)"
                      [framerate]="framerate"
                      [label]="definition.label"
                      [disabled]="
                        (withoutStateToggle
                          ? enabledFields?.isEnabled(definition.id)
                          : enabledFields?.isDisabled(definition.id)) ?? false
                      "
                      [withFooter]="withFooter"
                      [withTouchedIndicator]="withTouchedIndicator"
                      (blur)="onTouched()"
                    ></vdms-hq-form-input-timecode>
                  }
                  @case (types.SELECT_GROUP) {
                    <vdms-hq-grouped-selector
                      [ngModel]="model | objectPath: definition.input.objectPath"
                      (ngModelChange)="updateModelData($event, definition)"
                      [label]="definition.label"
                      [disabled]="
                        (withoutStateToggle
                          ? enabledFields?.isEnabled(definition.id)
                          : enabledFields?.isDisabled(definition.id)) ?? false
                      "
                      [withTouchedIndicator]="withTouchedIndicator"
                      (blur)="onTouched()"
                    ></vdms-hq-grouped-selector>
                  }
                  @case (types.CHECKBOX) {
                    <vdms-hq-ui-form-input-checkbox
                      [ngModel]="model | objectPath: definition.input.objectPath"
                      (ngModelChange)="updateModelData($event, definition)"
                      [label]="definition.label"
                      [disabled]="
                        (withoutStateToggle
                          ? enabledFields?.isEnabled(definition.id)
                          : enabledFields?.isDisabled(definition.id)) ?? false
                      "
                      [withFooter]="withFooter"
                      [withTouchedIndicator]="withTouchedIndicator"
                      (blur)="onTouched()"
                    ></vdms-hq-ui-form-input-checkbox>
                  }
                  @case (types.NUMBER) {
                    <vdms-hq-ui-form-input-number
                      [ngModel]="model | objectPath: definition.input.objectPath"
                      (ngModelChange)="updateModelData($event, definition)"
                      [label]="definition.label"
                      [disabled]="
                        (withoutStateToggle
                          ? enabledFields?.isEnabled(definition.id)
                          : enabledFields?.isDisabled(definition.id)) ?? false
                      "
                      [withFooter]="withFooter"
                      [withTouchedIndicator]="withTouchedIndicator"
                      (blur)="onTouched()"
                    ></vdms-hq-ui-form-input-number>
                  }
                  @case (types.TEXT) {
                    <vdms-hq-ui-form-input-text
                      [ngModel]="model | objectPath: definition.input.objectPath"
                      (ngModelChange)="updateModelData($event, definition)"
                      [label]="definition.label"
                      [disabled]="
                        (withoutStateToggle
                          ? enabledFields?.isEnabled(definition.id)
                          : enabledFields?.isDisabled(definition.id)) ?? false
                      "
                      [mask]="definition.input.mask ?? ''"
                      [withFooter]="withFooter"
                      [withTouchedIndicator]="withTouchedIndicator"
                      (blur)="onTouched()"
                      [name]="definition.sourceListKey"
                    ></vdms-hq-ui-form-input-text>
                  }
                  @case (types.FILE_SIZE) {
                    <vdms-hq-ui-form-input-text
                      [ngModel]="model | objectPath: definition.input.objectPath"
                      (ngModelChange)="updateModelData($event, definition)"
                      [label]="definition.label"
                      [disabled]="
                        (withoutStateToggle
                          ? enabledFields?.isEnabled(definition.id)
                          : enabledFields?.isDisabled(definition.id)) ?? false
                      "
                      [mask]="definition.input.mask ?? ''"
                      [withFooter]="withFooter"
                      [withTouchedIndicator]="withTouchedIndicator"
                      (blur)="onTouched()"
                    ></vdms-hq-ui-form-input-text>
                  }
                  @case (types.TAGS) {
                    <vdms-hq-form-tags-selector
                      [ngModel]="model | objectPath: definition.input.objectPath"
                      (ngModelChange)="updateModelData($event, definition)"
                      [label]="definition.label"
                      [disabled]="
                        (withoutStateToggle
                          ? enabledFields?.isEnabled(definition.id)
                          : enabledFields?.isDisabled(definition.id)) ?? false
                      "
                      [withFooter]="withFooter"
                      [withTouchedIndicator]="withTouchedIndicator"
                      (blur)="onTouched()"
                    ></vdms-hq-form-tags-selector>
                  }
                  @case (types.AI_TRANSCODE_SUMMARY) {
                    <vdms-hq-ai-summary
                      [ngModel]="model | objectPath: definition.input.objectPath"
                      (ngModelChange)="updateModelData($event, definition)"
                      [label]="definition.label"
                      [disabled]="
                        (withoutStateToggle
                          ? enabledFields?.isEnabled(definition.id)
                          : enabledFields?.isDisabled(definition.id)) ?? false
                      "
                      [withFooter]="withFooter"
                      [withTouchedIndicator]="withTouchedIndicator"
                      (blur)="onTouched()"
                    ></vdms-hq-ai-summary>
                  }
                  @default {
                    <vdms-hq-ui-empty-results
                      icon="warning"
                      message="Unknown field type - {{ definition.input.type | json }}"
                    ></vdms-hq-ui-empty-results>
                  }
                }
              </div>
              @if (definition | isRelationLoading | async) {
                <vdms-hq-ui-loader [size]="19"></vdms-hq-ui-loader>
              }
            </div>
          }
        }
      }
    }
  </ng-template>
}
