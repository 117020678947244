import { inject, Injectable } from '@angular/core';
import { OrderService, SortOptions } from '@vdms-hq/api-contract';
import { BehaviorSubject, combineLatest, EMPTY, Observable, switchMap, takeWhile, debounceTime } from 'rxjs';
import { catchError, map, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { PageableDataSource, SelectableDataSource, SelectionManager, SortBy, SortDirection } from '@vdms-hq/shared';
import { AssetFiltersForm, OrderAssetViewModel2 } from './models';
import { HttpErrorResponse } from '@angular/common/http';
import { DataProviderService } from '@vdms-hq/selectors';
import { StorageUrlService } from '@vdms-hq/storage';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { TableAdvancedDataSource } from '@vdms-hq/ui';
import { RouterParamsPagination } from '@vdms-hq/view-settings';
import { CurrentOrderService } from './current-order.service';
import { FieldsScopeKey } from '@vdms-hq/fields';

@Injectable({ providedIn: 'root' })
export class CurrentOrderAssetsDataSource
  extends RouterParamsPagination
  implements
    TableAdvancedDataSource<OrderAssetViewModel2>,
    PageableDataSource,
    SelectableDataSource<OrderAssetViewModel2>
{
  static readonly defaultPerPage = 12;
  static readonly defaultPage = 0;

  private orderService: OrderService = inject(OrderService);
  private currentOrderService: CurrentOrderService = inject(CurrentOrderService);
  private dataProvider: DataProviderService = inject(DataProviderService);
  private storageUrlService: StorageUrlService = inject(StorageUrlService);
  private activatedClientService: ActivatedClientService = inject(ActivatedClientService);

  sortBy$ = new BehaviorSubject<SortBy>('');
  sortDirection$ = new BehaviorSubject<SortDirection>('asc');
  filters$ = new BehaviorSubject<Partial<AssetFiltersForm>>({});

  isLoading$ = new BehaviorSubject<boolean>(true);
  refresh$ = new BehaviorSubject<boolean>(false);
  scope: FieldsScopeKey = 'orders';

  allData$ = combineLatest([
    this.currentOrderService.currentId$.pipe(tap(() => this.selection.clear())),
    this.pageIndex$,
    this.pageSize$,
    this.sortBy$,
    this.sortDirection$,
    this.filters$,
    this.refresh$.pipe(tap(() => this.selection.clear())),
  ]).pipe(
    debounceTime(500),
    takeWhile(([, , , sortBy]) => !!sortBy),
    tap(() => this.isLoading$.next(true)),
    switchMap(([id, pageIndex, pageSize, sortBy, direction, filters, refresh]) => {
      if (!id) {
        this.isLoading$.next(false);
        return EMPTY;
      }
      return this.orderService
        .getAssets(
          id,
          {
            page: pageIndex,
            perPage: pageSize,
            orderBy: sortBy,
            orderDir: direction.toUpperCase(),
          },
          {
            'facility-of-origin': filters.facilityOfOrigin,
            type: filters.assetMasterType,
            text: filters.text,
            'tx-date-from': filters.txDate?.from?.toISOString(),
            'tx-date-to': filters.txDate?.to?.toISOString(),
          },
        )
        .pipe(
          shareReplay(1),
          catchError((err: HttpErrorResponse) => this.#errorHandler()),
        );
    }),
    tap((response) => this.total$.next(response.total)),
    withLatestFrom(this.currentOrderService.data$),
    map(([response, currentOrder]) =>
      (response.data ?? []).map((item) =>
        OrderAssetViewModel2.fromOrderItem(item, currentOrder, {
          dataProvider: this.dataProvider,
          storageUrlService: this.storageUrlService,
          activatedClientService: this.activatedClientService,
        }),
      ),
    ),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );

  connection$ = this.allData$;
  total$ = new BehaviorSubject<number>(0);

  selection: SelectionManager<OrderAssetViewModel2> = new SelectionManager(
    this,
    (item) => item.orderUuid ?? item.asset.filename,
  );

  sortChange($event: { active: string; direction: SortOptions['direction'] }) {
    this.sortBy$.next($event.active);
    this.sortDirection$.next($event.direction);
  }

  #errorHandler(): Observable<never> {
    this.isLoading$.next(false);
    return EMPTY;
  }

  applyFilters(filters: AssetFiltersForm) {
    this.filters$.next(filters);
  }
}
