import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DiacriticsConverter } from '@vdms-hq/shared';

@Injectable()
export class HeaderEncodingInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headerInputKeys = ['text', 'filter', 'name-subject'];
    const headers = request.headers.keys().reduce((acc, key) => {
      const value = request.headers.get(key);

      if (headerInputKeys.includes(key.toLowerCase()) && value && /[^\x00-\xFF]/.test(value)) {
        acc = acc.set(key, DiacriticsConverter(value));
      }

      return acc;
    }, request.headers);

    return next.handle(request.clone({ headers }));
  }
}
