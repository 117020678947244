import { inject, Injectable, ViewContainerRef } from '@angular/core';
import { ActiveAssetService } from './active-asset.service';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { AssetQuickPreviewComponent } from '../../components/asset-quick-preview/asset-quick-preview.component';
import { AssetEditFormFooterComponent } from '../../components/asset-edit-form-footer/asset-edit-form-footer.component';
import { DrawerDialogService } from '@vdms-hq/ui';
import { AuthService, UserAttributes } from '@vdms-hq/auth';
import { combineLatest, Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetViewModel } from '../models';
import { FieldRelationsServiceV2 } from '@vdms-hq/dynamic-form';

@Injectable({ providedIn: 'root' })
export class AssetQuickPreviewFormService {
  activeAssetService = inject(ActiveAssetService);
  drawerDialogService = inject(DrawerDialogService);
  fieldRelationsService = inject(FieldRelationsServiceV2);
  authService = inject(AuthService);
  updateSettingsSubscription?: Subscription;
  formGroup = new FormGroup({
    assetData: new FormControl<AssetViewModel>({} as AssetViewModel, {
      nonNullable: true,
      validators: Validators.required,
    }),
  });
  formViewMode: 'form' | 'read-only' = 'form';

  openAsset(
    viewContainerRef: ViewContainerRef,
    assetId: string,
    options: {
      edit: boolean;
    },
  ) {
    this.activeAssetService.unResolve();

    this.authService.userAttributesDefinite$
      .pipe(map((userAttributes: UserAttributes) => userAttributes.vida.preferredDialogDrawerView))
      .pipe(take(1))
      .subscribe((preferredDialogDrawerView) => {
        this.#renderView(viewContainerRef, assetId, options, preferredDialogDrawerView);
      });

    this.#listenToStateChanges();
  }

  #listenToStateChanges() {
    if (this.updateSettingsSubscription) {
      return;
    }

    this.updateSettingsSubscription = this.drawerDialogService.state$
      .pipe(
        switchMap((state) =>
          this.authService.updateAttributes({
            vida: {
              preferredDialogDrawerView: state,
            },
          }),
        ),
      )
      .subscribe();
  }

  #renderView(
    viewContainerRef: ViewContainerRef,
    assetId: string,
    options: {
      edit: boolean;
    },
    preferredDialogDrawerView: 'drawer' | 'dialog',
  ) {
    this.drawerDialogService.open(
      viewContainerRef,
      AssetQuickPreviewComponent,
      options.edit ? AssetEditFormFooterComponent : undefined,
      {
        state: preferredDialogDrawerView,
      },
    );

    // todo custom config for quick preview
    this.activeAssetService.setActiveAsset(assetId, 'launchpad_preview');
    this.formViewMode = options.edit ? 'form' : 'read-only';

    combineLatest([
      this.activeAssetService.currentAssetView$,
      this.activeAssetService.groupedElementsForFirstContainer$,
    ])
      .pipe(
        map(([assetView, groupedElements]) => ({ assetView, groupedElements })),
        takeUntil(this.drawerDialogService.onClose$),
      )
      .subscribe(({ assetView, groupedElements }) => {
        if (assetView) {
          this.formGroup.controls.assetData.patchValue(assetView);
          this.fieldRelationsService.listenToFieldRelations(
            this.formGroup.controls.assetData,
            groupedElements,
            assetView,
          );
        }
      });

    this.drawerDialogService.onClose$.subscribe(() => {
      this.activeAssetService.unResolve();
    });
  }
}
