import { Component, Inject, OnInit } from '@angular/core';
import { LOGIN_PAGE_CONFIG, LoginPageConfig } from '../../logic/config-tokens';

@Component({
  selector: 'vdms-hq-login-logo',
  templateUrl: './login-logo.component.html',
  styleUrls: ['./login-logo.component.scss'],
})
export class LoginLogoComponent implements OnInit {
  maxWidth = 200;

  vendorLogo?: {
    path: string;
    name: string;
  };

  appLogo = {
    path: 'assets/common/logos/logo_vida.png',
    name: 'VIDA',
  };

  constructor(@Inject(LOGIN_PAGE_CONFIG) private loginPageConfig: LoginPageConfig) {}

  ngOnInit() {
    const loginPageConfig = this.loginPageConfig;

    if (!loginPageConfig) {
      console.warn(
        `Login page configuration is missing. Provide 'LOGIN_PAGE_CONFIG' injection token with LoginPageConfig type object`,
      );

      return;
    }

    const hostname = globalThis.location.hostname;
    switch (true) {
      case hostname.includes('bbcmg'):
        this.vendorLogo = {
          path: 'assets/common/logos/bbcmg_getty.svg',
          name: 'BBC MG',
        };
        break;
    }

    switch (loginPageConfig.app) {
      case 'launchpad':
        this.appLogo = {
          path: 'assets/common/logos/launchpad.png',
          name: 'LAUNCHPAD',
        };
        this.maxWidth = 250;
        break;
      case 'workflow':
        this.appLogo = {
          path: 'assets/common/logos/workflow_designer.svg',
          name: 'Workflow Designer',
        };
        break;
      case 'storefront':
        this.appLogo = {
          path: 'assets/common/logos/storefront.svg',
          name: 'STOREFRONT',
        };
        this.maxWidth = 250;
        break;
      case 'connect2':
        this.appLogo = {
          path: 'assets/common/logos/connect2.svg',
          name: 'STOREFRONT',
        };
        this.maxWidth = 250;
        break;
      case 'vida-premiere-panel':
      case 'adobe-extension':
        this.appLogo = {
          path: 'assets/common/logos/logo_vida_basic.svg',
          name: 'VIDA',
        };
        this.maxWidth = 250;
        break;
    }
  }
}
