import { StatsStaticViewModel } from './stats-static.model';
import { StatsChartViewModel, StatsChartViewModelV2 } from './stats-chart-view.model';
import { StatsType } from './stats-type.enum';
import { CssChartBarViewModel } from './css-chart-bar-view.model';
import { StatsCounterViewModel } from '../../stats-counter/stats-counter.component';
import { StatsGridViewModel } from './stats-grid.model';

type mappedViewModelType =
  | StatsStaticViewModel
  | StatsChartViewModel
  | StatsChartViewModelV2
  | CssChartBarViewModel
  | StatsCounterViewModel
  | StatsGridViewModel;

type viewModelType = StatsStaticViewModel | StatsChartViewModel | StatsCounterViewModel | StatsGridViewModel;

export class StatsConfig {
  type: StatsType = StatsType.STATIC;
  title = '';
  icon = '';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  viewModel: viewModelType = {} as mappedViewModelType;

  constructor(type: StatsType, title: string, icon: string, viewModel: mappedViewModelType) {
    this.type = type;
    this.title = title;
    this.icon = icon;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.viewModel = viewModel;
  }
}
