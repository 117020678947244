import { Injectable, TrackByFunction } from '@angular/core';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import {
  LoadableDataSource,
  LocalDataSource,
  PageableDataSource,
  RelationalOption,
  SortableDataSource,
} from '@vdms-hq/shared';
import { Observable, take } from 'rxjs';
import { SelectorsFetcher } from './selectors-fetcher';
import { ViewSettingsService } from '@vdms-hq/view-settings';

@Injectable()
export class SelectorsDataSource
  extends LocalDataSource<RelationalOption>
  implements SortableDataSource, PageableDataSource, DataSource<RelationalOption>, LoadableDataSource
{
  public displayedColumns: string[] = ['uuid', 'type', 'label', 'enabled_in_search', 'enabled_in_forms', 'actions'];
  public isLoading$ = this.selectorsFetcher.loading$;

  public trackByUUID: TrackByFunction<RelationalOption> = (index: number, option: RelationalOption) => option.uuid;

  constructor(
    private readonly selectorsFetcher: SelectorsFetcher,
    private viewSettingsService: ViewSettingsService,
  ) {
    super(selectorsFetcher.fields$);
    this.viewSettingsService.perPageUserSettings$.pipe(take(1)).subscribe((perPage) => {
      this.pageSize$.next(perPage);
    });
  }

  optionsDeleted() {
    this.selectorsFetcher.refresh$.next();
  }

  optionsChanged() {
    this.selectorsFetcher.refresh$.next();
  }

  connect(collectionViewer: CollectionViewer): Observable<RelationalOption[]> {
    return this.connection$;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    super.close();
  }
}
