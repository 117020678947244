import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { AssetSearchService, SortOptions } from '@vdms-hq/api-contract';
import { AssetActionsService, AssetsUpdaterService, StreamAssetsToViewModel } from '@vdms-hq/asset-results';
import { FieldConfigId, FieldsFetcherService, FieldsScopeKey } from '@vdms-hq/fields';
import { DataProviderService } from '@vdms-hq/selectors';
import { PAGE_SIZE_OPTIONS, RefreshService, Selection, SelectionManager, SortableDataSource } from '@vdms-hq/shared';
import { StorageUrlService } from '@vdms-hq/storage';
import { TableAdvancedDataSource } from '@vdms-hq/ui';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { BrowseAssetViewModel } from '@vdms-hq/asset-search';

@Injectable({
  providedIn: 'root',
})
export class SearchResultsDataSource implements TableAdvancedDataSource<BrowseAssetViewModel>, SortableDataSource {
  scope: FieldsScopeKey = 'browse';
  pageIndex$ = new BehaviorSubject<number | null>(null);
  pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(10);
  pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;

  sortBy$ = new BehaviorSubject<FieldConfigId | null>(null);
  sortDirection$ = new BehaviorSubject<SortOptions['direction']>('asc');

  allData$ = combineLatest([this.assetSearchService.data$, this.refreshService.refresh$]).pipe(
    map(([assets]) =>
      assets.map((asset) =>
        BrowseAssetViewModel.fromBrowseAsset(asset, {
          dataProvider: this.dataProvider,
          storageUrlService: this.storageUrlService,
          activatedClientService: this.activatedClientService,
        }),
      ),
    ),
    tap((assets) => {
      this.updaterWSService.enableFilteringOnlyExistingUuids(assets.map((asset) => asset.props.uuid));
    }),
    shareReplay(1),
  );

  connection$ = this.allData$.pipe(
    switchMap((allAssets) => this.updaterWSService.listener$.pipe(StreamAssetsToViewModel(allAssets))),
  );

  total$: Observable<number> = this.assetSearchService.total$;
  isLoading$ = this.assetSearchService.loading$.asObservable();

  emptyResults$ = combineLatest([this.isLoading$, this.allData$]).pipe(
    map(([loading, items]) => items.length === 0 && !loading),
  );

  #destroyed = new Subject<void>();

  selection: Selection<BrowseAssetViewModel>;

  constructor(
    private readonly assetSearchService: AssetSearchService,
    private fieldsFetcherService: FieldsFetcherService,
    @Inject(DOCUMENT) private document: Document,
    private refreshService: RefreshService,
    private assetActions: AssetActionsService,
    private updaterWSService: AssetsUpdaterService,
    private dataProvider: DataProviderService,
    private storageUrlService: StorageUrlService,
    private activatedClientService: ActivatedClientService,
  ) {
    this.assetSearchService.currentParams$.pipe(takeUntil(this.#destroyed)).subscribe((params) => {
      this.pageSize$.next(params.perPage);
      this.pageIndex$.next(params.page);
      const sort = params.sortBy?.results2.sortObjectPath ?? params.sortBy?.id;
      sort && this.sortBy$.next(sort);

      const sortDirection = params.sortDirection;
      sortDirection && this.sortDirection$.next(sortDirection);

      if (params.initial) {
        this.selection?.clear();
      }
    });

    this.selection = new SelectionManager<BrowseAssetViewModel>(this, (item) => item.props.uuid);
  }

  trackBy = (_: number, item: BrowseAssetViewModel) => `${item.props.uuid}_${item?.touchedAt}_${item?.deletedAt}`;

  pageChange($event: PageEvent) {
    const table = this.document.querySelector('vdms-hq-ui-table .table-container');
    const grid = this.document.querySelector('vdms-hq-ui-grid');
    if (table) {
      table.scrollTo(0, 0);
    }
    if (grid) {
      window.scrollTo(0, 0);
    }
    this.pageIndex$.next($event.pageIndex);
    this.pageSize$.next($event.pageSize);
    this.updateResults();
  }

  sortChange($event: { active: string; direction: SortOptions['direction'] }) {
    this.sortBy$.next($event.active);
    this.sortDirection$.next($event.direction);
    this.pageIndex$.next(0);
    this.updateResults();
  }

  updateResults() {
    combineLatest([this.pageIndex$, this.pageSize$, this.sortDirection$, this.sortBy$])
      .pipe(
        withLatestFrom(this.assetSearchService.currentParams$, this.fieldsFetcherService.getConfiguration$(this.scope)),
        take(1),
        tap(([[pageIndex, pageSize, sortDirection, sortBy], prevParams, config]) => {
          const next = prevParams.clone();
          next.setPage(pageIndex ?? 0, pageSize);
          const fieldDefinition = config.table.visible.find(
            (def) => (def.results2.sortObjectPath ?? def.id) === sortBy,
          );
          next.setSort(fieldDefinition, sortDirection);

          this.assetSearchService.applyParams(next);
        }),
      )
      .subscribe();
  }

  destroyWebsocketListener() {
    this.updaterWSService.unregisterListener();
  }
}
