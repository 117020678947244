@if (!closed) {
  <div
    class="messages"
    [class.no-click]="clickAction === InfoBarClickAction.DISABLE"
    (click)="disableClose && clickBar()"
    [matTooltip]="emitMsgTooltip"
    [matTooltipDisabled]="emitMsgTooltip === ''"
  >
    <div class="message" [ngClass]="type">
      <div class="state-indicator">
        <mat-icon class="icon message-icon">{{
          type === InfoBarType.SUCCESS
            ? 'check_circle_outline'
            : type === InfoBarType.INFO
              ? 'info_outline'
              : 'error_outline'
        }}</mat-icon>
      </div>
      @if (message) {
        <div class="message-content" [innerHTML]="message"></div>
      }
      <ng-content></ng-content>
      @if (!disableClose) {
        <mat-icon class="icon" [ngClass]="type" (click)="close()" [sharedE2eId]="'info-bar-close'">close</mat-icon>
      }
    </div>
  </div>
}
