<div [class.readonly]="preventDefault" (click)="preventDefault ? preventDefaultToggle() : false">
  <mat-button-toggle-group [formControl]="innerFormControl" aria-label="Choose between">
    <mat-button-toggle value="bold" [value]="false" [sharedE2eId]="'toggle-button-left'"
      >{{ leftOptionLabel }}
    </mat-button-toggle>
    <mat-button-toggle value="italic" [value]="true" [sharedE2eId]="'toggle-button-right'"
      >{{ rightOptionLabel }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
