import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ImageCacheService {
  private http = inject(HttpClient);
  #cachedImages: Record<string, string> = {};

  getImage(url: string): string | undefined {
    return this.#cachedImages[url];
  }

  cacheImage(url: string, forceOverride = false) {
    if (this.#cachedImages[url] && !forceOverride) {
      return;
    }

    this.http
      .get(url, { responseType: 'blob' })
      .subscribe((response) => (this.#cachedImages[url] = URL.createObjectURL(response)));
  }
}
