import { Injectable } from '@angular/core';
import { NewType, Option, RelationalOption, SelectOption, Type } from '@vdms-hq/shared';
import { firstValueFrom, Observable } from 'rxjs';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { FieldOptionsEndpoints } from './fields-options.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FieldsOptionsService {
  constructor(private readonly apiService: ApiService) {}

  getField(uuid: string): Observable<RelationalOption> {
    return this.apiService
      .get<ApiResponse<RelationalOption>>(`${FieldOptionsEndpoints.ONE}/${uuid}`)
      .pipe(GetResponseData);
  }

  getAllFieldTypesSettings() {
    return this.apiService
      .get<ApiResponse<string[]>>(`${FieldOptionsEndpoints.ALL_TYPES}/${FieldOptionsEndpoints.ALL}`)
      .pipe(GetResponseData);
  }

  postField(field: Partial<Option>): Observable<RelationalOption> {
    return this.apiService.post(`${FieldOptionsEndpoints.ONE}`, field);
  }

  patchField(uuid: string, field: Partial<Option>): Observable<RelationalOption> {
    return this.apiService.patch(`${FieldOptionsEndpoints.ONE}/${uuid}`, field);
  }

  deleteField(uuid: string): Observable<void> {
    return this.apiService.delete(`${FieldOptionsEndpoints.ONE}/${uuid}`);
  }

  getTypes(): Observable<Type[]> {
    return this.apiService.get<ApiResponse<Type[]>>(`${FieldOptionsEndpoints.ALL_TYPES}`).pipe(GetResponseData);
  }

  getTypeByName(name: string): Observable<Type> {
    let headers = new HttpHeaders();
    headers = headers.append('Cache-Control', 'max-age=0');
    return this.apiService
      .get<ApiResponse<Type>>(`${FieldOptionsEndpoints.ONE_TYPE}/${name}`, { headers })
      .pipe(GetResponseData);
  }

  postType(payload: Partial<Type>): Observable<Type[]> {
    return this.apiService.post(`${FieldOptionsEndpoints.ONE_TYPE}`, payload);
  }

  patchType(payload: NewType) {
    return this.apiService.patch(`${FieldOptionsEndpoints.ONE_TYPE}/${payload.name}`, payload);
  }

  deleteType(name: string): Observable<void> {
    return this.apiService.delete(`${FieldOptionsEndpoints.ONE_TYPE}/${name}`);
  }

  getSelectOptions = async (): Promise<SelectOption[]> => {
    const types = (await firstValueFrom(this.getTypes())) || [];

    return types.map((type) => ({ key: type.name, label: type.name }));
  };
}
