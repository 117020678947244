/*
 * Copyright 2024 ByOmakase, LLC (https://byomakase.org)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { AudioMediaTrack, Channel, MasterManifest, VideoMediaTrack } from '../model/domain.model';

export class DomainUtil {
  static resolveFrameRate(masterManifest: MasterManifest, videoMediaTracks: VideoMediaTrack[] | undefined): number {
    if (masterManifest.frame_rate) {
      const parts = masterManifest.frame_rate.split('/');

      if (parts.length === 2) {
        const numerator = parseInt(parts[0]);
        const denominator = parseInt(parts[1]);

        if (isNaN(numerator) || isNaN(denominator) || numerator < 1 || denominator < 0) {
          throw new Error(`Numerator and denominator must be integers larger than 0`);
        }

        return parseFloat((numerator / denominator).toFixed(masterManifest.drop_frame ? 2 : 10));
      } else {
        throw new Error(`Frame rate must be in format: numerator / denominator`);
      }
    } else if (DomainUtil.isAudioOnly(videoMediaTracks)) {
      return 100;
    } else {
      throw new Error('Frame rate cannot be resolved or audio only media is not valid');
    }
  }

  static isAudioOnly(videoMediaTracks: VideoMediaTrack[] | undefined): boolean {
    // assuming we have audio-only manifest
    return !videoMediaTracks || videoMediaTracks.length === 0;
  }

  static framerateToNumeratorDenominator(framerate: string) {
    switch (framerate) {
      case '23.976':
      case '23.98':
        return '24000/1001';
      case '24':
        return '24000/1000';
      case '25':
        return '25000/1000';
      case '29.97':
        return '30000/1001';
      case '30':
        return '30000/1000';
      case '48':
        return '48000/1000';
      case '50':
        return '50000/1000';
      case '59.94':
        return '60000/1001';
      case '59.819':
        return '59819/1000';
      case '60':
        return '60000/1000';
      case '72':
        return '72/1';
      case '90':
        return '90/1';
      case '100':
        return '100/1';
      case '120':
        return '120/1';
      case '144':
        return '144/1';
      case '240':
        return '240/1';
      case '300':
        return '300/1';
      case '480':
        return '480/1';
      case '960':
        return '960/1';
      case '1000':
        return '1000/1';
      default:
        return `${(Number(framerate) * 1000).toFixed()}/1000`;
    }
  }

  static resolveSoundFieldLabel(audioMediaTrack: AudioMediaTrack): string | undefined {
    if (audioMediaTrack.sound_field) {
      let text = audioMediaTrack.sound_field;
      switch (audioMediaTrack.sound_field) {
        case 'mono':
          text = '1.0';
          break;
        case 'stereo':
          text = '2.0';
          break;
        default:
          break;
      }
      return text;
    } else {
      return void 0;
    }
  }

  static resolveTextTrackUsageLabel(textMediaTrack: { usageType?: string }): string | undefined {
    if (textMediaTrack.usageType) {
      switch (textMediaTrack.usageType) {
        case 'subtitles':
          return 'SUB';
        case 'fn_subtitles':
          return 'FN';
        case 'captions':
          return 'CC';
        case 'transcriptions':
          return 'TR';
        default:
          return void 0;
      }
    } else {
      return void 0;
    }
  }

  static resolveAudioMediaTrackChannelsInOrder(audioMediaTrack: AudioMediaTrack): Channel[] | undefined {
    let channelsInOrder: Channel[] | undefined = [];
    if (audioMediaTrack.channels) {
      let channelLayoutOrders = audioMediaTrack.channel_layout ? audioMediaTrack.channel_layout.split(' ') : void 0;
      if (
        channelLayoutOrders &&
        channelLayoutOrders.length > 0 &&
        audioMediaTrack.channels &&
        channelLayoutOrders.length === audioMediaTrack.channels.length
      ) {
        for (const channelLayoutOrder of channelLayoutOrders) {
          if (audioMediaTrack.channels?.findIndex((p) => p.channel_order === channelLayoutOrder) < 0) {
            channelLayoutOrders = void 0;
            break;
          }
        }
      } else {
        channelLayoutOrders = void 0;
      }

      if (channelLayoutOrders) {
        channelsInOrder = channelLayoutOrders.map(
          (channelLayoutOrder) =>
            audioMediaTrack.channels?.find((p) => p.channel_order === channelLayoutOrder) ?? ({} as Channel),
        );
      } else {
        channelsInOrder = audioMediaTrack.channels;
      }
    }

    return channelsInOrder;
  }
}
