import { Observable, Subscribable } from 'rxjs';
import { ValueDisplayFormatConfig } from './value-renderer.model';

export enum ValueFormat {
  DATE = 'date',
  DATETIME = 'date-time',
  TZ_DATE = 'tz-date',
  TZ_DATETIME = 'tz-date-time',
  AS_IS = 'as-is',
  YES_NO = 'yes-no',
  YES_EMPTY = 'yes-empty',
  TICK_CROSS = 'tick-cross',
  TICK_CROSS_WAIT = 'tick-cross-wait',
  APPROVAL_STATUS = 'approval-status',
  FILE_SIZE = 'file-size',
  ELLIPSIS = 'ellipsis',
  UUID = 'uuid',
  TRANSLATE = 'translate',
  HOT_COLD = 'hot-cold',
  COLD = 'cold',
  SELECTOR = 'selector',
  SELECTOR_MULTI = 'selector_multi',
  SELECT_GROUP = 'select_group',
  BITRATE_UNIT = 'bitrate_unit',
  NATURAL_NUMBERS_WITH_ZERO = 'natural_numbers_with_zero',
  UPPERCASE = 'uppercase',
  DATE_TIME_UNIX_TIMESTAMP_LIMITED = 'date_time_unix_timestamp_limited',
  MINIFIED_USER_DETAILS = 'minified_user_details',
  CAMEL_CASE = 'camel_case',
  FRAMERATE = 'FRAMERATE',
  PROCESSING_STATUS = 'processing_status',
  VALUES_OF_OBJECT = 'values_of_object',
  TAGS = 'tags',
}

export declare type OptionalPrimitive = number | string | boolean | null | undefined;
export declare type FormatterOutput =
  | Observable<Array<OptionalPrimitive>>
  | Array<OptionalPrimitive>
  | Observable<OptionalPrimitive>
  | Subscribable<OptionalPrimitive>
  | Promise<OptionalPrimitive>
  | OptionalPrimitive;

export interface ValueFormatter {
  format(value: any, formatType: { sourceListKey?: string; valueFormat: ValueFormat }): FormatterOutput;

  formatClasses(
    value: any,
    definition?: {
      path?: string;
      valueFormat?: ValueFormat;
      viewFormat?: ValueDisplayFormatConfig;
    },
  ): string;
}
