import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@vdms-hq/shared';
import { FieldsFetcherService } from '@vdms-hq/fields';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ClientContentStructure } from '@vdms-hq/firebase-contract';

@Pipe({
  name: 'availableList',
  standalone: true,
})
export class AvailableListPipe implements PipeTransform {
  constructor(private activatedClientConfigService: FieldsFetcherService) {}

  transform(structure: ClientContentStructure): Observable<SelectOption[]> {
    return this.activatedClientConfigService.getConfiguration$('default').pipe(
      take(1),
      map((all) =>
        all.filters
          .getSystemWithAggregationsAsSelectOptions(true)
          .filter((item) => !structure?.fields?.includes(String(item.key))),
      ),
    );
  }
}
