import { Pipe, PipeTransform } from '@angular/core';
import { RendererValue, ValueDisplayFormatConfig } from '@vdms-hq/shared';
import { isDate } from 'moment/moment';
import moment from 'moment';

@Pipe({ name: 'applyPillsClasses', standalone: true })
export class ValuePillApplierPipe implements PipeTransform {
  static getPillClasses(value: RendererValue, config?: ValueDisplayFormatConfig): string[] {
    if (!config?.pills) {
      return [];
    }

    if ((value || value === 0) && config.modifiers?.metadataMissing) {
      return [];
    }

    let valueToFind = String(value).toLowerCase();

    if (config.modifiers?.dateFormat && isDate(value)) {
      const dateToCompare = moment(value).local();
      const now = moment();

      valueToFind = dateToCompare.isSameOrBefore(now) ? 'past' : 'future';
    }

    const matchType = config.pillsMatch ?? 'exact';

    if (matchType === 'include') {
      const entries = Object.entries(config.pills);
      for (const [key, value] of entries) {
        if (valueToFind.toLowerCase().includes(key)) {
          return ['pill', value];
        }
      }
    }

    return ['pill', config.pills[valueToFind] ?? config.pills['default']];
  }

  transform(value: RendererValue, config?: ValueDisplayFormatConfig): string {
    return ValuePillApplierPipe.getPillClasses(value, config).join(' ');
  }
}
