<vdms-hq-ui-results-wrapper
  [dataSource]="typesDS"
  [breadcrumbs]="[{ name: ('common.transcode_base.types.page_title' | translate) }]"
  [configuration]="headerConfig"
  (headerAction)="handleAction($event)"
>
  <vdms-hq-ui-multiple-data-presentation
    [dataSource]="typesDS"
    [configuration]="viewConfig"
    (action)="handleAction($event)"
  >
  </vdms-hq-ui-multiple-data-presentation>
</vdms-hq-ui-results-wrapper>
