import { Permission } from './permission';
import { MixinAbstractConstructor, MixinConstructor } from '@vdms-hq/shared';

export function WithPermissions<T extends MixinConstructor>(Base: T = class {} as T) {
  return class extends Base {
    public readonly Permission: typeof Permission = Permission;
  };
}

export function AbstractWithPermissions<T extends MixinAbstractConstructor>(Base: T = {} as T) {
  abstract class AbstractWithPermissions extends Base {
    public readonly Permission: typeof Permission = Permission;
  }
  return AbstractWithPermissions;
}
