import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FieldDefinitionModel, fieldDefinitions, FieldType, FilterType } from '@vdms-hq/fields';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { enumValueToSelectOption, SelectOption, ValueFormat } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { FieldsDataSource } from '../../logic/fields-data-source';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { SelectorsModule } from '@vdms-hq/selectors';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

interface EditFieldDialogInput {
  item: FieldDefinitionModel;
  autocompleteOptions: SelectOption[];
}

@Component({
  selector: 'vdms-hq-edit-field-dialog',
  templateUrl: './edit-field-dialog.component.html',
  styleUrls: ['./edit-field-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UIFormModule, SelectorsModule, UIDialogWrapperModule, TranslateModule, UIButtonModule, CommonModule],
})
export class EditFieldDialogComponent implements OnInit {
  data = inject<EditFieldDialogInput>(MAT_DIALOG_DATA);
  fieldsDataSource = inject(FieldsDataSource);
  dialogRef: MatDialogRef<EditFieldDialogComponent, void> = inject(MatDialogRef);
  toastService = inject(ToastService);

  availableValueFormats = [{ key: null, label: 'N/A' }, ...Object.values(ValueFormat).map(enumValueToSelectOption)];
  availableTypes: SelectOption[] = [];
  availableFilterTypes: SelectOption[] = [];
  orgDefinition!: FieldDefinitionModel;
  form!: FormGroup;
  loading = false;
  typeSelectOptions: SelectOption[] = this.data?.autocompleteOptions ?? [];
  ngOnInit(): void {
    const org = fieldDefinitions.find((item) => item.id === this.data.item.id);

    if (!org) {
      throw Error('Missing original definition');
    }
    this.orgDefinition = org;

    const orgType = this.orgDefinition.input?.type;
    const changeableInputTypes = [FieldType.TEXT, FieldType.TEXTAREA, FieldType.SELECT, FieldType.NUMBER];
    if (orgType && changeableInputTypes.includes(orgType)) {
      this.availableTypes = changeableInputTypes.map(enumValueToSelectOption);
    }

    const orgFilterType = this.orgDefinition.filters?.type;
    const changeableFilterTypes = [
      FilterType.TEXT_AUTOCOMPLETE,
      FilterType.TEXT,
      FilterType.SELECTOR,
      FilterType.NUMBER,
    ];
    if (orgFilterType && changeableFilterTypes.includes(orgFilterType)) {
      this.availableFilterTypes = [{ key: null, label: 'N/A' }, ...changeableFilterTypes.map(enumValueToSelectOption)];
    }

    const field = this.data.item;
    this.form = new FormGroup({
      label: new UntypedFormControl(field.label, [Validators.required, Validators.minLength(2)]),
      format: new UntypedFormControl(field.format),
      sourceListKey: new UntypedFormControl(field.sourceListKey),
      input: new FormGroup({
        type: new FormControl({
          value: field.input?.type ?? null,
          disabled: !this.availableTypes?.length,
        }),
        readonly: new FormControl({
          value: field.input?.readonly ?? false,
          disabled: this.orgDefinition.input?.readonly === true,
        }),
        mask: new FormControl(field.input?.mask),
        objectPath: new FormControl(field.input?.objectPath),
      }),
      filters: new FormGroup({
        type: new FormControl(field.filters?.type),
      }),
    });
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.loading = true;

    const value = this.form.value;

    this.fieldsDataSource.save(this.data.item.id, value).subscribe(() => {
      this.loading = false;
      this.toastService.success({
        id: 'save',
        message: 'notifications.save.done',
      });
      this.close();
    });
  }
}
