import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { DialogResponse, SelectOption } from '@vdms-hq/shared';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fieldsToSelectOptions } from '../../logic/transformers';
import { Subject } from 'rxjs';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FieldsSaverService } from '../../logic/fields-saver.service';
import { FieldsFetcherService } from '../../logic/fields-fetcher.service';
import { FilterFieldsConfiguration } from '../../logic/fields-scoped-configuration.model';
import { AsyncPipe } from '@angular/common';
import { FieldsScopeKey } from '../../models/field-config.id';

export type FiltersConfigDialogData = { scope: FieldsScopeKey };

@Component({
  selector: 'vdms-hq-fields-filters-config-dialog',
  templateUrl: './filters-config-dialog.component.html',
  styleUrls: ['./filters-config-dialog.component.scss'],
  standalone: true,
  imports: [UIDialogWrapperModule, UIFormModule, UIButtonModule, TranslateModule, AsyncPipe],
})
export class FiltersConfigDialogComponent implements OnInit, OnDestroy {
  formGroup = new FormGroup({
    listSortableCheckboxValue: new FormControl([], Validators.required),
  });
  availableList: SelectOption[] = [];
  alwaysEnabledList = [];
  #destroyed = new Subject<void>();
  isUserConfig = false;

  private fieldsSaverService = inject(FieldsSaverService);

  private columnsConfigDialogRef = inject(MatDialogRef<FiltersConfigDialogComponent>);
  private fieldsFetcher = inject(FieldsFetcherService);
  public data = inject<FiltersConfigDialogData>(MAT_DIALOG_DATA);
  private currentSettings: SelectOption[] = [];
  private defaultList: SelectOption[] = [];
  isSaving$ = this.fieldsSaverService.isSaving$;

  get listSortableCheckboxValues(): AbstractControl | null {
    return this.formGroup.get('listSortableCheckboxValue');
  }

  ngOnInit() {
    this.#initialize();
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  #initialize = () => {
    if (!this.data.scope) {
      console.error('RuntimeError: ScopeName or table config is required');
      return;
    }

    this.fieldsFetcher.getConfiguration$(this.data.scope).subscribe((config) => {
      if (!config.filters) {
        console.error('RuntimeError: Filters configuration does not exist for scope ' + this.data.scope);
        return;
      }

      this.#initConfig(config.filters);
    });
  };

  #initConfig(filters: FilterFieldsConfiguration) {
    this.currentSettings = fieldsToSelectOptions(filters.visible);
    this.defaultList = fieldsToSelectOptions(filters.client.enabled);
    this.availableList = fieldsToSelectOptions(filters.getEnabledWithAvailable());

    this.isUserConfig = filters.user !== null;

    this.listSortableCheckboxValues?.patchValue(this.currentSettings.map((item) => item.key));
  }

  onSubmit() {
    const columnsConfig = this.listSortableCheckboxValues?.value;

    this.fieldsSaverService.saveUserFilterConfig(this.data.scope, columnsConfig).subscribe((results) => {
      results &&
        this.columnsConfigDialogRef.close({
          status: DialogResponse.OK,
          columns: this.listSortableCheckboxValues?.value,
        });
    });
  }

  cancel(): void {
    this.columnsConfigDialogRef.close({ status: DialogResponse.ABORT });
  }

  selectAll() {
    this.listSortableCheckboxValues?.patchValue(this.availableList.map((item) => item.key));
  }

  deselectAll() {
    this.listSortableCheckboxValues?.patchValue([]);
  }

  revertDefaults() {
    this.listSortableCheckboxValues?.patchValue(this.defaultList.map((item) => item.key));
  }
}
