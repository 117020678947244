import { Component, inject, Input } from '@angular/core';
import { AllListComponentConfig, ConfigLessComponent } from '@vdms-hq/firebase-contract';
import { UIFormModule } from '@vdms-hq/ui';
import { AssetQuickPreviewFormService } from '@vdms-hq/asset-details';
import { FormBuilderComponent } from '@vdms-hq/dynamic-form';

import { InputDefinitionModel } from '@vdms-hq/fields';

@Component({
  standalone: true,
  selector: 'vdms-hq-asset-edit-form[groupedElements]',
  templateUrl: './asset-edit-form.component.html',
  imports: [FormBuilderComponent, UIFormModule],
})
export class AssetEditFormComponent {
  @Input() groupedElements: (InputDefinitionModel | ConfigLessComponent | AllListComponentConfig)[] = [];
  @Input() columns = 1;
  assetQuickPreviewFormService = inject(AssetQuickPreviewFormService);
}
